import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import LoadingIndicator from './loadingIndicator';

type PrivateRouteProps = Omit<RouteProps, 'component'>;

const RouteWithAuthenticationRequired = withAuthenticationRequired(Route);

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  ...routeProps
}) => {
  const { isLoading } = useAuth0();

  return (
    <>
      <LoadingIndicator show={isLoading} />
      <RouteWithAuthenticationRequired {...routeProps}>
        {children}
      </RouteWithAuthenticationRequired>
    </>
  );
};

export default PrivateRoute;
