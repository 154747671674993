import { combineReducers, Slice } from '@reduxjs/toolkit';
import { Action, AnyAction } from 'redux';

import intelligenceReducer from '../areas/intelligence/state/reducer';
import mainReducer from '../areas/main/state/reducer';
import assetsReducer from '../areas/assets/state/reducer';
import testSliceReducerFactory from '../areas/test/state/sliceReducerFactory';
import { Middleware } from '../types/middleware';

export const staticReducers = {
  intelligence: intelligenceReducer, // lol
  main: mainReducer,
  assets: assetsReducer,
};

/* eslint-disable @typescript-eslint/no-explicit-any */
type DynamicReducer<T extends (...args: never) => Slice<any, any, string>> =
  ReturnType<ReturnType<T>['reducer']>;

// Anything added using addSlice should be typed here as DynamicReducer<typeof myReducerFactory>
// Also, be sure to type as optional (?:) since if addSlice hasn't been called yet, the reducer will be undefined
interface DynamicReducers {
  test?: DynamicReducer<typeof testSliceReducerFactory>;
}

const reducer = combineReducers(staticReducers);

export type State = ReturnType<typeof reducer> & DynamicReducers;
export type StateMiddleware<E extends AnyAction = Action> = Middleware<
  State,
  E
>;

export default reducer;
