import { v4 as uuidv4 } from 'uuid';
import { DisplayData } from '../areas/main/types/json';

let nextIncrementingId: Record<string, number> = {};
export const getIncrementingId = (key: string): number => {
  nextIncrementingId = {
    ...nextIncrementingId,
    [key]: (nextIncrementingId[key] ?? 0) + 1,
  };
  return nextIncrementingId[key];
};
export const getAllIncrementingIds = () => nextIncrementingId;
export const resetIncrementingIds = () => (nextIncrementingId = {});
export const restoreIncrementingIdsFromDisplayData = (
  displayData: DisplayData
) => (nextIncrementingId = displayData.incrementingIds);

// Using version 4 random number GUIDs http://guid.one/guid
export const generateGuid = (): string => {
  return uuidv4();
};

export const emptyGuid = '00000000-0000-0000-0000-000000000000';
