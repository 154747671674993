// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1Hfd-ibQXfOfl_jxyrS_RQ {\n  display: inline-flex;\n  flex-direction: column;\n  overflow: hidden;\n  min-height: 10px;\n}\n\n._5qbc0PoFrqw7zjSJL5hMO {\n  background: var(--v-ui-color-field-background);\n  border-color: var(--v-ui-color-field);\n  border-radius: var(--v-ui-border-radius-field);\n  border-style: solid;\n  border-width: var(--v-ui-border-width-field);\n  caret-color: var(--v-ui-color-field-caret);\n  color: inherit;\n  font-size: 1.6rem;\n  padding: var(--v-ui-padding-field);\n}\n\n._1CSu0NoG6QEZOl7ibN8Vr2 {\n  overflow: hidden;\n  resize: none;\n}\n\n._5qbc0PoFrqw7zjSJL5hMO:disabled {\n  border-color: var(--v-ui-color-field-disabled);\n  color: var(--v-ui-color-field-disabled-text);\n  cursor: not-allowed;\n  filter: var(--v-ui-filter-disabled);\n}\n\n._5qbc0PoFrqw7zjSJL5hMO:not(:disabled):focus {\n  border-color: var(--v-ui-color-field-focus);\n  outline: none;\n}\n\n._5qbc0PoFrqw7zjSJL5hMO:not(:disabled):hover {\n  filter: var(--v-ui-filter-hover);\n}\n\n._2ZlvjgSVvcKXoSvL6-qrgZ {\n  border-color: var(--v-ui-color-invalid);\n}\n\n.Ic6qwpYeT0E3U6qeLpm8i {\n  resize: none;\n}\n\n._1ssmWHmLUJRgl2xfGnfvNa {\n  font-size: 1.3rem;\n  margin-top: 8px;\n  color: var(--v-ui-color-invalid);\n}\n", ""]);
// Exports
exports.locals = {
	"textArea": "_1Hfd-ibQXfOfl_jxyrS_RQ",
	"textArea_input": "_5qbc0PoFrqw7zjSJL5hMO",
	"textArea_input__autoExpand": "_1CSu0NoG6QEZOl7ibN8Vr2",
	"textArea_input__invalid": "_2ZlvjgSVvcKXoSvL6-qrgZ",
	"disable_resize": "Ic6qwpYeT0E3U6qeLpm8i",
	"error": "_1ssmWHmLUJRgl2xfGnfvNa"
};
module.exports = exports;
