import classNames from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';
import { ReactFlowProvider } from 'react-flow-renderer';
import { Provider } from 'react-redux';

import { Auth0Provider } from '@auth0/auth0-react';
import { themes, useGlobalTheme } from '@strivr/ui';

import ErrorBoundary from './components/errorBoundary';
import { IsMobileContext } from './hooks/context/useIsMobile';
import { IsAxiosReadyContext } from './hooks/context/useIsAxiosReady';
import useInitAxios from './hooks/init/useInitAxios';
import useInitDatadog from './hooks/init/useInitDatadog';
import useInitErrorLog from './hooks/init/useInitErrorLog';
import useInitIsMobile from './hooks/init/useInitIsMobile';
import useInitMixpanel from './hooks/init/useInitMixpanel';
import useDispatch from './hooks/useDispatch';
import store from './state/store';
import Router from './router';
import './wdyr';

import styles from './app.module.css';
import './global.css';
import ClientToggleProvider from './providers/clientToggleProvider';

import { onAuth0Redirect } from './utils/onAuth0Redirect';

window.logContext = {};

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .register('/sw.js')
      .then((registration) => {
        // eslint-disable-next-line no-console
        console.log('SW registered: ', registration);
      })
      .catch((registrationError) => {
        // eslint-disable-next-line no-console
        console.log('SW registration failed: ', registrationError);
      });
  });
}

export const App: React.FC = () => {
  useGlobalTheme(themes.reynisfjara);

  useInitDatadog();
  useInitErrorLog();
  useInitMixpanel();

  const isMobile = useInitIsMobile();
  const isAxiosReady = useInitAxios();

  return (
    <IsAxiosReadyContext.Provider value={isAxiosReady}>
      <IsMobileContext.Provider value={isMobile}>
        <ReactFlowProvider>
          <div className={classNames(styles.app)}>
            <ErrorBoundary>
              <ClientToggleProvider>
                <Router />
              </ClientToggleProvider>
            </ErrorBoundary>
          </div>
        </ReactFlowProvider>
      </IsMobileContext.Provider>
    </IsAxiosReadyContext.Provider>
  );
};

export const AppWithAuth0 = () => {
  const dispatch = useDispatch();

  return (
    <Auth0Provider
      audience={process.env.REACT_APP_AUTH0_AUDIENCE as string}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
      domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
      redirectUri={`${window.location.origin}${window.location.pathname}`}
      onRedirectCallback={onAuth0Redirect(dispatch)}
    >
      <App />
    </Auth0Provider>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <AppWithAuth0 />
  </Provider>,
  document.getElementById('app'),
);

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').catch(() => {
      // eslint-disable-next-line no-console
      console.log('Service worker not found. Skipping initialization.');
    });
  });
}
