import { useEffect } from 'react';
import sliceReducer from '../../areas/main/state/preview/sliceReducer';
import { UnityPreviewEvents } from '../../areas/main/state/preview/unityMessages/utils';
import { unityContext } from '../../areas/main/views/preview';
import useDispatch from '../useDispatch';

const useDeletedContainerMessageFromPlayer = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    unityContext.on(UnityPreviewEvents.OnOperationContainerDestroyed, () => {
      dispatch(
        sliceReducer.actions.update({
          shouldRemovePresentContainer: false,
        })
      );
    });
  }, [dispatch]);
};

export default useDeletedContainerMessageFromPlayer;
