// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3WSlajY9VQosaGZbEYRK3C {\n    align-items: center;\n    background: var(--v-ui-color-shield);\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    justify-content: center;\n    left: 0;\n    opacity: 0;\n    pointer-events: none;\n    position: absolute;\n    top: 0;\n    transition: opacity 0.3s ease;\n    width: 100%;\n    z-index: 5;\n}\n\n._33TVyl3Enlcz1FCjBa9VjO {\n    opacity: 1;\n    pointer-events: visible;\n}\n\n.mHxKQpVMJ68AMtsWegIeY {\n    position: relative;\n}\n\n.LRA9W51mcJlliMjXf9ZC9 {\n    transition: filter 0.3s linear;\n}\n\n._3XJBLr672GN4MWCZOeikkM {\n    filter: var(--v-ui-filter-shield)\n}\n\n", ""]);
// Exports
exports.locals = {
	"container": "_3WSlajY9VQosaGZbEYRK3C",
	"container__showing": "_33TVyl3Enlcz1FCjBa9VjO",
	"content": "mHxKQpVMJ68AMtsWegIeY",
	"coveredRoot": "LRA9W51mcJlliMjXf9ZC9",
	"coveredRoot__showing": "_3XJBLr672GN4MWCZOeikkM"
};
module.exports = exports;
