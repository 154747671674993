import { classNames } from '../utils/helpers';
import React from 'react';

import Portal from './portal';
import Shield from './shield';

import styles from './popupMenu.module.css';

export type Position = { x: number; y: number };

export interface PopupMenuItem {
  disabled?: boolean;
  onClick: () => void;
  rightText?: string;
  text: string;
}

export interface PopupMenuItemGroup {
  items: PopupMenuItem[];
}

export interface PopupMenuProps {
  itemGroups: PopupMenuItemGroup[];
  onClose?: () => void;
  openPos: Position | null;
  shieldRef?: React.ComponentProps<typeof Shield>['ref'];
}

const PopupMenu: React.FC<PopupMenuProps> = ({
  itemGroups,
  onClose,
  openPos,
  shieldRef,
}) => {
  const handleItemClick = (item: PopupMenuItem) => {
    if (item.disabled) return;
    item.onClick();
    onClose?.();
  };

  // Note that Shield inside Portal means a second right-click while menu is open will ignore the target area bounds
  // Portal inside Shield would fix this, but then a left-click outside the target area would not close the menu
  return (
    <Portal isHidden={openPos === null}>
      <Shield
        className={styles.shield}
        onClick={onClose}
        onEsc={onClose}
        ref={shieldRef}
        show={openPos !== null}
      >
        <div
          className={styles.popupMenu}
          role="menu"
          style={{ left: openPos?.x, top: openPos?.y }}
        >
          {itemGroups.map((itemGroup, itemGroupIdx) => (
            <ul className={styles.itemGroup} key={itemGroupIdx}>
              {itemGroup.items.map((item, itemIdx) => (
                <li
                  className={styles.item}
                  key={itemIdx}
                  onClick={() => handleItemClick(item)}
                  onKeyDown={undefined}
                  role="menuitem"
                  aria-disabled={item.disabled}
                >
                  <span
                    className={classNames(styles.textContainer, {
                      [styles.textContainer__disabled]: item.disabled,
                    })}
                  >
                    <span className={styles.leftText}>{item.text}</span>
                    <span className={styles.rightText}>{item.rightText}</span>
                  </span>
                </li>
              ))}
            </ul>
          ))}
        </div>
      </Shield>
    </Portal>
  );
};

/**
 * This is intended for popup menus driven by mouse interaction, such as context menu and follow-on.
 * Note that it is therefore not keyboard-accessible.
 */
export default PopupMenu;
