import { Operation } from '../../areas/main/types/operations';
import useSelector from '../useSelector';

const useCurrentOperation = () => {
  const operations = useSelector(
    (state) => state.main.operationContainers.operations
  );
  const currSelected = useSelector(
    (state) => state.main.nodes.elementsSelected
  );
  const lastSelected = useSelector(
    (state) => state.main.nodes.mostRecentlyTouchedOperationIds
  );
  let currOperationId = lastSelected[0];
  if (currSelected[0]) {
    currOperationId = currSelected[0].id;
  }

  //Check the currently selected stack op if we have to.
  const stackItem = useSelector((state) => state.main.nodes.stackItemSelected);
  if (stackItem?.id) {
    currOperationId = stackItem.id;
  }
  const currOperation = (Object.values(operations) as Array<Operation>).find(
    (e) => e.id === currOperationId
  );

  return currOperation;
};

export default useCurrentOperation;
