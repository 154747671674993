import React from 'react';

import { classNames } from '../utils/helpers';

import styles from './progressBar.module.css';

export interface ProgressBarProps {
  className?: string;
  total: number;
  current: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  className,
  total,
  current,
}) => {
  const percent = total === 0 || current < 0 ? 0 : (100 * current) / total;
  return (
    //I couldn't get the inner div to align properly with the outer div, so I figured that removing the
    //left most border would look better. It does, but when there's no progress, we need to use a style
    //that has a left border.
    <div className={classNames(styles.progressSection, className)}>
      {percent === 0 && <div className={styles.progressBar} />}
      {percent !== 0 && (
        <div
          className={classNames(
            styles.progressBar,
            styles.progressBar__noLeftBorder
          )}
        >
          <div
            className={styles.progressBar_filledPortion}
            style={{ width: `${percent}%` }}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(ProgressBar);
