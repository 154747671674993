import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EnvironmentProperties, SceneProperties } from '../../types/models';
import { withAutosave, withUndo } from '../../../../utils/middleware';

// Enforce typing
export interface SceneState {
  scenes: Record<string, SceneProperties>;
  copiedScenes: Record<string, SceneProperties>;
}
export const getInitialSceneState = (): SceneState => {
  return {
    scenes: {},
    copiedScenes: {},
  };
};

export const initialState: SceneState = getInitialSceneState();

type UpdateScene = {
  id: string;
  sceneProperties: Omit<Partial<SceneProperties>, 'id'>;
};
type UpdateEnvironment = {
  sceneId: string;
  envId: string;
  environmentProperties: Omit<Partial<EnvironmentProperties>, 'id'>;
};
export type State = typeof initialState;
type Update = Partial<State>;

const sliceReducer = createSlice({
  name: 'main/sceneProperties',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Update>) => ({
      ...state,
      ...action.payload,
    }),
    updateScene: (state, action: PayloadAction<UpdateScene>) => {
      const sceneOfInterest = state.scenes[action.payload.id];
      if (!sceneOfInterest) return;

      const modifiedSceneOfInterest = {
        ...sceneOfInterest,
        ...action.payload.sceneProperties,
      };

      return {
        ...state,
        scenes: {
          ...state.scenes,
          [modifiedSceneOfInterest.id]: modifiedSceneOfInterest,
        },
      };
    },
    updateEnvironment: (state, action: PayloadAction<UpdateEnvironment>) => {
      const sceneOfInterest = state.scenes[action.payload.sceneId];
      if (!sceneOfInterest) return;

      const envOfInterest = sceneOfInterest.environments.find(
        (env) => env.id === action.payload.envId,
      );
      if (!envOfInterest) return;

      const modifiedSceneOfInterest = {
        ...sceneOfInterest,
        environments: [
          ...sceneOfInterest.environments.filter(
            (env) => env.id !== action.payload.envId,
          ),
          {
            ...envOfInterest,
            ...action.payload.environmentProperties,
          },
        ],
      };

      return {
        ...state,
        scenes: {
          ...state.scenes,
          [modifiedSceneOfInterest.id]: modifiedSceneOfInterest,
        },
      };
    },
  },
});

export default withAutosave(withUndo(sliceReducer));
