export const deleteRecordByKey = (
  keyToDelete: string,
  records: Record<string, string>
): Record<string, string> => {
  const filtered: Record<string, string> = {};
  for (const key in records) {
    if (key !== keyToDelete) {
      filtered[key] = records[key];
    }
  }
  return filtered;
};
