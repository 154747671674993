import assets from './assets/sliceSaga';
import currentExperience from './experience/sliceSaga';
import nodes from './nodes/sliceSaga';
import sceneProperties from './sceneProperties/sliceSaga';
import tenants from './tenants/sliceSaga';
import operations from './operationContainers/sliceSaga';
import ui from './ui/sliceSaga';
import preview from './preview/sliceSaga';
import undo from './undo/sliceSaga';

import { all } from 'typed-redux-saga';

export default function* saga() {
  const sagas = [
    assets.saga(),
    currentExperience.saga(),
    nodes.saga(),
    sceneProperties.saga(),
    operations.saga(),
    tenants.saga(),
    ui.saga(),
    preview.saga(),
    undo.saga(),
  ];

  yield* all(sagas);
}
