import { NodeData, StackItem } from '../../types/nodeData';
import { State } from '../../../../areas/main/state/nodes/sliceReducer';
import { SignedUrlMetadata } from './sliceSaga';
import {
  AssetTypes,
  ObjectMetadata,
  SceneProperties,
} from '../../types/models';
import { filterNodes } from '../../../../utils/node';
import { Elements } from 'react-flow-renderer/dist/types';
import { OperationContainerData } from '@strivr/player-models';
import { Operation, PlaybackPhaseOperation } from '../../types/operations';
import { OperationType } from '../../enums/models';

export const isSceneSelected = (elementsSelected: Elements<NodeData>) => {
  return elementsSelected.length > 0 && elementsSelected[0]?.type === 'scene';
};

export const getCurrentContainerId = (presentElements: State) => {
  let containerId = '';
  const currSelectedElementLength = presentElements.elementsSelected.length;
  const firstSelectedElement = presentElements.elementsSelected[0];

  const firstSelectedElementNodeType = firstSelectedElement?.type;
  const isStackSelected =
    currSelectedElementLength === 1 && firstSelectedElementNodeType === 'stack';
  const isStackItemSelected = presentElements.stackItemSelected;
  const noSelectedElements =
    !isStackItemSelected && !isStackSelected && currSelectedElementLength === 0;
  const multipleNodesSelected = currSelectedElementLength > 1;
  const sceneSelected = isSceneSelected(presentElements.elementsSelected); // all cases where you may have selected more than one scene
  const notPlayableNodeTypes = ['playback phase', 'playback'];
  const isNotPlayableNodeType = Boolean(
    firstSelectedElementNodeType &&
      notPlayableNodeTypes.includes(firstSelectedElementNodeType)
  );
  const isEdge =
    currSelectedElementLength > 0 &&
    firstSelectedElementNodeType === 'customEdge';

  // Do not send over OperationContainerData to load in any of these cases
  if (
    noSelectedElements ||
    isEdge ||
    multipleNodesSelected ||
    sceneSelected ||
    isNotPlayableNodeType
  ) {
    return containerId;
  }

  // Special rule for finding a container ID for selecting individual nodes in a stack
  if (isStackItemSelected) {
    containerId = presentElements.stackSelectedId;
    return containerId;
  }

  // If you've selected a container stack...
  if (isStackSelected) {
    containerId = firstSelectedElement.id;
    return containerId;
  }

  // Otherwise an individual node is selected
  containerId = (<StackItem>(<unknown>firstSelectedElement))?.data?.containerId;

  return containerId || '';
};

export const getCurrentlySelectedSceneId = (
  currSelected: Elements<NodeData>,
  scenes: Record<string, SceneProperties>
) => {
  const sceneIds = Object.keys(scenes) as Array<string>;
  const nodes = filterNodes(currSelected);
  const isOneSceneSelected =
    currSelected.length === 1 && currSelected[0].type === 'scene';
  const isOneContainerSelected =
    currSelected.length === 1 && nodes.length === 1;
  let currSceneId = '';

  if (isOneSceneSelected) {
    currSceneId = currSelected[0].id;
  } else if (isOneContainerSelected) {
    const parentId = nodes[0].parentId ?? '';
    currSceneId = sceneIds.includes(parentId) ? parentId : '';
  }

  return currSceneId;
};

export const shouldChangeToPlaybackPerspective = (
  container: OperationContainerData.OperationContainerData,
  allOperations: Record<string, Operation>
) => {
  if (container?.operations === null || container?.operations === undefined) {
    return false;
  }

  const isOneOperation = container.operations.length === 1;
  const operation = container.operations[0];
  const isPlaybackCompatibleOperation =
    operation.type === OperationType.MultipleChoiceQuestion ||
    operation.type === OperationType.NotificationDialog2;
  const isOperationIncludedInAPlaybackPhase = () =>
    Object.values(allOperations).some(
      (op) =>
        op.type === OperationType.PlaybackPhase &&
        (op as PlaybackPhaseOperation).flipCamera &&
        (op as PlaybackPhaseOperation).containersToPlayback.includes(
          container.id ?? ''
        )
    );

  return (
    isOneOperation &&
    isPlaybackCompatibleOperation &&
    isOperationIncludedInAPlaybackPhase()
  );
};

export interface SignedUrlAssetData {
  signedUrls: SignedUrlMetadata[] | null;
  assetListsByType: Record<AssetTypes, ObjectMetadata[]>;
}

export const getSignedUrlFromData = (
  id: string,
  data: SignedUrlAssetData | undefined,
  assetType: AssetTypes
): string => {
  let url = '';
  if (id && data) {
    const { signedUrls, assetListsByType } = data;

    let asset = null;
    if (assetType.includes('assetbundle')) {
      asset = assetListsByType[assetType].find(
        (a) => a.versionId === id && a.variant.os_platform === 'webgl'
      );
    } else {
      asset = assetListsByType[assetType].find((a) => a.versionId === id);
    }
    const versionVariantId = asset?.versionVariantId;

    const signedUrl = signedUrls?.find(
      (signedUrlEntry) => signedUrlEntry.versionVariantId === versionVariantId
    )?.signedUrl;
    url = signedUrl || '';
  }

  return url;
};
