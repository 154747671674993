import { StateMiddleware } from '../reducer';
import experiencesSaga from '../../areas/main/state/experience/sliceSaga';
import uiReducer from '../../areas/main/state/ui/sliceReducer';
import { getAutosaveActions } from '../../utils/middleware';

const autosaveDebounceDelayMs = 3000;
let autosaveTimeoutId: number;

const autosave: StateMiddleware = (store) => (next) => (action) => {
  const result = next(action);
  const state = store.getState();

  if (!state.main.ui.isAutosaveEnabled) return result;
  if (
    getAutosaveActions().includes(action.type) &&
    state.main.tenants.selectedTenant
  ) {
    if (autosaveTimeoutId) window.clearTimeout(autosaveTimeoutId);

    if (!state.main.ui.isDirty) {
      store.dispatch(
        uiReducer.actions.update({
          ...state.main.ui,
          isDirty: true,
        })
      );
    }
    autosaveTimeoutId = window.setTimeout(() => {
      store.dispatch(experiencesSaga.actions.saveExperience({}));
    }, autosaveDebounceDelayMs);
  }
  return result;
};

export default autosave;
