import {
  TypedUseSelectorHook,
  useSelector as useUntypedSelector, // eslint-disable-line no-restricted-imports
} from 'react-redux';

import { State } from '../state/reducer';

const useSelector: TypedUseSelectorHook<State> = useUntypedSelector;

export default useSelector;
