import useSelector from '../useSelector';

const useCurrentScene = () => {
  const scenes = useSelector((state) => state.main.sceneProperties.scenes);
  const currSelected = useSelector(
    (state) => state.main.nodes.elementsSelected
  );
  // Kind of a hack, but if there is any stack node selected then we don't want to return anything.
  const currStack = useSelector((state) => state.main.nodes.stackItemSelected);
  if (currStack?.id) {
    return undefined;
  }
  let currSceneId = '';
  if (currSelected[0]) {
    currSceneId = currSelected[0].id;
  }

  const currScene = scenes[currSceneId];

  return currScene;
};

export default useCurrentScene;
