import { useEffect } from 'react';
import sliceReducer from '../../areas/main/state/preview/sliceReducer';
import { UnityPreviewEvents } from '../../areas/main/state/preview/unityMessages/utils';
import { unityContext } from '../../areas/main/views/preview';
import useDispatch from '../useDispatch';

const useLoadedContainerMessageFromPlayer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    unityContext.on(
      UnityPreviewEvents.OnOperationContainerLoaded,
      (id: string) => {
        dispatch(sliceReducer.actions.update({ lastLoadedContainerId: id }));
      }
    );
  }, [dispatch]);
};

export default useLoadedContainerMessageFromPlayer;
