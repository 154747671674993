import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState = {
  isAutosaveEnabled: false,
  autosaveDisableLock: false,
  isDirty: false,
  isNew: true,
  isRenamingExperience: false,
  isRetry: false,
  lastSave: {
    savedAt: new Date().toString(),
    hash: 0,
  },
  isFirstOpen: true,
  isUserActive: true,
  isMissingAssetsResolved: true,
};

type Update = Partial<typeof initialState>;

const sliceReducer = createSlice({
  name: 'main/ui',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Update>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export default sliceReducer;
