import { useEffect } from 'react';
import { unityContext } from '../../areas/main/views/preview';
import { UnityPreviewEvents } from '../../areas/main/state/preview/unityMessages/utils';
import useDispatch from '../useDispatch';
import sliceSaga from '../../areas/main/state/preview/sliceSaga';

const useExperienceFinishedMessageFromPlayer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    unityContext.on(UnityPreviewEvents.OnExperienceFinished, () => {
      dispatch(sliceSaga.actions.endPreviewExperience());
    });
  }, [dispatch]);
};

export default useExperienceFinishedMessageFromPlayer;
