import { useEffect } from 'react';

import useThrottleWithDebounce from './useThrottleWithDebounce';

const useGlobalResizeHandler = (callback: () => void, delayMs = 100): void => {
  const handleResize = useThrottleWithDebounce(callback, delayMs);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    return () => {
      window.removeEventListener('orientationchange', handleResize);
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);
};

export default useGlobalResizeHandler;
