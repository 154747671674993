// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1xYClGgk-A2Rpw9Op-TSjx {\n    background: var(--v-ui-color-field-background);\n    border-radius: 8px;\n    box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.2);\n    font-size: 1.4rem;\n    max-height: 45rem;\n    overflow-y: auto;\n    padding: 4px;\n    position: fixed;\n    z-index: 10000;\n}\n\n._1jZXEBELQcVcwLlI1NeDEN {\n    background: unset;\n    transition: none;\n}\n\n._1I5LG3BNRvbkZCd59p9j0U {\n    list-style-position: inside;\n    list-style-type: none;\n    margin: 0;\n    padding: 4px 0;\n}\n\n._1I5LG3BNRvbkZCd59p9j0U:not(:last-of-type) {\n    border-bottom: 1px solid var(--v-ui-color-alternate-contrast);\n}\n\n._2inYHToZF7uHRF5bpcgL5o {\n    align-items: center;\n    border-radius: 4px;\n    display: flex;\n    height: 3.2rem;\n    min-width: 20rem;\n}\n\n._2inYHToZF7uHRF5bpcgL5o:hover {\n    background: #375088;\n    cursor: pointer;\n}\n\n._3MWtrYVFsMOC1Dx-l3F69e {\n    color: var(--v-ui-color-list-item-contrast);\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n}\n\n._15AJGr7k2BgIQ47vZ1WhBr {\n    color: var(--v-ui-color-list-item-contrast-disabled);\n}\n\n._2GAatds1BJBzjUPZdSOnls {\n    margin-left: 8px;\n}\n\n._39rh3MOMkjWSuLa9lVOfzX {\n    margin-right: 8px;\n}\n", ""]);
// Exports
exports.locals = {
	"popupMenu": "_1xYClGgk-A2Rpw9Op-TSjx",
	"shield": "_1jZXEBELQcVcwLlI1NeDEN",
	"itemGroup": "_1I5LG3BNRvbkZCd59p9j0U",
	"item": "_2inYHToZF7uHRF5bpcgL5o",
	"textContainer": "_3MWtrYVFsMOC1Dx-l3F69e",
	"textContainer__disabled": "_15AJGr7k2BgIQ47vZ1WhBr",
	"leftText": "_2GAatds1BJBzjUPZdSOnls",
	"rightText": "_39rh3MOMkjWSuLa9lVOfzX"
};
module.exports = exports;
