import { useEffect, useState } from 'react';

import { unityContext } from '../../areas/main/views/preview';
import { mapAnimationBundleToJSON } from '../../areas/main/state/experience/sliceSaga';
import useSelector from '../useSelector';
import {
  PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT,
  UnityPreviewAPIMethods,
} from '../../areas/main/state/preview/unityMessages/utils';
import { logToServer } from '../../utils/logging';
import {
  useAllAssetVersionsByType,
  useAnimationBundleSignedUrl,
} from '../useOldAssetVersions';

const useCurrentStateToLoadAnimationBundleInPlayer = () => {
  const [shouldReload, setShouldReload] = useState(false);
  const [currAnimationBundleUrl, setCurrAnimationBundleUrl] = useState<
    string | null
  >(null);
  const { animationBundle } = useSelector((state) => state.main.experience);
  const contentVersion = useSelector(
    (state) => state.main.experience.content_version,
  );
  const {
    areAllAssetBundleSignedURLsFetched,
    isPlayerPreviewReady,
    signedUrls,
  } = useSelector((state) => state.main.preview);
  const allAssetsByType = useAllAssetVersionsByType();

  const animationBundleSignedUrl = useAnimationBundleSignedUrl();

  useEffect(() => {
    if (
      areAllAssetBundleSignedURLsFetched &&
      isPlayerPreviewReady &&
      unityContext.unityInstance
    )
      setShouldReload(true);
  }, [areAllAssetBundleSignedURLsFetched, isPlayerPreviewReady]);

  useEffect(() => {
    if (signedUrls && isPlayerPreviewReady && unityContext.unityInstance)
      setShouldReload(true);
  }, [signedUrls, isPlayerPreviewReady]);

  useEffect(() => {
    if (
      animationBundle &&
      animationBundleSignedUrl &&
      currAnimationBundleUrl !== animationBundleSignedUrl.signedUrl &&
      shouldReload &&
      signedUrls &&
      unityContext.unityInstance
    ) {
      setShouldReload(false);

      setCurrAnimationBundleUrl(animationBundleSignedUrl.signedUrl);

      const signedUrlAssetData = {
        signedUrls,
        assetListsByType: allAssetsByType,
      };

      if (animationBundleSignedUrl)
        logToServer('warn', 'Unable to find webgl animation bundle');

      if (unityContext.unityInstance) {
        unityContext.send(
          PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT,
          UnityPreviewAPIMethods.LoadEntityAnimations,
          JSON.stringify(
            mapAnimationBundleToJSON(
              animationBundle,
              animationBundleSignedUrl?.versionVariantId ?? null,
              signedUrlAssetData,
            ),
          ),
        );
      }
    }
  }, [
    animationBundle,
    allAssetsByType,
    shouldReload,
    signedUrls,
    contentVersion,
    animationBundleSignedUrl,
    currAnimationBundleUrl,
  ]);
};

export default useCurrentStateToLoadAnimationBundleInPlayer;
