import tenants from './tenants/sliceSaga';
import assets from './assets/sliceSaga';
import voices from './voices/sliceSaga';

import { all } from 'typed-redux-saga';

export default function* saga() {
  const sagas = [tenants.saga(), assets.saga(), voices.saga()];

  yield* all(sagas);
}
