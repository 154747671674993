import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AnimationLibrary,
  AssetTypes,
  ObjectMetadata,
} from '../../types/models';

import { deleteRecordByKey } from '../../../../utils/deleteRecord';

type ImageDimensions = {
  x: number;
  y: number;
};

export enum AssetContainerType {
  EXPERIENCE,
  SCENE,
  OPERATION,
}

export interface AssetUpgradeInfo {
  newVersionId: string;
  assetType: AssetTypes;
  newVersionCreatedAt: string;
  name: string;
  container: {
    type: AssetContainerType;
    id: string;
  };
}

export interface AssetUpgradeCommand {
  oldVersionId: string;
  containerId: string;
  assetUpgradeInfo?: AssetUpgradeInfo;
}

export const initialState = {
  assetListsByType: {} as Record<AssetTypes, ObjectMetadata[]>,
  assetFilesSizes: {} as Record<string, number>,
  versionedAssetList: {} as Partial<Record<string, ObjectMetadata[]>>,
  assetURLs: {} as Record<string, string>,
  imageDimensions: {} as Record<string, ImageDimensions>,
  selectedAsset: null as ObjectMetadata | null,
  errors: {} as Record<string, string>,
  isFetchingAssetZip: false,
  animationLibrary: {} as AnimationLibrary,
  groupLabels: {} as Record<string, string[]>,
  // This is a map of asset version IDs => container IDs (e.g. scene ID, experience ID) => upgrade info
  suggestedAssetUpdates: {} as Partial<
    Record<string, Partial<Record<string, AssetUpgradeInfo>>>
  >,
  showSuggestedAssetUpdates: false,
  isAutoAssetUpdatingEnabled: true,
  // Determines whether or not we display experiences with a name like `New Experience ___` in the "Open Experience" modal
  ignoreNewExperiences: true,
};

type Update = Partial<typeof initialState>;

type URLEntry = {
  field: string;
  url: string;
};
interface FetchCGMetadataForAsset {
  updatedAssetWithCGMetadata: ObjectMetadata;
}

const sliceReducer = createSlice({
  name: 'main/assets',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Update>) => ({
      ...state,
      ...action.payload,
    }),
    updateURLs: (state, action: PayloadAction<URLEntry>) => ({
      ...state,
      assetURLs: {
        ...state.assetURLs,
        [action.payload.field]: action.payload.url,
      },
    }),
    updateAssets: (
      state,
      action: PayloadAction<Record<string, ObjectMetadata[]>>,
    ) => {
      return {
        ...state,
        assetListsByType: {
          ...state.assetListsByType,
          ...action.payload,
        },
      };
    },
    updateAssetWithCGMetadata: (
      state,
      action: PayloadAction<FetchCGMetadataForAsset>,
    ) => {
      const { updatedAssetWithCGMetadata } = action.payload;
      const assetType = updatedAssetWithCGMetadata.type;
      if (assetType !== '') {
        const assetListByType = state.assetListsByType[assetType];
        let updatedAssets = [] as ObjectMetadata[];
        for (const asset of assetListByType) {
          if (
            asset.versionId === updatedAssetWithCGMetadata.versionId &&
            asset.variant.os_platform ===
              updatedAssetWithCGMetadata.variant.os_platform
          ) {
            updatedAssets = [...updatedAssets, updatedAssetWithCGMetadata];
          } else {
            updatedAssets = [...updatedAssets, asset];
          }
        }

        return {
          ...state,
          assetListsByType: {
            ...state.assetListsByType,
            [assetType]: updatedAssets,
          },
        };
      }

      return state;
    },
    updateErrors: (
      state,
      action: PayloadAction<{
        field: string;
        error: unknown;
      }>,
    ) => {
      const { error, field } = action.payload;

      const errorMessage =
        error instanceof Error
          ? error.message
          : typeof error === 'string'
            ? error
            : JSON.stringify(error);

      return {
        ...state,
        errors: {
          ...state.errors,
          [field]: errorMessage,
        },
      };
    },
    clearErrorsByKey: (state, action: PayloadAction<string>) => ({
      ...state,
      errors: deleteRecordByKey(action.payload, state.errors),
    }),
    setSuggestedAssetUpdate: (
      state,
      action: PayloadAction<AssetUpgradeCommand>,
    ) => {
      const { oldVersionId, assetUpgradeInfo } = action.payload;
      return {
        ...state,
        suggestedAssetUpdates: {
          ...state.suggestedAssetUpdates,
          [oldVersionId]: {
            ...state.suggestedAssetUpdates[oldVersionId],
            [action.payload.containerId]: assetUpgradeInfo,
          },
        },
      };
    },
    setVersionedAsset: (
      state,
      action: PayloadAction<{
        versionId: string;
        assetMetadatas?: ObjectMetadata[];
      }>,
    ) => {
      return {
        ...state,
        versionedAssetList: {
          ...state.versionedAssetList,
          [action.payload.versionId]: action.payload.assetMetadatas,
        },
      };
    },
    setShowSuggestedAssetUpdates: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        showSuggestedAssetUpdates: action.payload,
      };
    },
  },
});

export default sliceReducer;
