import { call, spawn, put, select } from 'typed-redux-saga';
import { SagaType, createSliceSaga } from 'redux-toolkit-saga';
import { AssetManagementApi } from '../../../../utils/api';
import sliceReducer from './sliceReducer';
import { logSagaError, xhrDownload } from '../../../../utils/saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { State } from '../../../../state/reducer';
import { ASSETBUNDLE_TYPES } from '../../constants';
import { unityContext } from '../../views/preview';
import {
  PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT,
  UnityPreviewAPIMethods,
} from './unityMessages/utils';
import { mapExperienceStateToJSON } from '../experience/sliceSaga';
import { getToggleFromLDClient } from '../../../../utils/toggle';
import {
  combineAssetListsAndVersionedAssets,
  getZipAssetArgs,
} from '../assets/utils';

const MAX_SIGNED_URLS_PER_REQUEST = 400;

export interface SignedUrlMetadata {
  versionVariantId: string;
  signedUrl: string | null;
}

export interface FetchSignedUrlsForVersionVariantIds {
  versionVariantIds: string[];
  download?: boolean;
}

const sliceSaga = createSliceSaga({
  name: sliceReducer.name,
  caseSagas: {
    fetchSignedUrlsForVersionVariantIds: {
      sagaType: SagaType.TakeEvery,
      *fn(action: PayloadAction<FetchSignedUrlsForVersionVariantIds>) {
        try {
          const versionVariantIdsCopy = [...action.payload.versionVariantIds];

          // In order to prevent overwhelming AMS with requests for assets,
          // we request chunks of signed URLs serially.
          while (versionVariantIdsCopy.length) {
            const request = AssetManagementApi().signedUrls.getSignedUrlsList(
              // We request a chunk of maximum `MAX_SIGNED_URLS_PER_REQUEST` assets.
              versionVariantIdsCopy.splice(0, MAX_SIGNED_URLS_PER_REQUEST),
            );
            const response = yield* call(request);
            const { data: newSignedUrlMetadatas } = response;

            yield* put(
              sliceReducer.actions.pushSignedUrls(newSignedUrlMetadatas),
            );

            if (action.payload.download) {
              for (const newSignedUrlMetadata of newSignedUrlMetadatas) {
                if (newSignedUrlMetadata.signedUrl)
                  yield* spawn(xhrDownload, newSignedUrlMetadata.signedUrl);
              }
            }
          }
        } catch (e) {
          yield* put(
            sliceReducer.actions.updateErrors({
              field: 'signedUrls',
              error: e,
            }),
          );
          logSagaError(action, e);
        }
      },
    },
    fetchAllAssetBundleSignedUrls: {
      sagaType: SagaType.TakeLatest,
      *fn(action: PayloadAction) {
        const state: State = yield* select();
        const { assetListsByType } = state.main.assets;
        const assetBundleVersionVariantIds = ASSETBUNDLE_TYPES.reduce(
          (acc, type) => {
            if (!assetListsByType[type]) return acc;

            const versionVariantIds = assetListsByType[type]
              .map((i) =>
                i.variant.os_platform === 'webgl' ? i.versionVariantId : null,
              )
              .filter((x): x is string => !!x);

            return [...acc, ...versionVariantIds];
          },
          [] as string[],
        );

        try {
          if (!assetBundleVersionVariantIds.length) {
            yield* put(
              sliceReducer.actions.update({
                areAllAssetBundleSignedURLsFetched: true,
              }),
            );
            return;
          }

          // In order to prevent overwhelming AMS with requests for assets,
          // we request chunks of signed URLs serially.
          while (assetBundleVersionVariantIds.length) {
            const useGroupLabelBasedAssetRetrieval = yield* call(() =>
              getToggleFromLDClient<boolean>(
                'use-group-label-based-asset-retrieval',
                false,
              ),
            );

            const request = AssetManagementApi({
              useGroupLabelBasedAssetRetrieval,
            }).signedUrls.getSignedUrlsList(
              // We request a chunk of maximum `MAX_SIGNED_URLS_PER_REQUEST` assets.
              assetBundleVersionVariantIds.splice(
                0,
                MAX_SIGNED_URLS_PER_REQUEST,
              ),
            );
            const response = yield* call(request);
            const newSignedUrlData: SignedUrlMetadata[] = response.data;

            yield* put(sliceReducer.actions.pushSignedUrls(newSignedUrlData));
          }

          // Once all of the chunks have been requested, mark the fetching as successful.
          yield* put(
            sliceReducer.actions.update({
              areAllAssetBundleSignedURLsFetched: true,
            }),
          );
        } catch (e) {
          yield* put(
            sliceReducer.actions.updateErrors({
              field: 'signedUrls',
              error: e,
            }),
          );
          logSagaError(action, e);
        }
      },
    },
    precacheAllAssetbundleSignedUrls: {
      sagaType: SagaType.TakeLatest,
      *fn(action: PayloadAction) {
        const state: State = yield* select();
        const { signedUrls } = state.main.preview;

        try {
          for (const signedUrlMetadata of signedUrls ?? [])
            if (signedUrlMetadata.signedUrl)
              yield* spawn(xhrDownload, signedUrlMetadata.signedUrl);
        } catch (e) {
          yield* put(
            sliceReducer.actions.updateErrors({
              field: 'precacheSignedUrls',
              error: e,
            }),
          );
          logSagaError(action, e);
        }
      },
    },
    precacheAllSignedUrlsInExperience: {
      sagaType: SagaType.TakeLatest,
      *fn(action: PayloadAction) {
        const state: State = yield* select();
        const { signedUrls } = state.main.preview;
        const { assetListsByType, versionedAssetList } = state.main.assets;
        const { operations } = state.main.operationContainers;
        const { scenes } = state.main.sceneProperties;

        try {
          const experienceJSON = mapExperienceStateToJSON(state);
          const programName = experienceJSON.name ?? '';

          // First make a hash table by vvid to avoid having to search the list on every iteration
          const signedUrlsByVersionVariantId = (signedUrls ?? [])
            .filter((signedUrlMetadata) => !!signedUrlMetadata.signedUrl)
            .reduce(
              (acc, cur) => ({
                ...acc,
                [cur.versionVariantId]: cur.signedUrl!,
              }),
              {} as Record<string, string>,
            );

          // We don't care about the paths but this also gives us all the vvids in the experience
          const experienceAssets = getZipAssetArgs(
            'webgl',
            experienceJSON,
            assetListsByType,
            versionedAssetList,
            scenes,
            operations,
            programName,
          );

          // Precache all the signed urls in the experience
          for (const experienceAsset of experienceAssets) {
            const signedUrl =
              signedUrlsByVersionVariantId[experienceAsset.versionVariantId];
            if (signedUrl) yield* spawn(xhrDownload, signedUrl);
          }
        } catch (e) {
          yield* put(
            sliceReducer.actions.updateErrors({
              field: 'precacheSignedUrls',
              error: e,
            }),
          );
          logSagaError(action, e);
        }
      },
    },
    previewExperience: {
      sagaType: SagaType.TakeLatest,
      *fn(action: PayloadAction) {
        const field = 'previewExperience';
        const state: State = yield* select();
        const { signedUrls } = state.main.preview;
        const { assetListsByType, versionedAssetList } = state.main.assets;
        const allAssetsByType = combineAssetListsAndVersionedAssets(
          assetListsByType,
          versionedAssetList,
        );
        const signedUrlAssetData = {
          signedUrls,
          assetListsByType: allAssetsByType,
        };

        try {
          const experience = JSON.stringify(
            mapExperienceStateToJSON(state, signedUrlAssetData, true),
          );
          if (unityContext.unityInstance) {
            yield* put(
              sliceReducer.actions.update({
                fullPreviewExperienceJson: experience,
              }),
            );

            unityContext.send(
              PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT,
              UnityPreviewAPIMethods.LoadExperience,
              experience,
            );
            unityContext.send(
              PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT,
              UnityPreviewAPIMethods.Play,
            );
          }
        } catch (e) {
          logSagaError(action, e);

          yield* put(
            sliceReducer.actions.updateErrors({
              field,
              error: e,
            }),
          );
        }
      },
    },
    endPreviewExperience: {
      sagaType: SagaType.TakeLatest,
      *fn(action: PayloadAction) {
        const field = 'endPreviewExperience';

        try {
          if (unityContext.unityInstance) {
            unityContext.send(
              PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT,
              UnityPreviewAPIMethods.UnloadExperience,
            );
          }
          yield* put(
            sliceReducer.actions.update({
              fullPreviewExperienceJson: null,
            }),
          );
        } catch (e) {
          logSagaError(action, e);

          yield* put(
            sliceReducer.actions.updateErrors({
              field,
              error: e,
            }),
          );
        }
      },
    },
    previewExperienceWindowClosed: {
      sagaType: SagaType.TakeLatest,
      *fn(action: PayloadAction) {
        const field = 'previewExperienceWindowClosed';

        try {
          yield* put(
            sliceReducer.actions.update({
              areAnimationsLoaded: false,
              isSceneReady: false,
            }),
          );
        } catch (e) {
          logSagaError(action, e);

          yield* put(
            sliceReducer.actions.updateErrors({
              field,
              error: e,
            }),
          );
        }
      },
    },
    restartPreviewExperience: {
      sagaType: SagaType.TakeLatest,
      *fn(action: PayloadAction) {
        const field = 'restartPreviewExperience';
        const state: State = yield* select();

        try {
          if (
            unityContext.unityInstance &&
            state.main.preview.fullPreviewExperienceJson
          ) {
            unityContext.send(
              PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT,
              UnityPreviewAPIMethods.UnloadExperience,
            );
            unityContext.send(
              PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT,
              UnityPreviewAPIMethods.LoadExperience,
              state.main.preview.fullPreviewExperienceJson,
            );
            unityContext.send(
              PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT,
              UnityPreviewAPIMethods.Play,
            );
          }
        } catch (e) {
          logSagaError(action, e);

          yield* put(
            sliceReducer.actions.updateErrors({
              field,
              error: e,
            }),
          );
        }
      },
    },
  },
});

export default sliceSaga;
