import React, { useEffect, useState } from 'react';

import { classNames } from '../utils/helpers';

import styles from './sliderInput.module.css';

import Slider from 'react-rangeslider';

import './sliderStyleOverrides.css';

export interface SliderInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  leftText?: string;
  rightText?: string;
  min: number;
  max: number;
  step: number;
  defaultValue: number;
  handleValueChange?: (value: number) => void;
}

const SliderInput: React.FC<SliderInputProps> = ({
  className,
  leftText,
  rightText,
  min,
  max,
  step,
  defaultValue,
  handleValueChange,
  ...sliderInputProps
}) => {
  const [currValue, setCurrValue] = useState(defaultValue);

  const handleChange = (value: number) => {
    setCurrValue(value);
    if (handleValueChange) handleValueChange(value);
  };

  useEffect(() => {
    setCurrValue(defaultValue);
  }, [defaultValue]);

  return (
    <div className={classNames(styles.root, className)}>
      <span className={classNames(styles.label)}>{leftText}</span>
      <Slider
        className={styles.slider}
        {...sliderInputProps}
        min={min}
        max={max}
        step={step}
        value={currValue}
        onChange={handleChange}
      />
      <span className={classNames(styles.label)}>{rightText}</span>
    </div>
  );
};

export default SliderInput;
