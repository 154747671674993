// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3vYZqv2GQkPQhvoeRNzFsZ {\n    display: flex;\n}\n\n.jyhVFejG5w0S4PFD5Q1Fk {\n    align-items: flex-end;\n    color: var(--v-ui-color-field-disabled-text);\n    display: flex;\n    flex-wrap: wrap-reverse;\n    justify-content: center;\n    padding-right: 8px;\n}\n\n.N_JKruApZQsLOCygXUDBa {\n    display: block;\n}\n", ""]);
// Exports
exports.locals = {
	"radioGroup": "_3vYZqv2GQkPQhvoeRNzFsZ",
	"radioLabel": "jyhVFejG5w0S4PFD5Q1Fk",
	"radioInput": "N_JKruApZQsLOCygXUDBa"
};
module.exports = exports;
