import { useEffect } from 'react';
import { unityContext } from '../../areas/main/views/preview';
import sliceReducer from '../../areas/main/state/preview/sliceReducer';
import useDispatch from '../useDispatch';
import { UnityPreviewEvents } from '../../areas/main/state/preview/unityMessages/utils';

const useAnimationsLoadedMessageFromPlayer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    unityContext.on(UnityPreviewEvents.OnAnimationsLoaded, () => {
      dispatch(
        sliceReducer.actions.update({
          areAnimationsLoaded: true,
        })
      );
    });
  }, [dispatch]);
};

export default useAnimationsLoadedMessageFromPlayer;
