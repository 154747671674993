import React, { useEffect, useCallback } from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import LoadingIndicator from './loadingIndicator';
import useIsAxiosReady from '../hooks/context/useIsAxiosReady';
import { Shield } from '@strivr/ui';
import useDispatch from '../hooks/useDispatch';
import sliceSaga from '../areas/main/state/tenants/sliceSaga';
import TenantPickerModal from './tenantPickerModal';
import useSelector from '../hooks/useSelector';
import Unauthorized from '../views/unauthorized';

type TenantProtectedRouteProps = Omit<RouteProps, 'component'>;

const RouteWithAuthenticationRequired = withAuthenticationRequired(Route);

const TenantProtectedRoute: React.FC<TenantProtectedRouteProps> = ({
  children,
  ...routeProps
}) => {
  const { isAuthenticated } = useAuth0();
  const dispatch = useDispatch();
  const isAxiosReady = useIsAxiosReady();

  const { isUnauthorized, selectedTenant, tenantList } = useSelector(
    (state) => state.main.tenants,
  );

  const hasLoadedTenants = tenantList !== null;
  const isTenantSelected = selectedTenant !== null;
  const isInitialLoading = !isAuthenticated || !hasLoadedTenants;

  const logout = useCallback(() => window.location.replace('/'), []);

  useEffect(() => {
    if (isAxiosReady) {
      dispatch(sliceSaga.actions.fetchTenants());
    }
  }, [dispatch, isAxiosReady]);

  return isUnauthorized ? (
    <Unauthorized />
  ) : (
    <>
      <Shield show={isInitialLoading || !isTenantSelected} overlayColor="black">
        <LoadingIndicator show={isInitialLoading} />
        <TenantPickerModal
          show={!isInitialLoading && !isTenantSelected}
          onTenantSelectionCancel={logout}
          onTenantSelectionClose={logout}
        />
      </Shield>

      <RouteWithAuthenticationRequired {...routeProps}>
        {children}
      </RouteWithAuthenticationRequired>
    </>
  );
};

export default TenantProtectedRoute;
