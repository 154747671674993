// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2QhSO_bNyOol9Ri68Sv46D {\n    color: var(--v-ui-color-link);\n    cursor: pointer;\n}\n\n._2QhSO_bNyOol9Ri68Sv46D:hover {\n    text-decoration: underline;\n}\n\n._395fbywI65NRw4LVYoh0J4 {\n    pointer-events: none;\n}\n\n@supports (-moz-appearance:none) {\n    ._2QhSO_bNyOol9Ri68Sv46D:focus {\n        outline-style: dotted;\n        outline-width: 1px;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"buttonDressedAsLink": "_2QhSO_bNyOol9Ri68Sv46D",
	"disabled": "_395fbywI65NRw4LVYoh0J4"
};
module.exports = exports;
