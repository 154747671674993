import { useEffect, useState } from 'react';

import { useGlobalResizeHandler } from '@strivr/ui';

const mobileScreenWidth = 500;
const forceMobileBelowPixelWidth = 0; // Disabled
const mobileFontScale = '87.5%'; // 14px rem baseline
const desktopFontScale = '62.5%'; // 10px rem baseline

const useInitIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  const initSizes = () => {
    const newIsMobile =
      screen.width < mobileScreenWidth ||
      window.innerWidth < forceMobileBelowPixelWidth;
    document.documentElement.style.fontSize = newIsMobile
      ? mobileFontScale
      : desktopFontScale;
    setIsMobile(newIsMobile);
  };
  useEffect(initSizes, []);
  useGlobalResizeHandler(initSizes);

  return isMobile;
};

export default useInitIsMobile;
