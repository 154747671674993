// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Zzt_Xxw-zFkZlKTB9PgrN {\n  display: inline-flex;\n  flex-direction: column;\n}\n\n._1EeAgJUQ6wT_7GQ8jJzryV {\n  background: var(--v-ui-color-field-background);\n  border-color: var(--v-ui-color-dropdown);\n  border-radius: var(--v-ui-border-radius-dropdown);\n  border-style: solid;\n  border-width: var(--v-ui-border-width-dropdown);\n  color: inherit;\n  font-size: inherit;\n  padding: var(--v-ui-padding-dropdown);\n}\n\n._1EeAgJUQ6wT_7GQ8jJzryV:disabled {\n  border-color: var(--v-ui-color-field-disabled);\n  cursor: not-allowed;\n  filter: var(--v-ui-filter-disabled);\n  opacity: var(--v-ui-filter-dropdown-disabled-opacity);\n}\n\n._1EeAgJUQ6wT_7GQ8jJzryV:not(:disabled):focus {\n  border-color: var(--v-ui-color-dropdown-focus);\n  outline: none;\n}\n\n._1EeAgJUQ6wT_7GQ8jJzryV:not(:disabled):hover {\n  filter: var(--v-ui-filter-hover);\n}\n\n._2jHSGbPlZVCyl_Qghq4kn5 {\n  border-color: var(--v-ui-color-invalid);\n}\n\n._3XjVGM9lAo6VUQbNzasIIj {\n  font-size: 1.3rem;\n  margin-top: 8px;\n  color: var(--v-ui-color-invalid);\n}\n\n._343SBEZ-zbN4Hpgrg4GLvw {\n  background: var(--v-ui-color-list-item);\n}\n\n._343SBEZ-zbN4Hpgrg4GLvw:not(:disabled) {\n  color: var(--v-ui-color-list-item-contrast);\n}\n\n._343SBEZ-zbN4Hpgrg4GLvw:disabled {\n  color: var(--v-ui-color-list-item-contrast-disabled);\n}", ""]);
// Exports
exports.locals = {
	"root": "Zzt_Xxw-zFkZlKTB9PgrN",
	"dropdown": "_1EeAgJUQ6wT_7GQ8jJzryV",
	"dropdown__invalid": "_2jHSGbPlZVCyl_Qghq4kn5",
	"error": "_3XjVGM9lAo6VUQbNzasIIj",
	"option": "_343SBEZ-zbN4Hpgrg4GLvw"
};
module.exports = exports;
