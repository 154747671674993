import { LDUser } from 'launchdarkly-js-sdk-common';

export const getToggleUserFromUser = (
  user: { sub?: string },
  tenantId: string,
) => {
  const ldUser: LDUser = {
    key: user.sub,
    custom: {
      tenantId,
    },
  };
  return ldUser;
};

export async function getToggleFromLDClient<T>(
  flagName: string,
  defaultValue?: T,
) {
  const { ldClient } = window;

  if (!ldClient) {
    throw new Error(
      `Cannot retrieve flag ${flagName}: LaunchDarkly client not yet initialized!`,
    );
  }

  await ldClient.waitUntilReady();
  return ldClient.variation(flagName, defaultValue) as T;
}
