import React from 'react';

import { classNames } from '../utils/helpers';

import styles from './button.module.css';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  flavor: 'confirm' | 'confirm-unselected' | 'alternate' | 'tertiary';
  size: 'xs' | 'small' | 'medium' | 'large' | 'xl';
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, className, flavor, size, ...buttonProps }, ref) => {
    return (
      <button
        {...buttonProps}
        className={classNames(
          styles.button,
          className,
          styles[flavor],
          styles[size],
        )}
        ref={ref}
      >
        {children}
      </button>
    );
  },
);

Button.displayName = 'Button';

export default Button;
