import { call, put } from 'typed-redux-saga';
import { SagaType, createSliceSaga } from 'redux-toolkit-saga';

import sliceReducer from './sliceReducer';
import { makeCamApi } from '../../../../utils/api';
import { CAMTenant } from '../../../../types/api';
import { logSagaError } from '../../../../utils/saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

const camApi = makeCamApi(1, 'Tenants');

const sliceSaga = createSliceSaga({
  name: sliceReducer.name,
  caseSagas: {
    fetchTenants: {
      sagaType: SagaType.TakeLatest,
      *fn(action: PayloadAction) {
        try {
          const request = camApi.makeGetRequest<Array<unknown>>('');
          const response = yield* call(request);
          const tenantList = response.data.map((tenant): CAMTenant => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const maybeTenant = tenant as any;

            return {
              id: maybeTenant.id,
              name: maybeTenant.name,
              tenant_name: maybeTenant.tenant_name ?? maybeTenant.slug,
              gcp_bucket_name: maybeTenant.gcp_bucket_name,
              gcp_storage_path: maybeTenant.gcp_storage_path,
              organization_id: maybeTenant.organization_id,
            };
          });

          yield* put(
            sliceReducer.actions.update({
              isUnauthorized: false,
              tenantList,
            }),
          );
        } catch (e) {
          yield* put(
            sliceReducer.actions.update({
              isUnauthorized: (e as AxiosError).response?.status === 403,
            }),
          );
          yield* put(
            sliceReducer.actions.updateErrors({
              field: 'tenantList',
              error: (e as Error).toString(),
            }),
          );
          logSagaError(action, e);
        }
      },
    },
  },
});

export default sliceSaga;
