// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1QLbco4GY30DKYAPtL_Nyt {\n  position: relative;\n}\n._1XNJKEQQ_1t5tjtvGiGia8 {\n  border-bottom: 1px solid rgb(35, 40, 40);\n  position: relative;\n}\n._1XNJKEQQ_1t5tjtvGiGia8 .ojmCCi4swN6SghZEX0HFu {\n  padding: 22px 33px;\n  cursor: pointer;\n}\n._1XNJKEQQ_1t5tjtvGiGia8 ._3ItmpqGB3VlTxhMjLu9N0q {\n  display: block;\n  position: absolute;\n  left: 10px;\n  transition: all 0.5s;\n}\n\n._1XNJKEQQ_1t5tjtvGiGia8 ._3l4es6BGddQTEQ8Bv0zxd8 {\n  transform: rotateZ(-180deg);\n}\n\n._1XNJKEQQ_1t5tjtvGiGia8 ._3RHzrTydJUZu2dW04H_FhB {\n  overflow: hidden;\n  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);\n  height: auto;\n  max-height: 9999px;\n}\n\n._1XNJKEQQ_1t5tjtvGiGia8 ._3GDOfPLwasbHAyBdlHF0i_ {\n  max-height: 0;\n  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);\n}\n\n._1XNJKEQQ_1t5tjtvGiGia8 ._3pQ-PeQFYWEyjPbPV9L1Zv {\n  padding: 20px 33px;\n}\n\n._1XNJKEQQ_1t5tjtvGiGia8 ._2VouI6O8_pIxca0Eo_Fhxt {\n  visibility: hidden;\n}\n", ""]);
// Exports
exports.locals = {
	"accordion": "_1QLbco4GY30DKYAPtL_Nyt",
	"fold": "_1XNJKEQQ_1t5tjtvGiGia8",
	"fold_trigger": "ojmCCi4swN6SghZEX0HFu",
	"caret": "_3ItmpqGB3VlTxhMjLu9N0q",
	"flip": "_3l4es6BGddQTEQ8Bv0zxd8",
	"fold_item": "_3RHzrTydJUZu2dW04H_FhB",
	"collapsed": "_3GDOfPLwasbHAyBdlHF0i_",
	"fold_content": "_3pQ-PeQFYWEyjPbPV9L1Zv",
	"fold_hidden": "_2VouI6O8_pIxca0Eo_Fhxt"
};
module.exports = exports;
