export const tryParseInt = (
  num: string | null | undefined,
  fallback: number
): number => {
  if (!num) return fallback;
  const numParsed = parseInt(num, 10);
  if (isNaN(numParsed)) return fallback;
  return numParsed;
};

export const tryParseFloat = (
  num: string | null | undefined,
  fallback: number
): number => {
  if (!num) return fallback;
  const numParsed = parseFloat(num);
  if (isNaN(numParsed)) return fallback;
  return numParsed;
};
