import React, {
  useEffect,
  useState,
  useRef,
  KeyboardEventHandler,
} from 'react';

import { classNames } from '../utils/helpers';
import Checkmark from '../../res/assets/images/Checkmark.svg';
import ArrowDown from '../../res/assets/images/ArrowDown.svg';
import Search from '../../res/assets/images/Search.svg';

import styles from './extendedDropdown.module.css';

export type OptionValue = string | number;

export interface DropdownOption<T>
  extends Omit<
    React.OptionHTMLAttributes<HTMLButtonElement>,
    'selected' | 'value'
  > {
  display?: string | null | undefined;
  value: T;
}

interface DropdownProps<T>
  extends Omit<
    React.SelectHTMLAttributes<HTMLButtonElement>,
    'defaultValue' | 'onChange' | 'value'
  > {
  defaultValue?: T;
  error?: string | null;
  onChange?: (value: T, e: React.MouseEvent<HTMLButtonElement>) => void;
  options: DropdownOption<T>[];
  isOpen?: boolean;
  isSearchable?: boolean;
  searchFilter?: (
    searchInput: string
  ) => (option: DropdownOption<T>) => boolean;
  notFoundMessage?: string;
  placeholder?: string;
  searchPlaceholder?: string;
  caretSize?: number;
  searchValue?: string;
  onSearchValueChange?: (value: string) => void;
}

/**
 * Return the given option if it matches with the search word.
 *
 * Currently, the filter is just limited to a simple string search.
 * If one wants to make a more robust filter, they can pass it as
 * the `searchFilter`, such as using stemming, trigrams, or dmetaphone.
 */
function defaultSearchFilter<T>(searchInput: string) {
  const cleanSearch = searchInput.trim().toLowerCase();

  return (option: DropdownOption<T>) => {
    if (!cleanSearch) {
      return true;
    }

    return (
      option.display?.toLowerCase().includes(cleanSearch) ||
      (typeof option.value === 'string' &&
        option.value?.toLowerCase().includes(cleanSearch))
    );
  };
}

const onEscPressed =
  (onPress: () => void): KeyboardEventHandler<HTMLDivElement> =>
  (e) => {
    if (e.key === 'Esc') {
      onPress();
    }
  };

function ExtendedDropdown<T extends OptionValue>(
  props: DropdownProps<T>
): JSX.Element {
  const selectorRef = useRef<HTMLButtonElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const searchRef = useRef<HTMLInputElement | null>(null);

  const [isOpen, setIsOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  const {
    className,
    defaultValue,
    error,
    onChange,
    options,
    notFoundMessage: inNotFoundMessage,
    searchValue,
    searchFilter,
    isOpen: inIsOpen,
    placeholder,
    caretSize,
    isSearchable,
    onSearchValueChange,
    searchPlaceholder,
    ...selectProps
  } = props;

  const [value, setValue] = useState(defaultValue);
  const currOptionSelected = options.find((o) => o.value === value);

  // The message to display when there are no options to display
  const notFoundMessage =
    inNotFoundMessage || 'No options matching the query were found.';

  const currSearchInput = searchValue || searchInput;
  const matchingOptions = options.filter(
    (searchFilter || defaultSearchFilter)(currSearchInput)
  );

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  // Handle selecting an option
  const handleChange =
    (selectedOption: DropdownOption<T>) =>
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setValue(selectedOption.value);
      if (onChange) onChange(selectedOption.value, e);
      setIsOpen(false);
    };

  // Handle clicks outside the dropdown
  useEffect(() => {
    function clickHandler(e: MouseEvent) {
      const { target } = e;
      if (
        ![selectorRef, menuRef].some(
          (ref) =>
            ref.current &&
            target instanceof Node &&
            ref.current.contains(target)
        )
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', clickHandler);

    return () => {
      document.removeEventListener('mousedown', clickHandler);
    };
  }, [selectorRef, menuRef]);

  useEffect(() => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
  }, [isOpen]);

  const currOpen = inIsOpen || isOpen;

  return (
    <span className={classNames(styles.root, className)}>
      <button
        {...selectProps}
        className={classNames(styles.dropdown, {
          [styles.dropdown__invalid]: !!error,
        })}
        onClick={(e) => {
          e.stopPropagation();
          inIsOpen === undefined && setIsOpen(!isOpen);
        }}
        ref={selectorRef}
        role="combobox"
        aria-controls="dropdown-menu"
        aria-expanded={currOpen}
      >
        <div className={classNames(styles.dropdown_content)}>
          {currOptionSelected ? (
            currOptionSelected.display
          ) : (
            <p>{placeholder}</p>
          )}
        </div>
        <div
          className={classNames(styles.dropdown_arrow, {
            [styles.flip]: currOpen,
          })}
        >
          <ArrowDown width={13} height={8} />
        </div>
      </button>
      {currOpen && (
        <div className={classNames(styles.menu_container)}>
          <div
            className={classNames(
              styles.dropdown_caret,
              styles.dropdown_caret__inverted
            )}
            style={{
              borderWidth: caretSize || 0,
            }}
          />
          <div
            className={classNames(styles.menu)}
            onClick={(e) => e.stopPropagation()}
            ref={menuRef}
            style={{
              marginTop: caretSize || 0,
            }}
            onKeyDown={onEscPressed(() => setIsOpen(false))}
            role="presentation"
            aria-labelledby="dropdown-menu"
          >
            {isSearchable && (
              <div className={classNames(styles.search_container)}>
                <input
                  className={classNames(styles.search)}
                  value={currSearchInput}
                  onChange={(e) => {
                    e.stopPropagation();
                    const {
                      target: { value },
                    } = e;
                    setSearchInput(value);
                    onSearchValueChange && onSearchValueChange(value);
                  }}
                  type="text"
                  placeholder={searchPlaceholder}
                  ref={searchRef}
                />
                <div className={classNames(styles.search_icon)}>
                  <Search />
                </div>
              </div>
            )}
            <div className={classNames(styles.option_list)}>
              {matchingOptions.length > 0 ? (
                matchingOptions.map((optionProps) => {
                  const selected = value === optionProps.value;

                  return (
                    <button
                      {...optionProps}
                      className={classNames(
                        styles.option,
                        optionProps.className,
                        {
                          [styles.selected]: selected,
                        }
                      )}
                      key={optionProps.value}
                      onClick={handleChange(optionProps)}
                      tabIndex={0}
                      role="option"
                      aria-selected={selected}
                    >
                      <span className={classNames(styles.checkmark_container)}>
                        {selected && <Checkmark width={15} height={12} />}
                      </span>
                      {optionProps.display}
                    </button>
                  );
                })
              ) : (
                <p className={classNames()}>{notFoundMessage}</p>
              )}
            </div>
          </div>
        </div>
      )}
    </span>
  );
}

export default ExtendedDropdown;
