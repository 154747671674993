import React, { useCallback, useEffect, useRef, useState } from 'react';

import PopupMenu, { PopupMenuItemGroup, Position } from './popupMenu';

export interface ContextMenuProps {
  containerRef: React.RefObject<HTMLElement>;
  itemGroups: PopupMenuItemGroup[];
  onClose?: (pos: Position) => void;
  onOpen?: (pos: Position) => void;
  setCursorPos?: (pos: Position) => void;
}

const CONTEXT_MENU_WIDTH = 208;
const CONTEXT_MENU_HEIGHT = 313;

const ContextMenu: React.FC<ContextMenuProps> = ({
  containerRef,
  itemGroups,
  onClose,
  onOpen,
  setCursorPos,
}) => {
  // We'll need to listen for events on the shield to handle the case of a second right-click on the menu
  const shieldRef = useRef(null as HTMLDivElement | null);

  const [openPos, setOpenPos] = useState(null as Position | null);

  const handleClose = useCallback(() => {
    if (onClose) {
      if (!openPos) throw new Error('openPos was null on closing context menu');
      onClose(openPos);
    }
    setOpenPos(null);
  }, [onClose, openPos]);
  useEffect(() => {
    const shield = shieldRef.current;
    const target = containerRef.current;

    const handleContextMenu = (e: MouseEvent) => {
      e.preventDefault();
      if (openPos) {
        handleClose();
      } else {
        const cursorPos = { x: e.clientX, y: e.clientY };
        const newPos = { x: e.clientX, y: e.clientY };

        const nodeViewBounds = target?.getBoundingClientRect();
        if (nodeViewBounds) {
          if (e.clientX + CONTEXT_MENU_WIDTH > nodeViewBounds?.right) {
            newPos.x = nodeViewBounds?.right - CONTEXT_MENU_WIDTH;
          }
          if (e.clientY + CONTEXT_MENU_HEIGHT > nodeViewBounds?.bottom) {
            newPos.y = nodeViewBounds?.bottom - CONTEXT_MENU_HEIGHT;
          }
        }

        setOpenPos(newPos);
        onOpen?.(newPos);
        setCursorPos?.(cursorPos);
      }
    };
    shield?.addEventListener('contextmenu', handleContextMenu);
    target?.addEventListener('contextmenu', handleContextMenu);
    return () => {
      target?.removeEventListener('contextmenu', handleContextMenu);
      shield?.removeEventListener('contextmenu', handleContextMenu);
    };
  }, [containerRef, onOpen, setCursorPos, handleClose, openPos]);
  return (
    <PopupMenu
      itemGroups={itemGroups}
      onClose={handleClose}
      openPos={openPos}
      shieldRef={shieldRef}
    />
  );
};

export default ContextMenu;
