/**
 * A simple and fast function to generate an *insecure* hash
 * for strings.
 *
 * cyrb53a (c) 2023 bryc (github.com/bryc)
 * > "A fast and simple 53-bit string hash function with decent collision resistance.
 *  Largely inspired by MurmurHash2/3, but with a focus on speed/simplicity.""
 *
 * @param str The string we want to hash.
 * @param seed A seed number to hash by.
 * @returns The 53-bit hash of the original string.
 */
export function simpleHash(str: string, seed: number = 0) {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;

  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 0x85ebca77);
    h2 = Math.imul(h2 ^ ch, 0xc2b2ae3d);
  }

  h1 ^= Math.imul(h1 ^ (h2 >>> 15), 0x735a2d97);
  h2 ^= Math.imul(h2 ^ (h1 >>> 15), 0xcaf649a9);
  h1 ^= h2 >>> 16;
  h2 ^= h1 >>> 16;

  return 2097152 * (h2 >>> 0) + (h1 >>> 11);
}
