// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3Y3NOm2nREM3QHJu1gj2qB {\n  color: inherit;\n  display: flex;\n  justify-content: space-between;\n}\n\n._3h4KAWUhwOaEqke43ncbdx {\n  width: 100%;\n}\n\n._3Flq9Kf7bUxfI4Vsf6B4Pl {\n  width: 50px;\n}", ""]);
// Exports
exports.locals = {
	"root": "_3Y3NOm2nREM3QHJu1gj2qB",
	"slider": "_3h4KAWUhwOaEqke43ncbdx",
	"label": "_3Flq9Kf7bUxfI4Vsf6B4Pl"
};
module.exports = exports;
