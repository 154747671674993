// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3m-ZSUmTXiBxSFx52R_BW2 {\n    background-color: rgba(0,0,0,0);\n    border: none;\n    color: inherit;\n    font: inherit;\n    letter-spacing: unset;\n    line-height: unset;\n    margin: unset;\n    padding: unset;\n    text-align: unset;\n    text-indent: unset;\n    text-shadow: unset;\n    text-transform: unset;\n    word-spacing: unset;\n    -moz-appearance: none;\n    -moz-osx-font-smoothing: inherit;\n    -webkit-appearance: none;\n    -webkit-font-smoothing: inherit;\n    -webkit-writing-mode: inherit;\n}\n\n._3sW6S-99PRJI9YxHA-9ONn {\n    pointer-events: none;\n    cursor: not-allowed;\n}\n\n@supports (-moz-appearance:none) {\n    ._3m-ZSUmTXiBxSFx52R_BW2::-moz-focus-inner {\n        border: none;\n        padding: 0;\n    }\n}\n", ""]);
// Exports
exports.locals = {
	"buttonUndressed": "_3m-ZSUmTXiBxSFx52R_BW2",
	"disabled": "_3sW6S-99PRJI9YxHA-9ONn"
};
module.exports = exports;
