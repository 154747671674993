import React, { useState } from 'react';

import { classNames } from '../utils/helpers';
import ButtonUndressed from './buttonUndressed';
import ArrowDown from '../../res/assets/images/ArrowDown.svg';

import styles from './accordion.module.css';

export interface AccordionProps {
  isExpanded?: boolean;
  title: string;
}

export interface FoldProps {
  active: boolean;
  content: React.ReactNode;
  onClick: () => void;
  title: string;
}

const Accordion: React.FC<AccordionProps> = ({
  children,
  isExpanded = false,
  title,
}) => {
  const [active, setActive] = useState(isExpanded);

  return (
    <div className={classNames(styles.accordion)}>
      <div className={classNames(styles.fold)}>
        <ButtonUndressed
          className={classNames(styles.fold_trigger)}
          onClick={() => setActive(!active)}
        >
          <div className={classNames(styles.caret, { [styles.flip]: active })}>
            <ArrowDown />
          </div>
          {title}
        </ButtonUndressed>
        <div
          className={
            active
              ? classNames(styles.fold_item)
              : classNames(styles.fold_item, styles.collapsed)
          }
        >
          <div
            aria-hidden={!active}
            key="content"
            className={classNames(styles.fold_content, {
              [styles.fold_hidden]: !active,
            })}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
