import { Socket } from 'socket.io-client';

const SOCKET_ACK_TIMEOUT = 1000; // 1 second to respond
export const NO_SOCKET_ERROR = 'Socket does not exist';

/**
 * Attempts to lock an experience to the current user.
 * @param socket The socket to use.
 * @param experienceId The experience to lock onto.
 * @returns Nothing. See the `@throws` section below for error cases.
 * @throws {string | Error} A string containing the name of the user currently
 * holding a lock on the experience, or an error about unexpected issues
 */
export function assignExperience(socket: Socket | null, experienceId: string) {
  return new Promise<void>((resolve, reject) => {
    if (!socket) {
      reject(new Error(NO_SOCKET_ERROR));
      return;
    }

    socket
      .timeout(SOCKET_ACK_TIMEOUT)
      .emit(
        'assign-experience',
        experienceId,
        (errorResponse: unknown, serverResponse: unknown) => {
          if (errorResponse) {
            reject(errorResponse);
            return;
          }

          if (typeof serverResponse !== 'string') {
            reject(new Error(`Unexpected response: ${String(serverResponse)}`));
            return;
          }

          if (serverResponse === 'saved') {
            resolve();
            return;
          }

          reject(serverResponse);
        },
      );
  });
}

export function unassignExperience(
  socket: Socket | null,
  experienceId: string,
) {
  return new Promise<void>((resolve, reject) => {
    if (!socket) {
      reject(new Error(NO_SOCKET_ERROR));
      return;
    }

    socket?.emit('unassign-experience', experienceId, () => resolve());
  });
}

/**
 * Confirms whether or not an experience is available for a user to use.
 * It will resolve successfully if the experience is available, or throw
 * if not.
 * @param socket The socket to use.
 * @param experienceId The experience to determine if available.
 * @returns Nothing. See the `@throws` section below for error cases.
 * @throws {string | Error} A string containing the name of the user currently
 * holding a lock on the experience, or an error about unexpected issues
 */
export function validateExperience(
  socket: Socket | null,
  experienceId: string,
) {
  return new Promise<void>((resolve, reject) => {
    if (!socket) {
      reject(new Error(NO_SOCKET_ERROR));
      return;
    }

    socket
      .timeout(SOCKET_ACK_TIMEOUT)
      .emit(
        'validate-experience',
        experienceId,
        (errorResponse: unknown, serverResponse: unknown) => {
          if (errorResponse) {
            reject(errorResponse);
            return;
          }

          if (typeof serverResponse !== 'string') {
            reject(new Error(`Unexpected response: ${String(serverResponse)}`));
            return;
          }

          if (serverResponse !== '') {
            reject(serverResponse);
            return;
          }

          resolve();
        },
      );
  });
}

export function registerUser(socket: Socket | null, username: string) {
  return new Promise<void>((resolve, reject) => {
    if (!socket) {
      reject(new Error(NO_SOCKET_ERROR));
      return;
    }

    socket.emit('register-user', username, (response: unknown) => {
      if (typeof response !== 'string') {
        reject(new Error(`Unexpected response: ${String(response)}`));
        return;
      }

      if (response === 'SAVED') {
        resolve();
        return;
      }

      reject(response);
    });
  });
}
