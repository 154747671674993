import { combineReducers } from '@reduxjs/toolkit';

import tenants from './tenants/sliceReducer';
import assets from './assets/sliceReducer';
import voices from './voices/sliceReducer';

const reducer = combineReducers({
  tenants: tenants.reducer,
  assets: assets.reducer,
  voices: voices.reducer,
});

export default reducer;
