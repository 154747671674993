import { useEffect } from 'react';
import { mapScenesToJSON } from '../../areas/main/state/experience/sliceSaga';
import {
  AssetTypes,
  EnvironmentProperties,
  ObjectMetadata,
  SceneProperties,
} from '../../areas/main/types/models';
import { unityContext } from '../../areas/main/views/preview';
import useSelector from '../useSelector';
import { NodeViewSceneData } from '@strivr/player-models/lib/types/NodeViewSceneData';
import {
  getCurrentlySelectedSceneId,
  getSignedUrlFromData,
  SignedUrlAssetData,
} from '../../areas/main/state/preview/utils';
import {
  PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT,
  UnityPreviewAPIMethods,
} from '../../areas/main/state/preview/unityMessages/utils';
import useDispatch from '../useDispatch';
import sliceReducer from '../../areas/main/state/preview/sliceReducer';
import { CONTENT_VERSION_FLAGS } from '../../../src/areas/main/constants';
import { checkContentVersion } from '../../utils/contentVersion';
import {
  useAllAssetVersionsByType,
  useAnimationBundleSignedUrl,
} from '../useOldAssetVersions';

const checkIfCGMetaDataExists = (
  scenes: Record<string, SceneProperties>,
  assetListsByType: Record<AssetTypes, ObjectMetadata[]>,
  sceneId: string,
  env: EnvironmentProperties | null,
) => {
  if (!Object.keys(scenes).includes(sceneId)) {
    return null;
  }

  const asset = assetListsByType['environment-assetbundle']?.find(
    (a) => a.versionId === env?.environmentAssetVersionId,
  );

  return Boolean(asset?.cgMetadata);
};

const setURLsForAssets = (
  sceneData: NodeViewSceneData,
  signedUrlAssetData: SignedUrlAssetData,
  contentVersion: number | undefined,
) => {
  sceneData.environments?.forEach((env) => {
    if (env.environment_asset !== null && env.environment_asset !== undefined) {
      const envVersionId = env.environment_asset?.asset_id ?? '';
      env.environment_asset.url = getSignedUrlFromData(
        envVersionId,
        signedUrlAssetData,
        'environment-assetbundle',
      );
    }
  });
  sceneData.characters?.forEach((char) => {
    if (char.entity_asset !== null && char.entity_asset !== undefined) {
      const charVersionId = char.entity_asset?.asset_id ?? '';
      char.entity_asset.url = getSignedUrlFromData(
        charVersionId,
        signedUrlAssetData,
        checkContentVersion(contentVersion, CONTENT_VERSION_FLAGS.genericRig)
          ? 'generic-character-assetbundle'
          : 'character-assetbundle',
      );
    }
  });
};

const useCurrentlySelectedSceneToLoadSceneInPlayer = () => {
  const state = useSelector((state) => state);
  const { scenes } = state.main.sceneProperties;
  const currSelected = state.main.nodes.elementsSelected;
  const currSceneId = getCurrentlySelectedSceneId(currSelected, scenes);
  // Change this when we need to support multiple environments
  const currEnv = scenes[currSceneId]?.environments[0] ?? null;
  const contentVersion = state.main.experience.content_version;
  const dispatch = useDispatch();

  // If the scene has changed, destroy currently loaded operation container
  useEffect(() => {
    if (unityContext.unityInstance) {
      unityContext.send(
        PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT,
        UnityPreviewAPIMethods.OperationContainerDeleted,
      );
      unityContext.send(
        PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT,
        UnityPreviewAPIMethods.ChangePerspective,
        JSON.stringify(null),
      );

      // In case there's a web preview Unity error,
      // clear unity Preview error message when you switch scenes,
      // since that will probably fix the error by resetting loaded data.
      // (We also advise that you do this in this error message, which
      // is also why we should remove this message at this point.)
      // Also, make sure this dispatch to update the unityError field
      // is inside a unityContext.unityInstance check, otherwise closing
      // or moving the preview window may cause a wasm error.
      dispatch(
        sliceReducer.actions.updateErrors({
          field: 'unityError',
          error: '',
        }),
      );
    }
  }, [currSceneId, dispatch]);

  const areAllAssetBundleSignedURLsFetched = useSelector(
    (state) => state.main.preview.areAllAssetBundleSignedURLsFetched,
  );
  const { operationContainers } = state.main;
  const {
    areAnimationsLoaded,
    fullPreviewExperienceJson,
    isPlayerPreviewReady,
    signedUrls,
  } = state.main.preview;
  const unformattedScenes = state.main.sceneProperties.scenes;
  const allAssetsByType = useAllAssetVersionsByType();
  const signedUrlAssetData = { signedUrls, assetListsByType: allAssetsByType };

  const hasAnimationBundleSignedUrl = useAnimationBundleSignedUrl();

  const isCGMetadataPresent = checkIfCGMetaDataExists(
    scenes,
    allAssetsByType,
    currSceneId,
    currEnv,
  );
  const isNoEnvironment = !currEnv?.environmentAssetVersionId;
  let sceneJsonData = '';

  // It is possible for a scene to contain an operation container that was
  // recently deleted but hasn't yet been removed from the scene yet. This
  // ensures that we don't map the scene json until the containers are valid.
  const validOperationContainerIds = Object.keys(
    operationContainers.containers,
  );

  const areOperationContainersInAllScenesValid = Object.keys(
    unformattedScenes,
  ).every(
    (sceneId) =>
      unformattedScenes[sceneId]?.operationContainerIds.every((containerId) =>
        validOperationContainerIds.includes(containerId),
      ),
  );

  if (
    (isCGMetadataPresent || isNoEnvironment) &&
    areOperationContainersInAllScenesValid
  ) {
    const formattedSceneData = mapScenesToJSON(
      scenes,
      operationContainers,
      state,
      signedUrlAssetData,
      true,
    );
    const currSceneData = formattedSceneData.find(
      (scene) => scene.id === currSceneId,
    );

    if (currSceneData !== undefined) {
      currSceneData.operation_containers = [];
      currSceneData.self_avatar = null;
      currSceneData.name = '';

      setURLsForAssets(currSceneData, signedUrlAssetData, contentVersion);
      sceneJsonData = JSON.stringify(currSceneData);
    }
  }

  const shouldSendLoadScene =
    sceneJsonData !== '' &&
    areAllAssetBundleSignedURLsFetched &&
    areAnimationsLoaded &&
    hasAnimationBundleSignedUrl &&
    ((isCGMetadataPresent ?? false) || isNoEnvironment) &&
    isPlayerPreviewReady &&
    !fullPreviewExperienceJson &&
    !!unityContext.unityInstance;

  useEffect(() => {
    // need to check if a Unity instance is present again and put
    // action dispatches that follow in this check too
    if (shouldSendLoadScene && !!unityContext.unityInstance) {
      dispatch(sliceReducer.actions.update({ isSceneReady: false }));

      unityContext.send(
        PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT,
        UnityPreviewAPIMethods.LoadScene,
        sceneJsonData,
      );

      dispatch(sliceReducer.actions.update({ attemptedToLoadAScene: true }));
    }
  }, [dispatch, sceneJsonData, shouldSendLoadScene]);
};

export default useCurrentlySelectedSceneToLoadSceneInPlayer;
