import { AnimationOperation, Operation } from '../areas/main/types/operations';
import { OperationType } from '../areas/main/enums/models';
import {
  ANIMATION_BUFFER_TIME_DEVICE,
  ANIMATION_BUFFER_TIME_INTERNAL,
} from '../areas/main/constants';
import { AnimationLibrary } from '../areas/main/types/models';
import { logToServer } from './logging';

export const calculateDuration = (
  op: Operation,
  operations: Record<string, Operation>,
  animationLibrary: AnimationLibrary
) => {
  switch (op.type) {
    case OperationType.Animation: {
      const allOpsInStack = Object.values(operations);
      if (allOpsInStack.some((o) => !o)) {
        logToServer('warn', 'calculateDuration skipped empty operation', {
          operations,
          op,
          animationLibrary,
        });
      }
      const otherOpsInStack = allOpsInStack.filter(
        (o) => o && o.containerId === op.containerId && o.id !== op.id
      );
      const nextOp = otherOpsInStack.reduce((acc, cur) => {
        if (
          cur.type === OperationType.Animation &&
          animationLibrary[cur.animation.clipName] &&
          (cur.delayStartTime === null ||
            (cur.delayStartTime > (op.delayStartTime ?? 0) &&
              (!acc?.delayStartTime ||
                cur.delayStartTime < acc.delayStartTime)))
        )
          return cur;
        return acc;
      }, null as AnimationOperation | null);
      const curAnimation = animationLibrary[op.animation.clipName];
      if (!curAnimation) return null;

      const curDuration = parseFloat(curAnimation.Duration);
      if (!nextOp) return curDuration;

      const delayBufferTime =
        ANIMATION_BUFFER_TIME_DEVICE + ANIMATION_BUFFER_TIME_INTERNAL;
      const nextAnimation = animationLibrary[nextOp.animation.clipName];
      const isBaseTransition = curAnimation.Mood !== nextAnimation.Mood;
      if (!isBaseTransition) return curDuration + delayBufferTime;

      // TODO (?): Add base transition duration + additional delay buffer
      return curDuration + delayBufferTime;
    }
    case OperationType.NotificationDialog2: {
      return op.showButton
        ? null
        : (op.delayStartTime ?? 0) + (op.maxDuration ?? 0);
    }
    case OperationType.CharacterLine: {
      // TODO (?): Need duration metadata for lipsync bundles
      return null;
    }
    default: {
      return null;
    }
  }
};
