import {
  Connection,
  Edge,
  Elements,
  FlowElement,
  Node,
  OnLoadParams,
  removeElements,
} from 'react-flow-renderer';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { put, select } from 'typed-redux-saga';
import {
  ActionCreatorWithoutPayload,
  ActionCreatorWithPayload,
  PayloadAction,
} from '@reduxjs/toolkit';

import sliceReducer from './sliceReducer';

import experienceSliceReducer from '../experience/sliceReducer';
import operationContainerSliceReducer from '../operationContainers/sliceReducer';
import scenePropertiesSliceReducer from '../sceneProperties/sliceReducer';
import undoSliceReducer from '../undo/sliceReducer';

import scenesSliceSaga, {
  DuplicateScenePayload,
} from '../sceneProperties/sliceSaga';
import operationsSliceSaga, {
  DuplicateOperationPayload,
} from '../operationContainers/sliceSaga';
import previewSliceReducer from '../preview/sliceReducer';

import { State } from '../../../../state/reducer';
import {
  BaseNodeData,
  NodeData,
  NodeType,
  OperationNodeData,
  StackItem,
  StackNode,
  StackNodeData,
  TypedNode,
  TypedNonSceneNode,
} from '../../types/nodeData';
import {
  emptyGuid,
  generateGuid,
  getIncrementingId,
} from '../../../../utils/id';
import { filterEdges, filterNodes, filterScenes } from '../../../../utils/node';
import { SceneProperties } from '../../types/models';
import {
  McqChoice,
  McqOperation,
  Operation,
  OperationContainer,
  PlaybackOperation,
  PlaybackPhaseOperation,
} from '../../types/operations';
import { setDifference } from '../../../../utils/setOperations';
import {
  McqQuestionType,
  OperationOutputType,
  OperationType,
} from '../../enums/models';
import { NODES_ERROR_KEYS } from './errorKeys';
import {
  ADD_NODE_CONTEXT_MENU_Y_OFFSET,
  ADD_SCENE_CONTEXT_MENU_Y_OFFSET,
  EXPAND_SCENE_BUFFER,
  IN_HANDLE_PREFIX,
  INITIAL_SCENE_NODE_HEIGHT,
  INITIAL_SCENE_NODE_WIDTH,
  MCQ_MIN_ANSWERS,
  NODE_BUTTON_HEIGHT,
  NODE_HEIGHT,
  NODE_HEIGHT_OFFSET,
  NODE_WIDTH_OFFSET,
  nodeTypesDisableOutConnectionOnStacking,
  OUT_HANDLE_PREFIX,
  UNSTACK_NODES_X_OFFSET,
  UNSTACK_NODES_Y_OFFSET,
} from '../../constants';
import { getIdsFromHandles } from '../../../../utils/edge';
import { logToServer } from '../../../../utils/logging';
import { withUndoSaga } from '../../../../utils/middleware';

interface AddNode {
  nodeType: NodeType;
  position: { x: number; y: number };
  reactFlowInstance: OnLoadParams | null;
  reactFlowBounds: { left: number; top: number };
}

interface AddScene {
  position: { x: number; y: number };
  reactFlowInstance: OnLoadParams | null;
  reactFlowBounds: { left: number; top: number };
}

interface StackNodePayload {
  targetNodeId: string;
}

interface PasteNodes {
  position: { x: number; y: number };
  reactFlowInstance: OnLoadParams | null;
  reactFlowBounds: { left: number; top: number };
  usedShortcutKey?: boolean;
}

interface UnstackNode {
  shouldDeleteAfterUnstack: boolean;
  position?: { x: number; y: number };
  reactFlowInstance?: OnLoadParams | null;
  reactFlowBounds?: { left: number; top: number };
}

interface AddNodeToPlaybackPhasePayload {
  nodeId: string;
}

interface AddNodeToPlaybackNodePayload {
  nodeId: string;
}

interface RemoveMcqAnswer {
  mcqOpId: string;
  mcqAnswerRid: string;
}

interface UpdateConnections {
  removeUndoStateLockAfterUpdate?: boolean;
}

interface DragNode {
  endDrag: boolean;
  node: Node<NodeData>;
}

// Add Operations Step
const getOperationTypeFromNodeTypeMapping = (
  nodeType: Exclude<NodeType, 'scene' | 'stack'>,
) =>
  ({
    mcq: OperationType.MultipleChoiceQuestion,
    animation: OperationType.Animation,
    'character line': OperationType.CharacterLine,
    overlay: OperationType.NotificationDialog2,
    'learner response': OperationType.LearnerResponse,
    'playback phase': OperationType.PlaybackPhase,
    playback: OperationType.PlaybackNode,
    video: OperationType.Video,
  })[nodeType];

/**
 * Example of how to calculate the `x` position for any new scene within the node view of the creator
 * tab layout
┌───────────────────────┬────────────────────────────────────────────────────────────────────────────────┐
│  Properties           │  Node view  (this node view box is the react-flow wrapper)                     │
│                       │                                                                                │
│                       │             *────────────────────────────────────────────────────┐             │
│ reactFlowBounds.left  │             │                                                    │             │
◄───────────────────────┤             │           Scene                                    │             │
│                       │             │                                                    │             │
│               contentMenuPos.x (e.clientX)                                               │             │
◄───────────────────────┬─────────────┐                                                    │             │
│                       │             │                                                    │             │
│                       │             │                                                    │             │
│                       xxxxxxxxxxxxxxx────────────────────────────────────────────────────┘             │
│                       │                                                                                │
│                       │             xxxxxxxxxxxxxxx = scene `x` value                                  │
│                       │             *triggered new scene here                                          │
│                       ├────────────────────────────────────────────────────────────────────────────────┤
│                       │ Preview                                                                        │
│                       │                                                                                │
│                       │  Note:                                                                         │
│                       │  1. Scene position is relative to origin (0,0) for the react-flow graph area   │
│                       │  2. e.clientX corresponds to where you triggered adding the scene node         │
│                       │  3. reactFlowBounds.left = left edge of react-flow boundary to browser edge    │
│                       │  4. End of arrow is the edge of the browser window                             │
│                       │                                                                                │
└───────────────────────┴────────────────────────────────────────────────────────────────────────────────┘
 *
 *
 * Example of how to calculate the `x` position for any node within a scene within the node view
 * of the creator tab layout
┌───────────────────────┬────────────────────────────────────────────────────────────────────────────────┐
│  Properties           │  Node view  (this node view box is the react-flow wrapper)                     │
│                       │                                                                                │
│                       │             ┌────────────────────────────────────────────────────┐             │
│ reactFlowBounds.left  │             │                                                    │             │
◄───────────────────────┤             │                     Scene                          │             │
│                       │ scenePos.x (x zoomScale)                                         │             │
│                       ◄─────────────┘                                                    │             │
│                       │             |                                                    │             │
│                       │             │                                                    │             │
│                       │             │                                                    │             │
│              contentMenuPos.x (e.clientX)   *──────────────┐                             │             │
◄───────────────────────┬─────────────┬───────┤              │                             │             │
│                       │             │       │   Overlay    │                             │             │
│                       │             │       │              │                             │             │
│                       │             xxxxxxxxx──────────────┘                             │             │
│                       │             │                                                    │             │
│                       │             └────────────────────────────────────────────────────┘             │
│                       │                                                                                │
│                       │             xxxxxxxxx = overlay `x` value                                      │
│                       │             *triggered new overlay here                                        │
│                       ├────────────────────────────────────────────────────────────────────────────────┤
│                       │ Preview                                                                        │
│                       │                                                                                │
│                       │  Note:                                                                         │
│                       │  1. Overlay position is relative to scene                                      │
│                       │  2. Scene position is relative to origin (0,0) for the react-flow graph area   │
│                       │  3. e.clientX corresponds to where you triggered adding the overlay node       │
│                       │  4. reactFlowBounds.left = left edge of react-flow boundary to browser edge    │
│                       │  5. End of arrow is the edge of the browser window                             │
│                       │                                                                                │
└───────────────────────┴────────────────────────────────────────────────────────────────────────────────┘
 *
 * Note: `contentMenuPos` is the e.clientX value (e: MouseEvent) of the onOpen handler of the ContextMenu.
 * An example in the react-flow documents use e.clientX / e.clientY: (https://reactflow.dev/docs/examples/drag-and-drop/).
 * https://developer.mozilla.org/en-US/docs/Web/API/MouseEvent/clientX
 */
const getNewNodePositionPreviewEnabled = (
  reactFlowInstance: OnLoadParams | null | undefined,
  contextMenuPos: { x: number; y: number } | undefined,
  reactFlowBounds: { left: number; top: number } | undefined,
  scenePos?: { x: number; y: number },
): { x: number; y: number } => {
  let nodePosition = { x: 0, y: 0 };

  if (!reactFlowInstance || !contextMenuPos || !reactFlowBounds)
    return nodePosition;

  nodePosition = {
    x: contextMenuPos.x - reactFlowBounds.left,
    y: contextMenuPos.y - reactFlowBounds.top,
  };

  // for operation nodes, we also need to subtract the scene position
  if (scenePos) {
    const zoomScale = reactFlowInstance.toObject().zoom;
    nodePosition = {
      x: nodePosition.x - scenePos.x * zoomScale,
      y: nodePosition.y - scenePos.y * zoomScale,
    };
  }

  return reactFlowInstance.project(nodePosition);
};

const getNewNodePosition = (
  reactFlowInstance: OnLoadParams | null | undefined,
  contextMenuPos: { x: number; y: number } | undefined,
  scenePos?: { x: number; y: number },
): { x: number; y: number } => {
  if (!reactFlowInstance || !contextMenuPos) return { x: 0, y: 0 };

  // for operation node
  if (scenePos) {
    const zoomScale = reactFlowInstance.toObject().zoom;
    return reactFlowInstance.project({
      x: contextMenuPos.x - scenePos.x * zoomScale,
      y:
        contextMenuPos.y -
        scenePos.y * zoomScale -
        ADD_NODE_CONTEXT_MENU_Y_OFFSET,
    });
  }

  // for scene node
  return reactFlowInstance.project({
    x: contextMenuPos.x,
    y: contextMenuPos.y - ADD_SCENE_CONTEXT_MENU_Y_OFFSET,
  });
};

const mapOperationTypeHeight = (operationType: OperationType): number => {
  switch (operationType) {
    case OperationType.Animation:
      return NODE_HEIGHT + NODE_BUTTON_HEIGHT;
    case OperationType.CharacterLine:
      return NODE_HEIGHT;
    case OperationType.LearnerResponse:
      return NODE_HEIGHT + NODE_BUTTON_HEIGHT;
    case OperationType.MultipleChoiceQuestion:
      // By default, we'll just assume the MCQ will have min height,
      // as this function will usually be used for fresh nodes. If
      // the node is pre-existing, or copied from something pre-
      // existing, it should use `getNodeHeight` below.
      return NODE_HEIGHT + MCQ_MIN_ANSWERS * NODE_BUTTON_HEIGHT;
    case OperationType.NotificationDialog2:
      return NODE_HEIGHT + NODE_BUTTON_HEIGHT;
    case OperationType.PlaybackNode || OperationType.PlaybackPhase:
      return NODE_HEIGHT;
    default:
      return 0;
  }
};

const getOperationHeight = (op: Operation): number => {
  switch (op.type) {
    case OperationType.Animation:
      return NODE_HEIGHT + (op.isOutputEnabled ? NODE_BUTTON_HEIGHT : 0);
    case OperationType.CharacterLine:
      return NODE_HEIGHT;
    case OperationType.LearnerResponse:
      return NODE_HEIGHT + NODE_BUTTON_HEIGHT;
    case OperationType.MultipleChoiceQuestion:
      return NODE_HEIGHT + op.children.length * NODE_BUTTON_HEIGHT;
    case OperationType.NotificationDialog2:
      return NODE_HEIGHT + (op.showButton ? NODE_BUTTON_HEIGHT : 0);
    case OperationType.PlaybackNode || OperationType.PlaybackPhase:
      return NODE_HEIGHT;
    default:
      return 0;
  }
};

const getNodeHeight = (
  node: TypedNonSceneNode | StackItem,
  operations: Partial<Record<string, Operation>>,
): number => {
  const nodeType = 'type' in node ? node.type : node.nodeType;

  if (!nodeType || (nodeType as NodeType) === 'scene') {
    return 0;
  }

  if (nodeType === 'stack') {
    return ((node as StackNode).data?.items ?? []).reduce((acc, item) => {
      return (
        acc +
        ((item.nodeType as NodeType) !== 'stack' &&
        (item.nodeType as NodeType) !== 'scene'
          ? getNodeHeight(item, operations)
          : 0)
      );
    }, 0);
  }

  const operation = operations[node.id];

  if (operation) {
    return getOperationHeight(operation);
  }

  return mapOperationTypeHeight(getOperationTypeFromNodeTypeMapping(nodeType));
};

type SceneResizeData = [
  {
    x: number;
    y: number;
  },
  number,
  number,
];

/**
 * Given information about a node's position and size, determine
 * if a scene needs to be resized to accommodate it.
 * @param nodeHeight
 * @param nodePosition
 * @param scenePosition
 * @param sceneWidth
 * @param sceneHeight
 * @returns The scene's new position, width, and height,
 * or `undefined` if the scene doesn't need to be resized
 */
const shouldResizeScene = (
  nodeHeight: number,
  nodePosition: { x: number; y: number },
  scenePosition: { x: number; y: number },
  sceneWidth: number | undefined,
  sceneHeight: number | undefined,
): SceneResizeData | undefined => {
  if (sceneHeight !== undefined && sceneWidth !== undefined) {
    const newPos = { ...scenePosition };
    let newWidth = sceneWidth;
    let newHeight = sceneHeight;
    // /**
    //  * NOTE: Trying to resize a scene to the top and left to accommodate
    //  * its inner nodes is *not* trivial. It requires not only moving the
    //  * entire scene, but also all of the nodes in it to reposition them
    //  * to their original location. Because of that, this functionality
    //  * is disabled, but left in place to serve as a tombstone in case
    //  * somebody decides to try this again, and/or pick up this work.
    //  */
    // if (nodePosition.x - EXPAND_SCENE_BUFFER < scenePosition.x) {
    //   newPos.x = nodePosition.x - EXPAND_SCENE_BUFFER;
    // }
    // if (nodePosition.y - EXPAND_SCENE_BUFFER < scenePosition.y) {
    //   newPos.y = nodePosition.y - EXPAND_SCENE_BUFFER;
    // }
    if (nodePosition.x + NODE_WIDTH_OFFSET > sceneWidth) {
      newWidth = nodePosition.x + NODE_WIDTH_OFFSET + EXPAND_SCENE_BUFFER;
    }
    if (nodePosition.y + nodeHeight + NODE_HEIGHT_OFFSET > sceneHeight) {
      newHeight =
        nodePosition.y + nodeHeight + NODE_HEIGHT_OFFSET + EXPAND_SCENE_BUFFER;
    }
    return [newPos, newWidth, newHeight];
  }

  return undefined;
};

const sliceSaga = createSliceSaga({
  name: sliceReducer.name,
  caseSagas: {
    addNode: {
      sagaType: SagaType.TakeEvery,
      *fn(action: PayloadAction<AddNode>) {
        const state: State = yield* select();
        const { elements, elementsSelected } = state.main.nodes;
        const { startingSceneId, startingOperationContainerId } =
          state.main.experience;
        const { isPreviewEnabled } = state.main.preview;

        const scenes = filterScenes(elementsSelected);
        const selectedScene = scenes[0];
        const nodes = filterNodes(elementsSelected);
        const selectedNode = nodes[0];
        let updatedElements = [...elements];

        // We need a single target scene to paste to
        // Failing that, we use the parent of the first selected node
        if (
          (!selectedScene && !selectedNode) ||
          action.payload.nodeType === 'scene' ||
          action.payload.nodeType === 'stack'
        )
          return;

        const id = generateGuid();
        const containerId = generateGuid();
        const sceneId = selectedScene
          ? selectedScene.id
          : selectedNode.parentId || '';
        const operationType = getOperationTypeFromNodeTypeMapping(
          action.payload.nodeType,
        );

        // elementsSelected may not have updated the scene position at this point, but elements will always be up to date
        const currScene = filterScenes(elements).filter(
          (e) => e.id === sceneId,
        )[0];

        const scenePosition = currScene.position;
        const newPos = !isPreviewEnabled
          ? getNewNodePosition(
              action.payload.reactFlowInstance,
              action.payload.position,
              scenePosition,
            )
          : getNewNodePositionPreviewEnabled(
              action.payload.reactFlowInstance,
              action.payload.position,
              action.payload.reactFlowBounds,
              scenePosition,
            );

        const scenePos = currScene.position;
        const sceneHeight = currScene.data?.height;
        const sceneWidth = currScene.data?.width;

        const nodeHeight = mapOperationTypeHeight(operationType);
        const sceneResizeData = shouldResizeScene(
          nodeHeight,
          newPos,
          scenePos,
          sceneWidth,
          sceneHeight,
        );
        if (sceneResizeData) {
          const [newScenePosition, newSceneWidth, newSceneHeight] =
            sceneResizeData;

          updatedElements = updatedElements.filter((e) => e.id !== sceneId);
          const resizedScene = {
            ...currScene,
            position: newScenePosition,
            data: {
              ...currScene?.data,
              width: newSceneWidth,
              height: newSceneHeight,
            },
          };
          updatedElements = [...updatedElements, resizedScene];
        }

        const newElement = {
          data: {
            containerId,
          },
          id,
          parentId: sceneId,
          position: newPos,
          type: action.payload.nodeType,
        } as TypedNode;

        yield* put(
          sliceReducer.actions.update({
            elements: [...updatedElements, newElement],
            elementsSelected: [newElement],
          }),
        );

        const hasStartNode =
          startingOperationContainerId &&
          startingOperationContainerId !== emptyGuid;
        yield* put(
          experienceSliceReducer.actions.update({
            startingSceneId: startingSceneId ?? sceneId,
            startingOperationContainerId: hasStartNode
              ? startingOperationContainerId
              : containerId,
          }),
        );

        yield* put(
          scenesSliceSaga.actions.addContainers({
            containerIds: [containerId],
            sceneId,
          }),
        );
        yield* put(
          operationsSliceSaga.actions.addOperations({
            ids: [id],
            containerId,
            type: operationType,
          }),
        );
      },
    },

    addScene: {
      sagaType: SagaType.TakeEvery,
      *fn(action: PayloadAction<AddScene>) {
        const state: State = yield* select();
        const { elements } = state.main.nodes;
        const { isPreviewEnabled } = state.main.preview;
        const id = generateGuid();

        const newPos = !isPreviewEnabled
          ? getNewNodePosition(
              action.payload.reactFlowInstance,
              action.payload.position,
            )
          : getNewNodePositionPreviewEnabled(
              action.payload.reactFlowInstance,
              action.payload.position,
              action.payload.reactFlowBounds,
            );

        const newElement = {
          data: {
            height: INITIAL_SCENE_NODE_HEIGHT,
            width: INITIAL_SCENE_NODE_WIDTH,
          },
          id,
          position: newPos,
          type: 'scene',
        };

        yield* put(
          sliceReducer.actions.update({
            elements: [...elements, newElement],
            elementsSelected: [newElement],
          }),
        );

        yield* put(
          sliceReducer.actions.updateMostRecentlyTouchedSceneIds({
            id: newElement.id,
          }),
        );

        const envId = generateGuid();
        yield* put(scenesSliceSaga.actions.addScene({ envId, id }));
      },
    },

    copySelectedNodes: {
      sagaType: SagaType.TakeEvery,
      *fn() {
        const state: State = yield* select();
        const {
          elements,
          elementsSelected,
          stackItemSelected,
          stackSelectedId,
        } = state.main.nodes;
        const { scenes } = state.main.sceneProperties;
        const { operations, containers } = state.main.operationContainers;

        // If we have a stack item selected, we need to handle it separately.
        if (stackItemSelected && Object.keys(stackItemSelected).length > 0) {
          // No element is technically selected so we have to make our own
          // Fine, I'll do it myself - Thanos
          // Majority of this code to create a new container/stack is from unstack
          const originalStack = filterNodes(elements).find(
            (e) => e.id === stackSelectedId,
          );
          if (!originalStack) {
            return;
          }
          const operationElement = {
            data: {
              ...stackItemSelected.data,
              containerId: generateGuid(),
            } as OperationNodeData,
            id: stackItemSelected.id,
            parentId: originalStack.parentId,
            position: { x: 0, y: 0 },
            type: stackItemSelected.nodeType,
          } as TypedNode;
          const newContainer: OperationContainer = {
            id: (<OperationNodeData>operationElement.data).containerId,
            inputIds: [],
            operationIds: [operationElement.id],
          };
          const originalOperation = operations[operationElement.id];
          // If we're taking a mcq/animation/overlay/character line node that has
          // out connection toggled off, we need to enable it again since
          // standalone nodes' out connection toggled is disabled, and they should be on
          const newOperation = {
            ...originalOperation,
            ...(originalOperation.outputType ===
            OperationOutputType.SingleOutput
              ? {
                  isOutputEnabled: true,
                  isActivityFinished: true,
                }
              : originalOperation.outputType === OperationOutputType.NoOutput
                ? {}
                : {
                    isOutputEnabled: true,
                    children: [
                      ...originalOperation.children.map((c) => {
                        return {
                          ...c,
                          isActivityFinished: true,
                        };
                      }),
                    ],
                  }),
          };

          yield* put(
            operationContainerSliceReducer.actions.update({
              copiedContainers: { [newContainer.id]: newContainer },
              copiedOperations: {
                [operationElement.id]: newOperation,
              },
            }),
          );
          yield* put(
            sliceReducer.actions.update({
              elementsCopied: [operationElement],
            }),
          );
        } else {
          const childNodes = filterScenes(elementsSelected).reduce(
            (acc, cur) => [
              ...acc,
              ...filterNodes(elements).filter((e) => e.parentId === cur.id),
            ],
            [] as Node<OperationNodeData | StackNodeData>[],
          );

          const copiedScenes = filterScenes(elementsSelected).reduce(
            (acc, curr) => ({
              ...acc,
              [curr.id]: scenes[curr.id],
            }),
            {} as Record<string, SceneProperties>,
          );

          yield* put(
            scenePropertiesSliceReducer.actions.update({
              copiedScenes,
            }),
          );
          const selectedNodes = filterNodes(
            elementsSelected,
          ) as Node<OperationNodeData>[];
          const nodesToCopy: Node<OperationNodeData | StackNodeData>[] = [
            ...childNodes,
            ...selectedNodes,
          ];

          // We not only need to get all the containers that are currently selected but also all the child nodes
          const copiedContainers = nodesToCopy.reduce(
            (acc, curr) => {
              const containerId =
                curr.type === 'stack'
                  ? curr.id
                  : (curr as Node<OperationNodeData>).data?.containerId || '';
              return {
                ...acc,
                [containerId]: containers[containerId],
              };
            },
            {} as Record<string, OperationContainer>,
          );
          const copiedOperations = nodesToCopy.reduce(
            (acc, curr) => {
              const containerId =
                curr.type === 'stack'
                  ? curr.id
                  : (curr as Node<OperationNodeData>).data?.containerId || '';
              return {
                ...acc,
                ...containers[containerId].operationIds.reduce(
                  (list, operationId) => ({
                    ...list,
                    [operationId]: operations[operationId],
                  }),
                  {} as Record<string, Operation>,
                ),
              };
            },
            {} as Record<string, Operation>,
          );

          const edges = filterEdges(elements);
          const copiedEdges = edges.filter(
            (e) =>
              [...elementsSelected, ...childNodes].some(
                (elem) => e.source === elem.id,
              ) &&
              [...elementsSelected, ...childNodes].some(
                (elem) => e.target === elem.id,
              ),
          );

          yield* put(
            operationContainerSliceReducer.actions.update({
              copiedContainers,
              copiedOperations,
            }),
          );
          yield* put(
            sliceReducer.actions.update({
              elementsCopied: [
                ...elementsSelected,
                ...childNodes,
                ...copiedEdges,
              ],
            }),
          );
        }
      },
    },

    pasteSelectedNodes: {
      sagaType: SagaType.TakeEvery,
      *fn(action: PayloadAction<PasteNodes>) {
        const state: State = yield* select();
        const { elements, elementsCopied, elementsSelected } = state.main.nodes;
        const { isPreviewEnabled } = state.main.preview;
        const { operations } = state.main.operationContainers;
        let updatedElements = [...elements];

        const copies: TypedNode[] = [];
        const copiedScenes = filterScenes(elementsCopied);
        const copiedNodes = filterNodes(elementsCopied);

        const sceneModelsToPaste = [] as Array<DuplicateScenePayload>;
        const operationModelsToPaste = [] as Array<DuplicateOperationPayload>;

        // Map old guids to new ones to paste edges properly
        const idMapping = {} as Record<string, string>;

        // If = Pasting scene, Else = Pasting nodes
        // TODO: Consolidate common logic for pasting nodes in both cases to reduce duplication
        if (copiedScenes.length > 0) {
          for (const scene of copiedScenes) {
            const newSceneId = generateGuid();
            idMapping[scene.id] = newSceneId;
            const sceneModelPastePayload = {
              newId: newSceneId,
              newName: `Untitled ${getIncrementingId('scene')}`,
              idToDuplicate: scene.id,
              containerIds: [] as Array<string>,
            };

            const newPos = !isPreviewEnabled
              ? getNewNodePosition(
                  action.payload.reactFlowInstance,
                  action.payload.position,
                )
              : getNewNodePositionPreviewEnabled(
                  action.payload.reactFlowInstance,
                  action.payload.position,
                  action.payload.reactFlowBounds,
                );

            copies.push({
              ...scene,
              data: {
                ...scene.data,
              },
              id: newSceneId,
              position: newPos,
            });
            const children = copiedNodes.filter((e) => e.parentId === scene.id);

            for (const child of children) {
              const newChildId = generateGuid();
              idMapping[child.id] = newChildId;
              if (child.type === 'stack') {
                const newNode = {
                  ...child,
                  data: {
                    ...child.data,
                    items: [] as StackItem[],
                  },
                  id: newChildId,
                  parentId: newSceneId,
                };

                for (const item of child.data?.items ?? []) {
                  const newOperationId = generateGuid();
                  idMapping[item.id] = newOperationId;
                  newNode.data.items.push({
                    data: {
                      ...item.data,
                      containerId: newChildId,
                    },
                    id: newOperationId,
                    nodeType: item.nodeType,
                  });
                  operationModelsToPaste.push({
                    newId: newOperationId,
                    newContainerId: newChildId,
                    newName: `Untitled ${getIncrementingId(item.nodeType)}`,
                    idToDuplicate: item.id,
                  });
                }
                sceneModelPastePayload.containerIds.push(newChildId);

                copies.push(newNode);
              } else {
                const newContainerId = generateGuid();
                idMapping[child.data?.containerId || ''] = newContainerId;
                copies.push({
                  ...child,
                  data: {
                    ...child.data,
                    containerId: newContainerId,
                  },
                  id: newChildId,
                  parentId: newSceneId,
                });
                sceneModelPastePayload.containerIds.push(newContainerId);
                operationModelsToPaste.push({
                  newId: newChildId,
                  idToDuplicate: child.id,
                  newContainerId,
                  newName: `Untitled ${getIncrementingId(child.type)}`,
                });
              }
            }

            sceneModelsToPaste.push(sceneModelPastePayload);

            yield* put(
              sliceReducer.actions.updateMostRecentlyTouchedSceneIds({
                id: scene.id,
              }),
            );
          }

          yield* put(
            scenesSliceSaga.actions.duplicateScenes({
              scenes: sceneModelsToPaste,
            }),
          );
          yield* put(
            operationsSliceSaga.actions.duplicateOperations({
              operations: operationModelsToPaste,
              idMapping,
            }),
          );
        } else {
          const selectedScenes = filterScenes(elementsSelected);

          // We need a single target scene to paste to
          if (selectedScenes.length > 1) return;

          // If no scenes are selected but a single scene's node(s) are selecting, try targeting the parent
          if (selectedScenes.length === 0) {
            const selectedNodes = filterNodes(elementsSelected);
            const selectedNodeParentIds = [
              ...new Set(selectedNodes.map((n) => n.parentId)),
            ];
            if (selectedNodeParentIds.length !== 1) return;

            const allScenes = filterScenes(elements);
            const selectedNodeParent = allScenes.find(
              (s) => s.id === selectedNodeParentIds[0],
            );
            if (!selectedNodeParent) return;

            selectedScenes.push(selectedNodeParent);
          }
          const selectedScene = selectedScenes[0];

          let newPos = { x: 0, y: 0 };
          // elementsSelected may not have updated position info, but elements always will
          const currScene = filterScenes(elements).filter(
            (e) => e.id === selectedScene.id,
          )[0];
          if (!action.payload.usedShortcutKey) {
            const scenePosition = currScene.position;
            newPos = !isPreviewEnabled
              ? getNewNodePosition(
                  action.payload.reactFlowInstance,
                  action.payload.position,
                  scenePosition,
                )
              : getNewNodePositionPreviewEnabled(
                  action.payload.reactFlowInstance,
                  action.payload.position,
                  action.payload.reactFlowBounds,
                  scenePosition,
                );
          }

          const sceneHeight = currScene.data?.height;
          const sceneWidth = currScene.data?.width;

          const operationModelsToPaste: DuplicateOperationPayload[] = [];
          let tallestNodeHeight = 0;
          for (const node of copiedNodes) {
            const newId = generateGuid();

            idMapping[node.id] = newId;
            if (node.type === 'stack') {
              const newNode = {
                ...node,
                data: {
                  ...node.data,
                  items: [] as StackItem[],
                },
                id: newId,
                parentId: selectedScene.id,
                position: newPos,
              };

              const stackHeight = getNodeHeight(node, operations);
              for (const item of node.data?.items ?? []) {
                const newOperationId = generateGuid();
                idMapping[item.id] = newOperationId;
                newNode.data.items.push({
                  data: {
                    ...item.data,
                    containerId: newId,
                  },
                  id: newOperationId,
                  nodeType: item.nodeType,
                });
                operationModelsToPaste.push({
                  newId: newOperationId,
                  newContainerId: newId,
                  newName: `Untitled ${getIncrementingId(item.nodeType)}`,
                  idToDuplicate: item.id,
                });
              }
              if (stackHeight > tallestNodeHeight)
                tallestNodeHeight = stackHeight;
              copies.push(newNode);
            } else {
              // In case we're *cut* and pasting the operation won't exist in operations. Use a default height in this case.
              const nodeHeight = getNodeHeight(node, operations);
              if (nodeHeight > tallestNodeHeight)
                tallestNodeHeight = nodeHeight;

              const newContainerId = generateGuid();
              idMapping[node.data?.containerId || ''] = newContainerId;
              copies.push({
                ...node,
                data: {
                  ...node.data,
                  containerId: newContainerId,
                },
                id: newId,
                parentId: selectedScene.id,
                position: newPos,
              });

              operationModelsToPaste.push({
                newId,
                newContainerId,
                newName: `Untitled ${getIncrementingId(
                  node.type ?? 'unknownNode',
                )}`,
                idToDuplicate: node.id,
              });
            }
          }

          // Without making it a set it will place the container id of a stack into the
          // scene as many times as there are operations in that stack
          const uniqueContainerIds = [
            ...new Set(
              operationModelsToPaste.map((model) => model.newContainerId),
            ),
          ];

          yield* put(
            scenesSliceSaga.actions.addContainers({
              sceneId: selectedScene.id,
              containerIds: uniqueContainerIds,
            }),
          );

          yield* put(
            operationsSliceSaga.actions.duplicateOperations({
              operations: operationModelsToPaste,
              idMapping,
            }),
          );

          const sceneResizeData = shouldResizeScene(
            tallestNodeHeight,
            newPos,
            currScene.position,
            sceneWidth,
            sceneHeight,
          );
          if (sceneResizeData) {
            const [newScenePosition, newSceneWidth, newSceneHeight] =
              sceneResizeData;

            updatedElements = updatedElements.filter(
              (e) => e.id !== selectedScene.id,
            );
            const resizedScene = {
              ...currScene,
              position: newScenePosition,
              data: {
                ...currScene.data,
                width: newSceneWidth,
                height: newSceneHeight,
              },
            };
            updatedElements = [...updatedElements, resizedScene];
          }
        }

        const sceneCopies = filterScenes(copies);

        // Copy all the edges
        const newEdges: (Connection | Edge)[] = filterEdges(elementsCopied).map(
          (edge) => {
            const { sourceChildId, sourceOperationId, targetOperationId } =
              getIdsFromHandles(edge);
            return {
              source: idMapping[edge.source],
              sourceHandle: sourceChildId
                ? `${OUT_HANDLE_PREFIX}${idMapping[sourceOperationId]}_${sourceChildId}`
                : `${OUT_HANDLE_PREFIX}${idMapping[sourceOperationId]}`,
              target: idMapping[edge.target],
              targetHandle: `${IN_HANDLE_PREFIX}${idMapping[targetOperationId]}`,
              type: 'customEdge',
            };
          },
        );
        yield* put(
          sliceReducer.actions.update({
            elements: [...updatedElements, ...copies],
            elementsSelected: sceneCopies.length ? sceneCopies : copies,
            edgesPendingAdd: newEdges,
          }),
        );
      },
    },

    deleteSelectedElements: {
      sagaType: SagaType.TakeEvery,
      *fn() {
        const state: State = yield* select();
        const {
          elements,
          elementsSelected,
          stackItemSelected,
          mostRecentlyTouchedSceneIds: currentMostRecentlyTouchedSceneIds,
        } = state.main.nodes;
        const { startingSceneId, startingOperationContainerId } =
          state.main.experience;
        const { operationContainers } = state.main;

        // Deleting a singular item from a stack is a special case in and of itself, so I'll put it here
        if (stackItemSelected && Object.keys(stackItemSelected).length > 0) {
          //We can essentially just unstack it, and then resume regular service
          const currSaga: {
            actions: {
              unstackNode: ActionCreatorWithPayload<UnstackNode>;
            };
          } = sliceSaga;
          yield put(
            currSaga.actions.unstackNode({ shouldDeleteAfterUnstack: true }),
          );
        } else {
          let allElementsToRemove: Elements<BaseNodeData>;

          // we want to delete all currently selected elements
          allElementsToRemove = elementsSelected;

          // this may be modified if any selected elements are scenes
          const toRemoveMostRecentlyTouchedSceneIds: Set<string> = new Set();

          // We want to do two things if we detect a scene node:
          // 1. keep track of any scenes that are deleted so that we remove them from `mostRcecentlyTouchedSceneIds`
          // 2. if any of the selected elements are scene nodes that parent overlay nodes, then delete those overlay nodes too
          // Also note that react-flow's `removeElements` will also remove any edges attached to nodes,
          // so we don't need to do that.
          for (const scene of filterScenes(elementsSelected)) {
            toRemoveMostRecentlyTouchedSceneIds.add(scene.id);

            const sceneNodes: FlowElement[] = filterNodes(elements).filter(
              (node) => node.parentId === scene.id,
            );
            allElementsToRemove = [...allElementsToRemove, ...sceneNodes];
          }

          const operationContainerIds = [] as string[];
          const playbackPhaseContainerIds = [] as string[];
          filterNodes(allElementsToRemove).forEach((node) => {
            if (node.type === 'stack') operationContainerIds.push(node.id);
            else if (node.data) {
              operationContainerIds.push(node.data.containerId);
              if (node.type === 'playback phase')
                playbackPhaseContainerIds.push(node.data.containerId);
            }
          });

          // don't allow users do delete the starting node
          if (
            allElementsToRemove.some((e) => e.id === startingSceneId) ||
            operationContainerIds.some(
              (e) => e === startingOperationContainerId,
            )
          ) {
            yield* put(
              sliceReducer.actions.updateErrors({
                field: NODES_ERROR_KEYS.DELETE_START_NODE,
                error: 'Cannot delete a start node',
              }),
            );
            return;
          }

          // Update other operations to reflect the changes we're making (ie. container id refs in playback phase nodes)
          const updateOperation = (operation: Operation) => {
            switch (operation.type) {
              case OperationType.PlaybackPhase:
                return {
                  ...operation,
                  containersToPlayback: operation.containersToPlayback.filter(
                    (id) => !operationContainerIds.includes(id),
                  ),
                };
              case OperationType.PlaybackNode:
                return {
                  ...operation,
                  phasesToPlayback: operation.phasesToPlayback.filter(
                    (id) => !playbackPhaseContainerIds.includes(id),
                  ),
                };
              default:
                return operation;
            }
          };

          const updatedOperations = Object.entries(
            operationContainers.operations,
          ).reduce(
            (acc, [k, v]) => ({
              ...acc,
              [k]: updateOperation(v),
            }),
            {} as Record<string, Operation>,
          );

          const newMostRecentlyTouchedSceneIds = [
            ...setDifference(
              new Set(currentMostRecentlyTouchedSceneIds),
              toRemoveMostRecentlyTouchedSceneIds,
            ),
          ];

          let newElementsSelected = [] as Elements<BaseNodeData>;
          if (newMostRecentlyTouchedSceneIds.length > 0) {
            // Select the most recently touched scene if there is one
            const scenes = filterScenes(elements);
            newElementsSelected = scenes.filter(
              (s) => s.id === newMostRecentlyTouchedSceneIds[0],
            );
          }

          // Edges that were selected for deletion OR that connected to or from a node which is selected for deletion
          const edges = [
            ...filterEdges(elementsSelected),
            ...filterEdges(elements).filter((edge) =>
              allElementsToRemove.some(
                (elem) => edge.source === elem.id || edge.target === elem.id,
              ),
            ),
          ];

          // Needed so mcq answers get updated properly
          const mcqAnswerTracker: Record<string, McqChoice[]> = {};

          const updateOutputProps = (
            operation: Operation,
            childRid?: string,
          ): Operation => {
            if (operation.outputType === OperationOutputType.NoOutput) {
              return {
                ...operation,
              };
            }
            if (operation.outputType === OperationOutputType.SingleOutput) {
              return {
                ...operation,
                doesNextNodeExist: false,
                isActivityFinished: operation.isOutputEnabled,
                nextId: emptyGuid,
              };
            }

            if (
              operation.type === OperationType.MultipleChoiceQuestion &&
              !childRid
            ) {
              const updatedChildren: McqChoice[] = operation.children.map(
                (c) => {
                  return {
                    ...c,
                    doesNextNodeExist: false,
                    isActivityFinished: true,
                    nextId: emptyGuid,
                  };
                },
              );

              return {
                ...operation,
                children: [...updatedChildren],
              };
            }

            if (!mcqAnswerTracker[operation.id]) {
              mcqAnswerTracker[operation.id] = operation.children;
            }

            const updatedChild: McqChoice = operation.children
              .filter((c) => c.rid === childRid)
              .map((c) => {
                return {
                  ...c,
                  doesNextNodeExist: false,
                  isActivityFinished: true,
                  nextId: emptyGuid,
                };
              })[0];

            const updatedChildren: Array<McqChoice> = mcqAnswerTracker[
              operation.id
            ].filter((c) => c.rid !== childRid);
            updatedChildren.push(updatedChild);
            mcqAnswerTracker[operation.id] = updatedChildren;

            return {
              ...operation,
              children: [...mcqAnswerTracker[operation.id]],
            };
          };

          yield* put(
            operationContainerSliceReducer.actions.update({
              ...operationContainers,
              operations: {
                ...updatedOperations,
                ...edges.reduce(
                  (acc, cur) => {
                    const { sourceChildId, sourceOperationId } =
                      getIdsFromHandles(cur);
                    const sourceOperation =
                      operationContainers.operations[sourceOperationId];
                    return {
                      ...acc,
                      [sourceOperationId]: updateOutputProps(
                        sourceOperation,
                        sourceChildId,
                      ),
                    };
                  },
                  {} as Record<string, Operation>,
                ),
              },
            }),
          );

          yield* put(
            sliceReducer.actions.update({
              elements: removeElements(allElementsToRemove, elements),
              elementsSelected: newElementsSelected,
              mostRecentlyTouchedSceneIds: newMostRecentlyTouchedSceneIds,
            }),
          );

          const sceneIds = filterScenes(elementsSelected).map(
            (sceneNode) => sceneNode.id,
          );
          const operationIds = filterNodes(elementsSelected)
            .filter((n) => n.type !== 'stack')
            .map((node) => node.id);
          //Need to delete stack separately otherwise there will be a silent failure
          const stackContainersToDelete = elementsSelected
            .filter((n) => n.type === 'stack')
            .map((n) => n.id);
          yield* put(
            operationsSliceSaga.actions.deleteContainers({
              ids: stackContainersToDelete,
            }),
          );
          yield* put(
            previewSliceReducer.actions.update({
              shouldRemovePresentContainer: true,
            }),
          );
          yield* put(
            operationsSliceSaga.actions.deleteOperations({ ids: operationIds }),
          );
          yield* put(scenesSliceSaga.actions.deleteScenes({ ids: sceneIds }));
          // In case we delete the last operation in a container, we
          // want to prune out any empty containers from the scene
          // slice.
          yield* put(scenesSliceSaga.actions.removeEmptyContainers());
        }
      },
    },

    setStartNode: {
      sagaType: SagaType.TakeEvery,
      *fn() {
        const state: State = yield* select();
        const { elementsSelected } = state.main.nodes;

        const nodes = filterNodes(elementsSelected);
        const selectedNode = nodes[0];

        // We need a single target operation to make into the start node
        if (!selectedNode) return;

        yield* put(
          experienceSliceReducer.actions.update({
            startingSceneId: selectedNode.parentId,
            startingOperationContainerId:
              selectedNode.type === 'stack'
                ? selectedNode.id
                : selectedNode.data?.containerId,
          }),
        );
      },
    },

    toggleOutput: {
      sagaType: SagaType.TakeEvery,
      *fn(action: PayloadAction<string>) {
        const state: State = yield* select();
        const { operations } = state.main.operationContainers;
        const { elements } = state.main.nodes;
        const operationId = action.payload;
        const operation = operations[operationId];

        if (operation.outputType === OperationOutputType.NoOutput)
          throw new Error(
            'toggleOutput is not supported for no output operations',
          );

        const updatedIsOutputEnabled = !operation.isOutputEnabled;
        const curDoesNextNodeExist =
          operation.outputType !== OperationOutputType.MultipleOutput
            ? operation.doesNextNodeExist
            : operation.children.reduce<boolean>((acc, cur) => {
                return acc || cur.doesNextNodeExist;
              }, false);

        if (!updatedIsOutputEnabled && curDoesNextNodeExist) {
          const edgeToRemove = filterEdges(elements).filter((e) => {
            const { sourceChildId, sourceOperationId } = getIdsFromHandles(e);
            return [sourceChildId, sourceOperationId].includes(operationId);
          });
          yield* put(
            sliceReducer.actions.update({
              elements: removeElements(edgeToRemove, elements),
            }),
          );
        }

        yield* put(
          operationContainerSliceReducer.actions.update({
            operations: {
              ...operations,
              [operationId]: {
                ...operation,
                isOutputEnabled: updatedIsOutputEnabled,
                ...(operation.outputType === OperationOutputType.SingleOutput
                  ? {
                      doesNextNodeExist: updatedIsOutputEnabled
                        ? curDoesNextNodeExist
                        : false,
                      isActivityFinished:
                        updatedIsOutputEnabled && !curDoesNextNodeExist,
                      nextId:
                        updatedIsOutputEnabled && curDoesNextNodeExist
                          ? operation.nextId
                          : emptyGuid,
                    }
                  : {
                      children: [
                        ...operation.children.map((c) => {
                          return {
                            ...c,
                            isActivityFinished:
                              updatedIsOutputEnabled && !curDoesNextNodeExist,
                            doesNextNodeExist: updatedIsOutputEnabled
                              ? curDoesNextNodeExist
                              : false,
                            nextId:
                              updatedIsOutputEnabled && curDoesNextNodeExist
                                ? c.nextId
                                : emptyGuid,
                          };
                        }),
                      ],
                    }),
              },
            },
          }),
        );
      },
    },

    stackNode: {
      sagaType: SagaType.TakeEvery,
      *fn(action: PayloadAction<StackNodePayload>) {
        const state: State = yield* select();
        const { elements, elementsSelected } = state.main.nodes;
        const { startingOperationContainerId } = state.main.experience;
        const { operationContainers, sceneProperties } = state.main;
        const edges = filterEdges(elements);
        const nodes = filterNodes(elements);
        const selectedNodes = filterNodes(elementsSelected);
        const outConnectionToggleNodesIds = selectedNodes
          .filter((node) =>
            nodeTypesDisableOutConnectionOnStacking.includes(node.type),
          )
          .map((node) => node.id);

        const targetNode = nodes.find(
          (n) => n.id === action.payload.targetNodeId,
        );
        if (
          !targetNode?.data ||
          !targetNode.parentId ||
          selectedNodes.some((n) => n.id === targetNode.id)
        ) {
          yield* put(
            sliceReducer.actions.update({
              stacking: {
                isStacking: false,
                stackingId: '',
              },
            }),
          );
          return;
        }

        // If target is a stack, we'll just add to it. Otherwise, we need to create a new stack to add nodes to.
        const newGuid = generateGuid();
        const baseStackNode: StackNode =
          targetNode.type === 'stack'
            ? targetNode
            : {
                data: {
                  items: [
                    {
                      data: {
                        ...targetNode.data,
                        containerId: newGuid,
                      },
                      id: targetNode.id,
                      nodeType: targetNode.type,
                    },
                  ],
                },
                id: newGuid,
                parentId: targetNode.parentId,
                position: targetNode.position,
                type: 'stack',
              };

        // Add the selected nodes to the new or existing target stack
        const updatedTargetStack = {
          ...baseStackNode,
          data: {
            ...baseStackNode.data,
            items: [
              ...(baseStackNode.data?.items ?? []),
              ...selectedNodes.reduce((acc, cur) => {
                if (!cur.data) return acc;
                if (cur.type === 'stack') {
                  return [
                    ...acc,
                    ...cur.data.items.map((item) => ({
                      ...item,
                      data: {
                        ...item.data,
                        containerId: baseStackNode.id,
                      },
                    })),
                  ];
                }
                return [
                  ...acc,
                  {
                    data: {
                      ...cur.data,
                      containerId: baseStackNode.id,
                    },
                    id: cur.id,
                    nodeType: cur.type,
                  },
                ];
              }, [] as StackItem[]),
            ],
          },
        };

        // Update the operationContainers and sceneProperties slices to reflect the changes we're making to nodes
        const containerIdsToRemove = selectedNodes.map((sn) =>
          sn.type === 'stack' ? sn.id : sn.data?.containerId,
        );
        if (targetNode.data && targetNode.type !== 'stack')
          containerIdsToRemove.push(targetNode.data.containerId);
        const operationIdsToStack = updatedTargetStack.data.items.map(
          (i) => i.id,
        );
        const otherContainers = Object.entries(operationContainers.containers)
          .filter(([k]) => !containerIdsToRemove.includes(k))
          .reduce(
            (acc, [k, v]) => ({
              ...acc,
              [k]: v,
            }),
            {} as Record<string, OperationContainer>,
          );
        const existingOperationIds =
          baseStackNode.data?.items.map((i) => i.id) ?? [];
        const addedOperationIds = operationIdsToStack.filter(
          (id) => !existingOperationIds.includes(id),
        );

        // Update other operations to reflect the changes we're making (ie. container id refs in playback phase nodes)
        const updateOperation = (operation: Operation) => {
          switch (operation.type) {
            case OperationType.PlaybackPhase:
              return {
                ...operation,
                containersToPlayback: [
                  ...new Set(
                    operation.containersToPlayback.map((containerId) =>
                      containerIdsToRemove.includes(containerId)
                        ? updatedTargetStack.id
                        : containerId,
                    ),
                  ),
                ],
              };

            default:
              return operation;
          }
        };
        const otherOperations = Object.entries(
          operationContainers.operations,
        ).reduce(
          (acc, [k, v]) => ({
            ...acc,
            [k]: updateOperation(v),
          }),
          {} as Record<string, Operation>,
        );

        // Also update "Out Connection"- for MVP, Overlays and Animation nodes
        // should automatically turn Out Connection off upon stacking
        const stackedOperations = operationIdsToStack.reduce(
          (acc, cur) => {
            const existing = operationContainers.operations[cur];
            return {
              ...acc,
              [cur]: {
                ...existing,
                containerId: updatedTargetStack.id,
                delayStartTime: addedOperationIds.includes(cur)
                  ? null
                  : existing.delayStartTime,
                endTime: addedOperationIds.includes(cur)
                  ? null
                  : existing.endTime,
                ...(existing.outputType === OperationOutputType.SingleOutput
                  ? {
                      doesNextNodeExist: false,
                      isOutputEnabled: outConnectionToggleNodesIds.includes(cur)
                        ? false
                        : existing.isOutputEnabled,
                      isActivityFinished: outConnectionToggleNodesIds.includes(
                        cur,
                      )
                        ? false
                        : existing.isOutputEnabled,
                      nextId: emptyGuid,
                    }
                  : {}),
              },
            };
          },
          {} as Record<string, Operation>,
        );
        yield* put(
          operationContainerSliceReducer.actions.update({
            ...operationContainers,
            containers: {
              ...otherContainers,
              [updatedTargetStack.id]: {
                id: updatedTargetStack.id,
                inputIds: [],
                operationIds: operationIdsToStack,
              },
            },
            operations: {
              ...otherOperations,
              ...stackedOperations,
            },
          }),
        );
        yield* put(operationsSliceSaga.actions.calculateOperationTimes());
        yield* put(
          scenePropertiesSliceReducer.actions.update({
            ...sceneProperties,
            scenes: {
              ...Object.entries(sceneProperties.scenes).reduce(
                (acc, [k, v]) => ({
                  ...acc,
                  [k]: {
                    ...v,
                    operationContainerIds: [
                      ...new Set([
                        ...v.operationContainerIds.filter(
                          (id) => !containerIdsToRemove.includes(id),
                        ),
                        ...(k === targetNode.parentId
                          ? [updatedTargetStack.id]
                          : []),
                      ]),
                    ],
                  },
                }),
                {} as Record<string, SceneProperties>,
              ),
            },
          }),
        );

        // If one of the nodes we're stacking was the starting node, we need to make the target stack the starting node
        if (
          startingOperationContainerId &&
          containerIdsToRemove.includes(startingOperationContainerId)
        ) {
          yield* put(
            experienceSliceReducer.actions.update({
              startingOperationContainerId: updatedTargetStack.id,
              startingSceneId: updatedTargetStack.parentId,
            }),
          );
        }

        // We for sure want to remove any edges that have a target in our selected nodes.
        // It's ok to keep edges that have sources in the target node, assuming the target node is a stack
        // However if the source node is not a stack, we definitely want to remove it
        const edgesToRemove = edges.filter((e) =>
          [...selectedNodes, targetNode].some(
            (sn) =>
              // Remove any outbound connections that are not from a stack
              (sn.id === e.source && sn.type !== 'stack') ||
              // Remove any outbound from stack connections that are in selectedNodes, because these need to be edited
              (sn.id === e.source &&
                sn.type === 'stack' &&
                sn.id !== targetNode.id) ||
              // Remove inbound connections
              sn.id === e.target,
          ),
        );
        // We want to keep edges that come out (does the source match one of our nodes?)
        // If a node is not a stack then we'll have to edit it. Otherwise we are free to leave it in
        // Add it to the nodes to remove then make a new 'edge' with the new data, create an element, and return it
        const edgesToChange = edges.filter((e) => {
          return (
            // If the edge has a target in our selected nodes, get rid of it
            ![...selectedNodes, targetNode].some((sn) => sn.id === e.target) &&
            [...selectedNodes, targetNode].some(
              (sn) =>
                // Change any outbound connections that are not from a stack or a node that will get Out Connection toggled off
                (sn.id === e.source &&
                  sn.type !== 'stack' &&
                  !outConnectionToggleNodesIds.includes(sn.id)) ||
                // Change any outbound from stack connections that are in selectedNodes, because these need to be edited
                (sn.id === e.source &&
                  sn.type === 'stack' &&
                  sn.id !== targetNode.id),
            )
          );
        });
        const changedEdges: (Connection | Edge)[] = edgesToChange.map((e) => {
          // This edge is not in a stack, but should be. The updated target stack should have all the relevant information.
          // Create a new Edge where the source is updatedTargetStack with the proper edge handle
          const changedEdge = {
            source: updatedTargetStack.id,
            sourceHandle: e.sourceHandle || '',
            target: e.target,
            targetHandle: e.targetHandle || '',
            type: 'customEdge',
          };
          return changedEdge;
        });

        const { parentId } = updatedTargetStack;
        let parentScene = filterScenes(elements).find((e) => e.id === parentId);

        const nodeHeight = getNodeHeight(
          updatedTargetStack,
          operationContainers.operations,
        );

        // If the node doesn't have a height or the parent scene
        // wasn't found, return. This isn't such an issue that we'd
        // need to throw an error (unless this is needed to debug)
        if (parentId !== undefined && parentScene && nodeHeight) {
          const sceneResizeData = shouldResizeScene(
            nodeHeight,
            updatedTargetStack.position,
            parentScene.position,
            parentScene.data?.width || 0,
            parentScene.data?.height || 0,
          );

          if (sceneResizeData) {
            const [newScenePosition, newSceneWidth, newSceneHeight] =
              sceneResizeData;

            parentScene = {
              ...parentScene,
              position: newScenePosition,
              data: {
                ...parentScene?.data,
                width: newSceneWidth,
                height: newSceneHeight,
              },
            };
          }
        }

        const otherElements = elements.filter(
          (e) =>
            e.id !== targetNode.id &&
            e.id !== parentId &&
            !selectedNodes.some((sn) => sn.id === e.id) &&
            !edgesToRemove.some((edge) => edge.id === e.id),
        );

        yield* put(
          sliceReducer.actions.update({
            elements: [
              ...otherElements,
              updatedTargetStack,
              ...(parentScene ? [parentScene] : []),
            ],
            edgesPendingAdd: changedEdges,
            elementsSelected: [updatedTargetStack],
            stacking: {
              isStacking: false,
              stackingId: '',
            },
          }),
        );

        // Validation/logging related to bug 39973
        const updatedState: State = yield* select();
        const getInvalidNextIds = (s: State) => {
          const ret: string[] = [];
          for (const o of Object.values(
            s.main.operationContainers.operations,
          )) {
            if (
              o.outputType !== OperationOutputType.SingleOutput ||
              !o.nextId ||
              o.nextId === emptyGuid
            )
              continue;
            if (
              !Object.keys(s.main.operationContainers.containers).includes(
                o.nextId,
              )
            )
              ret.push(o.nextId);
          }
          return ret;
        };
        const invalidNextIdsAfterStacking = getInvalidNextIds(updatedState);
        if (invalidNextIdsAfterStacking.length > 0) {
          const invalidNextIdsBeforeStacking = getInvalidNextIds(state);
          logToServer('warn', 'Invalid nextIds present after stacking', {
            invalidNextIdsBeforeStacking,
            invalidNextIdsAfterStacking,
          });
        }
      },
    },

    unstackNode: {
      sagaType: SagaType.TakeLatest,
      *fn(action: PayloadAction<UnstackNode>) {
        const state: State = yield* select();
        const {
          elements,
          stackItemSelected,
          stackSelectedId: stackSelected,
        } = state.main.nodes;
        const { isPreviewEnabled } = state.main.preview;
        const { startingOperationContainerId } = state.main.experience;
        const { operationContainers, sceneProperties } = state.main;
        const { containers, operations } = operationContainers;
        const edges = filterEdges(elements);
        const nodes = filterNodes(elements);
        if (!stackItemSelected) {
          return;
        }
        // Find the stack in the elements
        const originalStack = nodes.find(
          (n) => n.id === stackSelected,
        ) as StackNode;
        if (originalStack?.type !== 'stack') {
          return;
        }
        // Separate out the items and make a container out of it
        const unstacked = originalStack.data?.items.find(
          (item) => item.id === stackItemSelected.id,
        );
        if (
          !unstacked ||
          (unstacked.nodeType as NodeType) === 'scene' ||
          (unstacked.nodeType as NodeType) === 'stack'
        ) {
          return;
        }

        const currScene = filterScenes(elements).filter(
          (e) => e.id === originalStack.parentId,
        )[0];
        const scenePosition = currScene.position;
        const sceneHeight = currScene.data?.height;
        const sceneWidth = currScene.data?.width;

        const newPos = !isPreviewEnabled
          ? getNewNodePosition(
              action.payload.reactFlowInstance,
              action.payload.position,
              scenePosition,
            )
          : getNewNodePositionPreviewEnabled(
              action.payload.reactFlowInstance,
              action.payload.position,
              action.payload.reactFlowBounds,
              scenePosition,
            );

        //Generate a new container id for the separated item
        const operationElement = {
          data: {
            ...unstacked.data,
            containerId: generateGuid(),
          } as OperationNodeData,
          id: unstacked.id,
          parentId: originalStack.parentId,
          position: newPos,
          type: unstacked.nodeType,
        } as TypedNode;
        // Remove from the stack's info
        const filteredItems = originalStack.data?.items.filter(
          (item) => item.id !== stackItemSelected.id,
        );
        if (!filteredItems || !operationElement) {
          return;
        }
        const updatedStack: StackNode = {
          ...originalStack,
          data: {
            ...originalStack.data,
            items: filteredItems,
          },
        };
        // We may have to just make an operation container if there is only one node left in the 'stack'
        const remainderElement =
          filteredItems.length === 1
            ? {
                data: {
                  ...filteredItems[0].data,
                  containerId: generateGuid(),
                },
                id: filteredItems[0].id,
                parentId: originalStack.parentId,
                position: originalStack.position,
                type: filteredItems[0].nodeType,
              }
            : undefined;

        let updatedElements = elements.filter((e) => e.id !== originalStack.id);
        updatedElements.push(operationElement);
        remainderElement
          ? updatedElements.push(remainderElement)
          : updatedElements.push(updatedStack);

        const nodeHeight = getOperationHeight(operations[unstacked.id]);
        const sceneResizeData = shouldResizeScene(
          nodeHeight,
          newPos,
          currScene.position,
          sceneWidth,
          sceneHeight,
        );
        if (sceneResizeData) {
          const [newScenePosition, newSceneWidth, newSceneHeight] =
            sceneResizeData;

          updatedElements = updatedElements.filter(
            (e) => e.id !== currScene.id,
          );
          const resizedScene = {
            ...currScene,
            position: newScenePosition,
            data: {
              ...currScene?.data,
              width: newSceneWidth,
              height: newSceneHeight,
            },
          };
          updatedElements = [...updatedElements, resizedScene];
        }

        // Processing for operation containers
        // If we split into two nodes, remove the original container and add two new ones
        const newContainer: OperationContainer = {
          id: (<OperationNodeData>operationElement.data).containerId,
          inputIds: [],
          operationIds: [operationElement.id],
        };
        let editedContainers: Record<string, OperationContainer> = containers;
        if (remainderElement) {
          const remainderContainer: OperationContainer = {
            id: remainderElement.data.containerId,
            inputIds: [],
            operationIds: [remainderElement.id],
          };
          // Remove the old stack's container and add the remainder back in
          editedContainers = Object.entries(containers)
            .filter(([k]) => k !== originalStack.id)
            .reduce(
              (acc, [k, v]) => ({
                ...acc,
                [k]: v,
              }),
              {} as Record<string, OperationContainer>,
            );
          editedContainers[remainderContainer.id] = remainderContainer;
        } else {
          // Just remove the unstacked operation id
          const originalContainer = containers[originalStack.id];
          const filteredItems = originalContainer.operationIds.filter(
            (x) => x !== operationElement.id,
          );
          editedContainers = {
            ...containers,
            [originalStack.id]: {
              ...originalContainer,
              operationIds: filteredItems,
            },
          };
        }
        editedContainers[newContainer.id] = newContainer;
        // Update the container variable for the unstacked operation
        let editedOperations = {
          ...operations,
          [unstacked.id]:
            operations[unstacked.id].outputType ===
            OperationOutputType.SingleOutput
              ? {
                  ...operations[unstacked.id],
                  containerId: newContainer.id,
                  isActivityFinished: true,
                  doesNextNodeExist: false,
                  nextId: emptyGuid,
                  isOutputEnabled: true,
                }
              : {
                  ...operations[unstacked.id],
                  containerId: newContainer.id,
                  isOutputEnabled: true,
                  children: [
                    ...(operations[unstacked.id] as McqOperation).children.map(
                      (c) => {
                        return {
                          ...c,
                          isActivityFinished: true,
                          doesNextNodeExist: false,
                          nextId: emptyGuid,
                        };
                      },
                    ),
                  ],
                },
        };
        // Update the container variable for the original operation if necessary
        if (remainderElement) {
          editedOperations = {
            ...editedOperations,
            [remainderElement.id]: {
              ...editedOperations[remainderElement.id],
              containerId: remainderElement.data.containerId,
              isOutputEnabled: true,
            },
          };
        }
        // Update other operations to reflect the changes we're making (ie. container id refs in playback phase nodes)
        const updateOperation = (operation: Operation) => {
          switch (operation.type) {
            case OperationType.PlaybackPhase:
              if (filteredItems.length > 1) return operation;

              return {
                ...operation,
                containersToPlayback: operation.containersToPlayback.filter(
                  (id) => id !== originalStack.id,
                ),
              };

            default:
              return operation;
          }
        };
        editedOperations = Object.entries(editedOperations).reduce(
          (acc, [k, v]) => ({
            ...acc,
            [k]: updateOperation(v),
          }),
          {} as Record<string, Operation>,
        );

        // Processing for the scene slice
        const scene = sceneProperties.scenes[originalStack.parentId || ''];
        if (!scene) {
          return;
        }
        const updatedContainers = remainderElement
          ? scene.operationContainerIds.filter((x) => x !== originalStack.id)
          : [...scene.operationContainerIds];
        updatedContainers.push(newContainer.id);
        if (remainderElement) {
          updatedContainers.push(remainderElement.data.containerId);
        }

        // Update container slice
        yield* put(
          operationContainerSliceReducer.actions.update({
            ...operationContainers,
            containers: {
              ...editedContainers,
            },
            operations: {
              ...editedOperations,
            },
          }),
        );

        // Update Scene Slice
        yield* put(
          scenePropertiesSliceReducer.actions.update({
            ...sceneProperties,
            scenes: {
              ...sceneProperties.scenes,
              [scene.id]: {
                ...scene,
                operationContainerIds: updatedContainers,
              },
            },
          }),
        );

        // In the case where we split a start stack that has two nodes (and therefore need to make two containers)
        // Assign the start node to the remainder
        if (
          startingOperationContainerId &&
          originalStack.id === startingOperationContainerId &&
          remainderElement
        ) {
          yield* put(
            experienceSliceReducer.actions.update({
              startingOperationContainerId: remainderElement.data.containerId,
            }),
          );
        }

        // Clear edges
        // We only need to remove an edge if it a. comes from our split node (check the handle)
        // or b. we have a remainder, in which case we should also replace any edge in the original stack
        const edgesToRemove = edges.filter((e) => {
          const { sourceChildId } = getIdsFromHandles(e);
          const opEltSourceHandle = sourceChildId
            ? `${OUT_HANDLE_PREFIX}${operationElement.id}_${sourceChildId}`
            : `${OUT_HANDLE_PREFIX}${operationElement.id}`;
          return e.sourceHandle === opEltSourceHandle || remainderElement
            ? originalStack.id === e.source || originalStack.id === e.target
            : false;
        });
        const otherElements = updatedElements.filter(
          (e) => !edgesToRemove.some((edge) => edge.id === e.id),
        );

        // Create new edges for all the edges that must be replaced. We actually do not want to replace the edge
        // coming out of the recently unstacked node.
        const edgesToAdd: (Connection | Edge)[] = edgesToRemove
          .filter((e) => {
            const { sourceChildId } = getIdsFromHandles(e);
            const opEltSourceHandle = sourceChildId
              ? `${OUT_HANDLE_PREFIX}${operationElement.id}_${sourceChildId}`
              : `${OUT_HANDLE_PREFIX}${operationElement.id}`;
            // filter out the edge coming out of the unstacked node.
            return e.sourceHandle !== opEltSourceHandle;
          })
          .map((e) => {
            // Case where we need the remainder element
            // We need to check if we edit the source or the target
            const { sourceChildId } = getIdsFromHandles(e);
            const opEltSourceHandle = sourceChildId
              ? `${OUT_HANDLE_PREFIX}${remainderElement?.id}_${sourceChildId}`
              : `${OUT_HANDLE_PREFIX}${remainderElement?.id}`;

            return {
              source:
                originalStack.id === e.source
                  ? remainderElement?.id || ''
                  : e.source,
              sourceHandle:
                originalStack.id === e.source
                  ? opEltSourceHandle
                  : e.sourceHandle || '',
              target:
                originalStack.id === e.target
                  ? remainderElement?.id || ''
                  : e.target || '',
              targetHandle:
                originalStack.id === e.target
                  ? `${IN_HANDLE_PREFIX}${remainderElement?.id}`
                  : e.targetHandle || '',
              type: 'customEdge',
            };
          });
        // Update the node slice
        yield* put(
          sliceReducer.actions.update({
            elements: [...otherElements],
            elementsSelected: [operationElement],
            edgesPendingAdd: edgesToAdd,
            stackSelectedId: '',
            stackItemSelected: undefined,
          }),
        );
        // This is kind of a hack, but when deleting from the stack
        // The best approach is to unstack then delete
        if (action.payload.shouldDeleteAfterUnstack) {
          const currSaga: {
            actions: {
              deleteSelectedElements: ActionCreatorWithoutPayload;
            };
          } = sliceSaga;
          yield put(currSaga.actions.deleteSelectedElements());
        }
      },
    },

    unstackAllNodes: {
      sagaType: SagaType.TakeLatest,
      *fn() {
        const state: State = yield* select();
        const { elements, elementsSelected } = state.main.nodes;
        const { startingOperationContainerId } = state.main.experience;
        const { operationContainers, sceneProperties } = state.main;
        const edges = filterEdges(elements);
        const selectedNodes = filterNodes(elementsSelected);
        if (selectedNodes.length !== 1 || selectedNodes[0]?.type !== 'stack') {
          return;
        }

        const originalStack: StackNode = selectedNodes[0];
        const stackItems = originalStack.data?.items;
        if (!stackItems) {
          return;
        }
        const firstStackItemId = stackItems[0].id;

        // Processing for the scene slice
        const scene = sceneProperties.scenes[originalStack.parentId || ''];
        if (!scene) {
          return;
        }
        const updatedContainers = scene.operationContainerIds.filter(
          (x) => x !== originalStack.id,
        );

        // Processing for operation and operation containers
        const { containers, operations } = operationContainers;
        let editedContainers: Record<string, OperationContainer> = containers;
        editedContainers = Object.entries(containers)
          .filter(([k]) => k !== originalStack.id)
          .reduce(
            (acc, [k, v]) => ({
              ...acc,
              [k]: v,
            }),
            {} as Record<string, OperationContainer>,
          );
        let editedOperations = { ...operations };

        // Processing for elements
        const updatedElements = elements.filter(
          (e) => e.id !== originalStack.id,
        );

        // elementsSelected may not have updated the stack position at this point, but elements will always be up to date
        const originalStackPos = filterNodes(elements).filter(
          (e) => e.id === originalStack.id,
        )[0].position;

        const edgesToRemove: Edge<NodeData>[] = [];
        const edgesToChange: (Connection | Edge)[] = [];
        let runningPos = { x: originalStackPos.x, y: originalStackPos.y };

        stackItems.forEach((stackItem) => {
          // Create new element
          const operationElement = {
            data: {
              ...stackItem.data,
              containerId: generateGuid(),
            } as OperationNodeData,
            id: stackItem.id,
            parentId: originalStack.parentId,
            position: runningPos,
            type: stackItem.nodeType,
          } as TypedNode;
          updatedElements.push(operationElement);

          // Calculate position for next element
          runningPos = {
            x: runningPos.x + UNSTACK_NODES_X_OFFSET,
            y: runningPos.y + UNSTACK_NODES_Y_OFFSET,
          };

          // find edges to delete or change
          edges.forEach((e) => {
            const { sourceChildId } = getIdsFromHandles(e);
            const opEltSourceHandle = sourceChildId
              ? `${OUT_HANDLE_PREFIX}${stackItem.id}_${sourceChildId}`
              : `${OUT_HANDLE_PREFIX}${stackItem.id}`;
            if (
              e.sourceHandle === opEltSourceHandle ||
              originalStack.id === e.target
            ) {
              edgesToRemove.push(e);
              // want to push all the edges into edges to add
              if (firstStackItemId === stackItem.id) {
                if (e.source === originalStack.id) {
                  edgesToChange.push({
                    source: stackItem.id,
                    sourceHandle: opEltSourceHandle,
                    target: e.target,
                    targetHandle: e.targetHandle || '',
                    type: 'customEdge',
                  });
                } else if (e.target === originalStack.id) {
                  edgesToChange.push({
                    source: e.source,
                    sourceHandle: e.sourceHandle || '',
                    target: stackItem.id,
                    targetHandle: `${IN_HANDLE_PREFIX}${stackItem.id}`,
                    type: 'customEdge',
                  });
                }
              }
            }
          });

          // Create new container
          const newContainer = {
            id: (<OperationNodeData>operationElement.data).containerId,
            inputIds: [],
            operationIds: [operationElement.id],
          };
          editedContainers[newContainer.id] = newContainer;
          updatedContainers.push(newContainer.id);

          // Create new operation
          const newOperation =
            operations[stackItem.id].outputType ===
            OperationOutputType.SingleOutput
              ? {
                  ...operations[stackItem.id],
                  containerId: newContainer.id,
                  isActivityFinished: true,
                  doesNextNodeExist: false,
                  nextId: emptyGuid,
                  isOutputEnabled: true,
                }
              : {
                  ...operations[stackItem.id],
                  containerId: newContainer.id,
                  children: [
                    ...(operations[stackItem.id] as McqOperation).children.map(
                      (c) => {
                        return {
                          ...c,
                          isActivityFinished: true,
                          doesNextNodeExist: false,
                          nextId: emptyGuid,
                        };
                      },
                    ),
                  ],
                  isOutputEnabled: true,
                };
          editedOperations[stackItem.id] = newOperation;
        });

        const otherElements = updatedElements.filter(
          (e) => !edgesToRemove.some((edge) => edge.id === e.id),
        );

        // Update other operations to reflect the changes we're making (ie. container id refs in playback phase nodes)
        const updateOperation = (operation: Operation) => {
          switch (operation.type) {
            case OperationType.PlaybackPhase:
              return {
                ...operation,
                containersToPlayback: operation.containersToPlayback.filter(
                  (id) => id !== originalStack.id,
                ),
              };

            default:
              return operation;
          }
        };

        editedOperations = Object.entries(editedOperations).reduce(
          (acc, [k, v]) => ({
            ...acc,
            [k]: updateOperation(v),
          }),
          {} as Record<string, Operation>,
        );

        // Update container slice
        yield* put(
          operationContainerSliceReducer.actions.update({
            ...operationContainers,
            containers: {
              ...editedContainers,
            },
            operations: {
              ...editedOperations,
            },
          }),
        );

        // Update Scene Slice
        yield* put(
          scenePropertiesSliceReducer.actions.update({
            ...sceneProperties,
            scenes: {
              ...sceneProperties.scenes,
              [scene.id]: {
                ...scene,
                operationContainerIds: updatedContainers,
              },
            },
          }),
        );

        // In the case where we are unstacking a start node, assign the start node to the first node
        if (
          startingOperationContainerId &&
          originalStack.id === startingOperationContainerId
        ) {
          yield* put(
            experienceSliceReducer.actions.update({
              startingOperationContainerId:
                editedOperations[stackItems[0].id].containerId,
            }),
          );
        }

        // Update the node slice
        yield* put(
          sliceReducer.actions.update({
            elements: [...otherElements],
            elementsSelected: [],
            edgesPendingAdd: edgesToChange,
            stackSelectedId: '',
            stackItemSelected: undefined,
          }),
        );

        yield* put(
          previewSliceReducer.actions.update({
            shouldRemovePresentContainer: true,
          }),
        );

        // delete original stack
        const currSaga: {
          actions: {
            deleteSelectedElements: ActionCreatorWithoutPayload;
          };
        } = sliceSaga;
        yield put(currSaga.actions.deleteSelectedElements());
      },
    },

    addNodeToPlaybackPhase: {
      sagaType: SagaType.TakeLatest,
      *fn(action: PayloadAction<AddNodeToPlaybackPhasePayload>) {
        const state: State = yield* select();
        const { elementsSelected } = state.main.nodes;
        const { operations, containers } = state.main.operationContainers;
        const currPlaybackPhaseId = filterNodes(elementsSelected)[0]?.id;
        const playbackPhaseOperation = operations[
          currPlaybackPhaseId
        ] as PlaybackPhaseOperation;
        const selectedOperation = operations[action.payload.nodeId];
        const selectedStack = containers[action.payload.nodeId];
        // Potentially a stack
        const selectedOperationContainerId = selectedOperation
          ? selectedOperation.containerId
          : selectedStack
            ? action.payload.nodeId
            : '';
        // There is a bug where clicking to select a stack will immediately exist selection mode because it fails the below test.
        // Immediately return if we find a stack to avoid that. The stack still gets added because the event fires for both the node container in the stack
        // and the stack itself. For example, clicking the overlay in a stack fires this once for the overlay and once in the stack.
        if (selectedStack) {
          return;
        }
        if (
          !selectedOperation ||
          selectedOperation.type === OperationType.PlaybackNode ||
          selectedOperation.type === OperationType.PlaybackPhase
        ) {
          yield* put(
            sliceReducer.actions.update({
              selectingPlaybackPhaseNode: {
                isSelecting: false,
                playbackPhaseId: '',
              },
            }),
          );

          return;
        }
        if (
          playbackPhaseOperation.containersToPlayback.includes(
            selectedOperationContainerId,
          )
        ) {
          // Remove this container
          const filtered = playbackPhaseOperation.containersToPlayback.filter(
            (containerId) => containerId !== selectedOperationContainerId,
          );
          yield* put(
            operationContainerSliceReducer.actions.update({
              operations: {
                ...operations,
                [currPlaybackPhaseId]: {
                  ...playbackPhaseOperation,
                  containersToPlayback: filtered,
                },
              },
            }),
          );
        } else {
          yield* put(
            operationContainerSliceReducer.actions.update({
              operations: {
                ...operations,
                [currPlaybackPhaseId]: {
                  ...playbackPhaseOperation,
                  containersToPlayback: [
                    ...playbackPhaseOperation.containersToPlayback,
                    selectedOperationContainerId,
                  ],
                },
              },
            }),
          );
        }
      },
    },

    addNodeToPlaybackNode: {
      sagaType: SagaType.TakeLatest,
      *fn(action: PayloadAction<AddNodeToPlaybackNodePayload>) {
        const state: State = yield* select();
        const { elementsSelected } = state.main.nodes;
        const { operations } = state.main.operationContainers;
        const currPlaybackNodeId = filterNodes(elementsSelected)[0].id;
        const playbackNodeOperation = operations[
          currPlaybackNodeId
        ] as PlaybackOperation;
        const selectedOperation = operations[action.payload.nodeId];
        const selectedOperationContainerId = selectedOperation?.containerId;

        if (
          !selectedOperation ||
          selectedOperation.type !== OperationType.PlaybackPhase
        ) {
          yield* put(
            sliceReducer.actions.update({
              selectingPlaybackNode: {
                isSelecting: false,
                playbackId: '',
              },
            }),
          );
          return;
        }
        if (
          playbackNodeOperation.phasesToPlayback.includes(
            selectedOperationContainerId,
          )
        ) {
          const filtered = playbackNodeOperation.phasesToPlayback.filter(
            (phaseId) => phaseId !== selectedOperationContainerId,
          );
          yield* put(
            operationContainerSliceReducer.actions.update({
              operations: {
                ...operations,
                [currPlaybackNodeId]: {
                  ...playbackNodeOperation,
                  phasesToPlayback: filtered,
                },
              },
            }),
          );
        } else {
          yield* put(
            operationContainerSliceReducer.actions.update({
              operations: {
                ...operations,
                [currPlaybackNodeId]: {
                  ...playbackNodeOperation,
                  phasesToPlayback: [
                    ...playbackNodeOperation.phasesToPlayback,
                    selectedOperationContainerId,
                  ],
                },
              },
            }),
          );
        }
      },
    },

    updateConnections: {
      sagaType: SagaType.TakeLatest,
      *fn(action: PayloadAction<UpdateConnections>) {
        const state: State = yield* select();
        const { elements } = state.main.nodes;
        const { operations } = state.main.operationContainers;
        const edges = filterEdges(elements);
        const nodes = filterNodes(elements);

        yield* put(
          operationContainerSliceReducer.actions.update({
            operations: {
              ...operations,
              ...edges.reduce(
                (acc, cur) => {
                  const { sourceOperationId, sourceChildId } =
                    getIdsFromHandles(cur);

                  const nextNode = nodes.find((n) => n.id === cur.target);
                  if (!nextNode) return {};
                  const nextContainerId =
                    nextNode.type === 'stack'
                      ? nextNode.id
                      : nextNode.data?.containerId;

                  const updateOutputProps = (
                    operation: Operation,
                  ): Operation => {
                    if (operation.outputType === OperationOutputType.NoOutput) {
                      return {
                        ...operation,
                      };
                    }
                    if (
                      operation.outputType === OperationOutputType.SingleOutput
                    ) {
                      return {
                        ...operation,
                        doesNextNodeExist: true,
                        isActivityFinished: false,
                        nextId: nextContainerId ?? emptyGuid,
                      };
                    }

                    if (operation.mcqType === McqQuestionType.Branching) {
                      const sourceChild = operation.children.find(
                        (child) => child.rid === sourceChildId,
                      );
                      if (!sourceChild)
                        throw new Error(
                          `Invalid source sourceOperationId=${sourceOperationId} sourceChildId=${sourceChildId}`,
                        );
                      const otherChildren = operation.children.filter(
                        (child) => child.rid !== sourceChildId,
                      );

                      return {
                        ...operation,
                        children: [
                          ...otherChildren,
                          {
                            ...sourceChild,
                            doesNextNodeExist: true,
                            isActivityFinished: false,
                            nextId: nextContainerId ?? emptyGuid,
                          },
                        ],
                      };
                    }

                    // non-branching MCQ will have a fake child to interface with in the UI,
                    // but update all the properties of the actual children
                    const updatedChildren = operation.children.map((c) => {
                      return {
                        ...c,
                        doesNextNodeExist: true,
                        isActivityFinished: false,
                        nextId: nextContainerId ?? emptyGuid,
                      };
                    });

                    return {
                      ...operation,
                      children: [...updatedChildren],
                    };
                  };

                  return {
                    ...acc,
                    [sourceOperationId]: updateOutputProps(
                      operations[sourceOperationId],
                    ),
                  };
                },
                {} as Record<string, Operation>,
              ),
            },
          }),
        );

        if (action.payload.removeUndoStateLockAfterUpdate) {
          yield* put(undoSliceReducer.actions.update({ undoStateLock: false }));
        }
      },
    },

    deleteMcqAnswer: {
      sagaType: SagaType.TakeLatest,
      *fn(action: PayloadAction<RemoveMcqAnswer>) {
        const state: State = yield* select();
        const { elements } = state.main.nodes;
        const { operations } = state.main.operationContainers;
        const { mcqOpId, mcqAnswerRid } = action.payload;
        const mcqOp = operations[mcqOpId] as McqOperation;
        const mcqAnswerIdx = mcqOp.children?.filter(
          (c) => c.rid === mcqAnswerRid,
        )[0]?.answerOrder;
        // reassign correct answer if deleting is correct
        const deletingCorrectAnswer = mcqOp.correctAnswerRid === mcqAnswerRid;

        const edges = filterEdges(elements);
        const edgeToRemove = edges.filter(
          (e) =>
            e.sourceHandle ===
            `${OUT_HANDLE_PREFIX}${action.payload.mcqOpId}_${action.payload.mcqAnswerRid}`,
        );

        const otherAnswers = mcqOp.children
          .filter((ans) => ans.rid !== mcqAnswerRid)
          .map((ans) => {
            // shift all the indexes of the answers down
            return {
              ...ans,
              answerOrder:
                ans.answerOrder > mcqAnswerIdx
                  ? ans.answerOrder - 1
                  : ans.answerOrder,
            };
          });

        yield* put(
          operationContainerSliceReducer.actions.update({
            operations: {
              ...operations,
              [mcqOpId]: {
                ...mcqOp,
                correctAnswerRid: deletingCorrectAnswer
                  ? otherAnswers.filter(
                      (ans) =>
                        ans.answerOrder ===
                        (mcqAnswerIdx === otherAnswers.length
                          ? mcqAnswerIdx - 1
                          : mcqAnswerIdx),
                    )[0]?.rid
                  : mcqOp.correctAnswerRid,
                children: [...otherAnswers],
              },
            },
          }),
        );

        yield* put(
          sliceReducer.actions.update({
            elements: removeElements(edgeToRemove, elements),
          }),
        );
      },
    },

    toggleMcqBranching: {
      sagaType: SagaType.TakeLatest,
      *fn(action: PayloadAction<string>) {
        const state: State = yield* select();
        const { elements } = state.main.nodes;
        const { operations } = state.main.operationContainers;
        const mcqOpId = action.payload;
        const mcqOp = operations[mcqOpId] as McqOperation;
        const currentlyIsBranching =
          mcqOp.mcqType === McqQuestionType.Branching;

        const edges = filterEdges(elements);
        const edgesToRemove = currentlyIsBranching
          ? edges.filter((e) => e.sourceHandle?.includes(mcqOpId))
          : edges.filter(
              (e) => e.sourceHandle === `${OUT_HANDLE_PREFIX}${mcqOpId}`,
            );

        const updatedChildren = mcqOp.children.map((ans) => {
          return {
            ...ans,
            isActivityFinished: true,
            doesNextNodeExist: false,
            nextId: emptyGuid,
          };
        });

        yield* put(
          operationContainerSliceReducer.actions.update({
            operations: {
              ...operations,
              [mcqOpId]: {
                ...mcqOp,
                mcqType: currentlyIsBranching
                  ? McqQuestionType.Regular
                  : McqQuestionType.Branching,
                children: [...updatedChildren],
              },
            },
          }),
        );

        yield* put(
          sliceReducer.actions.update({
            elements: removeElements(edgesToRemove, elements),
          }),
        );
      },
    },

    centerOutOfBoundOps: {
      sagaType: SagaType.TakeLatest,
      *fn() {
        const state: State = yield* select();
        const { elements, elementsSelected } = state.main.nodes;
        const scenes = filterScenes(elements);
        const nodes = filterNodes(elements);

        const selectedScenes = filterScenes(elementsSelected);
        if (selectedScenes.length !== 1) return;
        const selectedScene = selectedScenes[0];

        const editedNodes = nodes.map((n) => {
          if (
            n.parentId === selectedScene.id &&
            selectedScene.data &&
            selectedScene.data.width &&
            selectedScene.data.height
          ) {
            return {
              ...n,
              position: {
                x:
                  n.position.x > 0.8 * selectedScene.data.width ||
                  n.position.x < 0
                    ? 0
                    : n.position.x,
                y:
                  n.position.y > 0.8 * selectedScene.data.height ||
                  n.position.y < 0
                    ? 0
                    : n.position.y,
              },
            };
          }
          return n;
        });
        yield* put(
          sliceReducer.actions.update({
            elements: [...scenes, ...filterEdges(elements), ...editedNodes],
          }),
        );
      },
    },

    selectErrorCheckModalItem: {
      sagaType: SagaType.TakeLatest,
      *fn(action: PayloadAction<string>) {
        const state: State = yield* select();
        const { elements } = state.main.nodes;
        yield* put(
          sliceReducer.actions.update({
            elementsSelected: [
              ...elements.filter((elem) => elem.id === action.payload),
            ],
            stackItemSelected: undefined,
            stackSelectedId: '',
          }),
        );
      },
    },

    dragNode: {
      sagaType: SagaType.TakeEvery,
      *fn(action: PayloadAction<DragNode>) {
        const { node } = action.payload;
        const { id, position } = node;

        const state: State = yield* select();
        const { elements } = state.main.nodes;
        const { operations } = state.main.operationContainers;

        // Handle dragging and re-placing the node (either set dragging or set the position)
        if (!action.payload.endDrag) {
          yield* put(
            sliceReducer.actions.update({
              dragType: {
                isDragging: true,
                isOperation: node.type !== 'scene',
                id: node.id,
              },
            }),
          );
        } else {
          yield* put(
            sliceReducer.actions.updateElement({ id, element: { position } }),
          );
        }

        // If the node being dragged is not a scene,
        // possibly resize the container scene.
        if (node.type !== 'scene') {
          const currentlySavedNode = filterNodes(elements).find(
            (n) => n.id === node.id,
          );

          if (!currentlySavedNode) {
            return;
          }

          const nodeHeight = getNodeHeight(currentlySavedNode, operations);

          const { parentId } = currentlySavedNode;
          const parentScene = filterScenes(elements).find(
            (e) => e.id === parentId,
          );

          // If the node doesn't have a height or the parent scene
          // wasn't found, return. This isn't such an issue that we'd
          // need to throw an error (unless this is needed to debug)
          if (parentId === undefined || !parentScene || !nodeHeight) {
            return;
          }

          const sceneHeight = parentScene.data?.height;
          const sceneWidth = parentScene.data?.width;

          const sceneResizeData = shouldResizeScene(
            nodeHeight,
            position,
            parentScene.position,
            sceneWidth,
            sceneHeight,
          );

          if (sceneResizeData) {
            const [newScenePosition, newSceneWidth, newSceneHeight] =
              sceneResizeData;

            const resizedScene = {
              position: newScenePosition,
              data: {
                ...parentScene?.data,
                width: newSceneWidth,
                height: newSceneHeight,
              },
            };

            yield* put(
              sliceReducer.actions.updateElement({
                id: parentId,
                element: resizedScene,
              }),
            );
          }
        }
      },
    },
  },
});

export default withUndoSaga(sliceSaga);
