import React, { useCallback, useEffect, useState } from 'react';

import useGlobalResizeHandler from '../hooks/useGlobalResizeHandler';
import useGlobalScrollHandler from '../hooks/useGlobalScrollHandler';
import { classNames } from '../utils/helpers';
import Portal from './portal';

import styles from './popup.module.css';

type PopupBubblePosition = 'left' | 'middle' | 'right';
type PopupCaretPosition = 'left' | 'middle' | 'right';
type PopupPlacement = 'above' | 'below';
type PopupStandardEvent = 'click' | 'hover';

interface PopupPropsBase {
  /** (optional, default: 10) The percentage offset of the bubble from the edge of the caret (left / right positions only) */
  bubbleOffsetPct?: number;
  /** The position of the bubble in relation to the caret */
  bubblePosition: PopupBubblePosition;
  /** (optional, default: 10) The percentage offset of the caret from the edge of the target (left / right positions only) */
  caretOffsetPct?: number;
  /** The position of the caret in relation to the target element */
  caretPosition: PopupCaretPosition;
  /** (optional) For popup container (caret) styling for various purposes */
  caretClassName?: string;
  /** (optional) For popup container (inverted caret) styling for various purposes */
  caretInvertedClassName?: string;
  /** (optional, default: 10) The size in pixels of the caret diagonals */
  caretSize?: number;
  /** (optional) An additional css class to style the popup */
  className?: string;
  /** A ref to the target element to attach the popup to */
  forRef: React.RefObject<HTMLElement>;
  /** (optional) For popup container styling for various purposes */
  bubbleClassName?: string;
  /** (optional, default: 0) A margin in pixels between the caret and the target element */
  margin?: number;
  /** (optional, default: 'above') Where to place the popup in relation to the target element */
  popupPlacement?: PopupPlacement;
  /** (optional) ARIA role for the popup (ie. menu) */
  role?: string;
  /** (optional, default: 0.2) The length of the animation (in seconds) when showing/hiding the popup */
  transitionDuration?: number;
}

interface PopupPropsOnStandard {
  /** The event on which to show the popup */
  on: PopupStandardEvent;
}

interface PopupPropsOnSelectedId {
  /** The id of this popup */
  id: number | string;
  /** The event on which to show the popup */
  on: 'selectedId';
  /** The id of the currently selected popup (will show when selectedId === id) */
  selectedId: number | string | null;
}

export type PopupProps = PopupPropsBase &
  (PopupPropsOnStandard | PopupPropsOnSelectedId);

const Popup: React.FC<PopupProps> = ({
  bubbleClassName,
  bubbleOffsetPct = 10,
  bubblePosition,
  caretOffsetPct = 10,
  caretPosition,
  caretClassName,
  caretInvertedClassName,
  caretSize = 10,
  children,
  className,
  forRef,
  margin = 0,
  popupPlacement = 'above',
  role,
  transitionDuration = 0.2,
  ...dynamicProps
}) => {
  const [needsSizeUpdate, setNeedsSizeUpdate] = useState(true);
  const [show, setShow] = useState(false);
  const [[popupHeight, popupWidth], setPopupHeightWidth] = useState([0, 0]);

  useGlobalResizeHandler(() => setNeedsSizeUpdate(true));
  useGlobalScrollHandler(() => setNeedsSizeUpdate(true));

  useEffect(() => {
    if (show) setNeedsSizeUpdate(true);
  }, [show]);

  useEffect(() => {
    const handleEnter = () => {
      if (dynamicProps.on !== 'hover') return;

      setShow(true);
    };

    const handleLeave = () => {
      if (dynamicProps.on !== 'hover') return;

      setShow(false);
    };

    const curref = forRef.current;
    curref?.addEventListener('mouseenter', handleEnter);
    curref?.addEventListener('mouseleave', handleLeave);

    return () => {
      curref?.removeEventListener('mouseleave', handleLeave);
      curref?.removeEventListener('mouseenter', handleEnter);
    };
  }, [forRef, dynamicProps.on]);

  useEffect(() => {
    const handleClick = () => {
      if (dynamicProps.on !== 'click') return;

      setShow(!show);
    };

    const curref = forRef.current;
    curref?.addEventListener('click', handleClick);

    return () => {
      curref?.removeEventListener('click', handleClick);
    };
  }, [forRef, dynamicProps.on, show]);

  const shouldShowForSelectedId =
    dynamicProps.on === 'selectedId'
      ? dynamicProps.id === dynamicProps.selectedId
      : null;
  useEffect(() => {
    if (shouldShowForSelectedId !== null && show !== shouldShowForSelectedId)
      setShow(shouldShowForSelectedId);
  }, [forRef, shouldShowForSelectedId, show]);

  const popupCallbackRef = useCallback(
    (e: HTMLDivElement | null) => {
      if (!e || !needsSizeUpdate) return;

      setPopupHeightWidth([e.clientHeight, e.clientWidth]);
      setNeedsSizeUpdate(false);
    },
    [needsSizeUpdate]
  );

  const parentRect = forRef.current?.getBoundingClientRect() ?? {
    bottom: 0,
    left: 0,
    top: 0,
    width: 0,
  };

  const caretWidth = caretSize * 2;
  const caretAbsoluteOffset = (parentRect.width * caretOffsetPct) / 100;
  const caretOffset = (() => {
    switch (caretPosition) {
      case 'left':
        return caretAbsoluteOffset;
      case 'middle':
        return parentRect.width / 2 - caretWidth / 2;
      case 'right':
        return parentRect.width - caretAbsoluteOffset - caretWidth;
      default:
        throw new Error(`Invalid caretPosition: ${caretPosition}`);
    }
  })();
  const caretLeft = Math.max(parentRect.left + caretOffset, 0);
  const caretMarginTop =
    popupPlacement === 'above' ? -1 : (popupHeight + caretSize - 1) * -1;

  const bubbleAbsoluteOffset = (parentRect.width * bubbleOffsetPct) / 100;
  const bubbleOffset = (() => {
    switch (bubblePosition) {
      case 'left':
        return caretOffset + caretWidth - (popupWidth - bubbleAbsoluteOffset);
      case 'middle':
        return caretOffset - popupWidth / 2;
      case 'right':
        return caretOffset - bubbleAbsoluteOffset;
      default:
        throw new Error(`Invalid position: ${bubblePosition}`);
    }
  })();
  const bubbleTop =
    popupPlacement === 'above'
      ? Math.max(parentRect.top - caretSize - popupHeight - margin, 0)
      : Math.max(parentRect.bottom + caretSize + margin, 0);
  const bubbleLeft = Math.max(parentRect.left + bubbleOffset, 0);

  return (
    <Portal isHidden={!show}>
      <div
        aria-hidden={!show}
        className={classNames(styles.popup, className, {
          [styles.popup__showing]: show,
        })}
        ref={popupCallbackRef}
        role={role}
        style={{
          left: bubbleLeft,
          top: bubbleTop,
          transitionDuration: `${transitionDuration}s`,
        }}
      >
        <div className={classNames(styles.popup_bubble, bubbleClassName)}>
          {children}
        </div>
        <div
          className={classNames(
            styles.popup_caret,
            caretClassName,
            caretInvertedClassName,
            { [styles.popup_caret__inverted]: popupPlacement === 'below' }
          )}
          style={{
            borderWidth: caretSize,
            left: caretLeft,
            marginTop: caretMarginTop,
          }}
        />
      </div>
    </Portal>
  );
};

export default Popup;
