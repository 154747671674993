import { useEffect } from 'react';
import { handleBeforeUnload, removeWarnOnClose } from '../utils/warnOnClose';

// Based on: https://javascript.plainenglish.io/how-to-alert-a-user-before-leaving-a-page-in-react-a2858104ca94
// Used to prompt the default browser pop-up warning  a user just before they leave the page (through clicking
// a link or the forward and back buttons for example). Unforutunately, this pop-up is built in to browsers and
// not easily restylable.
const useInitWarnOnClose = (): void => {
  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      removeWarnOnClose();
    };
  }, []);
};

export default useInitWarnOnClose;
