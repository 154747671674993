import { useLayoutEffect } from 'react';

// TODO: Should be "theme: keyof typeof themes", but this is translating as "string | number | symbol". Loader issue?
/**
 * Use this hook to set the default theme for all components
 * To override for a component and its children, import themes and use ie. <div className={themes.otherTheme}>
 */
const useGlobalTheme = (theme: string): void => {
  useLayoutEffect(() => {
    const rootElement = document.querySelector(':root');
    rootElement?.classList.add(theme);
  }, [theme]);
};

export default useGlobalTheme;
