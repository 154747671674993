import { detailedDiff } from 'deep-object-diff';
import axios from 'axios';

import { StateMiddleware } from '../reducer';
import { logToServer } from '../../utils/logging';
import { getAutologActions } from '../../utils/middleware';

const LOG_STATE_ON_NUM_REMOVED_ELEMENTS = 5;
const NUM_PRECEDING_ACTIONS = 25;

const precedingActions = [] as string[];

const autolog: StateMiddleware = (store) => (next) => (action) => {
  const prevState = store.getState();
  const result = next(action);
  const nextState = store.getState();
  const stateDiff = detailedDiff(prevState, nextState);

  try {
    if (getAutologActions().includes(action.type)) {
      const sliceName = action.type.split('/')[0];
      logToServer('info', `${sliceName} changed`, {
        action,
        precedingActions,
        stateDiff,
      });

      // If a large number of elements were deleted, log additional state information
      const numElemsRemoved =
        prevState.main.nodes.elements.length -
        nextState.main.nodes.elements.length;
      if (numElemsRemoved >= LOG_STATE_ON_NUM_REMOVED_ELEMENTS) {
        logToServer('warn', `Elements removed above threshold`, {
          action,
          numElemsRemoved,
          precedingActions,
          stateDiff,
        });
      }
    }

    if (precedingActions.length >= NUM_PRECEDING_ACTIONS)
      precedingActions.pop();
    precedingActions.unshift(action.type);
  } catch (error) {
    try {
      logToServer('error', 'Failed to log message', {
        error: {
          message: (error as Error).message,
          response: axios.isAxiosError(error) ? error : undefined,
          stack: (error as Error).stack,
        },
      });
    } catch {}
  }

  return result;
};

export default autolog;
