import { CONTENT_VERSION_FLAGS } from '../areas/main/constants';

export function getContentVersion(isGenericCharacterRig: boolean) {
  return isGenericCharacterRig ? CONTENT_VERSION_FLAGS.genericRig : undefined;
}

// perform a bitwise check to check if content is enabled for this version.
export const checkContentVersion = (
  contentVersion: number | undefined,
  featureContentVersion: number,
) => {
  return contentVersion && (contentVersion & featureContentVersion) > 0;
};
