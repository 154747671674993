import React, { useEffect } from 'react';

import { logToServer } from '../utils/logging';

import styles from './unauthorized.module.css';
import { useAuth0 } from '@auth0/auth0-react';
import { ButtonDressedAsLink } from '@strivr/ui';

const Unauthorized: React.FC = () => {
  const { logout } = useAuth0();

  useEffect(() => logToServer('info', '403 page shown'), []);

  const handleLogout = () =>
    logout({
      returnTo: window.location.href,
    });

  return (
    <>
      <div className={styles.heading}>403</div>
      <div className={styles.description}>
        You are not authorized to view this page.
      </div>
      <div className={styles.description}>
        Please reach out to request permission or let us know if you believe
        this is in error.
      </div>
      <div className={styles.description}>
        <span>You can also </span>
        <ButtonDressedAsLink onClick={handleLogout}>
          click here to logout
        </ButtonDressedAsLink>
        <span> and sign in with a different account.</span>
      </div>
    </>
  );
};

export default Unauthorized;
