import { combineReducers } from '@reduxjs/toolkit';

import assets from './assets/sliceReducer';
import experience from './experience/sliceReducer';
import nodes from './nodes/sliceReducer';
import sceneProperties from './sceneProperties/sliceReducer';
import tenants from './tenants/sliceReducer';
import operationContainers from './operationContainers/sliceReducer';
import ui from './ui/sliceReducer';
import undo from './undo/sliceReducer';
import preview from './preview/sliceReducer';

const reducer = combineReducers({
  assets: assets.reducer,
  experience: experience.reducer,
  nodes: nodes.reducer,
  sceneProperties: sceneProperties.reducer,
  tenants: tenants.reducer,
  operationContainers: operationContainers.reducer,
  ui: ui.reducer,
  undo: undo.reducer,
  preview: preview.reducer,
});

export default reducer;
