import { useEffect } from 'react';
import sliceReducer from '../../areas/main/state/preview/sliceReducer';
import { UnityPreviewEvents } from '../../areas/main/state/preview/unityMessages/utils';
import { unityContext } from '../../areas/main/views/preview';
import useDispatch from '../useDispatch';

const usePlayerLoadedMessageFromPlayer = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    unityContext.on(UnityPreviewEvents.OnPlayerLoaded, () => {
      // This helps reinitialize the Preview scene correctly.
      // For example, if you close the preview window and re-open it,
      // a scene re-opens with the correct user camera transforms.
      // Also, if you quit the preview window via moving the Preview panel,
      // this will also re-initialize preview correctly.
      dispatch(sliceReducer.actions.update({ isPlayerPreviewReady: false }));

      dispatch(sliceReducer.actions.update({ isPlayerPreviewReady: true }));
    });
  }, [dispatch]);
};

export default usePlayerLoadedMessageFromPlayer;
