import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AssetTypes, ObjectMetadata } from '../../types/models';

import { deleteRecordByKey } from '../../../../utils/deleteRecord';

export const initialState = {
  assetListsByType: {} as Record<AssetTypes, ObjectMetadata[]>,
  assetToGroupLabelsMapping: {} as Record<string, string[]>,
  selectedAsset: null as ObjectMetadata | null,
  errors: {} as Record<string, string>,
  isLoadingAssetList: true,
  isLoadingGroupLabels: true,
};

type Update = Partial<typeof initialState>;

const sliceReducer = createSlice({
  name: 'assets/assets',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Update>) => ({
      ...state,
      ...action.payload,
    }),
    updateAssets: (
      state,
      action: PayloadAction<Record<string, ObjectMetadata[]>>,
    ) => {
      return {
        ...state,
        assetListsByType: {
          ...state.assetListsByType,
          ...action.payload,
        },
      };
    },
    updateIsLoadingAssets: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isLoadingAssetList: action.payload,
      };
    },
    updateIsLoadingGroupLabels: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isLoadingGroupLabels: action.payload,
      };
    },
    updateGroupLabels: (
      state,
      action: PayloadAction<Record<string, string[]>>,
    ) => {
      return {
        ...state,
        assetToGroupLabelsMapping: {
          ...state.assetToGroupLabelsMapping,
          ...action.payload,
        },
      };
    },
    updateErrors: (
      state,
      action: PayloadAction<{
        field: string;
        error: unknown;
      }>,
    ) => {
      const { error, field } = action.payload;

      const errorMessage =
        error instanceof Error
          ? error.message
          : typeof error === 'string'
            ? error
            : JSON.stringify(error);

      return {
        ...state,
        errors: {
          ...state.errors,
          [field]: errorMessage,
        },
      };
    },
    clearErrorsByKey: (state, action: PayloadAction<string>) => ({
      ...state,
      errors: deleteRecordByKey(action.payload, state.errors),
    }),
  },
});

export default sliceReducer;
