import React, { useLayoutEffect, useState } from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
  className?: string;
  isHidden?: boolean;
  onClick?: (e: MouseEvent) => void;
  rootElementId?: string;
}

const Portal: React.FC<PortalProps> = ({
  children,
  className,
  isHidden = false,
  onClick,
  rootElementId = 'strivr-ui-portal-root',
}) => {
  const [containerElement] = useState(() => document.createElement('div'));

  useLayoutEffect(() => {
    let rootElement = document.getElementById(rootElementId);
    if (!rootElement) {
      rootElement = document.createElement('div');
      rootElement.setAttribute('id', rootElementId);
      if (className) rootElement.setAttribute('class', className);
      document.body.appendChild(rootElement);
    }
    if (onClick) containerElement.addEventListener('click', onClick);
    rootElement.appendChild(containerElement);
    const root = rootElement;

    return () => {
      if (onClick) containerElement.removeEventListener('click', onClick);
      containerElement.remove();
      if (root.childElementCount === 0) root.remove();
    };
  }, [className, containerElement, onClick, rootElementId]);

  useLayoutEffect(() => {
    containerElement.setAttribute('aria-hidden', isHidden.toString());
  }, [containerElement, isHidden]);

  return ReactDOM.createPortal(children, containerElement);
};

export default Portal;
