import { NodeViewActivityData } from '@strivr/player-models';
import { getFileExtension } from '../../../../utils/getFileExtension';
import { OperationType } from '../../enums/models';
import {
  AssetTypes,
  ObjectMetadata,
  SceneProperties,
} from '../../types/models';
import { Operation } from '../../types/operations';
import { CONTENT_VERSION_FLAGS } from '../../constants';
import { checkContentVersion } from '../../../../utils/contentVersion';
import { State } from '../../../../state/reducer';

const PLATFORM_VARIANT_TO_PATH_MAPPING = {
  windows: 'Windows',
  android: 'Android',
  webgl: 'Webgl',
};

const SOUND_PATH = (programName: string) => `${programName}/Assets/Sounds/`;
const IMAGE_PATH = (programName: string) => `${programName}/Assets/Images/`;
const VIDEO_PATH = (programName: string) => `${programName}/Assets/Videos/`;

const lowerCaseStringEquals = (x: string, y: string): boolean =>
  x.toLowerCase() === y.toLowerCase();

const assetBundleArgsPerPlatformForVersion = (
  assets: ObjectMetadata[],
  programName: string,
) => {
  return assets.map((asset) => {
    if (!asset.variant.os_platform) {
      throw new Error(`Not able to export asset without platform.`);
    } else if (
      !(asset.variant.os_platform in PLATFORM_VARIANT_TO_PATH_MAPPING)
    ) {
      throw new Error('Invalid platform specified');
    }
    const variantPath =
      asset.variant.os_platform[0].toUpperCase() +
      asset.variant.os_platform.substring(1);
    return {
      versionVariantId: asset.versionVariantId,
      path: `${programName}/Assets/AssetBundleObjects/${variantPath}/${asset.versionId}.assetbundle`,
    };
  });
};

interface ZipAssetArgs {
  versionVariantId: string;
  path: string;
}

const assetBundleArgsPerPlatform = (
  assetListsByType: Record<AssetTypes, ObjectMetadata[]>,
  oldVariantsByType: Partial<Record<AssetTypes, ObjectMetadata[]>>,
  scenes: Record<string, SceneProperties>,
  programName: string,
  platform: ExportPlatform,
  contentVersion: number | undefined,
  animationBundleVersionId: string | undefined,
): ZipAssetArgs[] => {
  const characterAssetBundleType = checkContentVersion(
    contentVersion,
    CONTENT_VERSION_FLAGS.genericRig,
  )
    ? 'generic-character-assetbundle'
    : 'character-assetbundle';
  const characterAssetBundles = [
    ...assetListsByType[characterAssetBundleType],
    ...(oldVariantsByType[characterAssetBundleType] ?? []),
  ];
  const environmentAssetBundles = [
    ...assetListsByType['environment-assetbundle'],
    ...(oldVariantsByType['environment-assetbundle'] ?? []),
  ];
  const avatarAssetBundles = [
    ...assetListsByType['avatar-assetbundle'],
    ...(oldVariantsByType['avatar-assetbundle'] ?? []),
  ];

  const animationAssetBundleType = checkContentVersion(
    contentVersion,
    CONTENT_VERSION_FLAGS.genericRig,
  )
    ? 'generic-animation-assetbundle'
    : 'animation-assetbundle';
  const animationAssetBundles = [
    ...assetListsByType[animationAssetBundleType],
    ...(oldVariantsByType[animationAssetBundleType] ?? []),
  ].filter(
    (aab) =>
      aab.variant.os_platform &&
      lowerCaseStringEquals(aab.variant.os_platform, platform) &&
      aab.versionId === animationBundleVersionId,
  );

  return Object.values(scenes).reduce(
    (acc, scene) => {
      const characterAssetVariants = characterAssetBundles.filter(
        (cab) =>
          cab.versionId === scene.characterVersionId &&
          cab.variant.os_platform &&
          lowerCaseStringEquals(cab.variant.os_platform, platform),
      );

      if (characterAssetVariants.length > 0) {
        // eslint-disable-next-line no-param-reassign
        acc = [
          ...acc,
          ...assetBundleArgsPerPlatformForVersion(
            characterAssetVariants,
            programName,
          ),
        ];
      }

      const environmentAssetVariants = environmentAssetBundles.filter(
        (eab) =>
          scene.environments.some(
            (env) => eab.versionId === env.environmentAssetVersionId,
          ) &&
          eab.variant.os_platform &&
          lowerCaseStringEquals(eab.variant.os_platform, platform),
      );
      if (environmentAssetVariants.length > 0) {
        // eslint-disable-next-line no-param-reassign
        acc = [
          ...acc,
          ...assetBundleArgsPerPlatformForVersion(
            environmentAssetVariants,
            programName,
          ),
        ];
      }

      const avatarAssetVariants = avatarAssetBundles.filter(
        (ava) =>
          ava.versionId === scene.avatarVersionId &&
          ava.variant.os_platform &&
          lowerCaseStringEquals(ava.variant.os_platform, platform),
      );

      if (avatarAssetVariants.length > 0) {
        // eslint-disable-next-line no-param-reassign
        acc = [
          ...acc,
          ...assetBundleArgsPerPlatformForVersion(
            avatarAssetVariants,
            programName,
          ),
        ];
      }

      return acc;
    },
    [
      ...assetBundleArgsPerPlatformForVersion(
        animationAssetBundles,
        programName,
      ),
    ] as ZipAssetArgs[],
  );
};

const exportEnvBgAudios = (
  assetListsByType: Record<AssetTypes, ObjectMetadata[]>,
  oldVariantsByType: Partial<Record<AssetTypes, ObjectMetadata[]>>,
  scenes: Record<string, SceneProperties>,
  programName: string,
): ZipAssetArgs[] => {
  const sounds = [
    ...assetListsByType.sound,
    ...(oldVariantsByType.sound ?? []),
  ];
  return Object.values(scenes).reduce((acc, scene) => {
    for (const env of scene.environments) {
      const versionId = env.bgAudioAssetVersionId;
      const asset = sounds.find((e) => e.versionId === versionId);
      if (versionId && asset) {
        // eslint-disable-next-line no-param-reassign
        acc = [
          ...acc,
          {
            versionVariantId: asset.versionVariantId,
            path: `${SOUND_PATH(programName)}${versionId}.${getFileExtension(
              asset.name,
            )}`,
          },
        ];
      }
    }
    return acc;
  }, [] as ZipAssetArgs[]);
};

const exportEnvBgVideos = (
  assetListsByType: Record<AssetTypes, ObjectMetadata[]>,
  oldVariantsByType: Partial<Record<AssetTypes, ObjectMetadata[]>>,
  scenes: Record<string, SceneProperties>,
  programName: string,
): ZipAssetArgs[] => {
  const videos = [
    ...assetListsByType.video,
    ...(oldVariantsByType.video ?? []),
  ];
  return Object.values(scenes).reduce((acc, scene) => {
    for (const env of scene.environments) {
      const versionId = env.bgVideoAssetVersionId;
      const asset = videos.find((e) => e.versionId === versionId);
      if (versionId && asset) {
        // eslint-disable-next-line no-param-reassign
        acc = [
          ...acc,
          {
            versionVariantId: asset.versionVariantId,
            path: `${VIDEO_PATH(programName)}${versionId}.${getFileExtension(
              asset.name,
            )}`,
          },
        ];
      }
    }
    return acc;
  }, [] as ZipAssetArgs[]);
};

const exportOperationAssets = (
  operations: Record<string, Operation>,
  programName: string,
  assetListsByType: Record<AssetTypes, ObjectMetadata[]>,
  oldVariantsByType: Partial<Record<AssetTypes, ObjectMetadata[]>>,
  platform: ExportPlatform,
): ZipAssetArgs[] => {
  const assets = [] as ZipAssetArgs[];
  for (const id in operations) {
    const operation = operations[id];
    switch (operation.type) {
      case OperationType.NotificationDialog2: {
        if (operation.audio) {
          const soundAssets = [
            ...assetListsByType.sound,
            ...(oldVariantsByType.sound ?? []),
          ];
          const soundAsset = soundAssets.find(
            (sa) => sa.versionId === operation.audio?.id,
          );

          if (soundAsset) {
            assets.push({
              versionVariantId: soundAsset.versionVariantId,
              path: `${SOUND_PATH(programName)}${
                operation.audio.id
              }.${getFileExtension(operation.audio.name)}`,
            });
          } else {
            throw new Error('Asset lookup failed for audio in Overlay');
          }
        }
        if (operation.image) {
          const imageAssets = [
            ...assetListsByType.image,
            ...(oldVariantsByType.image ?? []),
          ];
          const imageAsset = imageAssets.find(
            (ia) => ia.versionId === operation.image?.id,
          );

          if (imageAsset) {
            assets.push({
              versionVariantId: imageAsset.versionVariantId,
              path: `${IMAGE_PATH(programName)}${
                operation.image.id
              }.${getFileExtension(operation.image.name)}`,
            });
          } else {
            throw new Error('Asset lookup failed for image in Overlay');
          }
        }
        break;
      }

      case OperationType.CharacterLine: {
        if (operation.vhSpeaks.lipSyncAsset || operation.vhSpeaks.jaliAsset) {
          const assetType = operation.vhSpeaks.lipSyncAsset
            ? 'lipsync-assetbundle'
            : 'jali-assetbundle';
          const versionId = operation.vhSpeaks.lipSyncAsset
            ? operation.vhSpeaks.lipSyncAsset?.id
            : operation.vhSpeaks.jaliAsset?.id;

          const newAssets = [
            ...assetListsByType[assetType],
            ...(oldVariantsByType[assetType] ?? []),
          ].filter((asset) => asset.versionId === versionId);

          const variants = newAssets.filter(
            (asset) =>
              asset.versionId === versionId &&
              asset.variant.os_platform &&
              lowerCaseStringEquals(asset.variant.os_platform, platform),
          );

          if (newAssets.length > 1) {
            assets.push(
              ...assetBundleArgsPerPlatformForVersion(variants, programName),
            );
          }
        } else if (operation.vhSpeaks.soundAsset) {
          const soundAsset = [
            ...assetListsByType.sound,
            ...(oldVariantsByType.sound ?? []),
          ].find((sa) => sa.versionId === operation.vhSpeaks.soundAsset?.id);

          if (soundAsset) {
            assets.push({
              versionVariantId: soundAsset.versionVariantId,
              path: `${SOUND_PATH(programName)}${
                operation.vhSpeaks.soundAsset.id
              }.${getFileExtension(operation.vhSpeaks.soundAsset.name)}`,
            });
          } else {
            throw new Error('Asset lookup failed for sound in CharacterLine');
          }
        }
        break;
      }

      case OperationType.Video: {
        if (operation.video) {
          const videoAssets = [
            ...assetListsByType.video,
            ...(oldVariantsByType.video ?? []),
          ];
          const videoAsset = videoAssets.find(
            (ia) => ia.versionId === operation.video?.id,
          );

          if (videoAsset) {
            assets.push({
              versionVariantId: videoAsset.versionVariantId,
              path: `${VIDEO_PATH(programName)}${
                operation.video.id
              }.${getFileExtension(operation.video.name)}`,
            });
          } else {
            throw new Error('Asset lookup failed for video in Video');
          }
        }
        break;
      }
    }
  }
  return assets;
};

const exportExperienceAssets = (
  assetListByType: Record<AssetTypes, ObjectMetadata[]>,
  oldVariantsByType: Partial<Record<AssetTypes, ObjectMetadata[]>>,
  programName: string,
  experienceJSON: NodeViewActivityData.NodeViewActivityData,
): ZipAssetArgs[] => {
  if (!experienceJSON.image) return [];

  const titleAsset = [
    ...assetListByType.image,
    ...(oldVariantsByType.image ?? []),
  ].find((img) => img.versionId === experienceJSON.image?.asset_id);
  return [
    {
      versionVariantId: titleAsset?.versionVariantId || '',
      path: `${IMAGE_PATH(
        programName,
      )}${titleAsset?.versionId}.${getFileExtension(titleAsset?.name || '')}`,
    },
  ];
};
export const getZipAssetArgs = (
  platform: ExportPlatform,
  experienceJSON: NodeViewActivityData.NodeViewActivityData,
  assetListsByType: Record<AssetTypes, ObjectMetadata[]>,
  outdatedAssets: Partial<Record<string, ObjectMetadata[]>>,
  scenes: Record<string, SceneProperties>,
  operations: Record<string, Operation>,
  programName: string,
): ZipAssetArgs[] => {
  // Here, we flatten the old variants into an object much like `assetListsByType`
  // so it can be accessed in the same manner.
  const oldVariantsByType = Object.values(outdatedAssets).reduce(
    (acc, maybeVersionedAssets) => {
      if (!maybeVersionedAssets || maybeVersionedAssets.length === 0) {
        return acc;
      }

      const assetType = maybeVersionedAssets[0].type;

      const existingAssetsOfType = acc[assetType] ?? [];

      return {
        ...acc,
        [assetType]: [...existingAssetsOfType, ...maybeVersionedAssets],
      };
    },
    {} as Partial<Record<AssetTypes | '', ObjectMetadata[]>>,
  );
  // TODO: This can be optimized a little more by preprocessing the scene to parse out
  // the versionIds organized by asset type a single time (while avoiding duplicates)
  // and then getting the asset args from the single list without duplicates.
  // As of now, we're iterating over the same list multiple times.
  const contentVersion = experienceJSON.content_version;
  const animationBundleVersionId = experienceJSON.vh_animation_bundle?.asset_id;
  const assets = [
    ...assetBundleArgsPerPlatform(
      assetListsByType,
      oldVariantsByType,
      scenes,
      programName,
      platform,
      contentVersion,
      animationBundleVersionId,
    ),
    ...exportEnvBgAudios(
      assetListsByType,
      oldVariantsByType,
      scenes,
      programName,
    ),
    ...exportEnvBgVideos(
      assetListsByType,
      oldVariantsByType,
      scenes,
      programName,
    ),
    ...exportOperationAssets(
      operations,
      programName,
      assetListsByType,
      oldVariantsByType,
      platform,
    ),
    ...exportExperienceAssets(
      assetListsByType,
      oldVariantsByType,
      programName,
      experienceJSON,
    ),
  ];
  // We want to remove all duplicates which unfortunately requires an O(n) iteration as we can't just shove everything into a set and call it
  const assetSet = new Set();
  const filtered = assets.reduce((acc: ZipAssetArgs[], curr) => {
    if (assetSet.has(curr.versionVariantId)) {
      return acc;
    }
    assetSet.add(curr.versionVariantId);
    return [...acc, ...[curr]];
  }, [] as ZipAssetArgs[]);
  return filtered;
};

export function findVersionedAssetInfo(
  state: State,
  assetType: AssetTypes,
  versionId: string | null | undefined,
) {
  if (!versionId) {
    return undefined;
  }

  const { assetListsByType, versionedAssetList } = state.main.assets;

  // Try to find the asset in the list of up-to-date assets
  const currentAsset = assetListsByType[assetType]?.find(
    (e) => e.versionId === versionId,
  );

  // If it can't find an updated asset, try to find an old version.
  // Otherwise, return undefined.
  return currentAsset ?? versionedAssetList[versionId]?.[0] ?? undefined;
}

export function combineAssetListsAndVersionedAssets(
  assetListsByType: Record<AssetTypes, ObjectMetadata[]>,
  outdatedAssets: Partial<Record<string, ObjectMetadata[]>>,
) {
  const allAssetsByType = { ...assetListsByType };

  // Add the outdated assets metadatas into the list of
  // metadatas being sent to preview
  for (const versionId in outdatedAssets) {
    const versionVariants = outdatedAssets[versionId];

    if (!versionVariants || versionVariants.length === 0) {
      continue;
    }

    const { type } = versionVariants[0];

    if (type === '') {
      continue;
    }

    allAssetsByType[type] = [...allAssetsByType[type], ...versionVariants];
  }

  return allAssetsByType;
}
