import React from 'react';

import { classNames } from '../utils/helpers';
import ButtonUndressed from './buttonUndressed';

import styles from './buttonDressedAsLink.module.css';

export type ButtonDressedAsLinkProps =
  React.ButtonHTMLAttributes<HTMLButtonElement>;

const ButtonDressedAsLink = React.forwardRef<
  HTMLButtonElement,
  ButtonDressedAsLinkProps
>(({ children, className, ...buttonProps }, ref) => {
  return (
    <ButtonUndressed
      {...buttonProps}
      className={classNames(styles.buttonDressedAsLink, className, {
        [styles.disabled]: buttonProps.disabled,
      })}
      ref={ref}
    >
      {children}
    </ButtonUndressed>
  );
});

ButtonDressedAsLink.displayName = 'ButtonDressedAsLink';

/**
 * For "links" which don't redirect, but instead perform an action on the page
 * This avoids "javascript:" href nastiness and provides better accessibility
 */
export default ButtonDressedAsLink;
