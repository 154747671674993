import { all, call, put, select } from 'typed-redux-saga';
import { SagaType, createSliceSaga } from 'redux-toolkit-saga';
import { AssetManagementApi } from '../../../../utils/api';
import sliceReducer, {
  AssetContainerType,
  AssetUpgradeCommand,
  AssetUpgradeInfo,
} from './sliceReducer';
import {
  sliceReducer as experienceSliceReducer,
  State as ExperienceState,
} from '../experience/sliceReducer';
import sceneReducer from '../sceneProperties/sliceReducer';
import operationReducer from '../operationContainers/sliceReducer';
import {
  AnimationLibrary,
  AssetBundleTypes,
  AssetTypes,
  CGMetadata,
  ObjectMetadata,
  SceneProperties,
} from '../../types/models';
import { logSagaError } from '../../../../utils/saga';
import { ActionCreatorWithPayload, PayloadAction } from '@reduxjs/toolkit';
import { Buffer } from 'buffer';
import { State } from '../../../../state/reducer';

import FileSaver from 'file-saver';
import { ASSET_ERROR_KEYS } from './errorKeys';
import {
  mapExperienceStateToJSON,
  wrapExperienceInProgramJSON,
} from '../experience/sliceSaga';
import previewSliceSaga from '../preview/sliceSaga';
import { getZipAssetArgs } from './utils';
import { Operation } from '../../types/operations';
import { OperationType } from '../../enums/models';
import { logToServer } from '../../../../utils/logging';
import JsZip from 'jszip';
import { getToggleFromLDClient } from '../../../../utils/toggle';
import { CONTENT_VERSION_FLAGS } from '../../constants';
import { checkContentVersion } from '../../../../utils/contentVersion';

/**
 * TODO: add character assetbundles here when the CG
 * begins creating CG metadata for characters
 */
const ASSET_TYPES_WITH_CG_METADATA = [
  'environment-assetbundle',
  'animation-assetbundle',
  'generic-animation-assetbundle',
];

interface FetchCGMetadataForAsset {
  asset: ObjectMetadata;
}

interface ZipForPlatform {
  platform: ExportPlatform;
}

interface FetchVersionedAsset {
  versionId: string;
  type: AssetTypes;
  experience?: ExperienceState;
  scene?: SceneProperties;
  operation?: Operation;
}
interface FetchSelectedAsset {
  type: AssetTypes | null;
  id: string | null;
}

interface SetExperienceAnimationLibrary {
  experienceVersionId?: string;
}

const sliceSaga = createSliceSaga({
  name: sliceReducer.name,
  caseSagas: {
    fetchAssetBundles: {
      sagaType: SagaType.TakeLatest,
      *fn(action: PayloadAction<Array<AssetBundleTypes>>) {
        const state: State = yield* select();
        try {
          const tenant = state.main.tenants.selectedTenant;
          if (!tenant) {
            throw new Error('No currently selected tenant.');
          }
          const useGroupLabelBasedAssetRetrieval = yield* call(() =>
            getToggleFromLDClient<boolean>(
              'use-group-label-based-asset-retrieval',
              false,
            ),
          );
          const assetBundleTypes = action.payload;
          const requests = assetBundleTypes.map((type) => {
            return call(
              AssetManagementApi({
                useGroupLabelBasedAssetRetrieval,
              }).assets.getAssets(
                {
                  type,
                },
                [tenant.tenant_name],
              ),
            );
          });
          const responses = yield* all(requests);
          for (let i = 0; i < assetBundleTypes.length; i++) {
            const assetType = assetBundleTypes[i];
            const response = responses[i];

            // Same data response mapping and data storing as fetchAssets
            const newAssets = response.data.map((asset) => {
              const {
                name,
                id,
                mimeType,
                tenant,
                version,
                versionId,
                type,
                dateFirstVersionCreated,
                dateCreated,
                versionVariantId,
                variant,
                hash,
                commitHash,
                unityVersion,
              } = asset;

              return {
                name,
                id,
                mimeType,
                tenant,
                version,
                versionId,
                type,
                dateFirstVersionCreated,
                dateCreated,
                versionVariantId,
                variant,
                hash,
                commitHash,
                unityVersion,
              };
            });
            yield* put(
              sliceReducer.actions.updateAssets({
                [assetType]: newAssets,
              }),
            );

            // Self-referencing breaks typings unless we create a new variable referencing the current saga
            const currentSaga: {
              actions: {
                fetchCGMetadataForAsset: ActionCreatorWithPayload<FetchCGMetadataForAsset>;
              };
            } = sliceSaga;

            const assetsWithCGMetadata = newAssets.filter((asset) =>
              ASSET_TYPES_WITH_CG_METADATA.includes(asset.type),
            );
            for (const asset of assetsWithCGMetadata) {
              yield* put(
                currentSaga.actions.fetchCGMetadataForAsset({
                  asset,
                }),
              );
            }
          }

          /**
           * Finally, now that we have stored all the versionVariantIds for each assetbundle,
           * we can now fetch signed URLs for each assetbundle
           */
          yield* put(previewSliceSaga.actions.fetchAllAssetBundleSignedUrls());
        } catch (e) {
          yield* put(
            sliceReducer.actions.updateErrors({
              field: 'assetList',
              error: e,
            }),
          );
          logSagaError(action, e);
        }
      },
    },
    fetchAssets: {
      sagaType: SagaType.TakeEvery,
      *fn(action: PayloadAction<AssetTypes>) {
        const state: State = yield* select();
        try {
          const tenant = state.main.tenants.selectedTenant;
          if (!tenant) {
            throw new Error('No currently selected tenant.');
          }
          const { ignoreNewExperiences } = state.main.assets;
          const useGroupLabelBasedAssetRetrieval = yield* call(() =>
            getToggleFromLDClient<boolean>(
              'use-group-label-based-asset-retrieval',
              false,
            ),
          );
          const assetType = action.payload;
          const request = AssetManagementApi({
            useGroupLabelBasedAssetRetrieval,
          }).assets.getAssets(
            {
              type: assetType,
              ignore_new_experiences:
                assetType === 'experience' ? ignoreNewExperiences : undefined,
              // !!!DELETEME!!!
              //
              // THIS BLOCKS SHARED EXPERIENCES!
              // Currently, since AMS uses Datastore, it cannot handle querying *all*
              // experiences and filtering them by group label. This line blocks
              // experience sharing by only querying experiences *in the current
              // tenant*.
              tenant:
                assetType === 'experience' ? tenant.tenant_name : undefined,
            },
            [tenant.tenant_name],
          );
          const response = yield* call(request);
          const newAssets = response.data.map((asset) => {
            const {
              name,
              id,
              mimeType,
              tenant,
              version,
              versionId,
              type,
              dateFirstVersionCreated,
              dateCreated,
              versionVariantId,
              variant,
              hash,
              commitHash,
              unityVersion,
            } = asset;
            return {
              name,
              id,
              mimeType,
              tenant,
              version,
              versionId,
              type,
              dateFirstVersionCreated,
              dateCreated,
              versionVariantId,
              variant,
              hash,
              commitHash,
              unityVersion,
            };
          });

          yield* put(
            sliceReducer.actions.updateAssets({
              [assetType]: newAssets,
            }),
          );

          /**
           * Finally, for image, sound and video assests, now that we have stored
           * all the versionVariantIds of each asset,
           * we can now fetch signed URLs for each asset.
           */
          if (
            assetType === 'image' ||
            assetType === 'sound' ||
            assetType === 'video'
          ) {
            const newAssetVersionVariantIds = response.data.map((asset) => {
              return asset.versionVariantId;
            });
            yield* put(
              previewSliceSaga.actions.fetchSignedUrlsForVersionVariantIds({
                versionVariantIds: newAssetVersionVariantIds,
                download: false,
              }),
            );
          }
        } catch (e) {
          yield* put(
            sliceReducer.actions.updateErrors({
              field: 'assetList',
              error: e,
            }),
          );
          logSagaError(action, e);
        }
      },
    },
    fetchCGMetadataForAsset: {
      sagaType: SagaType.TakeEvery,
      *fn(action: PayloadAction<FetchCGMetadataForAsset>) {
        const state: State = yield* select();
        try {
          const { asset } = action.payload;
          const tenantName = state.main.tenants.selectedTenant?.tenant_name;
          if (!tenantName) {
            throw new Error('No currently selected tenant.');
          }
          const useGroupLabelBasedAssetRetrieval = yield* call(() =>
            getToggleFromLDClient<boolean>(
              'use-group-label-based-asset-retrieval',
              false,
            ),
          );

          // `AnimationLibrary` if this an `animation-assetbundle` type,
          // otherwise it's `CGMetadata`
          const cgMetadataRequest = AssetManagementApi({
            useGroupLabelBasedAssetRetrieval,
          }).assets.getSingleAsset<CGMetadata | AnimationLibrary>(
            {
              tenant: tenantName,
              parent_version_id: asset.versionId,
            },
            [tenantName],
          );

          const cgMetadata = yield* call(cgMetadataRequest);
          const { data } = cgMetadata;
          if (data) {
            const updatedAssetWithCGMetadata = {
              ...asset,
              cgMetadata: data,
            } as ObjectMetadata;
            yield* put(
              sliceReducer.actions.updateAssetWithCGMetadata({
                updatedAssetWithCGMetadata,
              }),
            );
            if (asset.type.endsWith('animation-assetbundle')) {
              yield* put(
                sliceReducer.actions.update({
                  animationLibrary: data as AnimationLibrary,
                }),
              );
            }
          }
        } catch (e) {
          yield* put(
            sliceReducer.actions.updateErrors({
              // Could be multiple that fail and we should store errors for each
              field: `fetchCGMetadataForAsset-${action.payload.asset.versionId}`,
              error: e,
            }),
          );
          logSagaError(action, e);
        }
      },
    },
    fetchAssetPreview: {
      sagaType: SagaType.TakeEvery,
      *fn(action: PayloadAction<FetchSelectedAsset>) {
        const state: State = yield* select();
        try {
          const tenant = state.main.tenants.selectedTenant;
          const { assetFilesSizes } = state.main.assets;
          if (!tenant) {
            throw new Error('No currently selected tenant.');
          }
          if (!action.payload.type) {
            throw new Error('No type specified');
          }
          if (!action.payload.id) {
            return;
          }
          const assets =
            state.main.assets.assetListsByType[action.payload.type];
          if (!assets) {
            throw new Error('No assets of the selected type');
          }
          const metadata = assets.find((e) => e.id === action.payload.id);
          const urls = state.main.assets.assetURLs;
          const useGroupLabelBasedAssetRetrieval = yield* call(() =>
            getToggleFromLDClient<boolean>(
              'use-group-label-based-asset-retrieval',
              false,
            ),
          );
          // If the cache doesn't already contain this id, we should make the request and update the cache.
          if (action.payload.id && metadata && !urls[metadata?.versionId]) {
            const request = AssetManagementApi({
              useGroupLabelBasedAssetRetrieval,
            }).assets.getSingleAsset<Blob>(
              {
                tenant: tenant.tenant_name,
                id: action.payload.id,
              },
              [tenant.tenant_name],
              { responseType: 'blob' },
            );
            const response = yield* call(request);
            // Get the objectmetadata of the asset
            const url = URL.createObjectURL(response.data);
            yield* put(
              sliceReducer.actions.updateURLs({
                field: metadata.versionId,
                url,
              }),
            );
            // Get filesize
            if (metadata.type !== '') {
              yield* put(
                sliceReducer.actions.update({
                  assetFilesSizes: {
                    ...assetFilesSizes,
                    [metadata.versionId]: response.data.size,
                  },
                }),
              );
            }
          }
        } catch (e) {
          yield* put(
            sliceReducer.actions.updateErrors({
              field: 'assetList',
              error: e,
            }),
          );
          logSagaError(action, e);
        }
      },
    },
    setSelectedAsset: {
      sagaType: SagaType.TakeEvery,
      *fn(action: PayloadAction<FetchSelectedAsset>) {
        const state: State = yield* select();
        try {
          //This is used mostly for the image/sound previews
          const tenant = state.main.tenants.selectedTenant;
          if (!tenant) {
            throw new Error('No currently selected tenant.');
          }
          if (!action.payload.type) {
            throw new Error('No type specified');
          }
          const assets =
            state.main.assets.assetListsByType[action.payload.type];
          if (!assets) {
            throw new Error('No assets of the selected type');
          }
          const metadata = assets.find(
            (e) => e.versionId === action.payload.id,
          );
          yield* put(
            sliceReducer.actions.update({
              selectedAsset: metadata,
            }),
          );
        } catch (e) {
          yield* put(
            sliceReducer.actions.updateErrors({
              field: 'assetList',
              error: e,
            }),
          );
          logSagaError(action, e);
        }
      },
    },

    fetchAssetZipPackage: {
      sagaType: SagaType.TakeLatest,
      *fn(action: PayloadAction<ZipForPlatform>) {
        const state: State = yield* select();
        try {
          yield* put(sliceReducer.actions.update({ isFetchingAssetZip: true }));
          const experienceJSON = mapExperienceStateToJSON(state);
          const programJSON = wrapExperienceInProgramJSON(experienceJSON);
          const { assetListsByType, versionedAssetList } = state.main.assets;
          const { operations } = state.main.operationContainers;
          const { scenes } = state.main.sceneProperties;

          const programName = experienceJSON.name ?? '';
          // TODO: Need to map all asset data here (assetbundles, images, sounds)
          const payload = {
            assets: getZipAssetArgs(
              action.payload.platform,
              experienceJSON,
              assetListsByType,
              versionedAssetList,
              scenes,
              operations,
              programName,
            ),
            programJson: programJSON,
            experienceName: experienceJSON.name,
          };

          const zip = new JsZip();
          const useGroupLabelBasedAssetRetrieval = yield* call(() =>
            getToggleFromLDClient<boolean>(
              'use-group-label-based-asset-retrieval',
              false,
            ),
          );

          // If there are assets, create Assets/ folder and related folders and files
          for (const asset of payload.assets) {
            const tenant = state.main.tenants.selectedTenant?.tenant_name;
            const request = AssetManagementApi({
              useGroupLabelBasedAssetRetrieval,
            }).assets.getSingleAsset<Blob>(
              {
                tenant,
                version_variant_id: asset.versionVariantId,
              },
              tenant ? [tenant] : undefined,
              { responseType: 'blob', timeout: 900000 },
            );
            const response = yield* call(request);
            const fileBuffer = response.data;
            zip.file(asset.path, fileBuffer, { createFolders: true });
          }

          // Add program.json at root program folder
          const programJsonBuffer = Buffer.from(JSON.stringify(programJSON));
          zip.file(
            `${experienceJSON.name}/${programJSON.id}.json`,
            programJsonBuffer,
            {
              createFolders: true,
            },
          );

          zip.generateAsync({ type: 'blob' }).then(
            (zipFile) => {
              FileSaver.saveAs(zipFile, `${programName}.zip`);
            },
            () => logToServer('warn', 'Failed to generate zip'),
          );

          yield* put(
            sliceReducer.actions.update({ isFetchingAssetZip: false }),
          );
          yield* put(
            sliceReducer.actions.clearErrorsByKey(ASSET_ERROR_KEYS.ZIP_PACKAGE),
          );
        } catch (e) {
          yield* put(
            sliceReducer.actions.updateErrors({
              field: ASSET_ERROR_KEYS.ZIP_PACKAGE,
              error: e,
            }),
          );
          yield* put(
            sliceReducer.actions.update({ isFetchingAssetZip: false }),
          );
          logSagaError(action, e);
        }
      },
    },
    fetchVersionedAsset: {
      sagaType: SagaType.TakeEvery,
      *fn(action: PayloadAction<FetchVersionedAsset>) {
        const state: State = yield* select();
        try {
          const tenant = state.main.tenants.selectedTenant;
          const contentVersion = state.main.experience.content_version;
          const animationBundletype = checkContentVersion(
            contentVersion,
            CONTENT_VERSION_FLAGS.genericRig,
          )
            ? 'generic-animation-assetbundle'
            : 'animation-assetbundle';

          if (!tenant) {
            throw new Error('No currently selected tenant.');
          }

          // Check if this version already exists in the versioned assets list
          const asset =
            state.main.assets.versionedAssetList[action.payload.versionId]?.[0];

          // Map this version to the latest asset version
          let latestAsset = state.main.assets.assetListsByType[
            action.payload.type
          ].find((a) => a.id === asset?.id);

          const useGroupLabelBasedAssetRetrieval = yield* call(() =>
            getToggleFromLDClient<boolean>(
              'use-group-label-based-asset-retrieval',
              false,
            ),
          );

          let oldAssetVersionedMetadatas: ObjectMetadata[] = [];

          // If we can't find it, retrieve it from the backend
          if (!latestAsset) {
            const oldAssetsRequest = AssetManagementApi({
              useGroupLabelBasedAssetRetrieval,
            }).assets.getAssets(
              {
                version_id: action.payload.versionId,
              },
              [tenant.tenant_name],
            );
            const oldAssetsResponse = yield* call(oldAssetsRequest);
            oldAssetVersionedMetadatas = oldAssetsResponse.data.map((asset) => {
              const {
                name,
                id,
                mimeType,
                tenant,
                version,
                versionId,
                type,
                dateFirstVersionCreated,
                dateCreated,
                versionVariantId,
                variant,
              } = asset;
              return {
                name,
                id,
                mimeType,
                tenant,
                version,
                versionId,
                type,
                dateFirstVersionCreated,
                dateCreated,
                versionVariantId,
                variant,
              };
            });
            let cgMetadata: CGMetadata | AnimationLibrary | undefined;
            // Try to gather the CGMetadata for the outdated asset. This lets us save
            // experiences with old environments. If this fails, it either means that
            // the asset didn't have CGMetadata to begin with, or the server didn't
            // respond with CGMetadata (which means bad data).
            try {
              const cgMetadataRequest = AssetManagementApi({
                useGroupLabelBasedAssetRetrieval,
              }).assets.getSingleAsset<CGMetadata | AnimationLibrary>(
                {
                  tenant: tenant.tenant_name,
                  parent_version_id: action.payload.versionId,
                },
                [tenant.tenant_name],
              );

              const cgMetadataResponse = yield* call(cgMetadataRequest);
              cgMetadata = cgMetadataResponse.data;
            } catch (e) {
              logToServer(
                'info',
                `No cgMetadata found for ${oldAssetVersionedMetadatas[0]?.type} asset version ${action.payload.versionId}`,
              );
            }
            yield* put(
              sliceReducer.actions.setVersionedAsset({
                versionId: action.payload.versionId,
                assetMetadatas: oldAssetVersionedMetadatas.map((a) => ({
                  ...a,
                  cgMetadata: cgMetadata as CGMetadata | undefined,
                })),
              }),
            );
            const currentAssets =
              state.main.assets.assetListsByType[action.payload.type];
            // Find the asset that shares the same asset ID
            latestAsset = currentAssets.find(
              (a) => a.id === oldAssetVersionedMetadatas?.[0]?.id,
            );
          }

          // If we still can't find the asset, it may not exist for this tenant
          // In some cases, we may be want to replace it with what we have elsewhere
          // An example would be the animation-assetbundle after cloning
          if (!latestAsset) {
            if (action.payload.type === animationBundletype) {
              const currentAnimationBundle =
                state.main.assets.assetListsByType[animationBundletype][0];
              if (currentAnimationBundle) {
                yield* put(
                  experienceSliceReducer.actions.update({
                    animationBundle: {
                      id: currentAnimationBundle.versionId,
                      assetType: animationBundletype,
                      name: currentAnimationBundle.name,
                    },
                  }),
                );
              }
            }
          }

          if (latestAsset) {
            let container: AssetUpgradeInfo['container'] | undefined;

            // First, we figure out which container we have to upgrade the asset into
            if (action.payload.experience) {
              container = {
                type: AssetContainerType.EXPERIENCE,
                id: action.payload.experience.id,
              };
            }

            if (action.payload.scene) {
              container = {
                type: AssetContainerType.SCENE,
                id: action.payload.scene.id,
              };
            }
            if (action.payload.operation) {
              container = {
                type: AssetContainerType.OPERATION,
                id: action.payload.operation.id,
              };
            }

            if (!container) {
              throw new Error(
                `Failed to upgrade asset ${action.payload.versionId}: No container found!`,
              );
            }

            const assetUpgradeInfo: AssetUpgradeInfo = {
              newVersionId: latestAsset.versionId,
              newVersionCreatedAt: latestAsset.dateCreated,
              assetType: action.payload.type,
              name: latestAsset.name,
              container,
            };

            // Prepare the command to upgrade the asset (which asset to replace, where to do it)
            const assetUpgradeCommand: AssetUpgradeCommand = {
              oldVersionId: action.payload.versionId,
              containerId: container.id,
              assetUpgradeInfo,
            };

            const useManualAssetUpgrading = yield* call(() =>
              getToggleFromLDClient<boolean>(
                'use-manual-asset-upgrading',
                false,
              ),
            );

            // Self-referencing breaks typings unless we create a new variable referencing the current saga
            const currentSaga: {
              actions: {
                upgradeAsset: ActionCreatorWithPayload<AssetUpgradeCommand>;
              };
            } = sliceSaga;

            if (
              useManualAssetUpgrading ||
              !state.main.assets.isAutoAssetUpdatingEnabled
            ) {
              // Store the asset upgrade command into state so we can manually choose
              // to run it.
              yield* put(
                sliceReducer.actions.setSuggestedAssetUpdate(
                  assetUpgradeCommand,
                ),
              );
              // Get the signed URL for the asset so we can see it in Preview
              yield* put(
                previewSliceSaga.actions.fetchSignedUrlsForVersionVariantIds({
                  versionVariantIds: oldAssetVersionedMetadatas.map(
                    (v) => v.versionVariantId,
                  ),
                  download: true,
                }),
              );
              // Open the UI to show this
              yield* put(
                sliceReducer.actions.setShowSuggestedAssetUpdates(true),
              );
            } else {
              // Automatically upgrade the asset
              yield* put(currentSaga.actions.upgradeAsset(assetUpgradeCommand));
            }
          }
        } catch (e) {
          logSagaError(action, e);
        }
      },
    },

    upgradeAsset: {
      sagaType: SagaType.TakeEvery,
      *fn(action: PayloadAction<AssetUpgradeCommand>) {
        const state: State = yield* select();

        const contentVersion = state.main.experience.content_version;
        const animationBundletype = checkContentVersion(
          contentVersion,
          CONTENT_VERSION_FLAGS.genericRig,
        )
          ? 'generic-animation-assetbundle'
          : 'animation-assetbundle';
        const characterBundleType = checkContentVersion(
          contentVersion,
          CONTENT_VERSION_FLAGS.genericRig,
        )
          ? 'generic-character-assetbundle'
          : 'character-assetbundle';

        const { oldVersionId: versionIdBeforeUpdate, assetUpgradeInfo } =
          action.payload;
        if (!assetUpgradeInfo) {
          throw new Error(
            `Cannot update asset version ${versionIdBeforeUpdate}: no asset upgrade info provided`,
          );
        }
        const { type: containerType, id: containerId } =
          assetUpgradeInfo.container;
        const versionIdAfterUpdate = assetUpgradeInfo.newVersionId;
        let message = `Updated ${assetUpgradeInfo.assetType} version id `;

        // Remove the asset update suggestion, if one exists
        yield* put(
          sliceReducer.actions.setSuggestedAssetUpdate({
            oldVersionId: versionIdBeforeUpdate,
            containerId,
            assetUpgradeInfo: undefined,
          }),
        );

        if (containerType === AssetContainerType.EXPERIENCE) {
          if (assetUpgradeInfo.assetType === animationBundletype) {
            yield* put(
              experienceSliceReducer.actions.update({
                animationBundle: {
                  id: versionIdAfterUpdate,
                  assetType: animationBundletype,
                  name: assetUpgradeInfo.name,
                },
              }),
            );

            const currentSaga: {
              actions: {
                setExperienceAnimationLibrary: ActionCreatorWithPayload<SetExperienceAnimationLibrary>;
              };
            } = sliceSaga;

            yield put(currentSaga.actions.setExperienceAnimationLibrary({}));
          }
        } else if (containerType === AssetContainerType.SCENE) {
          const scene = state.main.sceneProperties.scenes[containerId];

          if (!scene) {
            throw new Error(
              `Failed to update asset ${versionIdAfterUpdate} in scene ${containerId}: scene could not be found!`,
            );
          }

          if (assetUpgradeInfo.assetType === characterBundleType) {
            yield* put(
              sceneReducer.actions.updateScene({
                id: containerId,
                sceneProperties: {
                  characterVersionId: versionIdAfterUpdate,
                },
              }),
            );
          } else if (assetUpgradeInfo.assetType === 'environment-assetbundle') {
            const env = scene.environments.find(
              (env) => env.environmentAssetVersionId === versionIdBeforeUpdate,
            );
            if (env) {
              yield* put(
                sceneReducer.actions.updateEnvironment({
                  sceneId: containerId,
                  envId: env.id,
                  environmentProperties: {
                    environmentAssetVersionId: versionIdAfterUpdate,
                  },
                }),
              );
            }
          } else if (assetUpgradeInfo.assetType === 'avatar-assetbundle') {
            yield* put(
              sceneReducer.actions.updateScene({
                id: containerId,
                sceneProperties: {
                  avatarVersionId: versionIdAfterUpdate,
                },
              }),
            );
          } else if (assetUpgradeInfo.assetType === 'sound') {
            const env = scene.environments.find(
              (env) => env.bgAudioAssetVersionId === versionIdBeforeUpdate,
            );
            if (env) {
              yield* put(
                sceneReducer.actions.updateEnvironment({
                  sceneId: containerId,
                  envId: env.id,
                  environmentProperties: {
                    bgAudioAssetVersionId: versionIdAfterUpdate,
                  },
                }),
              );
            }
          } else if (assetUpgradeInfo.assetType === 'video') {
            const env = scene.environments.find(
              (env) => env.bgVideoAssetVersionId === versionIdBeforeUpdate,
            );
            if (env) {
              yield* put(
                sceneReducer.actions.updateEnvironment({
                  sceneId: containerId,
                  envId: env.id,
                  environmentProperties: {
                    bgVideoAssetVersionId: versionIdAfterUpdate,
                  },
                }),
              );
            }
          }
        } else if (containerType === AssetContainerType.OPERATION) {
          const operationsState = state.main.operationContainers.operations;
          const operation = operationsState[containerId];

          if (!operation) {
            throw new Error(
              `Failed to update asset ${versionIdAfterUpdate} in operation ${containerId}: operation could not be found!`,
            );
          }

          switch (operation.type) {
            case OperationType.NotificationDialog2:
              if (operation.audio) {
                message += 'for Overlay Audio';
                yield* put(
                  operationReducer.actions.update({
                    operations: {
                      ...operationsState,
                      [containerId]: {
                        ...operation,
                        audio: {
                          ...operation.audio,
                          id: versionIdAfterUpdate,
                        },
                      },
                    },
                  }),
                );
              } else if (operation.image) {
                message += 'for Overlay Image';
                yield* put(
                  operationReducer.actions.update({
                    operations: {
                      ...operationsState,
                      [containerId]: {
                        ...operation,
                        image: {
                          ...operation.image,
                          id: versionIdAfterUpdate,
                        },
                      },
                    },
                  }),
                );
              }
              break;
            case OperationType.CharacterLine:
              if (operation.vhSpeaks.lipSyncAsset) {
                message += 'for Lipsync Pro asset bundle';
                yield* put(
                  operationReducer.actions.update({
                    operations: {
                      ...operationsState,
                      [containerId]: {
                        ...operation,
                        vhSpeaks: {
                          ...operation.vhSpeaks,
                          lipSyncAsset: {
                            ...operation.vhSpeaks.lipSyncAsset,
                            id: versionIdAfterUpdate,
                          },
                        },
                      },
                    },
                  }),
                );
              } else if (operation.vhSpeaks.jaliAsset) {
                message += 'for Jali Lipsync asset bundle';
                yield* put(
                  operationReducer.actions.update({
                    operations: {
                      ...operationsState,
                      [containerId]: {
                        ...operation,
                        vhSpeaks: {
                          ...operation.vhSpeaks,
                          jaliAsset: {
                            ...operation.vhSpeaks.jaliAsset,
                            id: versionIdAfterUpdate,
                          },
                        },
                      },
                    },
                  }),
                );
              } else if (operation.vhSpeaks.soundAsset) {
                message += 'for Character Line audio';
                yield* put(
                  operationReducer.actions.update({
                    operations: {
                      ...operationsState,
                      [containerId]: {
                        ...operation,
                        vhSpeaks: {
                          ...operation.vhSpeaks,
                          soundAsset: {
                            ...operation.vhSpeaks.soundAsset,
                            id: versionIdAfterUpdate,
                          },
                        },
                      },
                    },
                  }),
                );
              }
              break;
          }
        }
        logToServer('info', message, {
          versionIdBeforeUpdate,
          versionIdAfterUpdate,
        });
      },
    },

    setExperienceAnimationLibrary: {
      sagaType: SagaType.TakeLatest,
      *fn(action: PayloadAction<SetExperienceAnimationLibrary>) {
        const state: State = yield* select();
        try {
          const tenantName = state.main.tenants.selectedTenant?.tenant_name;
          const { animationBundle } = state.main.experience;
          const useGroupLabelBasedAssetRetrieval = yield* call(() =>
            getToggleFromLDClient<boolean>(
              'use-group-label-based-asset-retrieval',
              false,
            ),
          );

          const cgMetadataRequest = AssetManagementApi({
            useGroupLabelBasedAssetRetrieval,
          }).assets.getSingleAsset<AnimationLibrary>(
            { tenant: tenantName, parent_version_id: animationBundle?.id },
            [tenantName ?? ''],
          );
          const cgMetadata = yield* call(cgMetadataRequest);
          const { data } = cgMetadata;
          if (data) {
            yield* put(
              sliceReducer.actions.update({
                animationLibrary: data as AnimationLibrary,
              }),
            );
          }
        } catch (e) {
          yield* put(
            sliceReducer.actions.updateErrors({
              // Could be multiple that fail and we should store errors for each
              field: `setExperienceAnimationLibrary-${state.main.experience.animationBundle?.id}`,
              error: e,
            }),
          );
          logSagaError(action, e);
        }
      },
    },

    setIgnoreNewExperiencesAndReload: {
      sagaType: SagaType.TakeLatest,
      *fn(action: PayloadAction<boolean>) {
        yield* put(
          sliceReducer.actions.update({
            ignoreNewExperiences: action.payload,
          }),
        );

        const currentSaga: {
          actions: {
            fetchAssets: ActionCreatorWithPayload<AssetTypes>;
          };
        } = sliceSaga;

        // Now that we've changed the `Show New Experience` property,
        // reload the list of experiences
        yield put(currentSaga.actions.fetchAssets('experience'));
      },
    },
  },
});

export default sliceSaga;
