import { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';

import { initTracking } from '../../utils/tracking';

const useInitMixpanel = () => {
  const { user } = useAuth0();

  useEffect(() => {
    if (user) initTracking(user);
  }, [user]);
};

export default useInitMixpanel;
