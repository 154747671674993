import axios, { AxiosRequestConfig } from 'axios';
import { AssetTestModel, ObjectMetadata } from '../areas/main/types/models';

const getTypesafeWrappers = (baseUrl: string) => {
  return {
    makeDeleteRequest:
      <T = never>(url: string, config?: AxiosRequestConfig) =>
      () =>
        axios.delete<T>(baseUrl + url, config),
    makeGetRequest:
      <T = never>(url: string, config?: AxiosRequestConfig) =>
      () =>
        axios.get<T>(baseUrl + url, config),
    makePatchRequest:
      <T = never>(url: string, data?: unknown, config?: AxiosRequestConfig) =>
      () =>
        axios.patch<T>(baseUrl + url, data, config),
    makePostRequest:
      <T = never>(url: string, data?: unknown, config?: AxiosRequestConfig) =>
      () =>
        axios.post<T>(baseUrl + url, data, config),
    makePutRequest:
      <T = never>(url: string, data?: unknown, config?: AxiosRequestConfig) =>
      () =>
        axios.put<T>(baseUrl + url, data, config),
    makeRequest:
      <T = never>(config: AxiosRequestConfig) =>
      () =>
        axios.request<T>({
          ...config,
          baseURL: baseUrl + (config.baseURL ?? ''),
        }),
  };
};

export const makeCreatorGenAiApi = (controller?: string) => {
  const baseUrl = `${process.env.REACT_APP_CREATOR_GENAI_API_URL}/${
    controller ? `${controller}/` : ''
  }`;

  return getTypesafeWrappers(baseUrl);
};

export const makeAssetManagementBaseURL = (
  version: 1,
  controller?: string,
  additionalDomains?: string,
  hasParams?: boolean,
) => {
  let url = `${process.env.REACT_APP_ASSETS_API_URL}/v${version}`;
  const hasAdditionalParams = hasParams ?? true;

  if (controller && additionalDomains) {
    url = `${url}/${controller}/${additionalDomains}`;
  } else {
    url = `${url}/${controller ? `${controller}` : ''}`;
  }

  const additionalParamsString = hasAdditionalParams ? '?' : '';
  return `${url}${additionalParamsString}`;
};

export const makeAssetManagementApi = (
  version: 1,
  controller?: string,
  additionalDomains?: string,
  hasParams?: boolean,
) => {
  //Dummied with localhost
  return getTypesafeWrappers(
    makeAssetManagementBaseURL(
      version,
      controller,
      additionalDomains,
      hasParams,
    ),
  );
};

export const makeCamApi = (version: 1, controller?: string) => {
  const baseUrl = `${process.env.REACT_APP_CAM_API_URL}/v${version}/${
    controller ? `${controller}` : ''
  }`;

  return getTypesafeWrappers(baseUrl);
};

export function getAMSBaseURL(version: 1) {
  return `${process.env.REACT_APP_ASSETS_API_URL}/v${version}` as const;
}

interface AssetQuery {
  name?: string;
  tenant?: string;
  id?: string;
  all_versions?: boolean;
  version?: number;
  version_id?: string;
  version_variant_id?: string;
  parent_version_id?: string;
  variant?: Partial<Record<string, string>>;
  type?: string;
  mime_type?: string;
  ignore_new_experiences?: boolean;
}

export interface SignedUrlMetadata {
  versionVariantId: string;
  signedUrl: string | null;
}

export type AssetId = string;
export type GroupLabel = string;
export type AssetIdsToGroupLabelsMapping = Partial<
  Record<AssetId, GroupLabel[]>
>;

function assetQueryToQuerystring(query: AssetQuery): string {
  let querystring = '';

  if (query.id) {
    querystring += `id=${query.id}&`;
  }

  if (query.name) {
    querystring += `name=${query.name}&`;
  }

  if (query.tenant) {
    querystring += `tenant=${query.tenant}&`;
  }

  if (query.all_versions) {
    querystring += `allVersions=${query.all_versions}&`;
  }

  if (query.version_id) {
    querystring += `versionId=${query.version_id}&`;
  }

  if (query.version_variant_id) {
    querystring += `versionVariantId=${query.version_variant_id}&`;
  }

  if (query.parent_version_id) {
    querystring += `parentVersionId=${query.parent_version_id}&`;
  }

  if (query.variant) {
    const variants = query.variant;
    querystring += Object.keys(variants).reduce(
      (variantsString, variantKey) =>
        // Add each key of the variants to the querystring of the form `...variant[os_platform]=web&variant[foo]=bar&...`
        `${variantsString}variant[${variantKey}]=${
          variants[variantKey as keyof typeof variants]
        }&`,
      ``,
    );
  }

  if (query.type) {
    querystring += `type=${query.type}&`;
  }

  if (query.mime_type) {
    querystring += `mimeType=${query.mime_type}&`;
  }

  if (query.version) {
    querystring += `mimeType=${query.version}&`;
  }

  if (query.ignore_new_experiences) {
    querystring += `ignoreNewExps=${query.ignore_new_experiences}&`;
  }

  return querystring;
}

const AMS_BASE_URL = getAMSBaseURL(1);

interface AmsApiFlags {
  useGroupLabelBasedAssetRetrieval?: boolean;
}

const DEFAULT_FLAGS: AmsApiFlags = {
  useGroupLabelBasedAssetRetrieval: false,
};

export const AssetManagementApi = (flags: AmsApiFlags = DEFAULT_FLAGS) => ({
  assets: {
    getSingleAsset: <ResponseType = ObjectMetadata>(
      query: AssetQuery,
      groupLabels?: string[],
      requestConfig?: AxiosRequestConfig,
    ) => {
      if (!flags.useGroupLabelBasedAssetRetrieval) {
        return () =>
          axios.get<ResponseType>(
            `${AMS_BASE_URL}/asset?${assetQueryToQuerystring(query)}`,
            requestConfig,
          );
      }

      // !!!!!! TODO: DELETE THE LINE BELOW! !!!!!!
      // This is used to allow group-label-based retrieval, ignoring the
      // tenant field. In the future, this should be a filter option instead.
      delete query.tenant;

      return () =>
        axios.post<ResponseType>(
          `${AMS_BASE_URL}/asset/get`,
          {
            ...query,
            group_labels: groupLabels,
          },
          requestConfig,
        );
    },

    getAssets: (
      query: AssetQuery,
      groupLabels?: string[],
      requestConfig?: AxiosRequestConfig,
    ) => {
      if (!flags.useGroupLabelBasedAssetRetrieval) {
        return () =>
          axios.get<ObjectMetadata[]>(
            `${AMS_BASE_URL}/assets?${assetQueryToQuerystring(query)}`,
            requestConfig,
          );
      }

      return () =>
        axios.post<ObjectMetadata[]>(
          `${AMS_BASE_URL}/assets/get`,
          {
            ...query,
            group_labels: groupLabels,
          },
          requestConfig,
        );
    },

    uploadAsset: (
      assetMetadata: {
        name: string;
        tenant?: string;
        type: string;
        id?: string;
        versionId?: string;
        parentVersionId?: string;
        variant?: Record<string, string>;
        unityVersion?: string;
        hash?: string;
        commitHash?: string;
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      assetContent: any,
      contentType: 'application/json' | string,
      onProgress?: (e: ProgressEvent) => void,
    ) => {
      const assetMetadataToQueryString = Object.keys(assetMetadata).reduce(
        (querystring, currKey) => {
          const currValue =
            assetMetadata[currKey as keyof typeof assetMetadata];

          if (!currValue) {
            return querystring;
          }

          const valueString =
            typeof currValue === 'string'
              ? currValue
              : JSON.stringify(currValue);

          return `${querystring}${currKey}=${valueString}&`;
        },
        '',
      );

      return () =>
        axios.put<ObjectMetadata>(
          `${AMS_BASE_URL}/asset?${assetMetadataToQueryString}`,
          assetContent,
          {
            headers: { 'Content-type': contentType },
            onUploadProgress: onProgress,
          },
        );
    },

    cloneAsset: (
      versionId: string,
      sourceTenant: string,
      destinationTenant: string,
      newName: string,
    ) => {
      return () =>
        axios.put<{ asset_id: string }>(`${AMS_BASE_URL}/asset/clone`, {
          asset_version_id: versionId,
          source_tenant: sourceTenant,
          destination_tenant: destinationTenant,
          new_name: newName,
        });
    },
  },

  publish: {
    publishExperience: (assetVersionId: string, tenant: string) => {
      return () =>
        axios.post<{ asset_id: string }>(
          `${AMS_BASE_URL}/publishExperience?tenant=${tenant}`,
          {
            version_id: assetVersionId,
          },
        );
    },
  },

  signedUrls: {
    getSignedUrlsList: (
      assetVersionVariantIds: string[],
      groupLabels?: string[],
    ) => {
      return () =>
        axios.post<SignedUrlMetadata[]>(`${AMS_BASE_URL}/signedurls`, {
          version_variant_ids: assetVersionVariantIds,
          group_labels: groupLabels,
        });
    },
  },

  groupLabels: {
    getAssetGroupLabels: (assetIds: string[], groupLabelsFilter?: string[]) => {
      return () =>
        axios.post<Partial<Record<string, string[]>>>(
          `${AMS_BASE_URL}/group_label/get`,
          {
            asset_ids: assetIds,
            group_labels: groupLabelsFilter,
          },
        );
    },

    addGroupLabels: (
      groupLabelsForAssetIds: AssetIdsToGroupLabelsMapping,
      groupLabelsFilter?: string[],
    ) => {
      return () =>
        axios.post<void>(`${AMS_BASE_URL}/group_label`, {
          asset_ids: groupLabelsForAssetIds,
          group_labels: groupLabelsFilter,
        });
    },

    overwriteGroupLabels: (
      groupLabelsForAssetIds: AssetIdsToGroupLabelsMapping,
      groupLabelsFilter?: string[],
    ) => {
      return () =>
        axios.put<void>(`${AMS_BASE_URL}/group_label`, {
          asset_ids: groupLabelsForAssetIds,
          group_labels: groupLabelsFilter,
        });
    },

    deleteGroupLabels: (
      groupLabelsForAssetIds: AssetIdsToGroupLabelsMapping,
      groupLabelsFilter?: string[],
    ) => {
      return () =>
        axios.delete<void>(`${AMS_BASE_URL}/group_label`, {
          data: {
            asset_ids: groupLabelsForAssetIds,
            group_labels: groupLabelsFilter,
          },
        });
    },
  },

  testing: {
    queueAssetForTesting: (assetVersionId: string) => {
      return () =>
        axios.post<string>(`${AMS_BASE_URL}/testing/queue`, {
          version_id: assetVersionId,
        });
    },

    getAssetTests: (
      queryById: string,
      queryByIdType: 'test_id' | 'asset_id' | 'version_id',
    ) => {
      return () =>
        axios.post<AssetTestModel[]>(`${AMS_BASE_URL}/testing/get_tests`, {
          [queryByIdType]: queryById,
        });
    },

    uploadAssetTestResults: (
      testId: string,
      result: 'queued' | 'testing' | 'passed' | 'failed' | 'requeued',
      resultData?: string,
    ) => {
      return () =>
        axios.post<void>(`${AMS_BASE_URL}/testing/upload_results`, {
          test_id: testId,
          result,
          result_data: resultData,
        });
    },
  },
});
