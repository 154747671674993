import { useRef } from 'react';

const useThrottleWithDebounce = <T extends unknown[]>(
  callback: (...args: T) => void,
  delayMs: number
): ((...args: T) => void) => {
  const argsRef = useRef<T>();
  const isThrottling = useRef(false);
  const timeoutId = useRef<number>();

  return (...args: T) => {
    if (isThrottling.current) return;

    isThrottling.current = true;
    argsRef.current = args;
    callback(...args);

    if (timeoutId.current) window.clearTimeout(timeoutId.current);

    timeoutId.current = window.setTimeout(() => {
      if (argsRef.current) callback(...argsRef.current);
      isThrottling.current = false;
    }, delayMs);
  };
};

export default useThrottleWithDebounce;
