import React from 'react';
import classNames from 'classnames';

import CheckboxSelected from '../../res/assets/images/CheckboxSelected.svg';
import CheckboxUnselected from '../../res/assets/images/CheckboxUnselected.svg';
import styles from './checkBox.module.css';

export type CheckBoxProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'type'
>;

const CheckBox: React.FC<CheckBoxProps> = ({ ...checkBoxProps }) => {
  return (
    <label title={checkBoxProps.title}>
      <input
        {...checkBoxProps}
        className={classNames(styles.checkbox, {
          [styles.checked]: checkBoxProps.checked,
        })}
        type="checkbox"
      />
      {checkBoxProps.checked ? <CheckboxSelected /> : <CheckboxUnselected />}
    </label>
  );
};
export default CheckBox;
