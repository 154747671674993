// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._16gBEm-NQTtAt9QqiPfbO_ {\n    cursor: pointer;\n    transition: opacity 0.2s ease-out;\n}\n\n.lNpBc8NEHCU33FOBKXvWg {\n    margin-right: 5px;\n}\n\n._3NgG_-uXI8Vw1WxrSO69tD {\n    font-size: 14px;\n}", ""]);
// Exports
exports.locals = {
	"controlBar_button": "_16gBEm-NQTtAt9QqiPfbO_",
	"margin_right": "lNpBc8NEHCU33FOBKXvWg",
	"controlBar_fontSize": "_3NgG_-uXI8Vw1WxrSO69tD"
};
module.exports = exports;
