// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2qCnBhLl3gpmrjR70i0G7I {\n    background:#555454;\n    border-radius: 8px;\n    display: flex;\n    flex-direction: column;\n    min-height: 100vh;\n}\n\n.n2ZT1X_nMwZctgqRlmKpR {\n    align-self: center;\n    margin-top: 148px;\n}\n\n._3h4Li5Gugaud-QYen4cPKW {\n    text-align: center;\n    margin-top: 68px;\n    font-size: 32px;\n}\n\n.eTVs8WqW8M6ejiOAmaseA{\n    color: #989c9d;\n    text-align: center;\n    margin-top: 101px;\n}\n\n", ""]);
// Exports
exports.locals = {
	"container": "_2qCnBhLl3gpmrjR70i0G7I",
	"icon": "n2ZT1X_nMwZctgqRlmKpR",
	"description": "_3h4Li5Gugaud-QYen4cPKW",
	"contactSupport": "eTVs8WqW8M6ejiOAmaseA"
};
module.exports = exports;
