import createSagaMiddleware from 'redux-saga';

import { configureStore } from '@reduxjs/toolkit';
import autologMiddleware from './middleware/autolog';
import autosaveMiddleware from './middleware/autosave';
import undoMiddleware from './middleware/undo';
import reducer from './reducer';
import saga from './saga';

export const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: true,
      serializableCheck: {
        ignoredActionPaths: ['payload.reactFlowInstance'],
        warnAfter: 128,
      },
      thunk: false,
    }).concat([
      autologMiddleware,
      autosaveMiddleware,
      undoMiddleware,
      sagaMiddleware,
    ]),
});

sagaMiddleware.run(saga);

export type AppDispatch = typeof store.dispatch;

export default store;
