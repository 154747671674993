import React, { useCallback, useLayoutEffect, useRef } from 'react';

import { classNames } from '../utils/helpers';

import styles from './textArea.module.css';

interface TextAreaProps
  extends Omit<React.AllHTMLAttributes<HTMLTextAreaElement>, 'type'> {
  textAreaStyling?: string;
  autoExpand?: boolean;
  error?: string | null;
  resizable?: boolean;
}

const TextArea: React.FC<TextAreaProps> = ({
  textAreaStyling,
  autoExpand,
  className,
  error,
  resizable,
  ...textAreaProps
}) => {
  const textAreaRef = useRef(null as HTMLTextAreaElement | null);

  const handleInput = useCallback(() => {
    if (!textAreaRef.current || !autoExpand) return;
    // Need to set height to 0 first, or else highlight/delete block does not work
    textAreaRef.current.style.height = '0';
    const scrollHeightPx = textAreaRef.current.scrollHeight.toFixed(0);
    if (scrollHeightPx !== '0')
      textAreaRef.current.style.height = `${scrollHeightPx}px`;
  }, [autoExpand]);

  useLayoutEffect(() => {
    handleInput();
  }, [handleInput]);
  return (
    <span className={classNames(styles.textArea, className)}>
      <textarea
        {...textAreaProps}
        ref={textAreaRef}
        className={classNames(textAreaStyling || '', styles.textArea_input, {
          [styles.textArea_input__autoExpand]: !!autoExpand,
          [styles.textArea_input__invalid]: !!error,
          [styles.disable_resize]: !resizable,
        })}
        aria-invalid={!!error || textAreaProps['aria-invalid']}
        onInput={handleInput}
      ></textarea>
      <span className={styles.error}>{error}</span>
    </span>
  );
};

export default TextArea;
