// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3vLu6YZ9SOA8rbc8JpGTdk {\n  position: relative;\n  display: inline-block;\n  width: 100%;\n}\n\n._3gLU3Z7uy_Ytn8K1KyLBQ0 {\n  padding: 5px 10px;\n  z-index: 1;\n  height: 100%;\n  width: 100%;\n}\n\n._2-2R98zMnROzwcDktHAywR {\n  position: absolute;\n  z-index: 2;\n  width: inherit;\n  height: 20rem;\n  overflow: auto;\n  border-radius: 5px;\n  border: 1px solid #ADADAD;\n  background: #FFF;\n  box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.15);\n  padding: 1rem 0rem 1rem 1rem;\n}\n\n._2-2R98zMnROzwcDktHAywR label {\n  font-size: 1.5rem;\n  display: block;\n  color: black;\n}\n\n._2-2R98zMnROzwcDktHAywR label input[type=\"checkbox\"] {\n  margin-right: 5px;\n}", ""]);
// Exports
exports.locals = {
	"dropdown": "_3vLu6YZ9SOA8rbc8JpGTdk",
	"dropdownToggle": "_3gLU3Z7uy_Ytn8K1KyLBQ0",
	"dropdownContent": "_2-2R98zMnROzwcDktHAywR"
};
module.exports = exports;
