import { useMemo } from 'react';
import { CONTENT_VERSION_FLAGS } from '../areas/main/constants';
import { combineAssetListsAndVersionedAssets } from '../areas/main/state/assets/utils';
import { checkContentVersion } from '../utils/contentVersion';
import useSelector from './useSelector';

export function useAllAssetVersionsByType(): ReturnType<
  typeof combineAssetListsAndVersionedAssets
> {
  const { assetListsByType, versionedAssetList } = useSelector(
    (state) => state.main.assets,
  );

  return useMemo(
    () =>
      combineAssetListsAndVersionedAssets(assetListsByType, versionedAssetList),
    [assetListsByType, versionedAssetList],
  );
}

export function useAnimationBundleSignedUrl() {
  const { animationBundle } = useSelector((state) => state.main.experience);
  const contentVersion = useSelector(
    (state) => state.main.experience.content_version,
  );
  const { signedUrls } = useSelector((state) => state.main.preview);
  const allAssetsByType = useAllAssetVersionsByType();

  const animationAssets = checkContentVersion(
    contentVersion,
    CONTENT_VERSION_FLAGS.genericRig,
  )
    ? allAssetsByType['generic-animation-assetbundle']
    : allAssetsByType['animation-assetbundle'];
  const previewAsset = animationAssets?.find(
    (a) =>
      a.versionId === animationBundle?.id && a.variant.os_platform === 'webgl',
  );

  return signedUrls?.find(
    (urlData) => urlData.versionVariantId === previewAsset?.versionVariantId,
  );
}
