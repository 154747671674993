import React from 'react';
import useSelector from '../../../hooks/useSelector';
import PreviewErrorMessage from './previewErrorMessage';

const PreviewMessage: React.FC = () => {
  const mostRecentPreviewErrorMessage = useSelector(
    (state) => Object.values(state.main.preview.errors).slice(-1)[0]
  );

  if (mostRecentPreviewErrorMessage) {
    return <PreviewErrorMessage message={mostRecentPreviewErrorMessage} />;
  }

  return null;
};

export default React.memo(PreviewMessage);
