// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".-BVufx4WFQj94oPmFRXnv {\n  border: 0;\n  clip: rect(0 0 0 0);\n  height: 1px;\n  margin: -1px;\n  overflow: hidden;\n  padding: 0;\n  position: absolute;\n  white-space: nowrap;\n  width: 1px;\n}\n\n.-BVufx4WFQj94oPmFRXnv:disabled {\n  cursor: not-allowed;\n}\n\n.-BVufx4WFQj94oPmFRXnv:hover+svg > * {\n  stroke: var(--v-ui-color-actionable-hover);\n}\n\n.-BVufx4WFQj94oPmFRXnv._2a0BE_pUfXVz-e-zKALCQD:hover+svg > * {\n  fill: var(--v-ui-color-actionable-hover);\n}\n\n.-BVufx4WFQj94oPmFRXnv:disabled+svg > * {\n  stroke: var(--v-ui-color-field-disabled);\n}\n\n.-BVufx4WFQj94oPmFRXnv._2a0BE_pUfXVz-e-zKALCQD:disabled+svg > * {\n  stroke: var(--v-ui-color-actionable-disabled);\n  fill: var(--v-ui-color-actionable-disabled);\n}\n\n.-BVufx4WFQj94oPmFRXnv:focus+svg > * {\n  stroke: var(--v-ui-color-checkbox-focus);\n}\n\n.-BVufx4WFQj94oPmFRXnv._2a0BE_pUfXVz-e-zKALCQD:focus:not(:focus-visible)+svg > * {\n  stroke: var(--v-ui-color-actionable);\n}\n\n.-BVufx4WFQj94oPmFRXnv._2a0BE_pUfXVz-e-zKALCQD:hover:focus:not(:focus-visible)+svg > * {\n  stroke: var(--v-ui-color-actionable-hover);\n}\n\n.-BVufx4WFQj94oPmFRXnv:not(._2a0BE_pUfXVz-e-zKALCQD):focus:not(:focus-visible)+svg > * {\n  stroke: var(--v-ui-color-checkbox);\n}\n", ""]);
// Exports
exports.locals = {
	"checkbox": "-BVufx4WFQj94oPmFRXnv",
	"checked": "_2a0BE_pUfXVz-e-zKALCQD"
};
module.exports = exports;
