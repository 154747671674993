// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._109LWmMyw21tJZiv5xNZcI {\n    background: var(--v-main-color-panel-bar);\n    display: flex;\n    height: 36px;\n    justify-content: space-between;\n}\n\n._1E3VunU18zG3w6V1rwThuC {\n    display: flex;\n    align-items: center;\n}\n\n._33481UyX3HCJZlLKwlO75l {\n    cursor: pointer;\n    transition: opacity 0.2s ease-out;\n}\n\n._1w4HE8ytMmKbSvDrwQiINE {\n    margin-left: 16px;\n    margin-right: 16px;\n}\n\n._3hMPKC-wGOoO2L781Bzv2I {\n    opacity: 0;\n    pointer-events: none;\n}\n\n.q0l2PXSN_2LezQxXCXdXG {\n    margin-right: 8px;\n}\n\n._15yuGEpNZxkel3UPCqeyDd {\n    margin-right: 12px;\n}\n\nbutton:disabled{\n    color: var(--v-ui-color-field-disabled);\n}\n\n.G4c86vbLmg5E6lmhJ9h4i {\n    padding-bottom: 10px;\n}\n", ""]);
// Exports
exports.locals = {
	"controlBar": "_109LWmMyw21tJZiv5xNZcI",
	"controlBar_section": "_1E3VunU18zG3w6V1rwThuC",
	"controlBar_button": "_33481UyX3HCJZlLKwlO75l",
	"playPauseRestart_button": "_1w4HE8ytMmKbSvDrwQiINE",
	"controlBar_button__hidden": "_3hMPKC-wGOoO2L781Bzv2I",
	"vrHeadset_spacing": "q0l2PXSN_2LezQxXCXdXG",
	"vrInfo_spacing": "_15yuGEpNZxkel3UPCqeyDd",
	"instruction": "G4c86vbLmg5E6lmhJ9h4i"
};
module.exports = exports;
