import axios from 'axios';
import { useCallback, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { sleep } from '../../utils/async';
import { tryParseInt } from '../../utils/number';

const useInitAxios = () => {
  const { getAccessTokenSilently, isAuthenticated, loginWithRedirect } =
    useAuth0();
  const [isAxiosReady, setIsAxiosReady] = useState(false);

  const getTokenWithRetry = useCallback(
    async (retryAttempt = 0): Promise<string | null> => {
      try {
        const token = await getAccessTokenSilently();
        return token;
      } catch {
        const retryLimit = tryParseInt(
          process.env.REACT_APP_EXPIRED_TOKEN_RETRY_LIMIT,
          5
        );
        const retryInterval = tryParseInt(
          process.env.REACT_APP_EXPIRED_TOKEN_RETRY_INTERVAL,
          1000
        );
        if (retryAttempt >= retryLimit) {
          await loginWithRedirect();
          return null;
        }
        await sleep(retryInterval);
        const token = await getTokenWithRetry(retryAttempt + 1);
        return token;
      }
    },
    [getAccessTokenSilently, loginWithRedirect]
  );

  useEffect(() => {
    if (!isAuthenticated) return;
    axios.interceptors.request.use(
      async (config) => {
        config.headers.authorization = `Bearer ${await getTokenWithRetry()}`;
        return config;
      },
      (error) => Promise.reject(error)
    );

    if (process.env.REACT_APP_DEFAULT_REQUEST_TIMEOUT)
      axios.defaults.timeout = parseInt(
        process.env.REACT_APP_DEFAULT_REQUEST_TIMEOUT,
        10
      );

    setIsAxiosReady(true);
  }, [getTokenWithRetry, isAuthenticated]);

  return isAxiosReady;
};

export default useInitAxios;
