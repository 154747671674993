import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Connection, Edge, Elements, FlowElement } from 'react-flow-renderer';

import { deleteRecordByKey } from '../../../../utils/deleteRecord';
import { withAutolog, withAutosave } from '../../../../utils/middleware';
import { NodeData, StackItem } from '../../types/nodeData';

export const getInitialNodeState = () => {
  return {
    elements: [] as Elements<NodeData>,
    mostRecentlyTouchedSceneIds: [] as string[],
    mostRecentlyTouchedOperationIds: [] as string[],
    elementsCopied: [] as Elements<NodeData>,
    elementsSelected: [] as Elements<NodeData>,
    stackItemSelected: undefined as StackItem | undefined,
    stackSelectedId: '',
    edgesPendingAdd: [] as (Connection | Edge)[],
    errors: {} as Record<string, string>,
    stacking: { isStacking: false, stackingId: '' },
    dragType: { isDragging: false, isOperation: false, id: '' },
    selectingPlaybackPhaseNode: { isSelecting: false, playbackPhaseId: '' },
    selectingPlaybackNode: { isSelecting: false, playbackId: '' },
  };
};

export const initialState = getInitialNodeState();

export type State = typeof initialState;
type Update = Partial<State>;

const sliceReducer = createSlice({
  name: 'main/nodes',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Update>) => ({
      ...state,
      ...action.payload,
    }),
    updateElement: (
      state,
      action: PayloadAction<{
        id: string;
        element: Partial<FlowElement<NodeData>>;
      }>,
    ) => {
      const elmentIndex = state.elements.findIndex(
        (e) => e.id === action.payload.id,
      );

      if (elmentIndex === -1) return;

      const oldElement = state.elements[elmentIndex];
      const updatedElements = [...state.elements];

      updatedElements[elmentIndex] = {
        ...oldElement,
        ...action.payload.element,
      };

      return {
        ...state,
        elements: updatedElements,
      };
    },
    // Used for calculating z-index to apply to edges for scenes that overlap via dragging
    updateMostRecentlyTouchedSceneIds: (
      state,
      action: PayloadAction<{ id: string }>,
    ) => {
      return {
        ...state,
        // Add the most recently touched node scene ID to the front of the set
        mostRecentlyTouchedSceneIds: [
          action.payload.id,
          ...state.mostRecentlyTouchedSceneIds.filter(
            (i) => i !== action.payload.id,
          ),
        ],
      };
    },
    updateMostRecentlyTouchedOperationIds: (
      state,
      action: PayloadAction<{ id: string }>,
    ) => {
      return {
        ...state,
        // Add the most recently touched node scene ID to the front of the set
        mostRecentlyTouchedOperationIds: [
          action.payload.id,
          ...state.mostRecentlyTouchedOperationIds.filter(
            (i) => i !== action.payload.id,
          ),
        ],
      };
    },
    updateErrors: (
      state,
      action: PayloadAction<{
        field: string;
        error: unknown;
      }>,
    ) => {
      const { error, field } = action.payload;

      const errorMessage =
        error instanceof Error
          ? error.message
          : typeof error === 'string'
            ? error
            : JSON.stringify(error);

      return {
        ...state,
        errors: {
          ...state.errors,
          [field]: errorMessage,
        },
      };
    },
    clearErrorsByKey: (state, action: PayloadAction<string>) => ({
      ...state,
      errors: deleteRecordByKey(action.payload, state.errors),
    }),
  },
});

export default withAutolog(withAutosave(sliceReducer));
