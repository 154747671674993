import { call, put } from 'typed-redux-saga';
import { SagaType, createSliceSaga } from 'redux-toolkit-saga';

import sliceReducer from './sliceReducer';
import { makeCreatorGenAiApi } from '../../../../utils/api';
import { logSagaError } from '../../../../utils/saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { TextToSpeechInput, Voice } from '../../types/models';

const api = makeCreatorGenAiApi('voices');

const sliceSaga = createSliceSaga({
  name: sliceReducer.name,
  caseSagas: {
    fetchVoices: {
      sagaType: SagaType.TakeLatest,
      *fn(action: PayloadAction) {
        try {
          const request = api.makeGetRequest<Voice[]>('');
          const response = yield* call(request);
          const voiceList = response.data.map(({ id, name }) => ({
            display: name,
            value: id,
          }));
          yield* put(sliceReducer.actions.update({ voiceList }));
        } catch (e) {
          yield* put(
            sliceReducer.actions.updateErrors({
              field: 'voices',
              error: e,
            }),
          );
          logSagaError(action, e);
        }
      },
    },
    textToSpeech: {
      sagaType: SagaType.TakeLatest,
      *fn(action: PayloadAction<TextToSpeechInput>) {
        try {
          yield* put(
            sliceReducer.actions.update({
              textToSpeechInProgress: true,
            }),
          );
          const request = api.makePostRequest<Blob>(
            `${action.payload.voiceId}/tts`,
            {
              text: action.payload.text,
              options: {
                similarity: action.payload.similarity,
                stability: action.payload.stability,
              },
            },
            {
              responseType: 'blob',
            },
          );
          const response = yield* call(request);
          const url = URL.createObjectURL(response.data);
          yield* put(
            sliceReducer.actions.update({
              textToSpeechInProgress: false,
              textToSpeechLastInput: action.payload,
              textToSpeechPreviewUrl: url,
            }),
          );
        } catch (e) {
          yield* put(
            sliceReducer.actions.updateErrors({
              field: 'voices',
              error: e,
            }),
          );
          logSagaError(action, e);
        }
      },
    },
  },
});

export default sliceSaga;
