import { useEffect } from 'react';
import {
  PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT,
  UnityPreviewAPIMethods,
} from '../../areas/main/state/preview/unityMessages/utils';
import { unityContext } from '../../areas/main/views/preview';
import useSelector from '../useSelector';

const useCurrentlySelectedSceneToMessagePlayer = () => {
  const { elementsSelected, stackSelectedId } = useSelector(
    (state) => state.main.nodes
  );
  const {
    areAllAssetBundleSignedURLsFetched,
    areAnimationsLoaded,
    isPlayerPreviewReady,
  } = useSelector((state) => state.main.preview);
  const isCurrentSceneSelected =
    elementsSelected.length > 0 || stackSelectedId !== '';
  const isCurrentSceneSelectedData = JSON.stringify(isCurrentSceneSelected);

  const shouldSendToggleEmptyNodeView =
    areAllAssetBundleSignedURLsFetched &&
    isPlayerPreviewReady &&
    (areAnimationsLoaded || !isCurrentSceneSelected) &&
    !!unityContext.unityInstance;
  useEffect(() => {
    // need to check if a Unity instance is present again
    if (shouldSendToggleEmptyNodeView && !!unityContext.unityInstance) {
      unityContext.send(
        PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT,
        UnityPreviewAPIMethods.ToggleEmptyNodeView,
        isCurrentSceneSelectedData
      );
    }
  }, [isCurrentSceneSelectedData, shouldSendToggleEmptyNodeView]);
};

export default useCurrentlySelectedSceneToMessagePlayer;
