import { useEffect } from 'react';
import sliceReducer from '../../areas/main/state/preview/sliceReducer';
import {
  PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT,
  UnityPreviewAPIMethods,
} from '../../areas/main/state/preview/unityMessages/utils';
import { unityContext } from '../../areas/main/views/preview';
import useDispatch from '../useDispatch';
import useSelector from '../useSelector';

const useSceneCountToUnloadScene = () => {
  const { attemptedToLoadAScene } = useSelector((state) => state.main.preview);
  const scenes = useSelector((state) => state.main.sceneProperties.scenes);
  const areNoScenesPresent = Object.values(scenes).length === 0;
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      attemptedToLoadAScene &&
      areNoScenesPresent &&
      unityContext.unityInstance
    ) {
      unityContext.send(
        PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT,
        UnityPreviewAPIMethods.UnloadScene
      );
      dispatch(sliceReducer.actions.update({ attemptedToLoadAScene: false }));
    }
  }, [attemptedToLoadAScene, areNoScenesPresent, dispatch]);
};

export default useSceneCountToUnloadScene;
