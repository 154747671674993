import {
  AnimationOperationData,
  CharacterLineOperationData,
  LearnerResponseOperationData,
  MultipleChoiceQuestionData,
  NotificationOperation,
  OperationContainerData,
  PlaybackNodeOperationData,
  PlaybackPhaseOperationData,
} from '@strivr/player-models';
import {
  OperationType,
  OverlayType,
  overlayTypeToFullyQualifiedName,
} from '../areas/main/enums/models';
import { OperationContainersState } from '../areas/main/state/operationContainers/sliceReducer';
import { OperationJSON } from '../areas/main/types/json';
import {
  AnimationOperation,
  CharacterLineOperation,
  LearnerResponseOperation,
  McqOperation,
  Operation,
  OperationContainer,
  OverlayOperation,
  PlaybackOperation,
  PlaybackPhaseOperation,
  VideoOperation,
} from '../areas/main/types/operations';
import { allFrameworks, CONTENT_VERSION_FLAGS } from '../areas/main/constants';
import { getFileExtension } from './getFileExtension';
import { emptyGuid } from './id';
import {
  getSignedUrlFromData,
  SignedUrlAssetData,
} from '../areas/main/state/preview/utils';
import { checkContentVersion } from './contentVersion';
import { VideoOperationData } from '@strivr/player-models/lib/types/VideoOperationData';
import { SceneState } from '../areas/main/state/sceneProperties/sliceReducer';

const IMAGE_PATH = 'Images\\';
const SOUND_PATH = 'Sounds\\';
const VIDEO_PATH = 'Videos\\';
const ASSET_BUNDLE_PATH = 'AssetBundleObjects\\';

const ASSET_BUNDLE_EXTENSION = '.assetbundle';

const shouldKeepOverlayPropertiesField = (
  overlayType: OverlayType,
  fieldType: string,
) => {
  switch (overlayType) {
    case OverlayType.TitleBodyImagePlacard:
      switch (fieldType) {
        case 'description':
          return true;
        case 'image':
          return true;
      }
      break;
    case OverlayType.TitleBodyPlacard:
      switch (fieldType) {
        case 'description':
          return true;
        case 'image':
          return false;
      }
      break;
    case OverlayType.QuestionTextPlacard:
      switch (fieldType) {
        case 'description':
          return false;
        case 'image':
          return false;
      }
      break;
    case OverlayType.TitleImagePlacard:
      switch (fieldType) {
        case 'description':
          return false;
        case 'image':
          return true;
      }
      break;
  }
  return false;
};

// Adding Operations Step
const mapOperationToJSON = (
  contentVersion: number,
  operation: Operation,
  operationState: OperationContainersState,
  sceneId: string,
  sceneState: SceneState,
  signedUrlAssetData?: SignedUrlAssetData | null,
): OperationJSON => {
  const operationJSON = {
    rid: operation.id,
    name: operation.name,
    type: operation.type,
    type_name: OperationType[operation.type],
    fully_qualified_type_name: '',
  };
  const scene = sceneState.scenes[sceneId];

  switch (operation.type) {
    case OperationType.NotificationDialog2: {
      const overlay = operation as OverlayOperation;
      const fullyQualifiedTypeName = overlayTypeToFullyQualifiedName(
        overlay.overlayType,
      );
      const overlayJSON: NotificationOperation.NotificationOperation = {
        ...operationJSON,
        max_duration: overlay.maxDuration ?? 0, // 0 here actually means 'not timed' (additional context: a default of 1 will make an overlay go away too quickly)
        type: overlay.type,
        next_id: overlay.nextId ?? emptyGuid,
        does_next_node_exist: overlay.doesNextNodeExist,
        is_activity_finished: overlay.isActivityFinished,
        delay_start_time: overlay.delayStartTime ?? 0,
        theta: operation.position.x ?? 0,
        phi: operation.position.y ?? 0,
        rho: operation.position.z ?? 0,
        placard: {
          show_button: overlay.showButton,
          body: shouldKeepOverlayPropertiesField(
            overlay.overlayType,
            'description',
          )
            ? overlay.description
            : '',

          image:
            overlay.image &&
            shouldKeepOverlayPropertiesField(overlay.overlayType, 'image')
              ? {
                  asset_id: overlay.image.id,
                  name: overlay.image.name,
                  asset_type: 'Image',
                  ...(!signedUrlAssetData
                    ? {
                        relative_path: `${IMAGE_PATH}${
                          overlay.image.id
                        }.${getFileExtension(overlay.image.name)}`,
                      }
                    : {
                        url: getSignedUrlFromData(
                          overlay.image.id,
                          signedUrlAssetData,
                          'image',
                        ),
                      }),
                  fully_qualified_type_name: 'Strivr.Player.Model.Image',
                }
              : null,

          audio: overlay.audio
            ? {
                asset_id: overlay.audio.id,
                name: overlay.audio.name,
                asset_type: 'Sound',
                ...(!signedUrlAssetData
                  ? {
                      relative_path: `${SOUND_PATH}${
                        overlay.audio.id
                      }.${getFileExtension(overlay.audio.name)}`,
                    }
                  : {
                      url: getSignedUrlFromData(
                        overlay.audio.id,
                        signedUrlAssetData,
                        'sound',
                      ),
                    }),
                fully_qualified_type_name: 'Strivr.Player.Model.Sound',
              }
            : null,

          ...(fullyQualifiedTypeName ===
          'Strivr.Player.Model.QuestionTextPlacardData'
            ? {
                fully_qualified_type_name: fullyQualifiedTypeName,
                text: overlay.title,
              }
            : {
                fully_qualified_type_name: fullyQualifiedTypeName,
                title: overlay.title,
              }),
        },

        fully_qualified_type_name: 'Strivr.Player.Model.NotificationOperation',
      };

      return overlayJSON;
    }
    case OperationType.Animation: {
      const animationOperation = operation as AnimationOperation;

      const animationJSON: AnimationOperationData.AnimationOperationData = {
        ...operationJSON,
        type: animationOperation.type,
        next_id: animationOperation.nextId ?? emptyGuid,
        does_next_node_exist: animationOperation.doesNextNodeExist,
        is_activity_finished: animationOperation.isActivityFinished,
        character_id: animationOperation.characterIndex,
        delay_start_time: animationOperation.delayStartTime ?? 0,
        vh_animation: {
          clip_name: animationOperation.animation.clipName,
          start_time: animationOperation.animation.startTime,
          fully_qualified_type_name: 'Strivr.SoftSkills.Model.VHAnimation',
        },
        vh_animation_idle: {
          clip_name: checkContentVersion(
            contentVersion,
            CONTENT_VERSION_FLAGS.genericRig,
          )
            ? animationOperation.idle.clipName
            : '',
          start_time: checkContentVersion(
            contentVersion,
            CONTENT_VERSION_FLAGS.genericRig,
          )
            ? animationOperation.animation.startTime
            : 0,
          fully_qualified_type_name: 'Strivr.SoftSkills.Model.VHAnimation',
        },
        fully_qualified_type_name:
          'Strivr.Player.Model.Dialogue3.AnimationOperationData',
      };

      return animationJSON;
    }
    case OperationType.CharacterLine: {
      const characterLine = operation as CharacterLineOperation;

      const characterLineJSON: CharacterLineOperationData.CharacterLineOperationData =
        {
          ...operationJSON,
          type: characterLine.type,
          next_id: characterLine.nextId ?? emptyGuid,
          does_next_node_exist: characterLine.doesNextNodeExist,
          is_activity_finished: characterLine.isActivityFinished,
          character_id: characterLine.characterIndex,
          delay_start_time: characterLine.delayStartTime ?? 0,
          vh_speaks: {
            text_response: characterLine.vhSpeaks.textResponse,
            audio_response: characterLine.vhSpeaks.audioResponse,
            sound_asset: characterLine.vhSpeaks.soundAsset
              ? {
                  asset_id: characterLine.vhSpeaks.soundAsset.id,
                  name: characterLine.vhSpeaks.soundAsset.name,
                  asset_type: 'Sound',
                  ...(!signedUrlAssetData
                    ? {
                        relative_path: `${SOUND_PATH}${
                          characterLine.vhSpeaks.soundAsset.id
                        }.${getFileExtension(
                          characterLine.vhSpeaks.soundAsset.name,
                        )}`,
                      }
                    : {
                        url: getSignedUrlFromData(
                          characterLine.vhSpeaks.soundAsset.id,
                          signedUrlAssetData,
                          'sound',
                        ),
                      }),
                  fully_qualified_type_name: 'Strivr.Player.Model.Sound',
                }
              : null,

            lip_sync_asset: characterLine.vhSpeaks.lipSyncAsset
              ? {
                  asset_id: characterLine.vhSpeaks.lipSyncAsset.id,
                  name: characterLine.vhSpeaks.lipSyncAsset.name,
                  asset_type: 'AssetBundleObject',
                  ...(!signedUrlAssetData
                    ? {
                        relative_path: `${ASSET_BUNDLE_PATH}${characterLine.vhSpeaks.lipSyncAsset.id}${ASSET_BUNDLE_EXTENSION}`,
                      }
                    : {
                        url: getSignedUrlFromData(
                          characterLine.vhSpeaks.lipSyncAsset.id,
                          signedUrlAssetData,
                          'lipsync-assetbundle',
                        ),
                      }),
                  fully_qualified_type_name:
                    'Strivr.Player.Model.AssetBundleObject',
                }
              : null,

            jali_asset: characterLine.vhSpeaks.jaliAsset
              ? {
                  asset_id: characterLine.vhSpeaks.jaliAsset.id,
                  name: characterLine.vhSpeaks.jaliAsset.name,
                  asset_type: 'AssetBundleObject',
                  ...(!signedUrlAssetData
                    ? {
                        relative_path: `${ASSET_BUNDLE_PATH}${characterLine.vhSpeaks.jaliAsset.id}${ASSET_BUNDLE_EXTENSION}`,
                      }
                    : {
                        url: getSignedUrlFromData(
                          characterLine.vhSpeaks.jaliAsset.id,
                          signedUrlAssetData,
                          'jali-assetbundle',
                        ),
                      }),
                  fully_qualified_type_name:
                    'Strivr.Player.Model.AssetBundleObject',
                }
              : null,
          },
          vh_animation_idle: {
            clip_name: checkContentVersion(
              contentVersion,
              CONTENT_VERSION_FLAGS.genericRig,
            )
              ? characterLine.idle.clipName
              : '',
            start_time: checkContentVersion(
              contentVersion,
              CONTENT_VERSION_FLAGS.genericRig,
            )
              ? characterLine.idle.startTime
              : 0,
            fully_qualified_type_name: 'Strivr.SoftSkills.Model.VHAnimation',
          },
          name: characterLine.name,
          fully_qualified_type_name:
            'Strivr.Player.Model.Dialogue3.CharacterLineOperationData',
        };

      return characterLineJSON;
    }

    case OperationType.MultipleChoiceQuestion: {
      const mcq = operation as McqOperation;

      const mcqJSON: MultipleChoiceQuestionData.MultipleChoiceQuestionData = {
        ...operationJSON,
        answer_feedback_style: mcq.answerFeedbackStyle,
        answers: [...mcq.children]
          .sort((a, b) => a.answerOrder - b.answerOrder)
          .map((answer) => ({
            rid: answer.rid,
            next_id: answer.nextId ?? emptyGuid,
            does_next_node_exist: answer.doesNextNodeExist,
            is_activity_finished: answer.isActivityFinished,
            name: answer.useTextAsLabel ? answer.text : answer.label,
            text: answer.text,
            audio: answer.audio
              ? {
                  asset_type: 'Sound',
                  asset_id: answer.audio.id,
                  ...(!signedUrlAssetData
                    ? {
                        relative_path: `${SOUND_PATH}${
                          answer.rid
                        }.${getFileExtension(answer.audio.name)}`,
                      }
                    : {
                        url: getSignedUrlFromData(
                          answer.audio.id,
                          signedUrlAssetData,
                          'sound',
                        ),
                      }),
                  name: answer.audio.name,
                  fully_qualified_type_name: 'Strivr.Player.Model.Sound',
                }
              : null,
          })),
        show_background: mcq.showBackground,
        text: mcq.text,
        audio: mcq.audio
          ? {
              asset_type: 'Sound',
              asset_id: mcq.id,
              ...(!signedUrlAssetData
                ? {
                    relative_path: `${SOUND_PATH}${mcq.id}.${getFileExtension(
                      mcq.audio.name,
                    )}`,
                  }
                : {
                    url: getSignedUrlFromData(
                      mcq.id,
                      signedUrlAssetData,
                      'sound',
                    ),
                  }),
              name: mcq.name,
              fully_qualified_type_name: 'Strivr.Player.Model.Sound',
            }
          : null,
        randomize_answers: mcq.randomizeAnswers,
        correct_answer_rid: mcq.correctAnswerRid,
        should_play_answered_audio: mcq.shouldPlayAnsweredAudio,
        should_play_hover_audio: mcq.shouldPlayHoverAudio,
        is_redoable: mcq.isRedoable,
        points: mcq.points ?? 0,
        should_track_result: mcq.shouldTrackResult,
        is_scored: mcq.isScored,
        mcq_type: mcq.mcqType,
        delay_start_time: mcq.delayStartTime ?? 0,
        feedback_delay_duration: mcq.feedbackDelayDuration ?? undefined,
        type: OperationType.MultipleChoiceQuestion,
        theta: operation.position.x ?? 0,
        phi: operation.position.y ?? 0,
        rho: operation.position.z ?? 0,
        fully_qualified_type_name:
          'Strivr.Player.Model.MultipleChoiceQuestionData',
      };
      return mcqJSON;
    }
    case OperationType.PlaybackPhase: {
      const playbackPhase = operation as PlaybackPhaseOperation;
      const playbackPhaseJSON: PlaybackPhaseOperationData.PlaybackPhaseOperationData =
        {
          ...operationJSON,
          type: OperationType.PlaybackPhase,
          containers_to_playback: playbackPhase.containersToPlayback,
          flip_camera: playbackPhase.flipCamera,
          fully_qualified_type_name:
            'Strivr.Player.Model.Dialogue3.PlaybackPhaseOperationData',
        };
      return playbackPhaseJSON;
    }
    case OperationType.PlaybackNode: {
      const playback = operation as PlaybackOperation;
      const playbackJSON: PlaybackNodeOperationData.PlaybackNodeOperationData =
        {
          ...operationJSON,
          type: OperationType.PlaybackNode,
          fully_qualified_type_name:
            'Strivr.Player.Model.Dialogue3.PlaybackNodeOperationData',
          next_id: playback.nextId ?? emptyGuid,
          does_next_node_exist: playback.doesNextNodeExist,
          is_activity_finished: playback.isActivityFinished,
          delay_start_time: playback.delayStartTime ?? 0,
          playback_phases: playback.phasesToPlayback.reduce(
            (curr, containerId) => {
              // Get the operation from the containerId
              // Playback Phase nodes cannot be stacked so we should be good to grab just the first
              const operationId =
                operationState.containers[containerId].operationIds[0];
              const playbackPhase = operationState.operations[operationId];
              if (playbackPhase.type === OperationType.PlaybackPhase) {
                return [
                  ...curr,
                  {
                    rid: playbackPhase.id,
                    name: playbackPhase.name,
                    type: OperationType.PlaybackPhase,
                    type_name: OperationType[playbackPhase.type],
                    containers_to_playback: playbackPhase.containersToPlayback,
                    flip_camera: playbackPhase.flipCamera,
                    fully_qualified_type_name:
                      'Strivr.Player.Model.Dialogue3.PlaybackPhaseOperationData',
                  },
                ];
              }
              return curr;
            },
            [] as PlaybackPhaseOperationData.PlaybackPhaseOperationData[],
          ),
        };
      return playbackJSON;
    }
    case OperationType.LearnerResponse: {
      const response = operation as LearnerResponseOperation;
      const responseJSON: LearnerResponseOperationData.LearnerResponseOperationData =
        {
          ...operationJSON,
          type: response.type,
          is_activity_finished: response.isActivityFinished,
          delay_start_time: response.delayStartTime ?? 0,
          next_id: response.nextId ?? emptyGuid,
          does_next_node_exist: response.doesNextNodeExist,
          description: response.description,
          fully_qualified_type_name:
            'Strivr.Player.Model.Dialogue3.LearnerResponseOperationData',
          skill_frameworks: response.skillsFrameworks.reduce((curr, id) => {
            if (id === 0) {
              return curr;
            }
            return [
              ...curr,
              allFrameworks.find((framework) => framework.id === id)?.value ||
                '',
            ];
          }, [] as string[]),
        };
      return responseJSON;
    }
    case OperationType.Video: {
      const videoOperation = operation as VideoOperation;
      // Change this when we need to support multiple environments
      const videoTargetEnv = scene.environments[0];
      const videoOperationJSON: VideoOperationData = {
        ...operationJSON,
        type: videoOperation.type,
        is_activity_finished: videoOperation.isActivityFinished,
        delay_start_time: videoOperation.delayStartTime ?? 0,
        next_id: videoOperation.nextId ?? emptyGuid,
        does_next_node_exist: videoOperation.doesNextNodeExist,
        is_looping: videoOperation.looping,
        video_player_id: videoTargetEnv?.id ?? undefined,
        fully_qualified_type_name:
          'Strivr.Player.Model.Dialogue3.VideoOperationData',
        video: videoOperation.video
          ? {
              asset_type: 'Video',
              asset_id: videoOperation.video.id,
              name: videoOperation.video.name,
              ...(!signedUrlAssetData
                ? {
                    relative_path: `${VIDEO_PATH}${videoOperation.video.id}.mp4`,
                  }
                : {
                    url: getSignedUrlFromData(
                      videoOperation.video.id,
                      signedUrlAssetData,
                      'video',
                    ),
                  }),
            }
          : null,
      };
      return videoOperationJSON;
    }
  }
};

export const mapOperationContainerToJSON = (
  contentVersion: number,
  container: OperationContainer,
  operations: Record<string, Operation>,
  operationState: OperationContainersState,
  sceneId: string,
  sceneState: SceneState,
  signedURLAssetData?: SignedUrlAssetData | null,
): OperationContainerData.OperationContainerData => {
  return {
    id: container.id,
    inputs: container.inputIds,
    fully_qualified_type_name: 'Strivr.Player.Model.OperationContainerData',
    operations: container.operationIds.map((id: string) =>
      mapOperationToJSON(
        contentVersion,
        operations[id],
        operationState,
        sceneId,
        sceneState,
        signedURLAssetData,
      ),
    ),
  };
};
