import { useFlags } from 'launchdarkly-react-client-sdk';

const useClientToggle = <T extends boolean | string>(
  name: string,
  fallback: T
): T => {
  const ldFlags: Record<string, T | null | undefined> = useFlags();

  const flag = ldFlags?.[name];
  if (flag === undefined || flag === null) return fallback;
  return flag;
};

export default useClientToggle;
