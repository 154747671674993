// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2I16u08iMWdReXj4mAYWe6 {\n    color: var(--v-ui-color-link);\n    text-decoration: none;\n}\n\n._2I16u08iMWdReXj4mAYWe6:hover {\n    text-decoration: underline;\n}\n\n._2I16u08iMWdReXj4mAYWe6:visited {\n    color: var(--v-ui-color-link-visited);\n}\n", ""]);
// Exports
exports.locals = {
	"link": "_2I16u08iMWdReXj4mAYWe6"
};
module.exports = exports;
