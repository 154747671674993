import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import Unity, { UnityContext } from 'react-unity-webgl';
import dataUrl from '../../../../res/assets/unity/PreviewWindow.data.unityweb';
import frameworkUrl from '../../../../res/assets/unity/PreviewWindow.framework.js.unityweb';
import loaderUrl from '../../../../res/assets/unity/PreviewWindow.loader.js.unityasset';
import codeUrl from '../../../../res/assets/unity/PreviewWindow.wasm.unityweb';
import useAnimationsLoadedMessageFromPlayer from '../../../hooks/unityMessages/useAnimationsLoadedMessageFromPlayer';
import useCurrentlySelectedContainerToMessagePlayer from '../../../hooks/unityMessages/useCurrentlySelectedContainerToMessagePlayer';
import useCurrentlySelectedSceneToLoadSceneInPlayer from '../../../hooks/unityMessages/useCurrentlySelectedSceneToLoadSceneInPlayer';
import useCurrentlySelectedSceneToMessagePlayer from '../../../hooks/unityMessages/useCurrentlySelectedSceneToMessagePlayer';
import useCurrentStateToLoadAnimationBundleInPlayer from '../../../hooks/unityMessages/useCurrentStateToLoadAnimationBundleInPlayer';
import useDeletedContainerMessageFromPlayer from '../../../hooks/unityMessages/useDeletedContainerMessageFromPlayer';
import useDeletePresentPreviewContainerToMessagePlayer from '../../../hooks/unityMessages/useDeletePresentPreviewContainerToMessagePlayer';
import useErrorMessageFromPlayer from '../../../hooks/unityMessages/useErrorMessageFromPlayer';
import useLoadedContainerMessageFromPlayer from '../../../hooks/unityMessages/useLoadedContainerMessageFromPlayer';
import usePlayerLoadedMessageFromPlayer from '../../../hooks/unityMessages/usePlayerLoadedMessageFromPlayer';
import useQuitPreviewWindowToMessagePlayer from '../../../hooks/unityMessages/useQuitPreviewWindowToMessagePlayer';
import useSceneCountToUnloadScene from '../../../hooks/unityMessages/useSceneCountToUnloadScene';
import useSceneLoadedMessageFromPlayer from '../../../hooks/unityMessages/useSceneLoadedMessageFromPlayer';
import useSelector from '../../../hooks/useSelector';
import PreviewControls from '../components/previewControls';
import PreviewMessage from '../components/previewMessage';
import styles from './preview.module.css';
import useExperienceFinishedMessageFromPlayer from '../../../hooks/unityMessages/userExperienceFinishedMessageFromPlayer';
import Close from '../../../../res/assets/images/Close.svg';
import CloseHover from '../../../../res/assets/images/CloseHover.svg';
import useDispatch from '../../../hooks/useDispatch';
import previewSaga from '../state/preview/sliceSaga';
import useMicrophoneFromPlayer from '../../../hooks/unityMessages/useMicrophoneFromPlayer';
import { Button } from '@strivr/ui';
import CharacterIconSvg from '../../../../res/assets/images/gizmos/character.svg';
import MoveIconSvg from '../../../../res/assets/images/gizmos/move.svg';
import RotateIconSvg from '../../../../res/assets/images/gizmos/rotate.svg';
import ScaleIconSvg from '../../../../res/assets/images/gizmos/scale.svg';
import {
  PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT,
  UnityPreviewAPIMethods,
} from '../state/preview/unityMessages/utils';
import { combineAssetListsAndVersionedAssets } from '../state/assets/utils';
import useCurrentScene from '../../../hooks/properties/useCurrentScene';
import useClientToggle from '../../../hooks/useClientToggle';

export const unityContext = new UnityContext({
  loaderUrl,
  dataUrl,
  frameworkUrl,
  codeUrl,
  streamingAssetsUrl: 'StreamingAssets',
  webglContextAttributes: {},
});

interface PreviewProps {
  pageRef: React.RefObject<HTMLElement>;
}

type GizmoOp = 'move' | 'rotate' | 'scale';

const Preview: React.FC<PreviewProps> = ({ pageRef }) => {
  //#region Messages to Player
  useCurrentlySelectedSceneToMessagePlayer();
  useCurrentlySelectedSceneToLoadSceneInPlayer();
  useCurrentlySelectedContainerToMessagePlayer();
  useCurrentStateToLoadAnimationBundleInPlayer();
  useDeletePresentPreviewContainerToMessagePlayer();
  useSceneCountToUnloadScene();
  useQuitPreviewWindowToMessagePlayer();
  //#endregion

  //#region Messages from Player
  usePlayerLoadedMessageFromPlayer();
  useAnimationsLoadedMessageFromPlayer();
  useDeletedContainerMessageFromPlayer();
  useLoadedContainerMessageFromPlayer();
  useSceneLoadedMessageFromPlayer();
  useErrorMessageFromPlayer();
  useExperienceFinishedMessageFromPlayer();
  useMicrophoneFromPlayer();
  //#endregion

  const dispatch = useDispatch();

  const [isShowingCharacterGizmos, setIsShowingCharacterGizmos] =
    useState(false);
  const [gizmoOp, setGizmoOp] = useState('move' as GizmoOp);

  const { areAnimationsLoaded, fullPreviewExperienceJson, isSceneReady } =
    useSelector((state) => state.main.preview);

  const { assetListsByType, versionedAssetList } = useSelector(
    (state) => state.main.assets,
  );
  const allAssetsByType = combineAssetListsAndVersionedAssets(
    assetListsByType,
    versionedAssetList,
  );

  const gizmosStyle = useClientToggle<'off' | 'basic' | 'pretty'>(
    'gizmos',
    'off',
  );

  const currScene = useCurrentScene();
  const currCharacter = (allAssetsByType['character-assetbundle'] ?? []).find(
    (a) => a.versionId === currScene?.characterVersionId,
  );

  const handleCloseFullPreview = () => {
    dispatch(previewSaga.actions.endPreviewExperience());
  };

  // When shutting down the preview window, unload the scene and animations
  useEffect(() => {
    return () => {
      dispatch(previewSaga.actions.previewExperienceWindowClosed());
    };
  }, [dispatch]);

  const handleClickHideShowGizmos = () => {
    if (unityContext.unityInstance && currCharacter) {
      unityContext.send(
        PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT,
        UnityPreviewAPIMethods.SetGizmoTo,
        currCharacter.name,
      );
      unityContext.send(
        PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT,
        UnityPreviewAPIMethods.SetGizmosVisible,
        isShowingCharacterGizmos ? 'hide' : gizmoOp,
      );
    }
    setIsShowingCharacterGizmos(!isShowingCharacterGizmos);
  };

  const handleChangeGizmoOp = (value: GizmoOp) => {
    if (unityContext.unityInstance && isShowingCharacterGizmos) {
      unityContext.send(
        PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT,
        UnityPreviewAPIMethods.SetGizmosVisible,
        value,
      );
    }
    setGizmoOp(value);
  };

  const isShowingFullPreview = fullPreviewExperienceJson !== null;

  return (
    <div
      className={classNames(styles.preview_container, {
        [styles.preview_container__full]: isShowingFullPreview,
      })}
    >
      {isShowingFullPreview && (
        <button
          className={styles.close_container}
          onClick={handleCloseFullPreview}
        >
          <div className={styles.close_button}>
            <Close height={36} width={36} />
          </div>
          <div className={styles.close_hover}>
            <CloseHover height={36} width={36} />
          </div>
        </button>
      )}
      {gizmosStyle === 'pretty' && isSceneReady && (
        <div className={styles.gizmocontrols}>
          <Button
            className={classNames(styles.gizmobutton, {
              [styles.gizmobutton__selected]: isShowingCharacterGizmos,
            })}
            flavor="alternate"
            onClick={handleClickHideShowGizmos}
            size="small"
          >
            <CharacterIconSvg height={30} width={30} />
          </Button>
          {isShowingCharacterGizmos && (
            <>
              <Button
                className={classNames(styles.gizmobutton, {
                  [styles.gizmobutton__selected]: gizmoOp === 'move',
                })}
                flavor="alternate"
                onClick={() => handleChangeGizmoOp('move')}
                size="small"
              >
                <MoveIconSvg height={15} width={15} />
              </Button>
              <Button
                className={classNames(styles.gizmobutton, {
                  [styles.gizmobutton__selected]: gizmoOp === 'rotate',
                })}
                flavor="alternate"
                onClick={() => handleChangeGizmoOp('rotate')}
                size="small"
              >
                <RotateIconSvg height={15} width={15} />
              </Button>
              <Button
                className={classNames(styles.gizmobutton, {
                  [styles.gizmobutton__selected]: gizmoOp === 'scale',
                })}
                flavor="alternate"
                onClick={() => handleChangeGizmoOp('scale')}
                size="small"
              >
                <ScaleIconSvg height={15} width={15} />
              </Button>
            </>
          )}
        </div>
      )}
      <div className={styles.unity_context}>
        <Unity unityContext={unityContext} />
      </div>
      <PreviewMessage />
      {areAnimationsLoaded && <div aria-label="animationsReady" />}
      <PreviewControls pageRef={pageRef} />
    </div>
  );
};
export default Preview;
