import { createSliceSaga } from 'redux-toolkit-saga';

import sliceReducer from './sliceReducer';

const sliceSaga = createSliceSaga({
  name: sliceReducer.name,
  caseSagas: {},
});

export default sliceSaga;
