import { useEffect } from 'react';

import { logToServer } from '../../utils/logging';

const WARN_ERRORS = [
  'ResizeObserver loop completed with undelivered notifications.',
  'ResizeObserver loop limit exceeded',
];

const useInitErrorLog = () => {
  useEffect(() => {
    const handleError = (e: ErrorEvent) => {
      const message = e.error?.message ?? e.message;
      logToServer(
        WARN_ERRORS.includes(e.message) ? 'warn' : 'error',
        message ?? 'Component crashed',
        {
          error: {
            message,
            stack: e.error?.stack,
          },
        }
      );
    };
    window.addEventListener('error', handleError);

    return () => {
      window.removeEventListener('error', handleError);
    };
  }, []);
};

export default useInitErrorLog;
