import React, { useLayoutEffect, useState } from 'react';

import { classNames } from '../utils/helpers';

import styles from './shield.module.css';

export interface ShieldProps {
  className?: string;
  coverRef?: React.RefObject<HTMLElement>;
  onClick?: () => void;
  onEsc?: () => void;
  overlayColor?: string;
  show: boolean;
}

const Shield = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<ShieldProps>
>(
  (
    { children, className, coverRef, onClick, onEsc, show, overlayColor },
    ref
  ) => {
    const coveredRoot = coverRef?.current;

    const [hasShown, setHasShown] = useState(false);

    useLayoutEffect(() => {
      let prevOverflow: string;

      if (coveredRoot) {
        prevOverflow = window.getComputedStyle(coveredRoot).overflow;
        coveredRoot.style.overflow = 'hidden';
        coveredRoot.classList.add(styles.coveredRoot);
      }

      return () => {
        if (coveredRoot) {
          coveredRoot.classList.remove(
            styles.coveredRoot,
            styles.coveredRoot__showing
          );
          coveredRoot.style.overflow = prevOverflow;
        }
      };
    }, [coveredRoot]);

    useLayoutEffect(() => {
      if (show) {
        coveredRoot?.classList.add(styles.coveredRoot__showing);
        setHasShown(true);
      }
      //Add hasShown here to prevent side effects from other non-shown modals when the first time the modal render
      else if (hasShown) {
        coveredRoot?.classList.remove(styles.coveredRoot__showing);
      }
    }, [coveredRoot, show, hasShown]);

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
      if (onClick && e.target === e.currentTarget) onClick();
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (onEsc && (e.key === 'Escape' || e.key === 'Esc')) onEsc();
    };

    return (
      <div
        aria-label="Close"
        className={classNames(styles.container, className, {
          [styles.container__showing]: show,
        })}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        ref={ref}
        role="button"
        tabIndex={-1}
        style={overlayColor ? { backgroundColor: overlayColor } : {}}
      >
        {children}
      </div>
    );
  }
);

Shield.displayName = 'Shield';

export default Shield;
