import { AssetTypes, AssetBundleTypes } from './types/models';

export const ASSETBUNDLE_TYPES: AssetBundleTypes[] = [
  'environment-assetbundle',
  'character-assetbundle',
  'animation-assetbundle',
  'lipsync-assetbundle',
  'jali-assetbundle',
  'avatar-assetbundle',
  'generic-animation-assetbundle',
  'generic-character-assetbundle',
];
export const ASSET_TYPES: AssetTypes[] = [
  'experience',
  'image',
  'sound',
  'video',
];
