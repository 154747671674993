import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { withAutosave, withUndo } from '../../../../utils/middleware';
import { Operation, OperationContainer } from '../../types/operations';

// Enforce typing
export interface OperationContainersState {
  containers: Record<string, OperationContainer>;
  operations: Record<string, Operation>;
  copiedContainers: Record<string, OperationContainer>;
  copiedOperations: Record<string, Operation>;
  errors: Record<string, string>;
}
export const getInitialOperationsState = () => {
  return {
    // Flattened to be easier to do lookup at
    // runtime from selecting nodes in the UI.
    containers: {} as Record<string, OperationContainer>,
    operations: {} as Record<string, Operation>,
    copiedContainers: {} as Record<string, OperationContainer>,
    copiedOperations: {} as Record<string, Operation>,
    errors: {} as Record<string, string>,
  };
};

export const initialState: OperationContainersState =
  getInitialOperationsState();

type Update = Partial<typeof initialState>;

const sliceReducer = createSlice({
  name: 'main/operationContainers',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Update>) => ({
      ...state,
      ...action.payload,
    }),
    updateErrors: (
      state,
      action: PayloadAction<{
        field: string;
        error: unknown;
      }>,
    ) => {
      const { error, field } = action.payload;

      const errorMessage =
        error instanceof Error
          ? error.message
          : typeof error === 'string'
            ? error
            : JSON.stringify(error);

      return {
        ...state,
        errors: {
          ...state.errors,
          [field]: errorMessage,
        },
      };
    },
  },
});

export default withAutosave(withUndo(sliceReducer));
