import React, { useEffect, useRef, useState } from 'react';

import { classNames } from '../utils/helpers';

import styles from './slideDeck.module.css';

export interface SlideDeckProps {
  className?: string;
  currentIndex: number;
  /** Prevents unrendering prev/next after transition; Set only where handled manually, else may break keyboard nav a11y */
  renderInactive?: boolean;
  transitionDuration?: number;
}

const SlideDeck: React.FC<SlideDeckProps> = ({
  children,
  className,
  currentIndex,
  renderInactive,
  transitionDuration = 1,
}) => {
  const [currentActiveIndex, setCurrentActiveIndex] = useState(currentIndex);
  const [prevActiveIndex, setPrevActiveIndex] = useState(currentIndex);

  const transitionTimeoutRef = useRef<number>();

  const renderAll =
    renderInactive ||
    currentIndex !== currentActiveIndex ||
    prevActiveIndex !== currentActiveIndex;
  const slideStyle = {
    transitionDuration: `${transitionDuration}s`,
  };

  useEffect(() => {
    if (transitionTimeoutRef.current)
      window.clearTimeout(transitionTimeoutRef.current);

    transitionTimeoutRef.current = window.setTimeout(() => {
      setPrevActiveIndex(currentIndex);
    }, transitionDuration * 1000);
    setCurrentActiveIndex(currentIndex);

    return () => {
      window.clearTimeout(transitionTimeoutRef.current);
    };
  }, [currentIndex, transitionDuration]);

  return (
    <div className={classNames(styles.deck, className)}>
      {React.Children.map(children, (child, i) =>
        i === currentActiveIndex - 1 && renderAll ? (
          <div
            className={classNames(styles.slide, styles.prev)}
            style={slideStyle}
          >
            {child}
          </div>
        ) : i === currentActiveIndex ? (
          <div
            className={classNames(styles.slide, styles.cur)}
            style={slideStyle}
          >
            {child}
          </div>
        ) : i === currentActiveIndex + 1 && renderAll ? (
          <div
            className={classNames(styles.slide, styles.next)}
            style={slideStyle}
          >
            {child}
          </div>
        ) : null
      )}
    </div>
  );
};

export default SlideDeck;
