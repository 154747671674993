import {
  CharacterLineOperation,
  McqOperation,
  OverlayOperation,
  VideoOperation,
} from '../areas/main/types/operations';
import useCurrentOperation from './properties/useCurrentOperation';
import useCurrentStack from './properties/useCurrentStack';
import useSelector from './useSelector';

const usePreviewPlayableContainerId = () => {
  const { operations } = useSelector((state) => state.main.operationContainers);
  const currentStack = useCurrentStack();
  const currentOperation = useCurrentOperation();

  const isOperationPreviewable = (id: string) =>
    operations[id]?.endTime ||
    (operations[id] as McqOperation)?.delayStartTime ||
    (operations[id] as OverlayOperation)?.maxDuration ||
    (operations[id] as OverlayOperation)?.audio ||
    (operations[id] as CharacterLineOperation)?.vhSpeaks ||
    (operations[id] as VideoOperation)?.video;

  if (currentStack?.operationIds.some(isOperationPreviewable))
    return currentStack.id;
  if (currentOperation && isOperationPreviewable(currentOperation.id))
    return currentOperation.id;
  return null;
};

export default usePreviewPlayableContainerId;
