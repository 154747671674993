import { useEffect } from 'react';
import sliceReducer from '../../areas/main/state/preview/sliceReducer';
import { UnityPreviewEvents } from '../../areas/main/state/preview/unityMessages/utils';
import { unityContext } from '../../areas/main/views/preview';
import useDispatch from '../useDispatch';

const useSceneLoadedMessageFromPlayer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    unityContext.on(UnityPreviewEvents.OnSceneLoaded, (id: string) => {
      dispatch(
        sliceReducer.actions.update({
          lastLoadedSceneId: id,
          isSceneReady: true,
        })
      );
    });
  }, [dispatch]);
};

export default useSceneLoadedMessageFromPlayer;
