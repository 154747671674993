import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import LoadingIndicator from './components/loadingIndicator';
import PrivateRoute from './components/privateRoute';
import RedirectTo from './components/redirectTo';
import TenantProtectedRoute from './components/tenantProtectedRoute';
import { isWebAssemblySupported } from './utils/supportedFeatures';

const BuildExperience = React.lazy(
  () => import('./areas/main/views/buildExperience'),
);
const NotFound = React.lazy(() => import('./views/notFound'));
const StartExperience = React.lazy(
  () => import('./areas/intelligence/views/startExperience'),
);
const TestPage = React.lazy(() => import('./areas/test/views/testPage'));
const StartAMS = React.lazy(() => import('./areas/assets/views/startAMS'));

const Router: React.FC = () => {
  const isTestRouteEnabled = process.env.REACT_APP_ENV === 'local';

  return (
    <BrowserRouter>
      <Switch>
        <TenantProtectedRoute exact path="/">
          {isWebAssemblySupported ? (
            <React.Suspense fallback={<LoadingIndicator show />}>
              <BuildExperience />
            </React.Suspense>
          ) : (
            <RedirectTo path="/minimal" />
          )}
        </TenantProtectedRoute>
        <TenantProtectedRoute exact path="/minimal">
          <React.Suspense fallback={<LoadingIndicator show />}>
            <BuildExperience disablePreview />
          </React.Suspense>
        </TenantProtectedRoute>
        <TenantProtectedRoute exact path="/noautosave">
          <React.Suspense fallback={<LoadingIndicator show />}>
            <BuildExperience disableAutosave />
          </React.Suspense>
        </TenantProtectedRoute>
        <TenantProtectedRoute exact path="/noassetautoupdate">
          <React.Suspense fallback={<LoadingIndicator show />}>
            <BuildExperience disableAutoAssetUpdating />
          </React.Suspense>
        </TenantProtectedRoute>
        <PrivateRoute path="/start">
          <React.Suspense fallback={<LoadingIndicator show />}>
            <StartExperience />
          </React.Suspense>
        </PrivateRoute>
        <Route exact path="/assets">
          <RedirectTo path="/assets/assign" />
        </Route>
        <TenantProtectedRoute path="/assets/:subroute">
          <React.Suspense fallback={<LoadingIndicator show />}>
            <StartAMS />
          </React.Suspense>
        </TenantProtectedRoute>
        {isTestRouteEnabled && (
          <PrivateRoute path="/test">
            <React.Suspense fallback={<LoadingIndicator show />}>
              <TestPage />
            </React.Suspense>
          </PrivateRoute>
        )}
        <Route>
          <React.Suspense fallback={<LoadingIndicator show />}>
            <NotFound />
          </React.Suspense>
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
