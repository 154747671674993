import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { deleteRecordByKey } from '../../../../utils/deleteRecord';
import { generateGuid } from '../../../../utils/id';
import { DEFAULT_EXPERIENCE_NAME } from '../../constants';
import {
  ErrorCheckByTypes,
  ExperiencePropertiesData,
} from '../../components/errorCheckList';
import { Asset, AssetTestResult } from '../../types/models';

export const getInitialExperience = () => {
  return {
    id: generateGuid(),
    version: null as number | null,
    versionId: null as string | null,
    startingSceneId: null as string | null,
    startingOperationContainerId: null as string | null,
    content_version: undefined as number | undefined,
    name: DEFAULT_EXPERIENCE_NAME,
    errors: {} as Record<string, string>,
    // initialize dates here that'll be
    // updated by ones provided from the
    // backend
    dateCreated: new Date().toISOString(),
    lastModified: new Date().toISOString(),
    animationBundle: null as Asset | null,
    description: '' as string,
    durationInMinutes: 0,
    image: null as Asset | null,
    scenesAndOperationsErrorCheck: {} as ErrorCheckByTypes,
    expErrorCheckList: [] as ExperiencePropertiesData[],
    publishStatus: null as 'Check' | 'Warning' | 'Exclamation' | null,
    experienceTestInfo: undefined as
      | undefined
      | {
          versionId: string;
          status: AssetTestResult;
          resultData?: string;
          placeInQueue?: number;
        },
    isPublishing: false,
    isPublishSuccessful: false,
    isCloning: false,
    isCloneSuccessful: false,
    hasExperienceLock: false,
  };
};
export const initialState = getInitialExperience();
type Update = Partial<typeof initialState>;
export type State = typeof initialState;

export const sliceReducer = createSlice({
  name: 'main/experience',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Update>) => ({
      ...state,
      ...action.payload,
    }),
    updateErrors: (
      state,
      action: PayloadAction<{
        field: string;
        error: unknown;
      }>,
    ) => {
      const { error, field } = action.payload;

      const errorMessage =
        error instanceof Error
          ? error.message
          : typeof error === 'string'
            ? error
            : JSON.stringify(error);

      return {
        ...state,
        errors: {
          ...state.errors,
          [field]: errorMessage,
        },
      };
    },
    clearErrorsByKey: (state, action: PayloadAction<string>) => ({
      ...state,
      errors: deleteRecordByKey(action.payload, state.errors),
    }),
  },
});

export default sliceReducer;
