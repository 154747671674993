import { Edge, Elements } from 'react-flow-renderer';
import { SceneNode, TypedNonSceneNode } from '../areas/main/types/nodeData';

/** Nodes can be an operation (ie. overlay, animation) OR operation container (stack) */
export const filterNodes = <T>(elements: Elements<T>) =>
  elements.filter(
    (x) => (x as Edge).source === undefined && x.type !== 'scene'
  ) as TypedNonSceneNode[];

export const filterScenes = <T>(elements: Elements<T>) =>
  elements.filter(
    (x) => (x as Edge).source === undefined && x.type === 'scene'
  ) as SceneNode[];

export const filterEdges = <T>(elements: Elements<T>) =>
  elements.filter((x) => (x as Edge).source !== undefined) as Edge<T>[];

// Used to mostly to filter a list of selected elements
// to get a single selected scene.
export const getSingleScene = <T>(elements: Elements<T>) => {
  const scenes = filterScenes(elements);

  if (scenes.length !== 1) return null;

  return scenes.length !== 1 ? null : scenes[0];
};
