import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CAMTenant } from '../../../../types/api';
import { ExperienceVersionInfo } from '../../types/models';

export const initialState = {
  tenantList: null as Array<CAMTenant> | null,
  selectedTenant: null as CAMTenant | null,
  errors: null as Record<string, string> | null,
  experiences: null as Array<ExperienceVersionInfo> | null,
  revertToLegacy: false,
  isUnauthorized: false,
};

type Update = Partial<typeof initialState>;

const sliceReducer = createSlice({
  name: 'main/tenants',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Update>) => ({
      ...state,
      ...action.payload,
    }),
    updateErrors: (
      state,
      action: PayloadAction<{
        field: string;
        error: unknown;
      }>,
    ) => {
      const { error, field } = action.payload;

      const errorMessage =
        error instanceof Error
          ? error.message
          : typeof error === 'string'
            ? error
            : JSON.stringify(error);

      return {
        ...state,
        errors: {
          ...state.errors,
          [field]: errorMessage,
        },
      };
    },
  },
});

export default sliceReducer;
