import { OverlayTypeNames } from '../types/json';
import { NodeType } from '../types/nodeData';

// Needed at serialization by Player to determine the Operation type.
// There is already an established mapping between value to type, so
// we'll follow that here. Only adding the ones we currently need as
// we need them.
export enum OperationType {
  NoValueSelected = 0,
  OperationBase = 1,
  // NotificationDialog = 2,
  // Image = 3,
  // ResultsDialog = 4,
  // POI = 5,
  MultipleChoiceQuestion = 6,
  // HiddenObjectQuestion = 7,
  // Sound = 8,
  // MarkerQuestion = 9,
  NotificationDialog2 = 10,
  // TimedHunt = 11,
  // StandalonePoiContainer = 12,
  // Hotspot = 13,
  CharacterLine = 14,
  Animation = 15,
  LearnerResponse = 16,
  PlaybackPhase = 17,
  PlaybackNode = 18,
  Video = 19,
}

// If we want the human readable name from just an OperationType, we'll need this.
export const ReadableOperationTypes: Record<OperationType, string> = {
  0: 'No Value Selected',
  1: 'Operation Base',
  6: 'Multiple Choice Question',
  10: 'Overlay',
  14: 'Character Line',
  15: 'Animation',
  16: 'Learner Response',
  17: 'Playback Phase',
  18: 'Playback Node',
  19: 'Video',
};

export enum OperationOutputType {
  NoOutput,
  SingleOutput,
  MultipleOutput,
}

// Needed at serialization by Player to determine the Operation type.
// There is already an established mapping between value to type, so
// we'll follow that here. Only adding the ones we currently need as
// we need them.
// NOTE: These enums differ from the OverlayType enum in the C# classes
export enum OverlayType {
  TitleBodyImagePlacard = 0,
  TitleBodyPlacard = 1,
  QuestionTextPlacard = 2,
  TitleImagePlacard = 3,
}

export const overlayTypeToFullyQualifiedName = (
  overlayType: OverlayType,
): OverlayTypeNames => {
  switch (overlayType) {
    case OverlayType.TitleBodyImagePlacard:
      return 'Strivr.Player.Model.TitleBodyImagePlacardData';
    case OverlayType.TitleBodyPlacard:
      return 'Strivr.Player.Model.TitleBodyPlacardData';
    case OverlayType.QuestionTextPlacard:
      return 'Strivr.Player.Model.QuestionTextPlacardData';
    case OverlayType.TitleImagePlacard:
      return 'Strivr.Player.Model.TitleImagePlacardData';
  }
};

export const overlayTypeFullyQualifiedNameToType = (
  overlayTypeFullyQualifiedName: OverlayTypeNames,
): OverlayType => {
  switch (overlayTypeFullyQualifiedName) {
    case 'Strivr.Player.Model.TitleBodyImagePlacardData':
      return OverlayType.TitleBodyImagePlacard;
    case 'Strivr.Player.Model.TitleBodyPlacardData':
      return OverlayType.TitleBodyPlacard;
    case 'Strivr.Player.Model.QuestionTextPlacardData':
      return OverlayType.QuestionTextPlacard;
    case 'Strivr.Player.Model.TitleImagePlacardData':
      return OverlayType.TitleImagePlacard;
  }
};

export const operationTypeToDisplayName = (operationType: OperationType) => {
  switch (operationType) {
    case OperationType.Animation:
      return 'Animation';
    case OperationType.CharacterLine:
      return 'Character Line';
    case OperationType.NotificationDialog2:
      return 'Overlay';
    case OperationType.MultipleChoiceQuestion:
      return 'Multiple Choice Question';
    case OperationType.LearnerResponse:
      return 'Learner Response';
    case OperationType.PlaybackNode:
      return 'Playback';
    case OperationType.PlaybackPhase:
      return 'Playback Phase';
    case OperationType.Video:
      return 'Video';
    default:
      return 'Unknown Type';
  }
};

export const nodeTypeToDisplayName = (nodeType: NodeType) => {
  switch (nodeType) {
    case 'animation':
      return 'Animation';
    case 'character line':
      return 'Character Line';
    case 'overlay':
      return 'Overlay';
    case 'mcq':
      return 'Multiple Choice Question';
    case 'learner response':
      return 'Learner Response';
    case 'playback':
      return 'Playback';
    case 'playback phase':
      return 'Playback Phase';
    case 'scene':
      return 'Scene';
    case 'video':
      return 'Video';
    default:
      return 'Unknown Type';
  }
};

export enum FeedbackStyle {
  NotSet = 0,
  CompleteFeedback = 1,
  PartialFeedback = 2,
  NoFeedback = 3,
}

export enum McqQuestionType {
  Regular = 0,
  Branching = 1,
}
