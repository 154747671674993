import classNames from 'classnames';
import { ButtonUndressed } from '@strivr/ui';
import React, { useState } from 'react';
import EnterVRIcon from '../../../../res/assets/images/preview/enterVR.svg';
import VRDisabledIcon from '../../../../res/assets/images/preview/vrDisabled.svg';
import { unityContext } from '../views/preview';

import useSelector from '../../../hooks/useSelector';
import styles from './vrToggleButton.module.css';

const VRToggleButton: React.FC = () => {
  const [isInVRMode, setIsInVRMode] = useState(false);
  const { isWebVRSupportedOnBrowser } = useSelector(
    (state) => state.main.preview
  );

  const handleVR = () => {
    if (!unityContext.unityInstance) return;

    unityContext.unityInstance.Module.WebXR.OnEndXR = () =>
      setIsInVRMode(false);
    unityContext.unityInstance.Module.WebXR.toggleVR();
    setIsInVRMode(!isInVRMode);
  };

  const showEnterVRIcon = isWebVRSupportedOnBrowser && !isInVRMode;
  const showExitVR = isWebVRSupportedOnBrowser && isInVRMode;

  return (
    <>
      {showEnterVRIcon ? (
        <ButtonUndressed
          className={classNames(styles.controlBar_button, styles.margin_right)}
          onClick={handleVR}
        >
          <EnterVRIcon />
        </ButtonUndressed>
      ) : showExitVR ? (
        <ButtonUndressed
          className={classNames(styles.controlBar_button, styles.margin_right)}
          onClick={handleVR}
        >
          <div className={styles.controlBar_fontSize}>Exit VR</div>
        </ButtonUndressed>
      ) : (
        <ButtonUndressed
          className={classNames(styles.controlBar_button, styles.margin_right)}
          onClick={handleVR}
          disabled
        >
          <VRDisabledIcon />
        </ButtonUndressed>
      )}
    </>
  );
};

export default React.memo(VRToggleButton);
