// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3wYX9Xb3oYUqUUYTAip5eB {\n    border-radius: 7px;\n    border: 1px solid var(--v-ui-color-progress-bar);\n    height: 100%;\n    width: 100%;\n}\n  \n.slHc1wgm4y3TKJR9sO5Gc {\n    border-radius: 7px;\n    height: 100%;\n    transition: width 1s ease;\n    background-color: var(--v-ui-color-progress-bar);\n}\n\n.Kiwj88s1YFMDS-xjjjkK- {\n    border-left: none;\n}\n\n._2wdNQkqL66FW1c9EHHJqh4 {\n    display: flex;\n    align-items:center;\n    height: 100%;\n    width: 100%;\n}", ""]);
// Exports
exports.locals = {
	"progressBar": "_3wYX9Xb3oYUqUUYTAip5eB",
	"progressBar_filledPortion": "slHc1wgm4y3TKJR9sO5Gc",
	"progressBar__noLeftBorder": "Kiwj88s1YFMDS-xjjjkK-",
	"progressSection": "_2wdNQkqL66FW1c9EHHJqh4"
};
module.exports = exports;
