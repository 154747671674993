import React from 'react';
import FocusTrap from 'focus-trap-react';

import { classNames } from '../utils/helpers';
import Button, { ButtonProps } from './button';
import ButtonUndressed from './buttonUndressed';
import Portal from './portal';
import Shield from './shield';

import styles from './modal.module.css';
import Close from '../../res/assets/images/Close.svg';
import CloseHover from '../../res/assets/images/CloseHover.svg';

interface ModalProps {
  align?: 'center' | 'left';
  bodyClassName?: string;
  buttonSize?: ButtonProps['size'];
  className?: string;
  cancelText?: string;
  closeButton?: React.ReactNode;
  closeButtonHover?: React.ReactNode;
  confirmText?: string;
  contentClassName?: string;
  coverRef?: React.RefObject<HTMLElement>;
  modalType?: string;
  bottomLeftContent?: React.ReactElement;
  onCancel?: () => void;
  onClose?: () => void;
  onConfirm?: () => void;
  onSecondButton?: () => void;
  onThirdButton?: () => void;
  secondButtonText?: string;
  thirdButtonText?: string;
  cancelButtonFlavor?: ButtonProps['flavor'];
  confirmButtonFlavor?: ButtonProps['flavor'];
  secondButtonFlavor?: ButtonProps['flavor'];
  thirdButtonFlavor?: ButtonProps['flavor'];
  show: boolean;
  title?: string;
  cancelDisabled?: boolean;
  confirmDisabled?: boolean;
  secondButtonDisabled?: boolean;
  thirdButtonDisabled?: boolean;
  isAmsModal?: boolean;
  cancelFirst?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  align = 'left',
  bodyClassName,
  buttonSize = 'large',
  cancelText = 'Cancel',
  children,
  className,
  closeButton = <Close />,
  closeButtonHover = <CloseHover />,
  confirmText = 'Confirm',
  contentClassName,
  coverRef,
  bottomLeftContent,
  onCancel,
  onClose,
  onConfirm,
  onSecondButton,
  onThirdButton,
  secondButtonText,
  thirdButtonText,
  cancelButtonFlavor = 'alternate',
  confirmButtonFlavor = 'confirm',
  secondButtonFlavor = 'alternate',
  thirdButtonFlavor = 'alternate',
  show,
  title,
  cancelDisabled,
  confirmDisabled,
  secondButtonDisabled,
  thirdButtonDisabled,
  cancelFirst,
  isAmsModal,
}) => {
  const hasActions = onCancel || onConfirm || onSecondButton || onThirdButton;

  const hasFocusableElement =
    (onCancel && !cancelDisabled) ||
    (onConfirm && !confirmDisabled) ||
    (onSecondButton && !secondButtonDisabled) ||
    (onThirdButton && !thirdButtonDisabled);

  return (
    <Portal isHidden={!show}>
      <FocusTrap active={show}>
        <div>
          {/* Needed for FocusTrap - Don't delete */}
          <Shield
            coverRef={coverRef}
            onClick={onClose}
            onEsc={onClose}
            show={show}
          >
            <div
              aria-modal="true"
              className={classNames(
                styles.modal,
                {
                  [styles.modal__center]: align === 'center',
                },
                className,
              )}
              role="dialog"
            >
              {title && (
                <div className={styles.modal_heading}>
                  {align === 'center' && <div />}
                  <header>
                    <h2
                      className={styles.title}
                      tabIndex={!hasFocusableElement ? 1 : undefined}
                    >
                      {title}
                    </h2>
                  </header>
                  <nav>
                    <ButtonUndressed
                      className={styles.closeButton}
                      onClick={onClose}
                      tabIndex={show ? undefined : -1}
                      aria-label={'Close Modal'}
                      hidden={!onClose}
                    >
                      <span className={styles.closeButtonNonHover}>
                        {closeButton}
                      </span>
                      <span className={styles.closeButtonHover}>
                        {closeButtonHover ?? closeButton}
                      </span>
                    </ButtonUndressed>
                  </nav>
                </div>
              )}
              <div
                className={classNames(styles.modal_content, contentClassName)}
              >
                <main className={classNames(styles.modal_body, bodyClassName)}>
                  {children}
                </main>
                {hasActions && (
                  <>
                    {/* different layouts and styling of buttons depending on what app it is used in */}
                    {isAmsModal ? (
                      <div className={styles.controls_container}>
                        <nav className={styles.ams_controls}>
                          {cancelFirst
                            ? onCancel && (
                                <Button
                                  disabled={cancelDisabled}
                                  flavor={'alternate'}
                                  onClick={onCancel}
                                  size={buttonSize}
                                  tabIndex={show ? undefined : -1}
                                >
                                  {cancelText}
                                </Button>
                              )
                            : onConfirm && (
                                <Button
                                  disabled={confirmDisabled}
                                  flavor={'alternate'}
                                  onClick={onConfirm}
                                  size={buttonSize}
                                  tabIndex={show ? undefined : -1}
                                >
                                  {confirmText}
                                </Button>
                              )}
                          {onThirdButton && (
                            <Button
                              disabled={thirdButtonDisabled}
                              flavor={thirdButtonFlavor}
                              onClick={onThirdButton}
                              size={buttonSize}
                              tabIndex={show ? undefined : -1}
                            >
                              {thirdButtonText}
                            </Button>
                          )}
                          {onSecondButton && (
                            <Button
                              disabled={secondButtonDisabled}
                              flavor={secondButtonFlavor}
                              onClick={onSecondButton}
                              size={buttonSize}
                              tabIndex={show ? undefined : -1}
                            >
                              {secondButtonText}
                            </Button>
                          )}
                          {cancelFirst
                            ? onConfirm && (
                                <Button
                                  disabled={confirmDisabled}
                                  flavor={'confirm'}
                                  onClick={onConfirm}
                                  size={buttonSize}
                                  tabIndex={show ? undefined : -1}
                                >
                                  {confirmText}
                                </Button>
                              )
                            : onCancel && (
                                <Button
                                  disabled={cancelDisabled}
                                  flavor={'confirm'}
                                  onClick={onCancel}
                                  size={buttonSize}
                                  tabIndex={show ? undefined : -1}
                                >
                                  {cancelText}
                                </Button>
                              )}
                        </nav>
                      </div>
                    ) : (
                      <nav className={styles.controls}>
                        <div>{bottomLeftContent}</div>
                        <div>
                          {onCancel && (
                            <Button
                              disabled={cancelDisabled}
                              flavor={cancelButtonFlavor}
                              onClick={onCancel}
                              size={buttonSize}
                              tabIndex={show ? undefined : -1}
                            >
                              {cancelText}
                            </Button>
                          )}
                          {onThirdButton && (
                            <Button
                              disabled={thirdButtonDisabled}
                              flavor={thirdButtonFlavor}
                              onClick={onThirdButton}
                              size={buttonSize}
                              tabIndex={show ? undefined : -1}
                            >
                              {thirdButtonText}
                            </Button>
                          )}
                          {onSecondButton && (
                            <Button
                              disabled={secondButtonDisabled}
                              flavor={secondButtonFlavor}
                              onClick={onSecondButton}
                              size={buttonSize}
                              tabIndex={show ? undefined : -1}
                            >
                              {secondButtonText}
                            </Button>
                          )}
                          {onConfirm && (
                            <Button
                              disabled={confirmDisabled}
                              flavor={confirmButtonFlavor}
                              onClick={onConfirm}
                              size={buttonSize}
                              tabIndex={show ? undefined : -1}
                            >
                              {confirmText}
                            </Button>
                          )}
                        </div>
                      </nav>
                    )}
                  </>
                )}
              </div>
            </div>
          </Shield>
        </div>
      </FocusTrap>
    </Portal>
  );
};

export default Modal;
