import React from 'react';

import { ProgressBar, Shield } from '@strivr/ui';

import styles from './loadingIndicator.module.css';

interface LoadingIndicatorProps {
  coverRef?: React.RefObject<HTMLElement>;
  progress?: { current: number; total: number };
  show: boolean;
  text?: string;
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  coverRef,
  progress,
  show,
  text,
}) => {
  return (
    <Shield coverRef={coverRef} show={show}>
      <div aria-hidden={!show} className={styles.wrapper} role="status">
        <div className={styles.logo}>
          <div className={styles.dotFlashing} />
        </div>
        <div className={styles.text}>{text ?? 'Loading...'}</div>
        {progress && (
          <div className={styles.progress}>
            <ProgressBar current={progress.current} total={progress.total} />
          </div>
        )}
      </div>
    </Shield>
  );
};

export default React.memo(LoadingIndicator);
