// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3Jq2dsTJQXiYs41pGSy9dz {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    max-height: calc(100vh - 80px);\n    overflow: hidden;\n    height: 100%\n}\n\n._2btNmEUG0yACeJPW6N2MPc {\n    background: black;\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    justify-content: space-between;\n}\n\n._3A_kAGxBPAMx5SaGRYxiS6 {\n    height: 100vh;\n    left: 0;\n    position: fixed;\n    top: 0;\n    width: 100vw;\n    z-index: 100;\n}\n\n._3A_kAGxBPAMx5SaGRYxiS6 ._3Jq2dsTJQXiYs41pGSy9dz {\n    max-height: calc(100vh - 36px);\n}\n\n.Kz-9KACGYxDHanhyhHlmR {\n    background: none;\n    padding: 0 4px;\n    border: none;\n    margin: 0;\n    height: 20px;\n    position: absolute;\n    top: 10px;\n    right: 10px;\n}\n.Kz-9KACGYxDHanhyhHlmR:hover {\n    background: none;\n}\n._2YRHFBmrB1oB2NFt3CxRJp {\n    display: block;\n}\n\n._2OvETQi36tAjwqnCE8kITk {\n    display: none;\n}\n\n.Kz-9KACGYxDHanhyhHlmR:hover ._2YRHFBmrB1oB2NFt3CxRJp,\n.Kz-9KACGYxDHanhyhHlmR:focus ._2YRHFBmrB1oB2NFt3CxRJp {\n    display: none;\n}\n\n.Kz-9KACGYxDHanhyhHlmR:hover ._2OvETQi36tAjwqnCE8kITk,\n.Kz-9KACGYxDHanhyhHlmR:focus ._2OvETQi36tAjwqnCE8kITk {\n    display: block;\n}\n\n._3m31R3Zqg9P_7pRSjKV0el {\n    bottom: 40px;\n    fill: white;\n    left: 0;\n    position: absolute;\n    stroke: white;\n}\n\n._3sO8VYlSzGbiX8vSnr2QhS {\n    margin-left: 8px;\n}\n\n._3VH-5Z9_HKNcMVVaQYgRsA,\n._3VH-5Z9_HKNcMVVaQYgRsA:not(:active):not(:disabled):hover,\n._3VH-5Z9_HKNcMVVaQYgRsA:active:not(:disabled):hover {\n    background: black;\n}\n", ""]);
// Exports
exports.locals = {
	"unity_context": "_3Jq2dsTJQXiYs41pGSy9dz",
	"preview_container": "_2btNmEUG0yACeJPW6N2MPc",
	"preview_container__full": "_3A_kAGxBPAMx5SaGRYxiS6",
	"close_container": "Kz-9KACGYxDHanhyhHlmR",
	"close_button": "_2YRHFBmrB1oB2NFt3CxRJp",
	"close_hover": "_2OvETQi36tAjwqnCE8kITk",
	"gizmocontrols": "_3m31R3Zqg9P_7pRSjKV0el",
	"gizmobutton": "_3sO8VYlSzGbiX8vSnr2QhS",
	"gizmobutton__selected": "_3VH-5Z9_HKNcMVVaQYgRsA"
};
module.exports = exports;
