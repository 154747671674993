import React, { useEffect, useState } from 'react';

import { classNames } from '../utils/helpers';

import styles from './dropdown.module.css';

export type OptionValue = string | number;

export interface DropdownOption<T>
  extends Omit<
    React.OptionHTMLAttributes<HTMLOptionElement>,
    'selected' | 'value'
  > {
  display?: string | null | undefined;
  value: T;
}

interface DropdownProps<T>
  extends Omit<
    React.SelectHTMLAttributes<HTMLSelectElement>,
    'defaultValue' | 'onChange' | 'value'
  > {
  defaultValue?: T;
  error?: string | null;
  onChange?: (value: T, e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: DropdownOption<T>[];
}

function Dropdown<T extends OptionValue>(props: DropdownProps<T>): JSX.Element {
  const { className, defaultValue, error, onChange, options, ...selectProps } =
    props;

  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = options[e.target.selectedIndex];
    setValue(selectedOption.value);
    if (onChange) onChange(selectedOption.value, e);
  };

  return (
    <span className={classNames(styles.root, className)}>
      <select
        {...selectProps}
        className={classNames(className, styles.dropdown, {
          [styles.dropdown__invalid]: !!error,
        })}
        onChange={handleChange}
        value={value}
      >
        {options.map((optionProps) => (
          <option
            {...optionProps}
            className={classNames(
              className,
              styles.option,
              optionProps.className,
            )}
            key={optionProps.value}
          >
            {optionProps.display}
          </option>
        ))}
      </select>
    </span>
  );
}

export default Dropdown;
