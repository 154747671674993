// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".zm7sO1VJAbOaGFa8uBwhj {\n    display: inline-flex;\n    flex-direction: column;\n}\n\n._25QAI5fcOoZrpudU--D4SV {\n    background: var(--v-ui-color-field-background);\n    border-color: var(--v-ui-color-field);\n    border-radius: var(--v-ui-border-radius-field);\n    border-style: solid;\n    border-width: var(--v-ui-border-width-field);\n    caret-color: var(--v-ui-color-field-caret);\n    color: inherit;\n    font-family: inherit;\n    font-size: 1.6rem;\n    padding: var(--v-ui-padding-field);\n}\n\n._25QAI5fcOoZrpudU--D4SV:disabled {\n    border-color: var(--v-ui-color-field-disabled);\n    cursor: not-allowed;\n    filter: var(--v-ui-filter-disabled);\n    color: var(--v-ui-color-field-disabled-text);\n}\n\n._25QAI5fcOoZrpudU--D4SV:not(:disabled):focus {\n    border-color: var(--v-ui-color-field-focus);\n    outline: none;\n}\n\n._25QAI5fcOoZrpudU--D4SV:not(:disabled):hover {\n    filter: var(--v-ui-filter-hover);\n}\n\n._21PmKPLHDc6RuFV5MeBIfP {\n    border-color: var(--v-ui-color-invalid);\n}\n\n.tLKR02YKvM3kwKGc-VJXY::-webkit-outer-spin-button,\n.tLKR02YKvM3kwKGc-VJXY::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n._3rbm0Ru_yrHIWkdox1K2pw::-webkit-outer-spin-button,\n._3rbm0Ru_yrHIWkdox1K2pw::-webkit-inner-spin-button {\n  opacity: 1;\n  outline: none;\n  background-color: none;\n}\n._3JE1cR9NOfEgYMNPbJk9Gs {\n    font-size: 1.3rem;\n    margin-top: 8px;\n    color: var(--v-ui-color-invalid);\n}", ""]);
// Exports
exports.locals = {
	"root": "zm7sO1VJAbOaGFa8uBwhj",
	"input": "_25QAI5fcOoZrpudU--D4SV",
	"input__invalid": "_21PmKPLHDc6RuFV5MeBIfP",
	"no_spin": "tLKR02YKvM3kwKGc-VJXY",
	"show_spin": "_3rbm0Ru_yrHIWkdox1K2pw",
	"error": "_3JE1cR9NOfEgYMNPbJk9Gs"
};
module.exports = exports;
