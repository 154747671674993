// Based on: https://javascript.plainenglish.io/how-to-alert-a-user-before-leaving-a-page-in-react-a2858104ca94
// See useWarnOnClose.ts hook.
export const handleBeforeUnload = (e: BeforeUnloadEvent): void => {
  e.preventDefault();
  e.returnValue = '';
};

export const removeWarnOnClose = (): void => {
  window.removeEventListener('beforeunload', handleBeforeUnload);
};
