import { useEffect } from 'react';

import useThrottleWithDebounce from './useThrottleWithDebounce';

const useGlobalScrollHandler = (callback: () => void, delayMs = 100): void => {
  const handleScroll = useThrottleWithDebounce(callback, delayMs);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, true);

    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [handleScroll]);
};

export default useGlobalScrollHandler;
