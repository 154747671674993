import React, { useEffect, useRef, useState } from 'react';

import styles from './dropdownMultiselect.module.css';
import { DropdownOption } from './dropdown';

interface DropdownMultiselectProps {
  options: DropdownOption<string>[];
  defaultValues?: string[];
  onChange?: (value: string) => void;
}

const DropdownMultiselect: React.FC<DropdownMultiselectProps> = ({
  options,
  defaultValues,
  onChange,
}) => {
  const dropdownRef = useRef(null as HTMLDivElement | null);
  const [wasModified, setWasModified] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    defaultValues ? [...defaultValues] : [],
  );

  useEffect(() => {
    if (!wasModified) {
      setSelectedOptions(defaultValues ? [...defaultValues] : []);
    }
  }, [defaultValues, wasModified]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleOption = (value: string) => {
    setWasModified(true);
    if (selectedOptions && selectedOptions.includes(value)) {
      setSelectedOptions(selectedOptions.filter((option) => option !== value));
    } else {
      setSelectedOptions([...selectedOptions, value]);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  const handleChange = (optionValue: string) => {
    toggleOption(optionValue);
    if (onChange) onChange(optionValue);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className={styles.dropdown} ref={dropdownRef}>
      <button className={styles.dropdownToggle} onClick={toggleDropdown}>
        {selectedOptions.length === 0
          ? 'None Selected'
          : selectedOptions.length === 1
            ? options.filter((option) => option.value === selectedOptions[0])[0]
                ?.display
            : 'Multiple selected'}
      </button>
      {isOpen && (
        <div
          className={styles.dropdownContent}
          aria-label={'Dropdown Multiselect Options'}
        >
          {options.map((option) => (
            <label key={option.value}>
              <input
                type="checkbox"
                value={option.value}
                checked={selectedOptions.includes(option.value)}
                onChange={() => handleChange(option.value)}
              />
              {option.display}
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default React.memo(DropdownMultiselect);
