// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2pEU9aQsITHi3pGafi-fJD {\n    border-radius: var(--v-ui-border-radius-popup);\n    pointer-events: none;\n    opacity: 0;\n    position: fixed;\n    transition-property: opacity;\n    transition-timing-function: ease;\n    z-index: 100;\n    color: var(--v-ui-color-popup-contrast)\n}\n\n.C7V2miME7U86u9fcAEfHN {\n    display: block;\n}\n\n._1UJlu4encM5sF1Kixqz4BO {\n    opacity: 1;\n    pointer-events: visible;\n}\n\n._112NMtM_-j2xAlhhXRUBdi {\n    background: var(--v-ui-color-popup);\n    border-radius: var(--v-ui-border-radius-popup);\n    box-shadow: var(--v-ui-box-shadow-popup);\n    padding: 12px;\n}\n\n.XJcFhsLpV7EzbLCjUr_Ma {\n    content: ' ';\n    border-left: 0 solid transparent;\n    border-right: 0 solid transparent;\n    border-top: 0 solid var(--v-ui-color-popup);\n    height: 0;\n    position: fixed;\n    width: 0;\n}\n\n.NWdVT1mZcbOCoyVdvvTXu {\n    border-bottom: 0 solid var(--v-ui-color-popup);\n    border-top: none;\n}\n\n._1JPntBkP-xErzKQZuBUfmy {\n    left: 10px;\n}\n\n._3mw4V09ux5kVECY27xQjUC {\n    right: 10px;\n}", ""]);
// Exports
exports.locals = {
	"popup": "_2pEU9aQsITHi3pGafi-fJD",
	"popup__rendering": "C7V2miME7U86u9fcAEfHN",
	"popup__showing": "_1UJlu4encM5sF1Kixqz4BO",
	"popup_bubble": "_112NMtM_-j2xAlhhXRUBdi",
	"popup_caret": "XJcFhsLpV7EzbLCjUr_Ma",
	"popup_caret__inverted": "NWdVT1mZcbOCoyVdvvTXu",
	"popup_caret__alignLeft": "_1JPntBkP-xErzKQZuBUfmy",
	"popup_caret__alignRight": "_3mw4V09ux5kVECY27xQjUC"
};
module.exports = exports;
