import React from 'react';

/**
 * Added bc the built-in Redirect component was causing an issue w/auth0 when redirecting from root
 * Even if the returnTo path is ie. /start, auth0's callback would trigger an infinite loop when / routes to Redirect
 * Maybe some history weirdness? Straight up location.replace (as below) seems to work [shrug]
 */
const RedirectTo: React.FC<{ path: string }> = ({ path }) => {
  window.location.replace(path.startsWith('/') ? path.slice(1) : path);
  return null;
};

export default RedirectTo;
