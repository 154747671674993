import React, { ChangeEvent, useCallback } from 'react';

import { classNames } from '../utils/helpers';

import styles from './numericInput.module.css';

interface NumericInputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'onChange' | 'type' | 'value'
  > {
  error?: string | null;
  onChange?: (
    newValue: number | null,
    event: ChangeEvent<HTMLInputElement>
  ) => void;
  value?: number | null;
  showSpin?: boolean;
}

const NumericInput: React.FC<NumericInputProps> = ({
  className,
  error,
  onChange,
  value,
  showSpin,
  ...numericInputProps
}) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.currentTarget;

      if (value === '') {
        onChange?.(null, event);
      } else {
        // Prevents invalid inputs from wiping out
        // the text box.
        const valueFloat = parseFloat(value);
        if (!isNaN(valueFloat)) {
          const valueFloat = parseFloat(value);
          onChange?.(valueFloat, event);
        }
      }
    },
    [onChange]
  );

  return (
    <span className={classNames(styles.root, className)}>
      <input
        {...numericInputProps}
        aria-invalid={!!error || numericInputProps['aria-invalid']}
        className={classNames(styles.input, {
          [styles.show_spin]: showSpin,
          [styles.no_spin]: !showSpin,
          [styles.input__invalid]: !!error,
        })}
        onChange={handleChange}
        type="number"
        value={value === null ? '' : value}
      />
      <span className={styles.error}>{error}</span>
    </span>
  );
};

export default NumericInput;
