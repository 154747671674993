import { all, call, put, select } from 'typed-redux-saga';
import { SagaType, createSliceSaga } from 'redux-toolkit-saga';
import { AssetManagementApi } from '../../../../utils/api';
import sliceReducer from './sliceReducer';
import { ASSETBUNDLE_TYPES, ASSET_TYPES } from '../../constants';
import { logSagaError } from '../../../../utils/saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { State } from '../../../../state/reducer';
import { AssetTypes } from '../../types/models';

interface UploadAsset {
  assetObjectUrl: string;
  assetType: AssetTypes;
  mimeType: string;
  name: string;
}

const sliceSaga = createSliceSaga({
  name: sliceReducer.name,
  caseSagas: {
    fetchAllAssets: {
      sagaType: SagaType.TakeLatest,
      *fn(action) {
        // All of the asset retrieval for group labeling tool should be useGroupLabelBasedAssetRetrieval= true

        // Currently removing groupLabels parameter in getAssets call,
        // will make use of it once we have UI for filtering by group labels- TBD
        try {
          yield* put(sliceReducer.actions.updateIsLoadingAssets(true));
          // We are removing experience assets from being listed until we have properly working experience sharing
          const allAssetTypes = [...ASSETBUNDLE_TYPES, ...ASSET_TYPES].filter(
            (x) => x !== 'experience',
          );
          const requests = allAssetTypes.map((type) => {
            return call(
              AssetManagementApi({
                useGroupLabelBasedAssetRetrieval: true,
              }).assets.getAssets({
                type,
              }),
            );
          });
          const responses = yield* all(requests);
          for (let i = 0; i < allAssetTypes.length; i++) {
            const response = responses[i];
            const assetType = allAssetTypes[i];

            // Same data response mapping and data storing as fetchAssets
            const newAssets = response.data.map((asset) => {
              const {
                name,
                id,
                mimeType,
                tenant,
                version,
                versionId,
                type,
                dateFirstVersionCreated,
                dateCreated,
                versionVariantId,
                variant,
              } = asset;

              return {
                name,
                id,
                mimeType,
                tenant,
                version,
                versionId,
                type,
                dateFirstVersionCreated,
                dateCreated,
                versionVariantId,
                variant,
              };
            });

            yield* put(
              sliceReducer.actions.updateAssets({
                [assetType]: newAssets,
              }),
            );
          }
          yield* put(sliceReducer.actions.updateIsLoadingAssets(false));
        } catch (e) {
          yield* put(
            sliceReducer.actions.updateErrors({
              field: 'assetList',
              error: e,
            }),
          );
          logSagaError(action, e);
        }
      },
    },
    fetchGroupLabels: {
      sagaType: SagaType.TakeEvery,
      *fn(action: PayloadAction<Array<string>>) {
        yield* put(sliceReducer.actions.updateIsLoadingGroupLabels(true));

        try {
          if (action.payload.length === 0) throw new Error('No asset IDs');

          const request = AssetManagementApi({
            useGroupLabelBasedAssetRetrieval: true,
          }).groupLabels.getAssetGroupLabels(action.payload);
          const response = yield* call(request);
          const newAssetToGroupLabelsMapping: Record<string, string[]> = {};

          Object.entries(response.data).forEach(([assetId, groupLabels]) => {
            if (groupLabels) {
              newAssetToGroupLabelsMapping[assetId] = groupLabels;
            }
          });

          yield* put(
            sliceReducer.actions.updateGroupLabels({
              ...newAssetToGroupLabelsMapping,
            }),
          );
        } catch (e) {
          yield* put(
            sliceReducer.actions.updateErrors({
              field: 'groupLabelsList',
              error: e,
            }),
          );
          logSagaError(action, e);
        }
        yield* put(sliceReducer.actions.updateIsLoadingGroupLabels(false));
      },
    },
    updateGroupLabelMappings: {
      sagaType: SagaType.TakeEvery,
      *fn(action: PayloadAction<{ assetId: string; groupLabel: string }>) {
        const state: State = yield* select();
        const { assetToGroupLabelsMapping } = state.assets.assets;
        const { assetId } = action.payload;
        const { groupLabel } = action.payload;

        const groupLabelExists =
          assetToGroupLabelsMapping[assetId].includes(groupLabel);

        yield* put(
          sliceReducer.actions.updateGroupLabels({
            ...assetToGroupLabelsMapping,
            [assetId]: groupLabelExists
              ? [
                  ...assetToGroupLabelsMapping[assetId].filter(
                    (x) => x !== groupLabel,
                  ),
                ]
              : [...assetToGroupLabelsMapping[assetId], groupLabel],
          }),
        );
      },
    },
    overwriteGroupLabels: {
      sagaType: SagaType.TakeEvery,
      *fn(action: PayloadAction<string[]>) {
        const state: State = yield* select();
        const { assetToGroupLabelsMapping } = state.assets.assets;
        const selectedAssetIdList = action.payload as string[];
        try {
          if (selectedAssetIdList.length === 0) throw new Error('No asset IDs');

          const body: { [x: string]: string[] | undefined } = {};
          selectedAssetIdList.forEach((assetId) => {
            if (assetToGroupLabelsMapping[assetId]) {
              body[assetId] = assetToGroupLabelsMapping[assetId];
            }
          });
          const request = AssetManagementApi({
            useGroupLabelBasedAssetRetrieval: true,
          }).groupLabels.addGroupLabels(body);

          yield* call(request);
        } catch (e) {
          yield* put(
            sliceReducer.actions.updateErrors({
              field: 'overwriteGroupLabels',
              error: e,
            }),
          );
          logSagaError(action, e);
        }
      },
    },
    uploadAsset: {
      sagaType: SagaType.TakeEvery,
      *fn(action: PayloadAction<UploadAsset>) {
        try {
          const assetDataResponse = yield* call(
            fetch,
            action.payload.assetObjectUrl,
          );
          const assetData = yield assetDataResponse.blob();
          const request = AssetManagementApi().assets.uploadAsset(
            {
              type: action.payload.assetType,
              name: action.payload.name,
            },
            assetData,
            action.payload.mimeType,
          );
          const response = yield* call(request);

          // Save to assetListsByType so the new asset appears in the group labelling tab immediately
          const state: State = yield* select();
          if (response.data.type) {
            yield* put(
              sliceReducer.actions.updateAssets({
                [response.data.type]: [
                  ...state.assets.assets.assetListsByType[response.data.type],
                  response.data,
                ],
              }),
            );
          }
        } catch (e) {
          yield* put(
            sliceReducer.actions.updateErrors({
              field: 'assetUpload',
              error: e,
            }),
          );
          logSagaError(action, e);
        }
      },
    },
  },
});

export default sliceSaga;
