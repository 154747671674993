import { NodeType } from './types/nodeData';
import { Framework } from '../../types/shared';
import { AssetBundleTypes, AssetTypes } from './types/models';

/** Extra time needed to wait for the slowest headset to process animation changes (may decrease as headsets improve) */
export const ANIMATION_BUFFER_TIME_DEVICE = 0.35;
/** Extra time needed to wait for player to process animation changes (less likely to change than the above) */
export const ANIMATION_BUFFER_TIME_INTERNAL = 0.25;
export const CUSTOM_EDGE_TYPE_KEY = 'customEdge';
export const DEFAULT_ANIMATION_CLIP_NAME = '';
export const EDGE_BUTTON_TARGET_SIZE = 48;
export const EDGE_BUTTON_VISIBLE_SIZE = 9;
export const EDGE_UPDATER_RADIUS = 50;
export const UNSTACK_NODES_X_OFFSET = 20;
export const UNSTACK_NODES_Y_OFFSET = 50;
export const IN_HANDLE_PREFIX = 'IN_';
export const OUT_HANDLE_PREFIX = 'OUT_';
export const DEFAULT_EXPERIENCE_NAME = 'New Experience';
export const ADD_NODE_CONTEXT_MENU_Y_OFFSET = 160;
export const ADD_SCENE_CONTEXT_MENU_Y_OFFSET = 100;
export const INITIAL_SCENE_NODE_HEIGHT = 220;
export const INITIAL_SCENE_NODE_WIDTH = 800;
export const NODE_HEIGHT = 75;
export const NODE_BUTTON_HEIGHT = 31;
export const NODE_HEIGHT_OFFSET = 64;
export const NODE_WIDTH_OFFSET = 195;
export const EXPAND_SCENE_BUFFER = 30;
export const DRAG_STACK_ZONE_HEIGHT = 48;
export const nodeTypesNotAllowedForPlaybackPhase: NodeType[] = [
  'scene',
  'playback phase',
  'playback',
];
export const nodeTypesNotAllowedForStacking: NodeType[] = [
  'scene',
  'playback phase',
  'playback',
];
export const nodeTypesAllowedForPlayback: NodeType[] = ['playback phase'];
export const nodeTypesDisableOutConnectionOnStacking: NodeType[] = [
  'animation',
];

export const ASSETBUNDLE_TYPES: AssetBundleTypes[] = [
  'environment-assetbundle',
  'character-assetbundle',
  'animation-assetbundle',
  'lipsync-assetbundle',
  'jali-assetbundle',
  'avatar-assetbundle',
  'generic-animation-assetbundle',
  'generic-character-assetbundle',
];

export const ASSET_TYPES: AssetTypes[] = [
  'experience',
  'image',
  'sound',
  'video',
];

export const mcqPanelToolTipData: Record<string, string> = {
  randomized: `Check if you would like the answers to appear in a random order each time a different learner sees the question.\n\nLeave unchecked to make answers appear in the order you placed them.`,
  'is scored': `Check if you want this question to count toward the learner's total score for the experience. After you select Scored, you can then enter a score.`,
};
export const allFrameworks: Framework[] = [
  { id: 0, text: 'None', value: 'None' },
  { id: 1, text: 'Purposeful questioning', value: 'Purposeful questioning' },
  {
    id: 2,
    text: 'Acknowledging perspective',
    value: 'Acknowledging perspective',
  },
  { id: 3, text: 'Managing expectation', value: 'Managing expectation' },
  { id: 4, text: 'Providing feedback', value: 'Providing feedback' },
  { id: 5, text: 'Visual recognition', value: 'Visual recognition' },
  { id: 6, text: 'Spatial awareness', value: 'Spatial awareness' },
  { id: 7, text: 'Applied decision making', value: 'Applied decision making' },
  { id: 8, text: 'Verbal recognition', value: 'Verbal recognition' },
];

export const VECTOR_3_DEFAULT_VALUES_BY_FEATURE = {
  default: {
    position: { x: 0, y: 0, z: 0 },
    rotation: { x: 0, y: 0, z: 0 },
    scale: { x: 1, y: 1, z: 1 },
  },
  user_camera: {
    position: { x: 0, y: 1.3, z: 0 },
    rotation: { x: 0, y: 0, z: 0 },
    scale: { x: 1, y: 1, z: 1 },
  },
  character: {
    position: { x: 0, y: 0, z: 0 },
    rotation: { x: 0, y: 0, z: 0 },
    scale: { x: 1, y: 1, z: 1 },
  },
};

export const MCQ_MIN_ANSWERS = 2;
/**
 * The maximum amount of answers that a multiple-choice question can have
 */
export const MCQ_MAX_ANSWERS = 11;

export const CONTENT_VERSION_FLAGS = {
  genericRig: 8,
} as const;
