import React from 'react';
import styles from './genericError.module.css';
import IconErrorFace from '../../res/assets/images/iconErrorFace.svg';
const GenericError: React.FC = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.icon}>
          <IconErrorFace />
        </div>
        <div className={styles.description}>
          We seem to be having technical difficulties.
          <br />
          Please reload and try again.
        </div>
        <div className={styles.contactSupport}>
          If the problem persists, contact support.
        </div>
      </div>
    </>
  );
};
export default GenericError;
