// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2ud3xouoY4MyHmVuQ1gM3- {\n    padding: 4px 15px;\n    background: var(--v-main-color-scene-shadow);\n    flex: 1;\n    display: flex;\n    overflow: auto;\n    min-height: 22px;\n}\n\n._1wobYQqo_vbcN4dtkz-g5A {\n    display: flex;\n    min-height: -webkit-min-content;\n    min-height: -moz-min-content;\n    min-height: min-content;\n}\n\n._1TayKpoiKQDE0itnbPUPq6 {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n._2K4S4ovQAIxPEhtyreXGm2 {\n    height: 16px;\n    color: var(--v-main-color-preview-warning-text);\n    font-size: 12px;\n    font-weight: bold;\n    margin-left: 8px;\n    margin-bottom: -7px;\n}\n", ""]);
// Exports
exports.locals = {
	"background": "_2ud3xouoY4MyHmVuQ1gM3-",
	"overflow": "_1wobYQqo_vbcN4dtkz-g5A",
	"alignCenter": "_1TayKpoiKQDE0itnbPUPq6",
	"warningText": "_2K4S4ovQAIxPEhtyreXGm2"
};
module.exports = exports;
