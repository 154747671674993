import { datadogLogs } from '@datadog/browser-logs';

export type Severity = 'debug' | 'info' | 'warn' | 'error';

export const allSeverities: Severity[] = ['debug', 'info', 'warn', 'error'];
export const ignoreSeverities =
  (process.env.REACT_APP_LOG_IGNORE_SEVERITITES?.split('|') ??
    []) as Severity[];
export const logSeverities = allSeverities.filter(
  (x) => !ignoreSeverities.includes(x)
);

export const logToServer = (
  severity: Severity,
  message: string,
  data?: Record<string, unknown>
): void => {
  if (ignoreSeverities.includes(severity)) return;

  const structuredLog = {
    ...data,
    app: 'creator',
    context: window.logContext,
    location: window.location,
    message,
    platform: 'web',
    severity: severity.toUpperCase(),
    ua: navigator.userAgent,
    usr: window.logUser,
  };

  switch (process.env.REACT_APP_LOG_TARGET) {
    case 'datadog':
      datadogLogs.logger[severity](
        `${severity.toUpperCase()} ${structuredLog.message}`,
        structuredLog
      );
      break;
    case 'console':
      // eslint-disable-next-line no-console
      console[severity](
        `${severity.toUpperCase()} ${structuredLog.message}`,
        structuredLog
      );
      break;
    case 'none':
      break;
    default:
      throw new Error(
        `Invalid log target: "${process.env.REACT_APP_LOG_TARGET}"`
      );
  }
};
