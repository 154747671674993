import { mapOperationContainerToJSON } from './mapOperations';
import { unityContext } from '../areas/main/views/preview';
import { OperationContainersState } from '../areas/main/state/operationContainers/sliceReducer';
import { SignedUrlMetadata } from '../areas/main/state/preview/sliceSaga';
import { AssetTypes, ObjectMetadata } from '../areas/main/types/models';
import {
  PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT,
  UnityPreviewAPIMethods,
} from '../areas/main/state/preview/unityMessages/utils';
import { shouldChangeToPlaybackPerspective } from '../areas/main/state/preview/utils';
import { SceneState } from '../areas/main/state/sceneProperties/sliceReducer';

export const sendLoadOperationContainer = (
  contentVersion: number,
  containerId: string,
  operationContainers: OperationContainersState,
  signedUrls: SignedUrlMetadata[] | null,
  assetListsByType: Record<AssetTypes, ObjectMetadata[]>,
  isSceneReady: boolean,
  loadedEntityId: string | undefined,
  sceneId: string,
  sceneProperties: SceneState,
) => {
  const { operations, containers } = operationContainers;
  const container = containers[containerId];
  // Ensure that a container is present in the state, not just
  // a selectedContainerId, since this can lead to immediate
  // null error issues when opening previous experiences
  if (container && isSceneReady) {
    const signedUrlAssetData = {
      signedUrls,
      assetListsByType,
    };
    const operationContainerData = mapOperationContainerToJSON(
      contentVersion,
      container,
      operations,
      operationContainers,
      sceneId,
      sceneProperties,
      signedUrlAssetData,
    );

    const loadOperationContainerData = {
      operation_container_data: operationContainerData,
    };
    const payload = JSON.stringify(loadOperationContainerData);
    const perspectiveId = shouldChangeToPlaybackPerspective(
      operationContainerData,
      operations,
    )
      ? loadedEntityId
      : null;

    if (unityContext.unityInstance) {
      unityContext.send(
        PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT,
        UnityPreviewAPIMethods.ChangePerspective,
        JSON.stringify(perspectiveId),
      );

      unityContext.send(
        PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT,
        UnityPreviewAPIMethods.LoadOperationContainer,
        payload,
      );
    }
  }
};
