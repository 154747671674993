// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3rI0QVoGAifh_peVqhDXCw {\n    margin-left: 25px;\n    margin-right:  52px;\n}\n\n._30Fv8-qW7sYsoD0TeESMzH {\n    margin-bottom: 7px;\n}\n\n._1rtar8MPiFLmwIM01Ye1g_ {\n    margin-top: 7px;\n    width: 100%;\n}\n\n._2JqhbBSp8bhk0Vh1qgcnSj {\n    width: 100%;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "_3rI0QVoGAifh_peVqhDXCw",
	"customerLabel__DEPRECATED": "_30Fv8-qW7sYsoD0TeESMzH",
	"tenantPicker": "_1rtar8MPiFLmwIM01Ye1g_",
	"tenantPicker__DEPRECATED": "_2JqhbBSp8bhk0Vh1qgcnSj"
};
module.exports = exports;
