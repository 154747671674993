import { Edge } from 'react-flow-renderer';
import { IN_HANDLE_PREFIX, OUT_HANDLE_PREFIX } from '../areas/main/constants';

interface IdsFromHandles {
  sourceChildId: string;
  sourceOperationId: string;
  targetOperationId: string;
}

export const getIdsFromHandles = <T>(edge: Edge<T>): IdsFromHandles => {
  const [sourceOperationId, sourceChildId] = (
    edge.sourceHandle?.replace(OUT_HANDLE_PREFIX, '') ?? ''
  ).split('_');
  const [targetOperationId] = (
    edge.targetHandle?.replace(IN_HANDLE_PREFIX, '') ?? ''
  ).split('_');
  return {
    sourceChildId,
    sourceOperationId,
    targetOperationId,
  };
};
