import React from 'react';

import { logToServer } from '../utils/logging';

import GenericError from '../views/genericError';

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    logToServer('error', error?.message, {
      stack: error?.stack,
      ...errorInfo,
    });
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return <GenericError />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
