import { unityContext } from '../../../views/preview';

/**
 * Name of game object attached to player's PreviewWindow scene
 */
export const PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT =
  'PreviewWindowBehaviour';

/**
 * TODO: Provide summaries of these Unity methods
 * These are public methods in `player\Assets\_SCRIPTS\Dialogue3\Presentation\PreviewWindowBehaviour.cs`
 */
export enum UnityPreviewAPIMethods {
  ToggleEmptyNodeView = 'ToggleEmptyNodeView',
  LoadOperationContainer = 'LoadOperationContainer',
  ShowEmptyNodeView = 'ShowEmptyNodeView',
  LoadEntityAnimations = 'LoadEntityAnimations',
  LoadScene = 'LoadScene',
  Play = 'Play',
  Pause = 'Pause',
  UpdateEntities = 'UpdateEntities',
  UpdateEnvironments = 'UpdateEnvironments',
  OperationContainerDeleted = 'OperationContainerDeleted',
  SetKeyboardCapture = 'SetKeyboardCapture',
  UnloadScene = 'UnloadScene',
  ChangePerspective = 'ChangePerspective',
  LoadExperience = 'LoadExperience',
  UnloadExperience = 'UnloadExperience',
  SetGizmoTo = 'SetGizmoTo',
  SetGizmosVisible = 'SetGizmosVisible',
}

/**
 * A complete list of events called from Unity
 * in `player/Assets/Plugins/WebGL/PreviewWindow.jslib`
 * Only some of these are used by creator at the moment.
 */
export enum UnityPreviewEvents {
  OnPlayerLoaded = 'OnPlayerLoaded',
  OnPlayerInitialized = 'OnPlayerInitialized',
  OnSceneLoaded = 'OnSceneLoaded',
  OnAssetLoaded = 'OnAssetLoaded',
  OnEnvironmentLoaded = 'OnEnvironmentLoaded',
  OnEntityLoaded = 'OnEntityLoaded',
  OnAnimationsLoaded = 'OnAnimationsLoaded',
  OnEnvironmentUpdated = 'OnEnvironmentUpdated',
  OnEnvironmentsUpdated = 'OnEnvironmentsUpdated',
  OnEntityUpdated = 'OnEntityUpdated',
  OnEntitiesUpdated = 'OnEntitiesUpdated',
  OnAnimationUpdated = 'OnAnimationUpdated',
  OnSceneUnloaded = 'OnSceneUnloaded',
  OnAssetUnloaded = 'OnAssetUnloaded',
  OnEnvironmentUnloaded = 'OnEnvironmentUnloaded',
  OnEntityUnloaded = 'OnEntityUnloaded',
  OnAnimationUnloaded = 'OnAnimationUnloaded',
  OnOperationContainerDestroyed = 'OnOperationContainerDestroyed',
  OnOperationContainerLoaded = 'OnOperationContainerLoaded',
  OnPlayerPaused = 'OnPlayerPaused',
  OnPlayerResumed = 'OnPlayerResumed',
  OnReportWebError = 'OnReportWebError',
  OnVRSupportedCheck = 'onVRSupportedCheck',
  OnExperienceFinished = 'OnExperienceFinished',
  OnObjectMoved = 'OnObjectMoved',
  OnObjectRotated = 'OnObjectRotated',
  OnObjectScaled = 'OnObjectScaled',
}

/**
 * Sends Unity messages to clear and unload the scene
 * and destroy any present container operations
 */
export const unloadAndClearSceneAndContainerOperationsInPreview = () => {
  if (unityContext.unityInstance) {
    // Unload scene
    unityContext.send(
      PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT,
      UnityPreviewAPIMethods.UnloadScene,
    );

    // Destroy operations
    unityContext.send(
      PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT,
      UnityPreviewAPIMethods.OperationContainerDeleted,
    );
  }
};
