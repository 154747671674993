// Taken from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set#implementing_basic_set_operations

/**
 * Removes Set B from Set A while keeping the order of Set A
 */
export const setDifference = <T>(setA: Set<T>, setB: Set<T>) => {
  const difference = new Set(setA);
  for (const elem of setB) {
    difference.delete(elem);
  }
  return difference;
};
