import React from 'react';
import WarningIcon from '../../../../res/assets/images/iconsWarning.svg';
import styles from './previewErrorMessage.module.css';

interface MessageProps {
  message: string;
}

const PreviewErrorMessage = ({ message }: MessageProps) => {
  return (
    <div className={styles.background}>
      <div className={styles.overflow}>
        <div className={styles.alignCenter}>
          <WarningIcon />
          <div className={styles.warningText}>
            {`Error: ${message}. Try clicking another scene to refresh preview. If that doesn't work, then refresh this page to see an accurate preview of this experience.`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(PreviewErrorMessage);
