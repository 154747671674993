import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';
import * as LDClient from 'launchdarkly-js-client-sdk';
import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { parseJwt } from '../utils/jwt';
import { getToggleUserFromUser } from '../utils/toggle';

const ClientToggleProvider: React.FC = ({ children }) => {
  const client = useLDClient();
  const { user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    void (async () => {
      if (!client || !user) return;
      const jwt = await getAccessTokenSilently();
      const jwtParts = parseJwt(jwt);
      const tenantId = jwtParts['https://api.strivr.com/tenantId'];
      window.ldClient = LDClient.initialize(
        process.env.REACT_APP_LD_CLIENT_ID ?? '',
        getToggleUserFromUser(user, tenantId),
      );
      await client.identify(getToggleUserFromUser(user, tenantId));
    })();
  }, [client, getAccessTokenSilently, user]);

  return <>{children}</>;
};

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LD_CLIENT_ID ?? '',
  user: {
    key: 'unauthorized',
  },
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
})(ClientToggleProvider);
