import mixpanel, { Dict, Query } from 'mixpanel-browser';
import { logToServer } from './logging';

// Mixpanel JavaScript API Documentation https://developer.mixpanel.com/docs/javascript-full-api-reference

interface User {
  email?: string;
  name?: string;
}

type Section =
  | 'Intro Section'
  | 'Objectives Section'
  | 'Questions Section'
  | 'Summary Section';
type IntelligenceArea = { area: 'intelligence'; section: Section };
type MainArea = { area: 'main' };
type Area = IntelligenceArea | MainArea;

export const trackUserEvent = (
  event: string,
  area: Area,
  params?: Dict,
): void => {
  mixpanel.track(event, { ...params, area });
};

// Mixpanel is entirely asynchronous, without a simple way to force it to be synchronous. This means that when links
// are clicked, since the page changes there are no guarantees that the Mixpanel call will go through before the new
// link is visited. To get around this, they expose a method that lets you register an HTML element that when clicked
// on will load a new link, and Mixpanel will attempt to track the event before a new link is loaded.
export const initLinkTracking = (
  query: Query,
  event: string,
  area: Area,
  params?: Dict,
): void => {
  if (!query) return;

  mixpanel.track_links(query, event, { ...params, area });
};

// this should be only be used in the upper most root for an application
export const initTracking = (user: User): void => {
  if (!user) {
    return;
  }

  if (!process.env.REACT_APP_MIXPANEL_TOKEN) {
    logToServer(
      'warn',
      'Missing/invalid tracking token - Tracking not initialized',
    );
    return;
  }

  const { email, name } = user;

  mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);
  // Call identify after signup. Call alias if you're tracking before signup.
  // https://help.mixpanel.com/hc/en-us/articles/115004497803
  mixpanel.identify(email);
  mixpanel.people.set({
    AppName: 'Strivr.Creator.Web',
    AppVersion: '3.0.0',
    FirstName: name,
    distinct_id: email,
    $email: email,
    name: email,
  });

  mixpanel.track('Logged in');
};
