import { OperationContainer } from '../../areas/main/types/operations';
import useSelector from '../useSelector';

const useCurrentStack = () => {
  const operationContainers = useSelector(
    (state) => state.main.operationContainers.containers
  );
  const currSelected = useSelector(
    (state) => state.main.nodes.elementsSelected
  );
  const lastSelected = useSelector(
    (state) => state.main.nodes.mostRecentlyTouchedOperationIds
  );
  let currStackId = lastSelected[0];
  if (currSelected.length === 1) {
    currStackId = currSelected[0].id;
  }
  const currStack = (
    Object.values(operationContainers) as OperationContainer[]
  ).find((e) => e.id === currStackId);
  if (currStack?.operationIds?.length || 0 > 1) {
    return currStack;
  }
  return undefined;
};

export default useCurrentStack;
