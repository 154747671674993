import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UndoRecord } from '../../types/json';

export const initialState = {
  redoStack: [] as UndoRecord[],
  undoStack: [] as UndoRecord[],
  /**
   * This is used to prevent creating more than 1 undo state for a single action in some situations:
   * 1) When running a saga fn we should not create another state every time that saga calls a reducer
   * Doing so would create 3 undo states on every addNode for instance (addNode + update operations + update scenes)
   * 2) During the undo/redo process itself, ignore operation/scene updates that happen when restoring the state
   */
  undoStateLock: false,
};

type Update = Partial<typeof initialState>;

const sliceReducer = createSlice({
  name: 'main/undo',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Update>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export default sliceReducer;
