import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TextToSpeechInput } from '../../types/models';
import { DropdownOption } from '@strivr/ui';

export const initialState = {
  textToSpeechInProgress: false as boolean,
  textToSpeechLastInput: null as TextToSpeechInput | null,
  textToSpeechPreviewUrl: null as string | null,
  voiceList: null as DropdownOption<string>[] | null,
  errors: null as Record<string, string> | null,
};

type Update = Partial<typeof initialState>;

const sliceReducer = createSlice({
  name: 'assets/voices',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Update>) => ({
      ...state,
      ...action.payload,
    }),
    updateErrors: (
      state,
      action: PayloadAction<{
        field: string;
        error: unknown;
      }>,
    ) => {
      const { error, field } = action.payload;

      const errorMessage =
        error instanceof Error
          ? error.message
          : typeof error === 'string'
            ? error
            : JSON.stringify(error);

      return {
        ...state,
        errors: {
          ...state.errors,
          [field]: errorMessage,
        },
      };
    },
  },
});

export default sliceReducer;
