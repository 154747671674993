import React from 'react';

import { classNames } from '../utils/helpers';

import styles from './radioGroup.module.css';

interface RadioButton<T> {
  className?: string;
  label: string;
  value: T;
}

interface RadioGroupProps<T> {
  buttons: RadioButton<T>[];
  className?: string;
  id?: string;
  name: string;
  onChange?: (value: T) => void;
  value?: T;
}

function RadioGroup<T extends string | number>(
  props: RadioGroupProps<T>,
): JSX.Element {
  const { buttons, className, id, name, onChange, value } = props;

  const handleChange = (v: T) => {
    if (onChange) onChange(v);
  };

  return (
    <div
      className={classNames(styles.radioGroup, className)}
      id={id}
      role="radiogroup"
    >
      {buttons.map((button) => (
        <label className={styles.radioLabel} key={button.value}>
          <input
            checked={value === button.value}
            className={classNames(styles.radioInput, button.className)}
            name={name}
            onChange={() => handleChange(button.value)}
            type="radio"
            value={button.value}
          />
          {button.label}
        </label>
      ))}
    </div>
  );
}

export default RadioGroup;
