// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AY0FIFz3EOEZ8tYcP-vI {\n  height: 100%;\n}", ""]);
// Exports
exports.locals = {
	"app": "AY0FIFz3EOEZ8tYcP-vI"
};
module.exports = exports;
