import { useEffect } from 'react';
import useSelector from '../useSelector';
import {
  getCurrentContainerId,
  getCurrentlySelectedSceneId,
  isSceneSelected,
} from '../../areas/main/state/preview/utils';
import { sendLoadOperationContainer } from '../../utils/preview';
import useDispatch from '../useDispatch';
import sliceReducer from '../../areas/main/state/preview/sliceReducer';
import {
  useAllAssetVersionsByType,
  useAnimationBundleSignedUrl,
} from '../useOldAssetVersions';

const useCurrentlySelectedContainerToMessagePlayer = () => {
  const dispatch = useDispatch();
  const selectedContainerId = useSelector((state) =>
    getCurrentContainerId(state.main.nodes),
  );
  const { sceneProperties } = useSelector((state) => state.main);
  const { elementsSelected } = useSelector((state) => state.main.nodes);
  const { scenes } = useSelector((state) => state.main.sceneProperties);
  const currentSceneId = getCurrentlySelectedSceneId(elementsSelected, scenes);
  const {
    areAnimationsLoaded,
    isPlayerPreviewReady,
    isSceneReady,
    lastLoadedSceneId,
    signedUrls,
  } = useSelector((state) => state.main.preview);
  const hasAnimationBundleSignedUrl = useAnimationBundleSignedUrl();
  const { operationContainers } = useSelector((state) => state.main);
  const allAssetsByType = useAllAssetVersionsByType();
  const entityId = useSelector(
    (state) =>
      state.main.sceneProperties.scenes[currentSceneId]?.characterVersionId,
  );
  const isDifferentScene = lastLoadedSceneId !== currentSceneId;
  const lastLoadedContainerId = useSelector((state) =>
    isDifferentScene ? '' : state.main.preview.lastLoadedContainerId,
  );
  const state = useSelector((state) => state);
  const contentVersion = state.main.experience.content_version as number;
  // If we are currently selecting a scene instead of a container and change
  // the scene, we should reload the last loaded container.
  const containerId = isSceneSelected(elementsSelected)
    ? lastLoadedContainerId
    : selectedContainerId;

  // Reset last loaded container id if scene switched so you don't reload
  // a container that belongs to a different scene.
  useEffect(() => {
    if (isDifferentScene)
      dispatch(
        sliceReducer.actions.update({
          lastLoadedContainerId,
        }),
      );
  }, [dispatch, isDifferentScene, lastLoadedContainerId]);

  useEffect(() => {
    if (
      isPlayerPreviewReady &&
      areAnimationsLoaded &&
      hasAnimationBundleSignedUrl &&
      !isDifferentScene
    ) {
      sendLoadOperationContainer(
        contentVersion,
        containerId,
        operationContainers,
        signedUrls,
        allAssetsByType,
        isSceneReady,
        entityId,
        currentSceneId,
        sceneProperties,
      );
    }
  }, [
    areAnimationsLoaded,
    allAssetsByType,
    containerId,
    contentVersion,
    currentSceneId,
    dispatch,
    entityId,
    isDifferentScene,
    isPlayerPreviewReady,
    isSceneReady,
    hasAnimationBundleSignedUrl,
    operationContainers,
    sceneProperties,
    signedUrls,
  ]);
};

export default useCurrentlySelectedContainerToMessagePlayer;
