import React, { useMemo } from 'react';

import { classNames } from '../utils/helpers';

import styles from './link.module.css';

export type LinkProps = Omit<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  'href'
> & {
  href: string;
};

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  ({ children, className, href, rel, target, ...linkProps }, ref) => {
    const relSecure = useMemo(() => {
      if (target !== '_blank') return rel;

      const relParts = rel?.split(' ') ?? [];
      if (!relParts.includes('noopener')) relParts.push('noopener');
      return relParts.join(' ');
    }, [rel, target]);

    return (
      <a
        {...linkProps}
        className={classNames(styles.link, className)}
        href={href}
        ref={ref}
        rel={relSecure}
        target={target}
      >
        {children}
      </a>
    );
  }
);

Link.displayName = 'Link';

/**
 * This is for href links which direct to a new page
 * For "links" which perform an action but don't redirect, use buttonDressedAsLink instead
 */
export default Link;
