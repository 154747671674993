// Avoiding adding classNames as a dependency unless we need to.. Seems like we shouldn't need a lib for this
export const classNames = (
  ...classes: (
    | string
    | false
    | null
    | undefined
    | { [key: string]: boolean | null | undefined }
  )[]
): string => {
  const validClasses = [];
  for (const c of classes) {
    if (!c) continue;
    switch (typeof c) {
      case 'string':
        validClasses.push(c);
        break;
      case 'object':
        for (const [k, v] of Object.entries(c)) {
          if (v) validClasses.push(k);
        }
        break;
    }
  }
  return validClasses.join(' ');
};
