import { all, call, spawn } from 'typed-redux-saga';

import intelligenceSaga from '../areas/intelligence/state/saga';
import mainSaga from '../areas/main/state/saga';
import assetsSaga from '../areas/assets/state/saga';
import { logToServer } from '../utils/logging';

export default function* saga() {
  const sagas = [intelligenceSaga, mainSaga, assetsSaga];

  // Log and restart individual sagas on crash, without affecting other sagas
  // https://github.com/redux-saga/redux-saga/issues/570
  yield* all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield* call(saga);
            break;
          } catch (e) {
            logToServer('error', 'Saga crashed', {
              error: {
                message: (e as Error).message,
                stack: (e as Error).stack,
              },
              name: saga.name,
            });
          }
        }
      }),
    ),
  );
}
