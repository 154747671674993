import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { withAutosave } from '../../../../utils/middleware';
import { SignedUrlMetadata } from './sliceSaga';

// Enforce typing
export interface PreviewState {
  signedUrls: null | SignedUrlMetadata[];
  isPlayerPreviewReady: boolean;
  isPreviewEnabled: boolean;
  areAllAssetBundleSignedURLsFetched: boolean;
  shouldRemovePresentContainer: boolean;
  attemptedToLoadAScene: boolean;
  isSceneReady: boolean;
  lastLoadedSceneId: string;
  lastLoadedContainerId: string;
  areAnimationsLoaded: boolean;
  isWebVRSupportedOnBrowser: boolean;
  isWebVRModalShowing: boolean;
  fullPreviewExperienceJson: string | null;
  errors: Record<string, string>;
}
export const getInitialPreviewState = () => {
  return {
    signedUrls: null,
    isPlayerPreviewReady: false,
    isPreviewEnabled: true,
    areAllAssetBundleSignedURLsFetched: false,
    shouldRemovePresentContainer: false,
    attemptedToLoadAScene: false,
    isSceneReady: false,
    lastLoadedSceneId: '',
    lastLoadedContainerId: '',
    areAnimationsLoaded: false,
    isWebVRSupportedOnBrowser: false,
    isWebVRModalShowing: false,
    fullPreviewExperienceJson: null,
    errors: {} as Record<string, string>,
  };
};

const initialState: PreviewState = getInitialPreviewState();

type Update = Partial<typeof initialState>;

const sliceReducer = createSlice({
  name: 'main/preview',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Update>) => ({
      ...state,
      ...action.payload,
    }),
    pushSignedUrls: (state, action: PayloadAction<SignedUrlMetadata[]>) => ({
      ...state,
      signedUrls: [...(state.signedUrls ?? []), ...action.payload],
    }),
    updateErrors: (
      state,
      action: PayloadAction<{
        field: string;
        error: unknown;
      }>,
    ) => {
      const { error, field } = action.payload;

      const errorMessage =
        error instanceof Error
          ? error.message
          : typeof error === 'string'
            ? error
            : JSON.stringify(error);

      return {
        ...state,
        errors: {
          ...state.errors,
          [field]: errorMessage,
        },
      };
    },
  },
});

export default withAutosave(sliceReducer);
