import { useEffect } from 'react';
import useSelector from '../useSelector';
import { unityContext } from '../../areas/main/views/preview';
import {
  PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT,
  UnityPreviewAPIMethods,
} from '../../areas/main/state/preview/unityMessages/utils';

const useDeletePresentPreviewContainerToMessagePlayer = () => {
  const { shouldRemovePresentContainer } = useSelector(
    (state) => state.main.preview
  );

  useEffect(() => {
    if (shouldRemovePresentContainer && unityContext.unityInstance) {
      unityContext.send(
        PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT,
        UnityPreviewAPIMethods.OperationContainerDeleted
      );
    }
  }, [shouldRemovePresentContainer]);
};

export default useDeletePresentPreviewContainerToMessagePlayer;
