import { useEffect, useState } from 'react';
import { unityContext } from '../../areas/main/views/preview';
import { IDBPDatabase, openDB } from 'idb';
import { logToServer } from '../../utils/logging';
import useSelector from '../useSelector';

const MicrophoneReactAPI = {
  StartRecording: 'StrivrMicrophoneStartAudioRecording',
  StopRecording: 'StrivrMicrophoneStopAudioRecording',
  CreateAudioUrl: 'StrivrMicrophoneCreateAudioUrl',
  RevokeAudioUrl: 'StrivrMicrophoneRevokeAudioUrl',
  DeleteCachedClips: 'StrivrMicrophoneDeleteCachedAudioClips',
};
const MicrophoneUnityAPI = {
  AudioClipCreated: 'OnAudioClipUrlCreated',
};

const useMicrophoneFromPlayer = () => {
  const DB_NAME = 'Player';
  const DB_VERSION = 1;
  const STORE_NAME = 'LearnerResponses';
  const KEY = 'name';
  const UNITY_MICROPHONE_GAME_OBJECT = 'WebXRMicrophone';

  const [db, setDB] = useState<IDBPDatabase<unknown>>();
  const [recorder, setRecorder] = useState<MediaRecorder>();
  const [chunks, setChunks] = useState<BlobPart[]>([]);
  const [saveAudioName, setSaveAudioName] = useState<string>('');
  const { fullPreviewExperienceJson } = useSelector(
    (state) => state.main.preview,
  );

  // Set up DB
  useEffect(() => {
    const initDB = async () => {
      const openedDB = await openDB(DB_NAME, DB_VERSION, {
        upgrade(upgradedDB) {
          upgradedDB.createObjectStore(STORE_NAME, {
            keyPath: KEY,
          });
        },
      });
      setDB(openedDB);
    };
    initDB().catch((error) => {
      logToServer(
        'error',
        `Failed to initialize Learner Response Player cache: ${error.message}`,
      );
    });
  }, []);

  // Ask for user audio permissions and start recording
  useEffect(() => {
    const startRecording = async () => {
      if (!('mediaDevices' in navigator) || fullPreviewExperienceJson == null) {
        return;
      }

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const tempRecorder = new MediaRecorder(stream);
      setRecorder(tempRecorder);
      tempRecorder.start();
    };
    window.addEventListener(MicrophoneReactAPI.StartRecording, startRecording);
  }, [fullPreviewExperienceJson]);

  // Make sure the recorded data is getting updated and saved correctly after recording stops
  useEffect(() => {
    if (fullPreviewExperienceJson == null) {
      return;
    }

    let tempChunks: BlobPart[] = [];
    const dataAvailable = (audioBlob: BlobEvent) => {
      tempChunks = [...chunks, audioBlob.data];
      setChunks(tempChunks);
    };

    const stop = async () => {
      const wav = new Blob(tempChunks, { type: 'audio/wav; codecs=MS_PCM' });
      setChunks([]);
      const data = {
        name: saveAudioName,
        audio: wav,
      };

      await db?.add(STORE_NAME, data).catch((error) => {
        logToServer(
          'error',
          `Error caching Learner Response data: ${error.message}`,
        );
      });
    };

    const recordingDataAvailable = 'dataavailable';
    const recordingStopped = 'stop';
    recorder?.addEventListener(recordingDataAvailable, dataAvailable);
    recorder?.addEventListener(recordingStopped, stop);

    return () => {
      recorder?.removeEventListener(recordingDataAvailable, dataAvailable);
      recorder?.removeEventListener(recordingStopped, stop);
    };
  }, [recorder, db, chunks, saveAudioName, fullPreviewExperienceJson]);

  // Stop recording. Triggers the recorder 'stop' event which saves the data.
  useEffect(() => {
    if (fullPreviewExperienceJson == null) {
      return;
    }

    const StopRecording = (event: Event) => {
      setSaveAudioName((event as CustomEvent).detail.data);
      if (recorder) {
        recorder.stop();
      }
    };
    window.addEventListener(MicrophoneReactAPI.StopRecording, StopRecording);

    return () => {
      window.removeEventListener(
        MicrophoneReactAPI.StopRecording,
        StopRecording,
      );
    };
  }, [recorder, fullPreviewExperienceJson]);

  // Audio playback requested. Creates a temporary URL to the audio file to send to Unity.
  useEffect(() => {
    if (fullPreviewExperienceJson == null) {
      return;
    }

    const CreateAudioURL = async (event: Event) => {
      const requestedAudioName = (event as CustomEvent)?.detail.data;
      const savedData = await db
        ?.get(STORE_NAME, requestedAudioName)
        .catch((error) => {
          logToServer(
            'error',
            `Failed to get requested audio URL from cache: ${error.message}`,
          );
        });
      let wavUrl = '';

      if (savedData) {
        const wavBlob = savedData.audio;
        wavUrl = URL.createObjectURL(wavBlob);
      }

      // Even if we failed to retrieve the data from the db, we still need to send a message
      // back to Unity so it doesn't continue to wait for a response. An empty string should
      // be sent in this case.
      if (unityContext.unityInstance) {
        unityContext.send(
          UNITY_MICROPHONE_GAME_OBJECT,
          MicrophoneUnityAPI.AudioClipCreated,
          wavUrl,
        );
      }
    };
    window.addEventListener('StrivrMicrophoneCreateAudioUrl', CreateAudioURL);

    return () => {
      window.removeEventListener(
        'StrivrMicrophoneCreateAudioUrl',
        CreateAudioURL,
      );
    };
  }, [db, fullPreviewExperienceJson]);

  // Revokes temporary audio url after the requester is done with it.
  useEffect(() => {
    if (fullPreviewExperienceJson == null) {
      return;
    }

    const RevokeAudioURL = (event: Event) => {
      const wavUrl = (event as CustomEvent)?.detail.data;
      URL.revokeObjectURL(wavUrl);
    };
    window.addEventListener(MicrophoneReactAPI.RevokeAudioUrl, RevokeAudioURL);

    return () => {
      window.removeEventListener(
        'StrivrMicrophoneRevokeAudioUrl',
        RevokeAudioURL,
      );
    };
  }, [fullPreviewExperienceJson]);

  // Delete any cached audio clips to reduce memory usage on client's device.
  useEffect(() => {
    if (fullPreviewExperienceJson == null) {
      return;
    }

    const DeleteCachedAudioClips = async () => {
      await db?.clear(STORE_NAME);
    };
    window.addEventListener(
      MicrophoneReactAPI.DeleteCachedClips,
      DeleteCachedAudioClips,
    );

    return () => {
      window.removeEventListener(
        MicrophoneReactAPI.DeleteCachedClips,
        DeleteCachedAudioClips,
      );
    };
  }, [db, fullPreviewExperienceJson]);
};

export default useMicrophoneFromPlayer;
