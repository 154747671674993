import { useEffect } from 'react';
import { logToServer } from '../../utils/logging';
import { unityContext } from '../../areas/main/views/preview';
import sliceReducer from '../../areas/main/state/preview/sliceReducer';
import useDispatch from '../useDispatch';
import { UnityPreviewEvents } from '../../areas/main/state/preview/unityMessages/utils';

const useErrorMessageFromPlayer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    unityContext.on(UnityPreviewEvents.OnReportWebError, (msg: string) => {
      dispatch(
        sliceReducer.actions.updateErrors({
          field: 'unityError',
          error: msg,
        }),
      );
      logToServer(msg.startsWith('warning') ? 'warn' : 'error', msg);
    });
    // On Error is emitted while the Unity Player runs into an error. This is most likely a runtime error.
    // Do not show these errors right now because sometimes they are actually warnings and are not at risk of
    // breaking preview.
    unityContext.on('error', (msg: string) => {
      logToServer(msg.startsWith('warning') ? 'warn' : 'error', msg);
    });
  }, [dispatch]);
};

export default useErrorMessageFromPlayer;
