// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NhrLxiK_Nqn-Af1Nz9-F0 {\n    font-size: 4.8rem;\n    padding-bottom: 32px;\n    padding-top: 32px;\n    text-align: center;\n}\n\n._3wVqMxqCp3Sx7LoALShmHv {\n    font-size: 1.8rem;\n    margin-top: 16px;\n    text-align: center;\n}", ""]);
// Exports
exports.locals = {
	"heading": "NhrLxiK_Nqn-Af1Nz9-F0",
	"description": "_3wVqMxqCp3Sx7LoALShmHv"
};
module.exports = exports;
