import { ButtonUndressed, Modal } from '@strivr/ui';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import usePreviewPlayableContainerId from '../../../hooks/usePreviewPlayableContainerId';
import useSelector from '../../../hooks/useSelector';
import { logToServer } from '../../../utils/logging';
import { sendLoadOperationContainer } from '../../../utils/preview';
import { getCurrentlySelectedSceneId } from '../state/preview/utils';
import { unityContext } from '../views/preview';

import InfoIcon from '../../../../res/assets/images/info.svg';
import PauseIcon from '../../../../res/assets/images/preview/pause.svg';
import PlayIcon from '../../../../res/assets/images/preview/play.svg';
import PlayDisabledIcon from '../../../../res/assets/images/preview/playDisabled.svg';
import RestartIcon from '../../../../res/assets/images/preview/restart.svg';

import { filterNodes } from '../../../utils/node';
import {
  PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT,
  UnityPreviewAPIMethods,
  UnityPreviewEvents,
} from '../state/preview/unityMessages/utils';
import styles from './previewControls.module.css';
import VrToggleButton from './vrToggleButton';
import useDispatch from '../../../hooks/useDispatch';
import sliceReducer from '../state/preview/sliceReducer';
import sliceSaga from '../state/preview/sliceSaga';
import { useAllAssetVersionsByType } from '../../../hooks/useOldAssetVersions';

interface PreviewControlsProps {
  pageRef: React.RefObject<HTMLElement>;
}

const PreviewControls: React.FC<PreviewControlsProps> = ({ pageRef }) => {
  const dispatch = useDispatch();
  const playableContainerId = usePreviewPlayableContainerId();

  const { nodes, operationContainers, sceneProperties } = useSelector(
    (state) => state.main,
  );
  const { fullPreviewExperienceJson, signedUrls } = useSelector(
    (state) => state.main.preview,
  );
  const allAssetsByType = useAllAssetVersionsByType();
  const { elementsSelected } = useSelector((state) => state.main.nodes);
  const { scenes } = useSelector((state) => state.main.sceneProperties);
  const currentSceneId = getCurrentlySelectedSceneId(elementsSelected, scenes);
  const { isSceneReady } = useSelector((state) => state.main.preview);
  const entityId = useSelector(
    (state) =>
      state.main.sceneProperties.scenes[currentSceneId]?.characterVersionId,
  );
  const state = useSelector((state) => state);
  const contentVersion = state.main.experience.content_version as number;

  const isShowingFullPreview = fullPreviewExperienceJson !== null;
  const [playingFullExperience, setPlayingFullExperience] =
    useState(isShowingFullPreview);
  const [playingContainerId, setPlayingContainerId] = useState(
    null as string | null,
  );

  useEffect(() => {
    setPlayingContainerId(null);
    setPlayingFullExperience(!!fullPreviewExperienceJson);
  }, [fullPreviewExperienceJson]);

  useEffect(() => {
    unityContext.on(UnityPreviewEvents.OnPlayerPaused, () => {
      setPlayingContainerId(null);
      setPlayingFullExperience(false);
    });
    unityContext.on(UnityPreviewEvents.OnPlayerResumed, () => {
      if (isShowingFullPreview) setPlayingFullExperience(true);
      else setPlayingContainerId(playableContainerId);
    });
  }, [isShowingFullPreview, playableContainerId]);

  useEffect(() => {
    // Pause playback if playing container becomes unselected
    if (
      playingContainerId &&
      !nodes.elementsSelected.some((e) => e.id === playingContainerId) &&
      unityContext.unityInstance
    ) {
      unityContext.send(
        PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT,
        UnityPreviewAPIMethods.Pause,
      );
    }
  }, [playingContainerId, nodes.elementsSelected]);

  const handlePlay = () => {
    if (unityContext.unityInstance) {
      unityContext.send(
        PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT,
        UnityPreviewAPIMethods.Play,
      );
    }
  };

  const handlePause = () => {
    if (unityContext.unityInstance) {
      unityContext.send(
        PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT,
        UnityPreviewAPIMethods.Pause,
      );
    }
  };

  const handleRestart = () => {
    if (isShowingFullPreview) {
      dispatch(sliceSaga.actions.restartPreviewExperience());
      return;
    }

    const { elementsSelected } = nodes;
    const nodesSelected = filterNodes(elementsSelected);
    if (nodesSelected.length !== 1) {
      logToServer('warn', 'Tried to restart preview without valid container', {
        nodesSelected,
        playableContainerId,
        playingContainerId,
      });
      return;
    }

    if (unityContext.unityInstance) {
      unityContext.send(
        PREVIEW_WINDOW_BEHAVIOUR_UNITY_GAME_OBJECT,
        UnityPreviewAPIMethods.Pause,
      );
    } else {
      return;
    }

    const selectedContainerId =
      nodesSelected[0].type === 'stack'
        ? nodesSelected[0].id
        : nodesSelected[0].data?.containerId;
    if (selectedContainerId)
      sendLoadOperationContainer(
        contentVersion,
        selectedContainerId,
        operationContainers,
        signedUrls,
        allAssetsByType,
        isSceneReady,
        entityId,
        currentSceneId,
        sceneProperties,
      );
  };

  const { isWebVRModalShowing } = useSelector((state) => state.main.preview);
  const handleWebVRModal = () => {
    dispatch(
      sliceReducer.actions.update({
        isWebVRModalShowing: !isWebVRModalShowing,
      }),
    );
  };

  const webVRInstructions = [
    'On your PC, download and install the Oculus app.',
    'In the Oculus app settings, make sure Oculus is set as the active OpenXR Runtime.',
    'Pair your PC to your Quest headset (via cable or AirLink).',
    'In the headset, select Quick Settings at the bottom left of the display (the area with the clock).',
    'In the headset, Select Quest Link. Select your linked headset and select Launch.',
    'In Strivr Creator, click the VR headset icon. The experience should display in your headset. Your browser may ask you to allow the page to launch in VR.',
  ];
  const headsetNotConnectedCopy =
    'NOTE: Your headset is not connected to your computer. Follow these instructions and then relaunch Strivr Creator.';
  const { isWebVRSupportedOnBrowser } = useSelector(
    (state) => state.main.preview,
  );

  return (
    <div className={styles.controlBar}>
      <div className={styles.controlBar_section}>
        {!fullPreviewExperienceJson && (
          <ButtonUndressed
            className={classNames(
              styles.controlBar_button,
              styles.playPauseRestart_button,
              {
                [styles.controlBar_button__hidden]:
                  !playingContainerId && !playingFullExperience,
              },
            )}
            aria-label="Restart button"
            onClick={handleRestart}
          >
            <RestartIcon />
          </ButtonUndressed>
        )}
      </div>
      <div className={styles.controlBar_section}>
        {playingContainerId || playingFullExperience ? (
          <ButtonUndressed
            className={classNames(
              styles.controlBar_button,
              styles.playPauseRestart_button,
            )}
            aria-label="Pause button"
            onClick={handlePause}
          >
            <PauseIcon />
          </ButtonUndressed>
        ) : playableContainerId || isShowingFullPreview ? (
          <ButtonUndressed
            className={classNames(
              styles.controlBar_button,
              styles.playPauseRestart_button,
            )}
            aria-label="Play button"
            onClick={handlePlay}
          >
            <PlayIcon />
          </ButtonUndressed>
        ) : (
          <ButtonUndressed
            className={classNames(
              styles.controlBar_button,
              styles.playPauseRestart_button,
            )}
            disabled
          >
            <PlayDisabledIcon />
          </ButtonUndressed>
        )}
      </div>
      <div className={styles.controlBar_section}>
        <div className={styles.vrHeadset_spacing}>
          <VrToggleButton />
        </div>
        <ButtonUndressed onClick={handleWebVRModal}>
          <div
            className={classNames(
              styles.controlBar_button,
              styles.vrInfo_spacing,
            )}
          >
            <InfoIcon />
          </div>
        </ButtonUndressed>
        <Modal
          coverRef={pageRef}
          buttonSize="small"
          onClose={handleWebVRModal}
          onConfirm={handleWebVRModal}
          show={isWebVRModalShowing}
          title="Using WebVR with Strivr Creator"
          confirmText="Close"
        >
          {!isWebVRSupportedOnBrowser && (
            <div className={styles.instruction}>{headsetNotConnectedCopy}</div>
          )}
          <ol>
            {webVRInstructions.map((copy, idx) => {
              return (
                <li key={idx} className={styles.instruction}>
                  {copy}
                </li>
              );
            })}
          </ol>
        </Modal>
      </div>
    </div>
  );
};

export default React.memo(PreviewControls);
