import { Slice, SliceCaseReducers } from '@reduxjs/toolkit';
import { Slice as SagaSlice, SliceCaseSagas } from 'redux-toolkit-saga';

const autosaveActions: string[] = [];
const autologActions: string[] = [];
const undoActions: string[] = [];

export const getAutosaveActions = () => autosaveActions;
export const getAutologActions = () => autologActions;
export const getUndoActions = () => undoActions;

const generateReducerWrapper =
  (actions: string[]) =>
  <State, CaseReducers extends SliceCaseReducers<State>>(
    slice: Slice<State, CaseReducers, string>,
  ) => {
    for (const action of Object.keys(slice.actions))
      actions.push(`${slice.name}/${action}`);
    return slice;
  };

const generateSagaWrapper =
  (actions: string[]) =>
  <CaseSagas extends SliceCaseSagas>(slice: SagaSlice<CaseSagas, string>) => {
    for (const action of Object.keys(slice.actions))
      actions.push(`${slice.name}/${action}`);
    return slice;
  };

export const withAutosave = generateReducerWrapper(autosaveActions);
export const withAutolog = generateReducerWrapper(autologActions);
export const withUndo = generateReducerWrapper(undoActions);
export const withUndoSaga = generateSagaWrapper(undoActions);
