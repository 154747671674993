import { Action } from 'redux';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { put, select } from 'typed-redux-saga';

import { ExperienceRecord, UndoRecord } from '../../types/json';
import sliceReducer from './sliceReducer';
import { State } from '../../../../state/reducer';
import experienceSaga, {
  mapDisplayStateToJSON,
  mapExperienceStateToJSON,
  mapPortalStateToJSON,
} from '../experience/sliceSaga';

const sliceSaga = createSliceSaga({
  name: sliceReducer.name,
  caseSagas: {
    undo: {
      sagaType: SagaType.TakeLatest,
      *fn(action: Action) {
        const state: State = yield* select();
        if (state.main.undo.undoStack.length < 1) return;

        const currentRecord: UndoRecord = {
          actionType: action.type,
          displayData: mapDisplayStateToJSON(state),
          experience: mapExperienceStateToJSON(state),
          portalData: mapPortalStateToJSON(state),
        };
        const recordToRestore: ExperienceRecord = {
          displayData: state.main.undo.undoStack[0].displayData,
          experience: state.main.undo.undoStack[0].experience,
          portalData: mapPortalStateToJSON(state),
        };

        yield* put(
          sliceReducer.actions.update({
            redoStack: [currentRecord, ...state.main.undo.redoStack],
            undoStack: state.main.undo.undoStack.slice(1),
          })
        );

        yield* put(
          experienceSaga.actions.restoreExperienceFromRecord({
            errorKey: 'undo',
            experienceRecord: recordToRestore,
            preserveSelection: true,
          })
        );
      },
    },

    redo: {
      sagaType: SagaType.TakeLatest,
      *fn(action: Action) {
        const state: State = yield* select();
        if (state.main.undo.redoStack.length < 1) return;

        const currentRecord: UndoRecord = {
          actionType: action.type,
          displayData: mapDisplayStateToJSON(state),
          experience: mapExperienceStateToJSON(state),
          portalData: mapPortalStateToJSON(state),
        };
        const recordToRestore: ExperienceRecord = {
          displayData: state.main.undo.redoStack[0].displayData,
          experience: state.main.undo.redoStack[0].experience,
          portalData: mapPortalStateToJSON(state),
        };

        yield* put(
          sliceReducer.actions.update({
            redoStack: state.main.undo.redoStack.slice(1),
            undoStack: [currentRecord, ...state.main.undo.undoStack],
          })
        );

        yield* put(
          experienceSaga.actions.restoreExperienceFromRecord({
            errorKey: 'redo',
            experienceRecord: recordToRestore,
            preserveSelection: true,
          })
        );
      },
    },
  },
});

export default sliceSaga;
