import React from 'react';

import { classNames } from '../utils/helpers';

import styles from './textInput.module.css';

interface TextInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  error?: string | null;
  type?: string;
}

const TextInput: React.FC<TextInputProps> = ({
  className,
  error,
  type,
  ...textInputProps
}) => {
  return (
    <span className={classNames(styles.root, className)}>
      <input
        {...textInputProps}
        aria-invalid={!!error || textInputProps['aria-invalid']}
        className={classNames(styles.input, {
          [styles.input__invalid]: !!error,
        })}
        type={type || 'text'}
      />
      <span className={styles.error}>{error}</span>
    </span>
  );
};

export default TextInput;
