import { Connection, Edge, Node } from 'react-flow-renderer';
import {
  AnimationOperationData,
  CharacterLineOperationData,
  LearnerResponseOperationData,
  MultipleChoiceQuestionData,
  NodeViewActivityData,
  NotificationOperation,
  PlaybackNodeOperationData,
  PlaybackPhaseOperationData,
  QuestionTextPlacardData,
} from '@strivr/player-models';

import { getInitialExperience } from './sliceReducer';
import { getInitialNodeState } from '../nodes/sliceReducer';
import { getInitialSceneState } from '../sceneProperties/sliceReducer';
import { getInitialOperationsState } from '../operationContainers/sliceReducer';

import { Asset, SceneProperties } from '../../types/models';
import {
  Branchable,
  DisplayData,
  ExperienceRecord,
  MultipleOutputOperationJSON,
  OverlayTypeNames,
  PortalData,
  SingleOutputOperationJSON,
} from '../../types/json';
import { NodeData } from '../../types/nodeData';
import {
  AnimationOperation,
  CharacterLineOperation,
  LearnerResponseOperation,
  McqOperation,
  Operation,
  OperationContainer,
  OverlayOperation,
  PlaybackOperation,
  PlaybackPhaseOperation,
  VideoOperation,
} from '../../types/operations';
import {
  McqQuestionType,
  OperationOutputType,
  OperationType,
  overlayTypeFullyQualifiedNameToType,
} from '../../enums/models';
import {
  allFrameworks,
  CONTENT_VERSION_FLAGS,
  IN_HANDLE_PREFIX,
  INITIAL_SCENE_NODE_HEIGHT,
  INITIAL_SCENE_NODE_WIDTH,
  OUT_HANDLE_PREFIX,
} from '../../constants';
import { emptyGuid, generateGuid } from '../../../../utils/id';
import { logToServer } from '../../../../utils/logging';
import { checkContentVersion } from '../../../../utils/contentVersion';
import { VideoOperationData } from '@strivr/player-models/lib/types/VideoOperationData';

const experienceInitialState = getInitialExperience();
const nodeInitialState = getInitialNodeState();
const scenePropertiesInitialState = getInitialSceneState();
const operationContainersInitialState = getInitialOperationsState();

interface ExperienceJSONToStateData {
  newExperienceData: typeof experienceInitialState;
  newNodesData: typeof nodeInitialState;
  newScenePropertiesData: typeof scenePropertiesInitialState;
  newOperationContainersData: typeof operationContainersInitialState;
}
interface ExperienceJSONToStateDataOrErrors {
  data: ExperienceJSONToStateData | null;
  error: string | null;
}

export const mapOperationTypeToNodeType = (operationType: OperationType) => {
  switch (operationType) {
    case OperationType.Animation:
      return 'animation';
    case OperationType.CharacterLine:
      return 'character line';
    case OperationType.MultipleChoiceQuestion:
      return 'mcq';
    case OperationType.NotificationDialog2:
      return 'overlay';
    case OperationType.PlaybackPhase:
      return 'playback phase';
    case OperationType.LearnerResponse:
      return 'learner response';
    case OperationType.PlaybackNode:
      return 'playback';
    case OperationType.Video:
      return 'video';
    default:
      throw new Error(
        `Failed mapping unknown operation type "${operationType}"`,
      );
  }
};

const mapExperienceDataToState = (
  experienceJSON: NodeViewActivityData.NodeViewActivityData,
  portalData: PortalData,
  idMapping?: Record<string, string>,
  newExperienceName?: string,
  experienceVersionId?: string,
): typeof experienceInitialState => {
  return {
    ...experienceInitialState,
    id: idMapping ? generateGuid() : experienceJSON.activity_id!,
    version: experienceJSON.version_number!,
    versionId:
      typeof experienceJSON.version_id === 'string'
        ? experienceJSON.version_id
        : experienceVersionId ?? null,
    name: experienceJSON.name
      ? idMapping
        ? newExperienceName ?? ''
        : experienceJSON.name
      : '',
    dateCreated: idMapping
      ? new Date().toISOString()
      : experienceJSON.date_created ?? '',
    lastModified: idMapping
      ? new Date().toISOString()
      : experienceJSON.version_date!,
    startingSceneId: experienceJSON.starting_scene_id
      ? idMapping
        ? idMapping[experienceJSON.starting_scene_id]
        : experienceJSON.starting_scene_id
      : null,
    startingOperationContainerId: experienceJSON.starting_container_id
      ? idMapping
        ? idMapping[experienceJSON.starting_container_id]
        : experienceJSON.starting_container_id
      : null,
    content_version: experienceJSON.content_version,
    description: portalData?.description || '',
    durationInMinutes: portalData?.durationInMinutes || 0,
    image: experienceJSON.image
      ? {
          id: experienceJSON.image.asset_id!,
          name: experienceJSON.image.name ?? '',
          assetType: 'image',
        }
      : null,
    animationBundle: experienceJSON.vh_animation_bundle
      ? {
          id: experienceJSON.vh_animation_bundle.asset_id!,
          name: experienceJSON.vh_animation_bundle.name ?? '',
          assetType: checkContentVersion(
            experienceJSON.content_version,
            CONTENT_VERSION_FLAGS.genericRig,
          )
            ? 'generic-animation-assetbundle'
            : 'animation-assetbundle',
        }
      : null,
  };
};

const mapNodeDataToState = (
  experienceJSON: NodeViewActivityData.NodeViewActivityData,
  displayData: DisplayData,
  idMapping?: Record<string, string>,
): typeof nodeInitialState => {
  const scenes = experienceJSON.scenes ?? [];
  const nodes: Node<NodeData>[] = [];
  const edges: (Connection | Edge)[] = [];

  const allOperationContainers = scenes.reduce(
    (acc, cur) => [...acc, ...(cur.operation_containers ?? [])],
    [] as NodeViewActivityData.OperationContainerData[],
  );

  for (const scene of scenes) {
    const sceneDisplayData = displayData.scenes[scene.id!];

    nodes.push({
      data: {
        height: sceneDisplayData?.height ?? INITIAL_SCENE_NODE_HEIGHT,
        width: sceneDisplayData?.width ?? INITIAL_SCENE_NODE_WIDTH,
      },
      id: idMapping ? idMapping[scene.id!] : scene.id!,
      position: {
        x: sceneDisplayData?.x ?? 100,
        y: sceneDisplayData?.y ?? 100,
      },
      type: 'scene',
    });

    if (!sceneDisplayData) {
      logToServer(
        'warn',
        `Corresponding display data not found for scene. Default display data set. Scene: ${scene.name} ${scene.id}`,
      );
    }

    const operationContainers = scene.operation_containers ?? [];
    for (const operationContainer of operationContainers) {
      const addEdges = (output: Branchable, mcqOpRid?: string) => {
        const nextOperationContainer =
          output.does_next_node_exist &&
          allOperationContainers.find((oc) => oc.id === output.next_id);
        if (nextOperationContainer) {
          const nextNodeId =
            nextOperationContainer.operations?.length === 1
              ? nextOperationContainer.operations[0].rid
                ? idMapping
                  ? idMapping[nextOperationContainer.operations[0].rid]
                  : nextOperationContainer.operations[0].rid
                : null
              : idMapping
                ? idMapping[nextOperationContainer.id!]
                : nextOperationContainer.id!;
          edges.push({
            source:
              (operationContainer.operations?.length ?? 0) > 1
                ? idMapping
                  ? idMapping[operationContainer.id!]
                  : operationContainer.id!
                : mcqOpRid
                  ? idMapping
                    ? idMapping[mcqOpRid]
                    : mcqOpRid
                  : output.rid
                    ? idMapping
                      ? idMapping[output.rid]
                      : output.rid
                    : null,
            sourceHandle: mcqOpRid
              ? `${OUT_HANDLE_PREFIX}${
                  idMapping ? idMapping[mcqOpRid] : mcqOpRid
                }_${idMapping ? idMapping[output.rid!] : output.rid}`
              : `${OUT_HANDLE_PREFIX}${
                  idMapping ? idMapping[output.rid!] : output.rid
                }`,
            target: nextNodeId,
            targetHandle: `${IN_HANDLE_PREFIX}${nextNodeId}`,
            type: 'customEdge',
          });
        }
      };

      const addAllOperationEdges = (
        operation: NodeViewActivityData.OperationData,
      ) => {
        if (
          (operation as MultipleOutputOperationJSON).mcq_type ===
          McqQuestionType.Branching
        ) {
          for (const answer of (operation as MultipleOutputOperationJSON)
            ?.answers ?? []) {
            const newAnswer = idMapping
              ? {
                  ...answer,
                  rid: idMapping[answer.rid!],
                }
              : answer;
            addEdges(
              newAnswer,
              operation.rid
                ? idMapping
                  ? idMapping[operation.rid]
                  : operation.rid
                : undefined,
            );
          }
        } else if (
          (operation as MultipleOutputOperationJSON).mcq_type ===
          McqQuestionType.Regular
        ) {
          const { does_next_node_exist, is_activity_finished, next_id } =
            (operation as MultipleOutputOperationJSON).answers?.[0] ?? {};
          addEdges({
            ...(operation as SingleOutputOperationJSON),
            does_next_node_exist,
            is_activity_finished,
            next_id,
          });
        } else {
          addEdges(operation as SingleOutputOperationJSON);
        }
      };

      if (operationContainer.operations?.length === 1) {
        const operation = operationContainer.operations[0];
        if (!operation) throw new Error(`Operation not found`);
        if (!operation.rid) throw new Error(`Rid for operation was null`);
        if (!displayData.nodes[operation.rid])
          logToServer(
            'warn',
            `Corresponding display data not found for operation. Default display data set. Operation: ${operation.name} ${operation.id}`,
          );
        const operationDisplayData = displayData.nodes[operation.rid] ?? {
          x: 0,
          y: 0,
        };
        const type = mapOperationTypeToNodeType(
          /*
           * While the `Operations` type and `OperationType` are functionally the same,
           * they are declared in different codebases and `OperationType` is an enum.
           * Since `mapOperationTypeToNodeType()` has a default case if the type is
           * undefined, unknown, or unhandled, it should be safe to just cast here.
           */
          operation.type as OperationType,
        );
        nodes.push({
          data: {
            containerId: idMapping
              ? idMapping[operationContainer.id!]
              : operationContainer.id,
            height: operationDisplayData.height,
            width: operationDisplayData.width,
          },
          id: idMapping ? idMapping[operation.rid] : operation.rid,
          parentId: idMapping ? idMapping[scene.id!] : scene.id,
          position: {
            x: operationDisplayData.x,
            y: operationDisplayData.y,
          },
          type,
        });
        addAllOperationEdges(operation);
      } else if ((operationContainer.operations?.length ?? 0) > 1) {
        if (!displayData.nodes[operationContainer.id!])
          logToServer(
            'warn',
            `Corresponding display data not found for stack. Default display data set. OperationContainer: ${operationContainer.name} ${operationContainer.id}`,
          );
        const stackDisplayData = displayData.nodes[operationContainer.id!] ?? {
          x: 0,
          y: 0,
        };
        nodes.push({
          data: {
            height: stackDisplayData.height,
            items:
              operationContainer.operations?.map((operation) => {
                const { rid } = operation;
                if (!rid) throw new Error(`Rid for operation was null`);
                const nodeType = mapOperationTypeToNodeType(
                  /*
                   * While the `Operations` type and `OperationType` are functionally the same,
                   * they are declared in different codebases and `OperationType` is an enum.
                   * Since `mapOperationTypeToNodeType()` has a default case if the type is
                   * undefined, unknown, or unhandled, it should be safe to just cast here.
                   */
                  operation.type as OperationType,
                );
                return {
                  data: {
                    containerId: idMapping
                      ? idMapping[operationContainer.id!]
                      : operationContainer.id!,
                  },
                  id: idMapping ? idMapping[rid] : rid,
                  nodeType,
                };
              }) ?? [],
            width: stackDisplayData.width,
          },
          id: idMapping
            ? idMapping[operationContainer.id!]
            : operationContainer.id!,
          parentId: idMapping ? idMapping[scene.id!] : scene.id,
          position: {
            x: stackDisplayData.x,
            y: stackDisplayData.y,
          },
          type: 'stack',
        });

        for (const operation of operationContainer.operations ?? []) {
          addAllOperationEdges(operation);
        }
      }
    }
  }

  return {
    ...nodeInitialState,
    edgesPendingAdd: edges,
    elements: nodes,
  };
};

const mapScenePropertiesToState = (
  experienceJSON: NodeViewActivityData.NodeViewActivityData,
  idMapping?: Record<string, string>,
): typeof scenePropertiesInitialState => {
  const sceneMapper = (
    scenes: NodeViewActivityData.NodeViewSceneData[],
  ): Record<string, SceneProperties> => {
    const mappedOperationContainersToScene = (
      operationContainers: NodeViewActivityData.OperationContainerData[],
    ): string[] => {
      return operationContainers.map((operationContainer) =>
        idMapping ? idMapping[operationContainer.id!] : operationContainer.id!,
      );
    };

    let mappedScenes = {} as Record<string, SceneProperties>;
    for (const scene of scenes) {
      const sceneId = idMapping ? idMapping[scene.id!] : scene.id!;

      const characters =
        scene.characters
          ?.filter((c) => c.entity_asset)
          .map((c) => c.entity_asset!.asset_id) ?? [];
      const sceneData: SceneProperties = {
        id: sceneId,
        environments:
          scene.environments?.map((e) => ({
            id: e.id ?? generateGuid(),
            use360Sphere: e.use_three_sixty_sphere ?? false,
            environmentAssetVersionId: e.environment_asset?.asset_id ?? null,
            bgAudioAssetVersionId: e.sound_asset?.asset_id ?? null,
            bgVideoAssetVersionId: e.environment_video?.asset_id ?? null,
          })) ?? [],
        characterVersionId: characters.length > 0 ? characters[0]! : '', // This is an array in the JSON, but we only have a single character right now
        operationContainerIds: mappedOperationContainersToScene(
          scene.operation_containers ?? [],
        ),
        startingAnimationNameForCharacter: scene.characters
          ? scene.characters.map(
              (character) => character.starting_animation_name ?? '',
            )[0]
          : '',
        characterStance: scene.characters
          ? scene.characters.map(
              (character) => character.starting_legs_idle_animation_name ?? '',
            )[0]
          : '',
        startingBodyLanguage: scene.characters
          ? scene.characters.map(
              (character) => character.starting_arms_idle_animation_name ?? '',
            )[0]
          : '',
        startingFacialExpression: scene.characters
          ? scene.characters.map(
              (character) => character.starting_face_idle_animation_name ?? '',
            )[0]
          : '',
        name: scene.name ?? '',
        avatarVersionId: scene.self_avatar?.entity_asset?.asset_id ?? '',
        cameraPosition: {
          x: scene.user_camera_position?.x ?? null,
          y: scene.user_camera_position?.y ?? null,
          z: scene.user_camera_position?.z ?? null,
        },
        cameraRotation: {
          x: scene.user_camera_rotation?.x ?? null,
          y: scene.user_camera_rotation?.y ?? null,
          z: scene.user_camera_rotation?.z ?? null,
        },
        characterPosition: scene.characters?.map((character) => ({
          x: character.transform_data?.position_data?.x ?? null,
          y: character.transform_data?.position_data?.y ?? null,
          z: character.transform_data?.position_data?.z ?? null,
        }))?.[0] ?? { x: null, y: null, z: null },
        characterRotation: scene.characters?.map((character) => ({
          x: character.transform_data?.rotation_data?.x ?? null,
          y: character.transform_data?.rotation_data?.y ?? null,
          z: character.transform_data?.rotation_data?.z ?? null,
        }))?.[0] ?? { x: null, y: null, z: null },
        characterScale: scene.characters?.map((character) => ({
          x: character.transform_data?.scale_data?.x ?? null,
          y: character.transform_data?.scale_data?.y ?? null,
          z: character.transform_data?.scale_data?.z ?? null,
        }))?.[0] ?? { x: null, y: null, z: null },
      };
      mappedScenes = { ...mappedScenes, [sceneId]: sceneData };
    }
    return mappedScenes;
  };

  const newSceneProperties: typeof scenePropertiesInitialState = {
    ...scenePropertiesInitialState,
    scenes: sceneMapper(experienceJSON.scenes ?? []),
  };
  return newSceneProperties;
};

const mapOperationContainersToState = (
  experienceJSON: NodeViewActivityData.NodeViewActivityData,
  idMapping?: Record<string, string>,
): typeof operationContainersInitialState => {
  let containers = {} as Record<string, OperationContainer>;
  let operations = {} as Record<string, Operation>;

  for (const scene of experienceJSON.scenes ?? []) {
    for (const operationContainer of scene.operation_containers ?? []) {
      /**
       * Map containers
       */
      let container = {} as OperationContainer;
      const operationContainerId = idMapping
        ? idMapping[operationContainer.id!]
        : operationContainer.id!;
      const inputIds = operationContainer.inputs
        ? idMapping
          ? operationContainer.inputs.map((id) => idMapping[id])
          : operationContainer.inputs
        : [];

      container = {
        id: operationContainerId,
        inputIds,
        operationIds:
          operationContainer.operations
            ?.filter((op) => op.rid)
            .map((op) => (idMapping ? idMapping[op.rid!] : op.rid!)) ?? [],
      };

      containers = {
        ...containers,
        [container.id]: container,
      };

      /**
       * Map operations
       */
      for (const operation of operationContainer.operations ?? []) {
        if (!operation.rid) throw new Error(`Rid for operation was null`);
        const operationRid = idMapping
          ? idMapping[operation.rid!]
          : operation.rid;
        switch (operation.type) {
          case OperationType.NotificationDialog2: {
            const o = operation as NotificationOperation.NotificationOperation;
            if (!o.placard)
              throw new Error(`Placard for operation ${o.rid} was null`);

            const overlay: OverlayOperation = {
              id: operationRid,
              name: o.name ?? '',
              containerId: idMapping
                ? idMapping[operationContainerId!]
                : operationContainerId!,
              type: OperationType.NotificationDialog2,
              position: {
                x: o.theta ?? null,
                y: o.phi ?? null,
                z: o.rho ?? null,
              },
              nextId: o.next_id
                ? idMapping
                  ? idMapping[o.next_id]
                  : o.next_id
                : null,
              doesNextNodeExist: o.does_next_node_exist!,
              maxDuration: o.max_duration ?? null,
              isActivityFinished: o.is_activity_finished!,
              isOutputEnabled:
                o.is_activity_finished || o.does_next_node_exist!,
              overlayType: overlayTypeFullyQualifiedNameToType(
                o.placard.fully_qualified_type_name as OverlayTypeNames,
              ),
              title:
                o.placard.fully_qualified_type_name ===
                'Strivr.Player.Model.QuestionTextPlacardData'
                  ? (
                      o.placard as QuestionTextPlacardData.QuestionTextPlacardData
                    ).text ?? ''
                  : o.placard.title ?? '',
              description: o.placard.body ?? '',
              image: o.placard.image
                ? ({
                    id: o.placard.image.asset_id,
                    name: o.placard.image.name,
                    assetType: 'image',
                  } as Asset)
                : null,
              audio: o.placard.audio
                ? ({
                    id: o.placard.audio.asset_id,
                    name: o.placard.audio.name,
                    assetType: 'sound',
                  } as Asset)
                : null,
              showButton: o.placard.show_button!,
              delayStartTime: o.delay_start_time ?? null,
              endTime: null,
              outputType: OperationOutputType.SingleOutput,
            };

            operations = {
              ...operations,
              [overlay.id]: overlay,
            };

            break;
          }
          case OperationType.Animation: {
            const o =
              operation as AnimationOperationData.AnimationOperationData;

            const animation: AnimationOperation = {
              id: operationRid,
              name: o.name ?? '',
              containerId: operationContainerId,
              type: OperationType.Animation,
              nextId: o.next_id
                ? idMapping
                  ? idMapping[o.next_id]
                  : o.next_id
                : null,
              doesNextNodeExist: o.does_next_node_exist!,
              isActivityFinished: o.is_activity_finished!,
              isOutputEnabled:
                o.is_activity_finished || o.does_next_node_exist!,
              characterIndex: o.character_id!,
              // Animation is a legacy field used
              // by D1. In C3 we'll be relying
              // on delay time set at the operation
              // level so we can always hardcode
              // start time to 0 in the JSON.
              animation: {
                clipName: o.vh_animation?.clip_name ?? '',
                startTime: 0,
              },
              idle: {
                clipName: o.vh_animation_idle?.clip_name ?? '',
                startTime: 0,
              },
              delayStartTime: o.delay_start_time ?? null,
              endTime: null,
              outputType: OperationOutputType.SingleOutput,
            };

            operations = {
              ...operations,
              [animation.id]: animation,
            };

            break;
          }
          case OperationType.CharacterLine: {
            const o =
              operation as CharacterLineOperationData.CharacterLineOperationData;

            const characterLine: CharacterLineOperation = {
              id: operationRid,
              name: o.name ?? '',
              containerId: operationContainerId,
              type: OperationType.CharacterLine,
              nextId: o.next_id
                ? idMapping
                  ? idMapping[o.next_id]
                  : o.next_id
                : null,
              doesNextNodeExist: o.does_next_node_exist!,
              isActivityFinished: o.is_activity_finished!,
              characterIndex: o.character_id!,
              vhSpeaks: {
                textResponse: o.vh_speaks?.text_response ?? '',
                audioResponse: o.vh_speaks?.audio_response ?? '',
                soundAsset: o.vh_speaks?.sound_asset
                  ? ({
                      id: o.vh_speaks.sound_asset.asset_id,
                      name: o.vh_speaks.sound_asset.name,
                      assetType: 'sound',
                    } as Asset)
                  : null,
                lipSyncAsset: o.vh_speaks?.lip_sync_asset
                  ? ({
                      id: o.vh_speaks.lip_sync_asset.asset_id,
                      name: o.vh_speaks.lip_sync_asset.name,
                      assetType: 'lipsync-assetbundle',
                    } as Asset)
                  : null,
                jaliAsset: o.vh_speaks?.jali_asset
                  ? ({
                      id: o.vh_speaks.jali_asset.asset_id,
                      name: o.vh_speaks.jali_asset.name,
                      assetType: 'jali-assetbundle',
                    } as Asset)
                  : null,
              },
              idle: {
                clipName: o.vh_animation_idle?.clip_name ?? '',
                startTime: 0,
              },
              isOutputEnabled:
                o.is_activity_finished || o.does_next_node_exist!,
              delayStartTime: o.delay_start_time ?? null,
              endTime: null,
              outputType: OperationOutputType.SingleOutput,
            };

            operations = {
              ...operations,
              [characterLine.id]: characterLine,
            };

            break;
          }

          case OperationType.MultipleChoiceQuestion: {
            const o =
              operation as MultipleChoiceQuestionData.MultipleChoiceQuestionData;

            const mcq: McqOperation = {
              answerFeedbackStyle: o.answer_feedback_style!,
              children:
                o.answers
                  ?.filter((a) => a.rid)
                  .map((answerJSON, i) => ({
                    answerOrder: i,
                    doesNextNodeExist: answerJSON.does_next_node_exist!,
                    label: answerJSON.name ?? '',
                    nextId: answerJSON.next_id
                      ? idMapping
                        ? idMapping[answerJSON.next_id]
                        : answerJSON.next_id
                      : null,
                    isActivityFinished: answerJSON.is_activity_finished!,
                    text: answerJSON.text ?? '',
                    useTextAsLabel: answerJSON.name === answerJSON.text,
                    audio: answerJSON.audio
                      ? {
                          assetType: 'sound',
                          id: answerJSON.audio.asset_id!,
                          name: answerJSON.audio.name ?? '',
                        }
                      : null,
                    rid: idMapping
                      ? idMapping[answerJSON.rid!]
                      : answerJSON.rid!,
                  })) ?? [],
              showBackground: o.show_background!,
              text: o.text ?? '',
              audio: o.audio
                ? {
                    assetType: 'sound',
                    id: o.audio.asset_id!,
                    name: o.audio.name ?? '',
                  }
                : null,
              randomizeAnswers: o.randomize_answers!,
              correctAnswerRid: o.correct_answer_rid
                ? idMapping
                  ? idMapping[o.correct_answer_rid]
                  : o.correct_answer_rid
                : '',
              shouldPlayAnsweredAudio: o.should_play_answered_audio!,
              shouldPlayHoverAudio: o.should_play_hover_audio!,
              isRedoable: o.is_redoable!,
              isScored: o.is_scored!,
              mcqType: o.mcq_type!,
              points: o.points ?? null,
              shouldTrackResult: o.should_track_result!,
              type: OperationType.MultipleChoiceQuestion,
              position: {
                x: o.theta ?? null,
                y: o.phi ?? null,
                z: o.rho ?? null,
              },
              isOutputEnabled: o.answers
                ? o.answers?.reduce<boolean>((acc, cur) => {
                    if (
                      cur.does_next_node_exist !== undefined &&
                      cur.is_activity_finished !== undefined
                    ) {
                      return (
                        acc &&
                        (cur.does_next_node_exist || cur.is_activity_finished)
                      );
                    }
                    return acc;
                  }, true)
                : true,
              outputType: OperationOutputType.MultipleOutput,
              delayStartTime: o.delay_start_time ?? null,
              endTime: null,
              feedbackDelayDuration: o.feedback_delay_duration ?? null,
              name: o.name ?? '',
              id: operationRid,
              containerId: operationContainerId,
            };

            operations = {
              ...operations,
              [mcq.id]: mcq,
            };

            break;
          }
          case OperationType.PlaybackPhase: {
            const o =
              operation as PlaybackPhaseOperationData.PlaybackPhaseOperationData;

            const playbackPhase: PlaybackPhaseOperation = {
              type: OperationType.PlaybackPhase,
              /*
                Bug 49992:
                Step 1 (filter): Remove bad data where container_to_playback includes non-existant containerIds
                Step 2 (map): When duplicating, reference the new (mapped) container ids for containerToPlayback
              */
              containersToPlayback:
                o.containers_to_playback
                  ?.filter(
                    (ctp) =>
                      experienceJSON.scenes?.some(
                        (s) =>
                          s.operation_containers?.some((c) => c.id === ctp),
                      ),
                  )
                  ?.map((ctp) => (idMapping ? idMapping[ctp] : ctp)) ?? [],
              flipCamera: o.flip_camera!,
              outputType: OperationOutputType.NoOutput,
              name: o.name ?? '',
              id: operationRid,
              containerId: operationContainerId,
              delayStartTime: null,
              endTime: null,
            };
            operations = {
              ...operations,
              [playbackPhase.id]: playbackPhase,
            };
            break;
          }

          case OperationType.PlaybackNode: {
            const o =
              operation as PlaybackNodeOperationData.PlaybackNodeOperationData;

            const playback: PlaybackOperation = {
              type: OperationType.PlaybackNode,
              outputType: OperationOutputType.SingleOutput,
              name: o.name ?? '',
              id: operationRid,
              containerId: operationContainerId,
              delayStartTime: o.delay_start_time ?? null,
              isOutputEnabled: true,
              isActivityFinished: o.is_activity_finished!,
              endTime: null,
              nextId: o.next_id ?? null,
              doesNextNodeExist: o.does_next_node_exist!,
              phasesToPlayback:
                o.playback_phases?.map((operationJSON) => {
                  //Annoying n^3 lookup to find all the container for each operation
                  for (const s of experienceJSON.scenes ?? []) {
                    for (const c of s.operation_containers ?? []) {
                      for (const child of c.operations ?? []) {
                        if (child.rid === operationJSON.rid) {
                          return idMapping ? idMapping[c.id!] : c.id!;
                        }
                      }
                    }
                  }
                  return emptyGuid;
                }) ?? [],
            };
            operations = {
              ...operations,
              [playback.id]: playback,
            };
            break;
          }
          case OperationType.LearnerResponse: {
            const o =
              operation as LearnerResponseOperationData.LearnerResponseOperationData;

            const response: LearnerResponseOperation = {
              id: operationRid,
              name: o.name ?? '',
              containerId: operationContainerId,
              type: OperationType.LearnerResponse,
              nextId: o.next_id
                ? idMapping
                  ? idMapping[o.next_id]
                  : o.next_id
                : null,
              doesNextNodeExist: o.does_next_node_exist!,
              isActivityFinished: o.is_activity_finished!,
              isOutputEnabled:
                o.is_activity_finished || o.does_next_node_exist!,
              delayStartTime: o.delay_start_time ?? null,
              endTime: null,
              outputType: OperationOutputType.SingleOutput,
              description: o.description ?? '',
              skillsFrameworks:
                o.skill_frameworks?.map((skill) => {
                  return (
                    allFrameworks.find((framework) => framework.value === skill)
                      ?.id || 0
                  );
                }) ?? [],
            };
            operations = {
              ...operations,
              [response.id]: response,
            };
            break;
          }

          case OperationType.Video: {
            const o = operation as VideoOperationData;

            const response: VideoOperation = {
              id: operationRid,
              name: o.name ?? '',
              containerId: operationContainerId,
              type: OperationType.Video,
              nextId: o.next_id
                ? idMapping
                  ? idMapping[o.next_id]
                  : o.next_id
                : null,
              doesNextNodeExist: o.does_next_node_exist!,
              isActivityFinished: o.is_activity_finished!,
              isOutputEnabled:
                o.is_activity_finished || o.does_next_node_exist!,
              delayStartTime: o.delay_start_time ?? null,
              endTime: null,
              outputType: OperationOutputType.SingleOutput,
              looping: o.is_looping ?? false,
              video: o.video
                ? {
                    assetType: 'video',
                    name: o.video.name ?? '',
                    id: o.video.asset_id!,
                  }
                : null,
            };
            operations = {
              ...operations,
              [response.id]: response,
            };
            break;
          }
        }
      }
    }
  }

  const initialOperationContainerState = getInitialOperationsState();
  const operationContainerState = {
    ...initialOperationContainerState,
    operations: {
      ...initialOperationContainerState.operations,
      ...operations,
    },
    containers: {
      ...initialOperationContainerState.containers,
      ...containers,
    },
  };
  return operationContainerState;
};

/**
 * We want to do all the data mapping so in case
 * anything errors it's in a single transaction
 * Update every reducer with data from experience JSON:
 *
 * 1. experience
 * 2. nodes
 * 3. sceneProperties
 * 4. operationContainers
 */
export const experienceRecordToStateMapping = (
  experienceRecord: ExperienceRecord,
  isDuplicating?: boolean,
  newExperienceName?: string,
  experienceVersionId?: string,
): ExperienceJSONToStateDataOrErrors => {
  try {
    const scenes = experienceRecord.experience.scenes ?? [];
    const idMapping = {} as Record<string, string>;

    // create mapping for clone experience, since all GUIDs need to be updated
    if (isDuplicating) {
      for (const scene of scenes) {
        idMapping[scene.id!] = generateGuid();
        for (const oc of scene.operation_containers!) {
          idMapping[oc.id!] = generateGuid();
          for (const op of oc.operations!) {
            idMapping[op.rid!] = generateGuid();
            if ((op as MultipleOutputOperationJSON).answers !== undefined) {
              for (const ans of (op as MultipleOutputOperationJSON).answers!) {
                idMapping[ans.rid!] = generateGuid();
              }
            }
          }
        }
      }
    }

    // 1. experience
    const newExperienceData: typeof experienceInitialState =
      mapExperienceDataToState(
        experienceRecord.experience,
        experienceRecord.portalData,
        isDuplicating ? idMapping : undefined,
        newExperienceName ?? undefined,
        experienceVersionId,
      );

    // 2. nodes
    const newNodesData: typeof nodeInitialState = mapNodeDataToState(
      experienceRecord.experience,
      experienceRecord.displayData,
      isDuplicating ? idMapping : undefined,
    );

    // 3. sceneProperties
    const newScenePropertiesData: typeof scenePropertiesInitialState =
      mapScenePropertiesToState(
        experienceRecord.experience,
        isDuplicating ? idMapping : undefined,
      );

    // 4. operationContainers
    const newOperationContainersData: typeof operationContainersInitialState =
      mapOperationContainersToState(
        experienceRecord.experience,
        isDuplicating ? idMapping : undefined,
      );
    return {
      data: {
        newExperienceData,
        newNodesData,
        newScenePropertiesData,
        newOperationContainersData,
      },
      error: null,
    };
  } catch (e) {
    return { data: null, error: (e as Error).message };
  }
};

/**
 * @param path
 * @param id should be the versionId where relevant
 * @param extension e.g. .assetbundle, .png, etc.
 */
export const relativePath = (path: string, id: string, extension: string) =>
  `${path}${id}${extension}`;
