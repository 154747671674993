import React from 'react';

import { classNames } from '../utils/helpers';

import styles from './buttonUndressed.module.css';

export type ButtonUndressedProps =
  React.ButtonHTMLAttributes<HTMLButtonElement>;

const ButtonUndressed = React.forwardRef<
  HTMLButtonElement,
  ButtonUndressedProps
>(({ children, className, ...buttonProps }, ref) => {
  return (
    <button
      {...buttonProps}
      className={classNames(styles.buttonUndressed, className, {
        [styles.disabled]: buttonProps.disabled,
      })}
      ref={ref}
    >
      {children}
    </button>
  );
});

ButtonUndressed.displayName = 'ButtonUndressed';

/**
 * This is for things that don't look like button or links, but work like a button
 * The intention is to avoid using div role="button" only to avoid having to remove button styling
 */
export default ButtonUndressed;
