import React from 'react';

import { classNames } from '../utils/helpers';

import styles from './switch.module.css';

type SwitchProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'>;

/**
 * The Switch's label takes on the size of its child span, but sends events to the child input (which is invisible)
 * Note that the containing element must be a label due to this property, it will not work otherwise
 */
const Switch: React.FC<SwitchProps> = ({
  children,
  className,
  ...switchProps
}) => {
  return (
    <label className={styles.switch}>
      <input
        {...switchProps}
        className={classNames(styles.checkbox, className)}
        type="checkbox"
      />
      <span
        aria-checked={switchProps.checked}
        className={styles.slider}
        role="checkbox"
      />
      <span className={styles.content}>{children}</span>
    </label>
  );
};

export default Switch;
