import { useEffect } from 'react';
import { unityContext } from '../../areas/main/views/preview';

const useQuitPreviewWindowToMessagePlayer = () => {
  useEffect(() => {
    return () => {
      if (unityContext.unityInstance) {
        /**
         * The below steps are a syncronous version of unityContext.quitUnityInstance
         *
         * We have to do both unityInstance.Quit() _and_ unityInstance = null
         * here, and cannot just use unityContext.quitUnityInstance, because
         * quitUnityInstance is an async function, and we need the unityInstance
         * to immediately be set to null after we quit the application,
         * so that we prevent sending any more messages (otherwise we'll get
         * this getBoundingClientRect error: https://forum.unity.com/threads/getboundingclientrect-problem.1302099/).
         * Let's also dispatch "quitted" here in case we listen for that event
         * in creator in the future.
         *
         * Dispatching an action to update the store's state to isPlayerPreviewReady: false
         * here will throw a getBoundingClientRect error. This is partly why on
         * UnityPreviewEvents.OnPlayerLoaded, we first ensure that isPlayerPreviewReady is
         * set to `false` before resetting it to `true`.
         */
        unityContext.unityInstance.Quit();
        unityContext.unityInstance = null;
        unityContext.dispatchEvent('quitted');
      }
    };
  }, []);
};
export default useQuitPreviewWindowToMessagePlayer;
