import { put, select } from 'typed-redux-saga';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';

import {
  VECTOR_3_DEFAULT_VALUES_BY_FEATURE,
  CONTENT_VERSION_FLAGS,
} from '../../constants';
import { State } from '../../../../state/reducer';
import sliceReducer from './sliceReducer';
import { ObjectMetadata, SceneProperties } from '../../types/models';
import { PayloadAction } from '@reduxjs/toolkit';
import operationsSliceSaga from '../operationContainers/sliceSaga';
import { generateGuid, getIncrementingId } from '../../../../utils/id';
import { logToServer } from '../../../../utils/logging';
import { checkContentVersion } from '../../../../utils/contentVersion';

export interface DuplicateScenePayload {
  newId: string;
  newName: string;
  idToDuplicate: string;
  containerIds: string[];
  envIdMap?: Record<string, string>;
}

interface AddScene {
  envId: string;
  id: string;
}

interface AddContainers {
  sceneId: string;
  containerIds: string[];
}

interface DeleteScenes {
  ids: string[];
}

interface DuplicateScenes {
  scenes: DuplicateScenePayload[];
}

const sliceSaga = createSliceSaga({
  name: sliceReducer.name,
  caseSagas: {
    addScene: {
      sagaType: SagaType.TakeEvery,
      *fn(action: PayloadAction<AddScene>) {
        const state: State = yield* select();
        const { scenes } = state.main.sceneProperties;
        const { assetListsByType } = state.main.assets;
        const contentVersion = state.main.experience.content_version;
        const characterBundleType = checkContentVersion(
          contentVersion,
          CONTENT_VERSION_FLAGS.genericRig,
        )
          ? 'generic-character-assetbundle'
          : 'character-assetbundle';

        //For now, environment version and character version ids are set to the first id on the list
        const envList: ObjectMetadata[] =
          assetListsByType['environment-assetbundle'];
        const charList: ObjectMetadata[] =
          assetListsByType[characterBundleType];
        const avatarList: ObjectMetadata[] =
          assetListsByType['avatar-assetbundle'];

        const defaultEnvironmentVersionId = envList?.[0]?.versionId || '';
        const defaultCharacterVersionId = charList?.[0]?.versionId || '';

        // For the avatar, try to locate the first pipeline-built avatar
        const pipelineBuiltavatar = avatarList?.find((aab) => !!aab.commitHash);

        // Default to using the first pipeline-built avatar: otherwise, use the first
        // one available.
        const defaultAvatarVersionId =
          (pipelineBuiltavatar ?? avatarList?.[0])?.versionId || '';

        const cgMetadata = assetListsByType['environment-assetbundle']?.find(
          (asset) => defaultEnvironmentVersionId === asset.versionId,
        )?.cgMetadata;
        if (!cgMetadata)
          logToServer('warn', 'Environment asset missing cgMetadata');

        const defaultCameraPosition = cgMetadata
          ? {
              x: cgMetadata.CameraTransforms[0].Position.X,
              y: cgMetadata.CameraTransforms[0].Position.Y,
              z: cgMetadata.CameraTransforms[0].Position.Z,
            }
          : VECTOR_3_DEFAULT_VALUES_BY_FEATURE.user_camera.position;

        const defaultCameraRotation = cgMetadata
          ? {
              x: cgMetadata.CameraTransforms[0].Rotation.X,
              y: cgMetadata.CameraTransforms[0].Rotation.Y,
              z: cgMetadata.CameraTransforms[0].Rotation.Z,
            }
          : VECTOR_3_DEFAULT_VALUES_BY_FEATURE.user_camera.rotation;

        const defaultCharacterPosition = cgMetadata
          ? {
              x: cgMetadata.CharacterTransforms[0].Position.X,
              y: cgMetadata.CharacterTransforms[0].Position.Y,
              z: cgMetadata.CharacterTransforms[0].Position.Z,
            }
          : VECTOR_3_DEFAULT_VALUES_BY_FEATURE.character.position;

        const defaultCharacterRotation = cgMetadata
          ? {
              x: cgMetadata.CharacterTransforms[0].Rotation.X,
              y: cgMetadata.CharacterTransforms[0].Rotation.Y,
              z: cgMetadata.CharacterTransforms[0].Rotation.Z,
            }
          : VECTOR_3_DEFAULT_VALUES_BY_FEATURE.character.rotation;

        const defaultCharacterScale = cgMetadata
          ? {
              x: cgMetadata.CharacterTransforms[0].Scale.X,
              y: cgMetadata.CharacterTransforms[0].Scale.Y,
              z: cgMetadata.CharacterTransforms[0].Scale.Z,
            }
          : VECTOR_3_DEFAULT_VALUES_BY_FEATURE.character.scale;

        const newScene: SceneProperties = {
          id: action.payload.id,
          environments: [
            {
              id: action.payload.envId,
              environmentAssetVersionId: defaultEnvironmentVersionId,
              bgAudioAssetVersionId: null,
              bgVideoAssetVersionId: null,
              use360Sphere: false,
            },
          ],
          characterVersionId: defaultCharacterVersionId,
          avatarVersionId: defaultAvatarVersionId,
          operationContainerIds: [],
          startingAnimationNameForCharacter: '',
          characterStance: '',
          startingBodyLanguage: '',
          startingFacialExpression: '',
          name: `Untitled ${getIncrementingId('scene')}`,
          cameraPosition: defaultCameraPosition,
          cameraRotation: defaultCameraRotation,
          characterPosition: defaultCharacterPosition,
          characterRotation: defaultCharacterRotation,
          characterScale: defaultCharacterScale,
        };
        yield* put(
          sliceReducer.actions.update({
            scenes: { ...scenes, [action.payload.id]: newScene },
          }),
        );
      },
    },

    addContainers: {
      sagaType: SagaType.TakeEvery,
      *fn(action: PayloadAction<AddContainers>) {
        const state: State = yield* select();

        const scenes = { ...state.main.sceneProperties.scenes };
        const selectedScene = scenes[action.payload.sceneId];

        if (!selectedScene) return;

        yield* put(
          sliceReducer.actions.update({
            scenes: {
              ...scenes,
              [action.payload.sceneId]: {
                ...selectedScene,
                operationContainerIds: [
                  ...selectedScene.operationContainerIds,
                  ...action.payload.containerIds,
                ],
              },
            },
          }),
        );
      },
    },

    deleteScenes: {
      sagaType: SagaType.TakeEvery,
      *fn(action: PayloadAction<DeleteScenes>) {
        const state: State = yield* select();

        const updatedScenes = { ...state.main.sceneProperties.scenes };

        const containersToDelete = [] as string[];
        action.payload.ids.forEach((id) => {
          containersToDelete.push(...updatedScenes[id].operationContainerIds);
          delete updatedScenes[id];
        });

        yield* put(
          sliceReducer.actions.update({
            scenes: updatedScenes,
          }),
        );

        yield* put(
          operationsSliceSaga.actions.deleteContainers({
            ids: containersToDelete,
          }),
        );
      },
    },

    duplicateScenes: {
      sagaType: SagaType.TakeEvery,
      *fn(action: PayloadAction<DuplicateScenes>) {
        const state: State = yield* select();
        const { scenes, copiedScenes } = state.main.sceneProperties;
        const allScenes = { ...scenes, ...copiedScenes };
        const newScenes = action.payload.scenes.reduce(
          (accumulated, scenePayload) => {
            const oldScene = allScenes[scenePayload.idToDuplicate];

            const newScene: SceneProperties = {
              ...oldScene,
              id: scenePayload.newId,
              name: scenePayload.newName,
              characterVersionId: oldScene.characterVersionId,
              environments: oldScene.environments.map((env) => ({
                ...env,
                id: scenePayload.envIdMap
                  ? scenePayload.envIdMap[env.id]
                  : generateGuid(),
              })),
              operationContainerIds: scenePayload.containerIds,
            };

            return { ...accumulated, [scenePayload.newId]: newScene };
          },
          {},
        );

        yield* put(
          sliceReducer.actions.update({
            scenes: { ...scenes, ...newScenes },
          }),
        );
      },
    },

    removeEmptyContainers: {
      sagaType: SagaType.TakeEvery,
      *fn() {
        const state: State = yield* select();
        const updatedScenes = { ...state.main.sceneProperties.scenes };
        const { containers } = state.main.operationContainers;

        Object.keys(updatedScenes).forEach((sceneId) => {
          updatedScenes[sceneId] = {
            ...updatedScenes[sceneId],
            operationContainerIds: updatedScenes[
              sceneId
            ].operationContainerIds.filter((id) => id in containers),
          };
        });

        yield* put(
          sliceReducer.actions.update({
            scenes: updatedScenes,
          }),
        );
      },
    },
  },
});

export default sliceSaga;
