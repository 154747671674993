// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1LL2TucmIzqrYbCHIzfVbK {\n    height: 100%;\n    overflow-x: hidden;\n    overflow-y: auto;\n    position: relative;\n}\n\n._3v8HbVJKefTFnU5asmchbc {\n    left: 0;\n    position: absolute;\n    top: 0;\n    transition-property: transform;\n    transition-timing-function: ease-in-out;\n    width: 100%;\n}\n\n.Ar2oEc-nVVXhYX56RvJdy {\n    transform: translateX(-100%);\n}\n\n.hJqq1KHLVq8B0JGfDVMzF {\n    transform: none;\n}\n\n.kNlupUO5CO6EGMcdKP3md {\n    transform: translateX(100%);\n}", ""]);
// Exports
exports.locals = {
	"deck": "_1LL2TucmIzqrYbCHIzfVbK",
	"slide": "_3v8HbVJKefTFnU5asmchbc",
	"prev": "Ar2oEc-nVVXhYX56RvJdy",
	"cur": "hJqq1KHLVq8B0JGfDVMzF",
	"next": "kNlupUO5CO6EGMcdKP3md"
};
module.exports = exports;
