import { AppState } from '@auth0/auth0-react';

import sliceReducer from '../areas/main/state/tenants/sliceReducer';
import { CAMTenant } from '../types/api';
import { AppDispatch } from '../state/store';

export const onAuth0Redirect =
  (dispatch: AppDispatch) => (appState: AppState | undefined) => {
    const selectedTenant = (appState?.selectedTenant ??
      undefined) as unknown as CAMTenant | undefined;
    if (selectedTenant) {
      dispatch(
        sliceReducer.actions.update({
          selectedTenant,
        }),
      );
    }
  };
