import axios from 'axios';

import { StateMiddleware } from '../reducer';
import {
  mapDisplayStateToJSON,
  mapExperienceStateToJSON,
  mapPortalStateToJSON,
} from '../../areas/main/state/experience/sliceSaga';
import undoReducer from '../../areas/main/state/undo/sliceReducer';
import { UndoRecord } from '../../areas/main/types/json';
import { getUndoActions } from '../../utils/middleware';
import { logToServer } from '../../utils/logging';

// Don't set keep setting undo state if focus remains on a single element
// ie. While typing in a text input, we don't want to set an undo state for every character typed
let lastActiveElement = null as Element | null;
let hasChangedActiveElement = false;

const undo: StateMiddleware = (store) => (next) => (action) => {
  if (window.document.activeElement !== lastActiveElement) {
    hasChangedActiveElement = true;
    lastActiveElement = window.document.activeElement;
  }
  const state = store.getState();
  const shouldSetUndoState =
    getUndoActions().includes(action.type) && state.main.tenants.selectedTenant;
  let isSettingUndoState = false;
  if (
    shouldSetUndoState &&
    !state.main.undo.undoStateLock &&
    hasChangedActiveElement
  ) {
    hasChangedActiveElement = false;
    isSettingUndoState = true;

    try {
      const currentRecord: UndoRecord = {
        actionType: action.type,
        displayData: mapDisplayStateToJSON(state),
        experience: mapExperienceStateToJSON(state, null, true),
        portalData: mapPortalStateToJSON(state),
      };

      store.dispatch(
        undoReducer.actions.update({
          redoStack: [],
          undoStack: [currentRecord, ...state.main.undo.undoStack],
          undoStateLock: true,
        }),
      );
    } catch (error) {
      logToServer('warn', `Failed to set undo state for ${action.type}`, {
        action,
        error: {
          message: (error as Error).message,
          response: axios.isAxiosError(error) ? error.response : undefined,
          stack: (error as Error).stack,
        },
      });
    }
  }

  const result = next(action);

  if (isSettingUndoState)
    store.dispatch(undoReducer.actions.update({ undoStateLock: false }));
  return result;
};

export default undo;
