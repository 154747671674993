import {
  OperationType,
  OverlayType,
  FeedbackStyle,
  McqQuestionType,
  OperationOutputType,
} from '../areas/main/enums/models';
import {
  OverlayOperation,
  AnimationOperation,
  CharacterLineOperation,
  McqOperation,
  McqChoice,
  PlaybackPhaseOperation,
  LearnerResponseOperation,
  PlaybackOperation,
  VideoOperation,
} from '../areas/main/types/operations';
import { emptyGuid, generateGuid, getIncrementingId } from './id';

const makeOverlayOperation = (
  containerId: string,
  id?: string,
): OverlayOperation => ({
  name: `Untitled ${getIncrementingId('overlay')}`,
  containerId,
  id: id ?? generateGuid(),
  maxDuration: 0,
  nextId: emptyGuid,
  doesNextNodeExist: false,
  isActivityFinished: true,
  isOutputEnabled: true,
  type: OperationType.NotificationDialog2,
  title: '',
  description: '',
  image: null,
  audio: null,
  showButton: true,
  overlayType: OverlayType.QuestionTextPlacard,
  position: {
    x: 0,
    y: 0,
    // default value of 2 as requested by Player team
    z: 2,
  },
  delayStartTime: null,
  endTime: null,
  outputType: OperationOutputType.SingleOutput,
});

export const makeAnimationOperation = (
  containerId: string,
  clipName: string,
  id?: string,
): AnimationOperation => ({
  name: `Untitled ${getIncrementingId('animation')}`,
  containerId,
  id: id ?? generateGuid(),
  nextId: emptyGuid,
  doesNextNodeExist: false,
  isActivityFinished: true,
  isOutputEnabled: true,
  type: OperationType.Animation,
  characterIndex: 0,
  animation: { clipName, startTime: 0 },
  idle: { clipName, startTime: 0 },
  delayStartTime: null,
  endTime: null,
  outputType: OperationOutputType.SingleOutput,
});

export const makeCharacterLineOperation = (
  containerId: string,
  clipName: string,
  id?: string,
): CharacterLineOperation => ({
  name: `Untitled ${getIncrementingId('character line')}`,
  containerId,
  id: id ?? generateGuid(),
  nextId: emptyGuid,
  doesNextNodeExist: false,
  isActivityFinished: true,
  isOutputEnabled: true,
  type: OperationType.CharacterLine,
  characterIndex: 0,
  vhSpeaks: {
    textResponse: '',
    audioResponse: '',
    soundAsset: null,
    lipSyncAsset: null,
    jaliAsset: null,
  },
  idle: { clipName, startTime: 0 },
  delayStartTime: null,
  endTime: null,
  outputType: OperationOutputType.SingleOutput,
});

export const makeMcqChoice = (idx: number, nextId?: string): McqChoice => {
  return {
    answerOrder: idx,
    label: '',
    text: '',
    useTextAsLabel: true,
    audio: null,
    rid: generateGuid(),
    nextId: nextId ?? emptyGuid,
    isActivityFinished: nextId === undefined,
    doesNextNodeExist: nextId !== undefined,
  };
};

const makeMcqOperation = (containerId: string, id?: string): McqOperation => {
  // minimum answer choices is 2
  const initialMcqChoices = [makeMcqChoice(0), makeMcqChoice(1)];
  return {
    answerFeedbackStyle: FeedbackStyle.NotSet,
    name: `Untitled ${getIncrementingId('mcq')}`,
    text: '',
    showBackground: true,
    shouldPlayAnsweredAudio: false,
    shouldPlayHoverAudio: false,
    shouldTrackResult: true,
    audio: null,
    randomizeAnswers: false,
    isScored: true,
    isRedoable: false,
    mcqType: McqQuestionType.Branching,
    containerId,
    id: id ?? generateGuid(),
    type: OperationType.MultipleChoiceQuestion,
    children: initialMcqChoices,
    delayStartTime: 0,
    endTime: null,
    correctAnswerRid: initialMcqChoices[0].rid,
    points: 1,
    position: { x: 45, y: -10, z: 2 },
    isOutputEnabled: true,
    outputType: OperationOutputType.MultipleOutput,
    feedbackDelayDuration: null,
  };
};

const makePlaybackPhaseOperation = (
  containerId: string,
  id?: string,
): PlaybackPhaseOperation => {
  return {
    name: `Untitled ${getIncrementingId('playback phase')}`,
    containerId,
    id: id ?? generateGuid(),
    type: OperationType.PlaybackPhase,
    containersToPlayback: [],
    flipCamera: true,
    delayStartTime: null,
    endTime: null,
    outputType: OperationOutputType.NoOutput,
  };
};

const makeVideoOperation = (
  containerId: string,
  id?: string,
): VideoOperation => {
  return {
    name: `Untitled ${getIncrementingId('video')}`,
    containerId,
    id: id ?? generateGuid(),
    nextId: emptyGuid,
    doesNextNodeExist: false,
    isActivityFinished: true,
    isOutputEnabled: true,
    type: OperationType.Video,
    delayStartTime: null,
    endTime: null,
    outputType: OperationOutputType.SingleOutput,
    looping: false,
    video: null,
  };
};

const makePlaybackOperation = (
  containerId: string,
  id?: string,
): PlaybackOperation => {
  return {
    name: `Untitled ${getIncrementingId('playback')}`,
    containerId,
    id: id ?? generateGuid(),
    type: OperationType.PlaybackNode,
    phasesToPlayback: [],
    delayStartTime: null,
    endTime: null,
    outputType: OperationOutputType.SingleOutput,
    nextId: emptyGuid,
    doesNextNodeExist: false,
    isActivityFinished: true,
    isOutputEnabled: true,
  };
};
const makeLearnerResponseOperation = (
  containerId: string,
  id?: string,
): LearnerResponseOperation => {
  return {
    name: `Untitled ${getIncrementingId('learner response')}`,
    containerId,
    id: id ?? generateGuid(),
    nextId: emptyGuid,
    doesNextNodeExist: false,
    isActivityFinished: true,
    isOutputEnabled: true,
    type: OperationType.LearnerResponse,
    delayStartTime: null,
    endTime: null,
    outputType: OperationOutputType.SingleOutput,
    description: '',
    skillsFrameworks: [] as number[],
  };
};

const makeDefaultOperation = (containerId: string, id?: string) => {
  throw new Error(
    `Cannot make operation of containerId ${containerId} and id ${id}`,
  );
};

export const nodeTypeMap = {
  [OperationType.Animation]: makeAnimationOperation,
  [OperationType.CharacterLine]: makeCharacterLineOperation,
  [OperationType.LearnerResponse]: makeLearnerResponseOperation,
  [OperationType.MultipleChoiceQuestion]: makeMcqOperation,
  [OperationType.NotificationDialog2]: makeOverlayOperation,
  [OperationType.PlaybackNode]: makePlaybackOperation,
  [OperationType.PlaybackPhase]: makePlaybackPhaseOperation,
  [OperationType.Video]: makeVideoOperation,
  [OperationType.NoValueSelected]: makeDefaultOperation,
  [OperationType.OperationBase]: makeDefaultOperation,
};
