import { put, select } from 'typed-redux-saga';
import { createSliceSaga, SagaType } from 'redux-toolkit-saga';
import { ActionCreatorWithoutPayload, PayloadAction } from '@reduxjs/toolkit';

import sliceReducer from './sliceReducer';
import { State } from '../../../../state/reducer';

import {
  AnimationOperation,
  CharacterLineOperation,
  LearnerResponseOperation,
  McqOperation,
  Operation,
  OperationContainer,
  OverlayOperation,
  PlaybackPhaseOperation,
  PlaybackOperation,
} from '../../types/operations';
import { OperationType } from '../../enums/models';
import {
  makeAnimationOperation,
  makeCharacterLineOperation,
  nodeTypeMap,
} from '../../../../utils/operationFactory';
import { calculateDuration } from '../../../../utils/operationDuration';
import { emptyGuid, generateGuid } from '../../../../utils/id';

interface MultipleOperations {
  ids: string[];
}

interface AddOperations extends MultipleOperations {
  type: OperationType;
  containerId: string;
}

export interface DuplicateOperationPayload {
  newId: string;
  idToDuplicate: string;
  newContainerId: string;
  newName: string;
}

interface DuplicateOperation {
  operations: DuplicateOperationPayload[];
  idMapping: Record<string, string>;
}

interface ResetOperationTimes {
  containerId: string;
}

const sliceSaga = createSliceSaga({
  name: sliceReducer.name,
  caseSagas: {
    addOperations: {
      sagaType: SagaType.TakeEvery,
      *fn(action: PayloadAction<AddOperations>) {
        const state: State = yield* select();
        const container: OperationContainer = {
          id: action.payload.containerId,
          inputIds: [],
          operationIds: [
            ...(state.main.operationContainers.containers[
              action.payload.containerId
            ]?.operationIds ?? []),
            ...action.payload.ids,
          ],
        };

        yield* put(
          sliceReducer.actions.update({
            containers: {
              ...state.main.operationContainers.containers,
              [action.payload.containerId]: container,
            },
          }),
        );

        // Adding Operations Step
        switch (action.payload.type) {
          // Special case for animation nodes
          case OperationType.Animation: {
            // The initial default animation name
            // will always be empty string.
            const defaultAnimationName = '';

            yield* put(
              sliceReducer.actions.update({
                operations: {
                  ...state.main.operationContainers.operations,
                  ...action.payload.ids.reduce(
                    (acc, operationId) => ({
                      ...acc,
                      [operationId]: makeAnimationOperation(
                        action.payload.containerId,
                        defaultAnimationName,
                        operationId,
                      ),
                    }),
                    {} as Record<string, Operation>,
                  ),
                },
              }),
            );
            break;
          }

          // Special case for character line nodes
          case OperationType.CharacterLine: {
            // The initial default character line clip name
            // will always be empty string.
            const defaultClipName = '';

            yield* put(
              sliceReducer.actions.update({
                operations: {
                  ...state.main.operationContainers.operations,
                  ...action.payload.ids.reduce(
                    (acc, operationId) => ({
                      ...acc,
                      [operationId]: makeCharacterLineOperation(
                        action.payload.containerId,
                        defaultClipName,
                        operationId,
                      ),
                    }),
                    {} as Record<string, Operation>,
                  ),
                },
              }),
            );
            break;
          }
          default: {
            if (
              action.payload.type === OperationType.NoValueSelected ||
              action.payload.type === OperationType.OperationBase
            ) {
              return;
            }
            yield* put(
              sliceReducer.actions.update({
                operations: {
                  ...state.main.operationContainers.operations,
                  ...action.payload.ids.reduce(
                    (acc, cur) => ({
                      ...acc,
                      [cur]: (
                        nodeTypeMap[action.payload.type] as (
                          containerId: string,
                          id?: string,
                        ) => Operation
                      )(action.payload.containerId, cur),
                    }),
                    {} as Record<string, Operation>,
                  ),
                },
              }),
            );
          }
        }

        // Self-referencing breaks typings unless we create a new variable referencing the current saga
        const currentSaga: {
          actions: { calculateOperationTimes: ActionCreatorWithoutPayload };
        } = sliceSaga;
        yield put(currentSaga.actions.calculateOperationTimes());
      },
    },

    deleteContainers: {
      sagaType: SagaType.TakeEvery,
      *fn(action: PayloadAction<MultipleOperations>) {
        const state: State = yield* select();
        const { operations, containers } = state.main.operationContainers;

        const updatedContainerRecord = { ...containers };
        const updatedOperationRecord = { ...operations };
        const operationIdsToDelete = [] as string[];

        action.payload.ids.forEach((containerId) => {
          operationIdsToDelete.push(...containers[containerId].operationIds);
          delete updatedContainerRecord[containerId];
        });

        operationIdsToDelete.forEach((id) => {
          delete updatedOperationRecord[id];
        });

        yield* put(
          sliceReducer.actions.update({
            containers: updatedContainerRecord,
            operations: updatedOperationRecord,
          }),
        );
      },
    },

    deleteOperations: {
      sagaType: SagaType.TakeEvery,
      *fn(action: PayloadAction<MultipleOperations>) {
        const state: State = yield* select();
        const { operations, containers } = state.main.operationContainers;

        const updatedOperations = Object.keys(operations).reduce(
          (accumulated, key) =>
            action.payload.ids.includes(key)
              ? accumulated
              : { ...accumulated, [key]: operations[key] },
          {},
        );

        const updatedContainers = { ...containers };

        action.payload.ids.forEach((idToRemove) => {
          const targetContainer =
            updatedContainers[operations[idToRemove].containerId];
          const filteredIds = targetContainer.operationIds.filter(
            (operationId) => operationId !== idToRemove,
          );

          if (filteredIds.length === 0) {
            delete updatedContainers[operations[idToRemove].containerId];
          } else {
            updatedContainers[operations[idToRemove].containerId] = {
              ...targetContainer,
              operationIds: filteredIds,
            };
          }
        });

        yield* put(
          sliceReducer.actions.update({
            operations: updatedOperations,
            containers: updatedContainers,
          }),
        );
      },
    },

    duplicateOperations: {
      sagaType: SagaType.TakeEvery,
      *fn(action: PayloadAction<DuplicateOperation>) {
        const state: State = yield* select();
        const { operations, containers, copiedOperations } =
          state.main.operationContainers;
        const operationPool = { ...operations, ...copiedOperations };
        const newContainers = action.payload.operations.reduce(
          (acc, cur) => ({
            ...acc,
            [cur.newContainerId]: {
              id: cur.newContainerId,
              inputIds: [],
              operationIds: [
                ...(acc[cur.newContainerId]?.operationIds ?? []),
                cur.newId,
              ],
            },
          }),
          {} as Record<string, OperationContainer>,
        );
        const newOperations = action.payload.operations.reduce(
          (accumulated, operationPayload) => {
            // Adding Operations Step
            switch (operationPool[operationPayload.idToDuplicate].type) {
              case OperationType.NotificationDialog2: {
                const oldOperation = operationPool[
                  operationPayload.idToDuplicate
                ] as OverlayOperation;
                const isOutConnectionEnabled =
                  oldOperation.doesNextNodeExist ||
                  oldOperation.isActivityFinished;

                const newOperation = {
                  ...oldOperation,
                  id: operationPayload.newId,
                  containerId: operationPayload.newContainerId,
                  name: operationPayload.newName,
                  image: oldOperation.image ? { ...oldOperation.image } : null,
                  sound: oldOperation.audio ? { ...oldOperation.audio } : null,
                  nextId: emptyGuid,
                  doesNextNodeExist: false,
                  isActivityFinished: isOutConnectionEnabled,
                };

                return {
                  ...accumulated,
                  [operationPayload.newId]: newOperation,
                };
              }

              case OperationType.Animation: {
                const oldOperation = operationPool[
                  operationPayload.idToDuplicate
                ] as AnimationOperation;
                const isOutConnectionEnabled =
                  oldOperation.doesNextNodeExist ||
                  oldOperation.isActivityFinished;

                const newOperation = {
                  ...oldOperation,
                  id: operationPayload.newId,
                  containerId: operationPayload.newContainerId,
                  name: operationPayload.newName,
                  animation: {
                    ...oldOperation.animation,
                  },
                  nextId: emptyGuid,
                  doesNextNodeExist: false,
                  isActivityFinished: isOutConnectionEnabled,
                };

                return {
                  ...accumulated,
                  [operationPayload.newId]: newOperation,
                };
              }

              case OperationType.CharacterLine: {
                const oldOperation = operationPool[
                  operationPayload.idToDuplicate
                ] as CharacterLineOperation;
                const isOutConnectionEnabled =
                  oldOperation.doesNextNodeExist ||
                  oldOperation.isActivityFinished;

                const newOperation = {
                  ...oldOperation,
                  id: operationPayload.newId,
                  containerId: operationPayload.newContainerId,
                  name: operationPayload.newName,
                  vhSpeaks: {
                    ...oldOperation.vhSpeaks,
                    soundAsset: oldOperation.vhSpeaks.soundAsset
                      ? {
                          ...oldOperation.vhSpeaks.soundAsset,
                        }
                      : null,
                    lipSyncAsset: oldOperation.vhSpeaks.lipSyncAsset
                      ? {
                          ...oldOperation.vhSpeaks.lipSyncAsset,
                        }
                      : null,
                    jaliAsset: oldOperation.vhSpeaks.jaliAsset
                      ? {
                          ...oldOperation.vhSpeaks.jaliAsset,
                        }
                      : null,
                  },
                  nextId: emptyGuid,
                  doesNextNodeExist: false,
                  isActivityFinished: isOutConnectionEnabled,
                };

                return {
                  ...accumulated,
                  [operationPayload.newId]: newOperation,
                };
              }

              case OperationType.MultipleChoiceQuestion: {
                const oldOperation = operationPool[
                  operationPayload.idToDuplicate
                ] as McqOperation;

                const oldToNewRids = oldOperation.children.reduce(
                  (acc, cur) => ({
                    ...acc,
                    [cur.rid]: generateGuid(),
                  }),
                  {} as Record<string, string>,
                );

                const newOperation = {
                  ...oldOperation,
                  id: operationPayload.newId,
                  containerId: operationPayload.newContainerId,
                  name: operationPayload.newName,
                  children: oldOperation.children.map((child) => {
                    const isOutConnectionEnabled =
                      child.doesNextNodeExist || child.isActivityFinished;

                    return {
                      ...child,
                      rid: oldToNewRids[child.rid],
                      nextId: emptyGuid,
                      doesNextNodeExist: false,
                      isActivityFinished: isOutConnectionEnabled,
                    };
                  }),
                  correctAnswerRid: oldOperation.correctAnswerRid
                    ? oldToNewRids[oldOperation.correctAnswerRid]
                    : emptyGuid,
                };

                return {
                  ...accumulated,
                  [operationPayload.newId]: newOperation,
                };
              }
              case OperationType.LearnerResponse: {
                const oldOperation = operationPool[
                  operationPayload.idToDuplicate
                ] as LearnerResponseOperation;
                const isOutConnectionEnabled =
                  oldOperation.doesNextNodeExist ||
                  oldOperation.isActivityFinished;

                const newOperation = {
                  ...oldOperation,
                  id: operationPayload.newId,
                  containerId: operationPayload.newContainerId,
                  name: operationPayload.newName,
                  nextId: emptyGuid,
                  doesNextNodeExist: false,
                  isActivityFinished: isOutConnectionEnabled,
                };
                return {
                  ...accumulated,
                  [operationPayload.newId]: newOperation,
                };
              }

              case OperationType.PlaybackPhase: {
                const oldOperation = operationPool[
                  operationPayload.idToDuplicate
                ] as PlaybackPhaseOperation;

                // If pasting to a new scene, we'll have to update the container ids.
                const containersToKeep =
                  oldOperation.containersToPlayback.reduce((acc, curr) => {
                    if (action.payload.idMapping[curr]) {
                      acc.push(action.payload.idMapping[curr]);
                    }
                    return acc;
                  }, [] as string[]);
                // Check if pasting to a new scene
                const { scenes } = state.main.sceneProperties;
                let oldSceneId, newSceneId;
                for (const sceneId in scenes) {
                  if (
                    scenes[sceneId].operationContainerIds.includes(
                      oldOperation.containerId,
                    )
                  ) {
                    oldSceneId = sceneId;
                  }
                  if (
                    scenes[sceneId].operationContainerIds.includes(
                      operationPayload.newContainerId,
                    )
                  ) {
                    newSceneId = sceneId;
                  }
                }
                const isPastedNewScene = oldSceneId !== newSceneId;
                const newOperation = {
                  ...oldOperation,
                  id: operationPayload.newId,
                  containerId: operationPayload.newContainerId,
                  name: operationPayload.newName,
                  containersToPlayback: isPastedNewScene
                    ? containersToKeep
                    : oldOperation.containersToPlayback,
                  nextId: emptyGuid,
                  doesNextNodeExist: false,
                  isActivityFinished: true,
                };
                return {
                  ...accumulated,
                  [operationPayload.newId]: newOperation,
                };
              }

              case OperationType.PlaybackNode: {
                const oldOperation = operationPool[
                  operationPayload.idToDuplicate
                ] as PlaybackOperation;
                const isOutConnectionEnabled =
                  oldOperation.doesNextNodeExist ||
                  oldOperation.isActivityFinished;

                const phasesToKeep = oldOperation.phasesToPlayback.reduce(
                  (acc, curr) => {
                    if (action.payload.idMapping[curr]) {
                      acc.push(action.payload.idMapping[curr]);
                    }
                    return acc;
                  },
                  [] as string[],
                );
                // Check if pasting to a new scene
                const { scenes } = state.main.sceneProperties;
                let oldSceneId, newSceneId;
                for (const sceneId in scenes) {
                  if (
                    scenes[sceneId].operationContainerIds.includes(
                      oldOperation.containerId,
                    )
                  ) {
                    oldSceneId = sceneId;
                  }
                  if (
                    scenes[sceneId].operationContainerIds.includes(
                      operationPayload.newContainerId,
                    )
                  ) {
                    newSceneId = sceneId;
                  }
                }
                const isPastedNewScene = oldSceneId !== newSceneId;
                const newOperation = {
                  ...oldOperation,
                  id: operationPayload.newId,
                  containerId: operationPayload.newContainerId,
                  name: operationPayload.newName,
                  phasesToPlayback: isPastedNewScene
                    ? phasesToKeep
                    : oldOperation.phasesToPlayback,
                  nextId: emptyGuid,
                  doesNextNodeExist: false,
                  isActivityFinished: isOutConnectionEnabled,
                };
                return {
                  ...accumulated,
                  [operationPayload.newId]: newOperation,
                };
              }
              default:
                return accumulated;
            }
          },
          {} as Record<string, Operation>,
        );

        yield* put(
          sliceReducer.actions.update({
            operations: { ...operations, ...newOperations },
            containers: { ...containers, ...newContainers },
          }),
        );

        // Self-referencing breaks typings unless we create a new variable referencing the current saga
        const currentSaga: {
          actions: { calculateOperationTimes: ActionCreatorWithoutPayload };
        } = sliceSaga;
        yield put(currentSaga.actions.calculateOperationTimes());
      },
    },

    calculateOperationTimes: {
      sagaType: SagaType.TakeLatest,
      *fn() {
        const state: State = yield* select();
        const { operations } = state.main.operationContainers;
        const { animationLibrary } = state.main.assets;

        const operationEntries = Object.entries(operations);
        const sortedAnimationEntries = operationEntries
          .filter(
            ([, v]) =>
              v.type === OperationType.Animation && v.delayStartTime !== null,
          )
          .sort(
            ([, va], [, vb]) =>
              (va.delayStartTime ?? 0) - (vb.delayStartTime ?? 0),
          );
        const newAnimationEntries = Object.entries(operations).filter(
          ([, v]) =>
            v.type === OperationType.Animation && v.delayStartTime === null,
        );
        const otherEntries = Object.entries(operations).filter(
          ([, v]) => v.type !== OperationType.Animation,
        );
        const updatedAnimations = [
          ...sortedAnimationEntries,
          ...newAnimationEntries,
        ].reduce(
          (acc, [k, v]) => {
            const duration = calculateDuration(
              v,
              { ...operations, ...acc },
              animationLibrary,
            );
            const prevAnimations = Object.entries(acc).filter(
              ([, prevV]) => prevV.containerId === v.containerId,
            );
            const [, lastAnimation] = prevAnimations.length
              ? prevAnimations[prevAnimations.length - 1]
              : [null, null];
            const delayStartTime =
              v.delayStartTime === null ||
              (lastAnimation?.endTime &&
                lastAnimation.endTime > v.delayStartTime)
                ? lastAnimation?.endTime ?? 0
                : v.delayStartTime;
            const endTime = duration ? delayStartTime + duration : null;
            return {
              ...acc,
              [k]: {
                ...v,
                delayStartTime,
                endTime,
              },
            };
          },
          {} as Record<string, Operation>,
        );
        const updatedNonAnimations = otherEntries.reduce(
          (acc, [k, v]) => ({
            ...acc,
            [k]: {
              ...v,
              endTime: calculateDuration(
                v,
                { ...operations, ...acc },
                animationLibrary,
              ),
            },
          }),
          {} as Record<string, Operation>,
        );

        yield* put(
          sliceReducer.actions.update({
            operations: {
              ...operations,
              ...updatedAnimations,
              ...updatedNonAnimations,
            },
          }),
        );
      },
    },

    resetOperationTimes: {
      sagaType: SagaType.TakeLatest,
      *fn(action: PayloadAction<ResetOperationTimes>) {
        const state: State = yield* select();
        const { containers, operations } = state.main.operationContainers;
        const { operationIds } = containers[action.payload.containerId];

        const resetOperations = operationIds.reduce(
          (acc, cur) => ({
            ...acc,
            [cur]: {
              ...operations[cur],
              delayStartTime: null,
            },
          }),
          {} as Record<string, Operation>,
        );

        yield* put(
          sliceReducer.actions.update({
            operations: {
              ...operations,
              ...resetOperations,
            },
          }),
        );

        // Self-referencing breaks typings unless we create a new variable referencing the current saga
        const currentSaga: {
          actions: { calculateOperationTimes: ActionCreatorWithoutPayload };
        } = sliceSaga;
        yield put(currentSaga.actions.calculateOperationTimes());
      },
    },
  },
});

export default sliceSaga;
