import { useEffect } from 'react';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

const useInitDatadog = () => {
  useEffect(() => {
    if (
      process.env.REACT_APP_DATADOG_ID &&
      process.env.REACT_APP_DATADOG_TOKEN
    ) {
      datadogLogs.init({
        clientToken: process.env.REACT_APP_DATADOG_TOKEN,
        forwardErrorsToLogs: false,
        sampleRate: 100,
        site: 'datadoghq.com',
        service: 'web-creator',
        env: process.env.REACT_APP_DATADOG_ENV,
      });

      datadogRum.init({
        applicationId: process.env.REACT_APP_DATADOG_ID,
        clientToken: process.env.REACT_APP_DATADOG_TOKEN,
        site: 'datadoghq.com',
        service: 'web-creator',
        env: process.env.REACT_APP_DATADOG_ENV,
        allowedTracingOrigins: [
          process.env.REACT_APP_ASSETS_API_URL
            ? process.env.REACT_APP_ASSETS_API_URL
            : '',
          process.env.REACT_APP_CREATOR_GENAI_API_URL
            ? process.env.REACT_APP_CREATOR_GENAI_API_URL
            : '',
          process.env.REACT_APP_CAM_API_URL
            ? process.env.REACT_APP_CAM_API_URL
            : '',
        ],
        sampleRate: 100,
        premiumSampleRate: 100,
        trackInteractions:
          process.env.REACT_APP_DATADOG_INTERACTIONS?.toLowerCase() === 'true',
        defaultPrivacyLevel: 'mask-user-input',
      });

      datadogRum.startSessionReplayRecording();
    }

    return () => {
      datadogRum.stopSessionReplayRecording();
    };
  }, []);
};

export default useInitDatadog;
